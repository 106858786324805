import React, { useEffect, useMemo, useState } from "react";
import FormInput from "../../Components/FormInput/FormInput";
import FormTextArea from "../../Components/FormTextArea/FormTextArea";
import PluginParameters from "../../Components/Plugin/PluginParameters";
import {
    ModelLocationModel,
    ModelLocationSiteDataModel,
    ModelLocationSiteModel,
    PluginModel
} from "../../types/models";
import { PluginFieldValue, PluginParameters as PluginFieldParameters, PluginScopes } from "../../types/plugin";
import ModelService from "../../Services/model.service";
import { Toast } from "../../Components/Toast";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UpdateModelLocationSiteRequest } from "../../types/requests";
import { isNilOrEmpty } from "../../utils/utils";
import { ParameterMergerFactory } from "../../plugin/mergers/parameter-merger-factory";
import { cloneDeep, forEach } from "lodash";
import Button from "../../Components/Button";
import { useConfirmation } from "../../Components/ConfirmDialog/ConfirmationContext";
import { ValueChange } from "./types";

interface ModelSystemLocationSiteProps {
    location: ModelLocationModel;
    site: ModelLocationSiteModel;
    dataFiles: ModelLocationSiteDataModel[];
    plugin: PluginModel;
    defaultParameters: PluginFieldParameters;
    readOnly: boolean;
    onSiteDeleted: (site: ModelLocationSiteModel) => void;
    onSiteUpdated: (site: ModelLocationSiteModel) => void;
    onParametersChanged: (site: ModelLocationSiteModel, parameters: PluginFieldParameters) => void;
    onFilesAdded: (files: ModelLocationSiteDataModel[]) => void;
    onFileUpdated: (file: ModelLocationSiteDataModel) => void;
    onFileRemoved: (file: ModelLocationSiteDataModel) => void;
}

const schema = yup.object().shape({
    name: yup.string().required("Site name is required"),
    description: yup.string().optional().nullable()
});

interface ModelLocationSiteForm {
    name: string;
    description: string;
}

const ModelSystemLocationSite = ({
    location,
    site,
    dataFiles,
    plugin,
    defaultParameters,
    readOnly,
    onSiteDeleted,
    onSiteUpdated,
    onParametersChanged,
    onFilesAdded,
    onFileUpdated,
    onFileRemoved
}: ModelSystemLocationSiteProps) => {
    const [parameters, setParameters] = useState<PluginFieldParameters>(site.parameters);
    const confirm = useConfirmation();

    const { getValues, setValue, control, setError, clearErrors } = useForm<ModelLocationSiteForm>({
        resolver: yupResolver(schema),
        mode: "onBlur",
        reValidateMode: "onBlur"
    });

    const handleValueChanged = async (definition: string, field: string, value: PluginFieldValue) => {
        const next = { ...parameters, [definition]: { ...(parameters?.[definition] ?? {}), [field]: value } };

        const result = await ModelService.updateModelLocationSiteParameters(
            location.modelId,
            location.id,
            site.id,
            next
        );

        if (!result.success) {
            Toast.error(result.failure.message);
            return;
        }

        setParameters(result.parameters);

        onParametersChanged(site, result.parameters);
    };

    const handleValuesChanged = async (changedValues: ValueChange[]) => {
        const next = { ...parameters };

        forEach(changedValues, value => {
            if (!(value.definition in next)) {
                next[value.definition] = parameters?.[value.definition] ?? {};
            }
            next[value.definition][value.field] = value.value;
        });

        const result = await ModelService.updateModelLocationSiteParameters(
            location.modelId,
            location.id,
            site.id,
            next
        );

        if (!result.success) {
            Toast.error(result.failure.message);
            return;
        }

        setParameters(result.parameters);

        onParametersChanged(site, result.parameters);
    };

    const handleFormChanged = async () => {
        clearErrors();

        const values = getValues();

        const request: UpdateModelLocationSiteRequest = {
            name: values.name,
            description: values.description
        };

        if (isNilOrEmpty(request.name)) {
            setError("name", { message: "Site name is required" });
            return;
        }

        const updatedSite = await ModelService.updateModelLocationSite(location.modelId, location.id, site.id, request);

        onSiteUpdated(updatedSite);
    };

    const handleSiteDelete = async () => {
        const result = await confirm({
            title: "Site delete",
            description: "You are about to delete a site and all its content. Do you wish to continue?"
        });

        if (!result.success) {
            return;
        }

        await ModelService.deleteModelLocationSite(location.modelId, location.id, site.id);

        Toast.success("Successfully deleted site");

        onSiteDeleted(site);
    };

    useEffect(() => {
        setValue("name", site.name);
        setValue("description", site.description);
    }, [site.id]);

    const _defaultParameters = useMemo(() => {
        return cloneDeep(defaultParameters);
    }, [defaultParameters]);

    const displayedParameters = useMemo(() => {
        const factory = new ParameterMergerFactory();
        const merger = factory.getMerger(plugin.schema.id);

        return merger.merge(parameters, _defaultParameters);
    }, [_defaultParameters, parameters]);

    return (
        <div className="model-system-location-site">
            <form>
                <Controller
                    name="name"
                    control={control}
                    render={({ field, formState }) => {
                        return (
                            <FormInput
                                labelText="Site name"
                                id="name"
                                name="name"
                                type="text"
                                defaultValue={field.value}
                                error={formState.errors?.name}
                                disabled={readOnly}
                                onBlur={e => {
                                    setValue("name", e.target.value);
                                    handleFormChanged();
                                }}
                            />
                        );
                    }}
                />

                <Controller
                    name="description"
                    control={control}
                    render={({ field, formState }) => {
                        return (
                            <FormTextArea
                                labelText="Site notes"
                                id="description"
                                name="description"
                                rows={5}
                                defaultValue={field.value}
                                error={formState.errors?.description}
                                disabled={readOnly}
                                onBlur={e => {
                                    setValue("description", e.target.value);
                                    handleFormChanged();
                                }}
                            />
                        );
                    }}
                />
            </form>

            <PluginParameters
                id={`${location.id}-${site.id}`}
                parameters={displayedParameters}
                schema={plugin.schema}
                disabled={readOnly}
                scope={PluginScopes.SITE}
                data={{
                    modelId: location.modelId,
                    locationId: location.id,
                    siteId: site.id,
                    siteName: site.name,
                    dataFiles: dataFiles
                }}
                onValueChanged={handleValueChanged}
                onValuesChanged={handleValuesChanged}
                onFilesAdded={onFilesAdded}
                onFileUpdated={onFileUpdated}
                onFileRemoved={onFileRemoved}
            />

            <Button variant="attention" disabled={readOnly} onClick={handleSiteDelete}>
                Delete Site
            </Button>
        </div>
    );
};

export default ModelSystemLocationSite;
