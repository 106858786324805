import React, { useState } from "react";
import Checkbox from "../../Components/Checkbox";
import { SystemModel } from "../../types/models";
import DateTimeCell from "./DateTimeCell";
import SystemRowActions from "./SystemRowActions";
import SystemIcon from "../../Components/ERPIcon/SystemIcon";
import SystemLinkCell from "./SystemLinkCell";
import LockedIcon from "./LockedIcon";
import { fullname } from "../../utils/utils";

interface SystemRowProps {
    system: SystemModel;
    isSelected: boolean;
    isLocked: boolean;
    onSelected: (system: SystemModel, selected: boolean) => void;
    onSystemMove: (system: SystemModel) => void;
    onSystemDelete: (system: SystemModel) => void;
    onSystemCopy: (system: SystemModel) => void;
    onSystemEdit: (system: SystemModel) => void;
}

const SystemRow = ({
    system,
    isSelected,
    isLocked,
    onSelected,
    onSystemMove,
    onSystemDelete,
    onSystemCopy,
    onSystemEdit
}: SystemRowProps) => {
    const [isHovering, setIsHovering] = useState<boolean>(false);

    const handleSelected = (selected: boolean) => {
        onSelected(system, selected);
    };

    const handleMove = () => {
        onSystemMove(system);
    };

    const handleDelete = () => {
        onSystemDelete(system);
    };

    const handleCopy = () => {
        onSystemCopy(system);
    };

    const handleEdit = () => {
        onSystemEdit(system);
    };

    return (
        <tr className="system-row" onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
            <td style={{ width: 50 }} className="selection-cell">
                <Checkbox checked={isSelected} onChecked={handleSelected} />
            </td>

            <SystemLinkCell id={system.id}>
                <SystemIcon />
            </SystemLinkCell>

            <SystemLinkCell id={system.id}>
                <div>
                    {system.name}

                    {isLocked && <LockedIcon type="System" owner={system.lock.owner} />}
                </div>
            </SystemLinkCell>

            <SystemLinkCell id={system.id} className="centered-cell status-cell">
                <div>System</div>
            </SystemLinkCell>

            <SystemLinkCell id={system.id} className="centered-cell status-cell">
                {fullname(system.createdBy)}
            </SystemLinkCell>

            <DateTimeCell dateTime={system.updatedAt} />

            <td className="action-cell">
                {isHovering && (
                    <SystemRowActions
                        canEdit={!isLocked}
                        canDelete={!isLocked}
                        onMove={handleMove}
                        onDelete={handleDelete}
                        onCopy={handleCopy}
                        onEdit={handleEdit}
                    />
                )}
            </td>
        </tr>
    );
};

export default SystemRow;
