import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ModelHeader from "./ModelHeader";
import ModelTabs, { ModelTabType, TabSelection } from "./ModelTabs";
import ModelExplore from "./ModelExplore";
import ModelGeneralInfo from "./ModelGeneralInfo";
import ModelLocationSettings from "./ModelLocationSettings";
import ModelSettings from "./ModelSettings";
import ModelContextProvider from "./ModelContext";
import { useResourceLocker } from "../../Hooks/useResourceLocker";
import { ResourceLockErrors, ResourceLockModel, ResourceType } from "../../types/models";
import { isNil } from "lodash";
import LoadingIndicator from "../../Components/LoadingIndicator";
import { useAuth0 } from "../../auth/react-auth0-spa";
import ResourceLockedWarning from "../../Components/ResourceLockedWarning";
import { AttachmentFileUploaderModalProvider } from "../../Components/FileUploader/AttachmentFileUploaderContext";
import { Toast } from "../../Components/Toast";

import "./Model.scss";
import { FileUploaderModalProvider } from "../../Components/FileUploader/FileUploaderContext";

const Model = () => {
    const { id } = useParams<{ id: string }>();
    const { erpUser } = useAuth0();
    const [activeTab, setActiveTab] = useState<TabSelection>({ index: 0, type: ModelTabType.GENERAL });
    const [resourceLock, setResourceLock] = useState<ResourceLockModel>(null);
    const { lock, unlock } = useResourceLocker();
    const history = useHistory();

    useEffect(() => {
        const doInit = async (modelId: string) => {
            const lock_result = await lock(modelId, ResourceType.MODEL);

            if (!lock_result.success) {
                if (lock_result.error?.code === ResourceLockErrors.ENTITY_NOT_FOUND) {
                    Toast.error("Model could not be found");
                    history.push("/");
                }

                return;
            }

            setResourceLock(lock_result.lock);
        };

        doInit(id);

        return () => {
            unlock(id);
        };
    }, [id]);

    const isLockOwner = useMemo(() => {
        if (isNil(resourceLock?.owner)) {
            return false;
        }

        return resourceLock.owner.id === erpUser.id;
    }, [resourceLock]);

    if (isNil(resourceLock)) {
        return <LoadingIndicator className="model-loading" centered />;
    }

    return (
        <FileUploaderModalProvider>
            <AttachmentFileUploaderModalProvider>
                <ModelContextProvider modelId={id} readOnly={!isLockOwner}>
                    <div className="model-container">
                        <ModelHeader />

                        {!isLockOwner && <ResourceLockedWarning resourceType="model" owner={resourceLock.owner} />}

                        <ModelTabs selectedTab={activeTab} onTabSelected={setActiveTab} />

                        {activeTab.type === ModelTabType.GENERAL && <ModelGeneralInfo />}

                        {activeTab.type === ModelTabType.MODEL_SETTINGS && <ModelSettings />}

                        {activeTab.type === ModelTabType.LOCATION_SETTINGS && <ModelLocationSettings />}

                        {activeTab.type === ModelTabType.EXPLORE && <ModelExplore />}
                    </div>
                </ModelContextProvider>
            </AttachmentFileUploaderModalProvider>
        </FileUploaderModalProvider>
    );
};

export default Model;
