import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { ChartOptions } from "chart.js";
import ERPChartAxisTabs, { ERPChartAxisTabType, TabSelection } from "../../Components/Charts/ERPChartAxisTabs";
import ERPLineTimeseriesChart from "../../Components/Charts/ERPLineTimeseriesChart";
import TooltipIcon from "../../Components/TooltipIcon";
import { merge } from "lodash";
import React, { useMemo, useState } from "react";
import { ERPTimerseriesChartData, ExplorePeriodModel, IntermediateNodeResult } from "../../types/models";
import { formatYearlyIntermediateChartData } from "../../utils/explorer.utils";

interface ModelExploreNodeIntermediateYearlyChartProps {
    results: IntermediateNodeResult;
    nodeTitle: string;
    minValue: number;
    maxValue: number;
    yearlyPeriod: ExplorePeriodModel;
}

const CHART_OPTIONS: ChartOptions<"line"> = {
    maintainAspectRatio: false,
    normalized: true,
    interaction: {
        intersect: true,
        mode: "index"
    },
    clip: false,
    layout: {
        padding: 5
    },
    plugins: {
        tooltip: {
            enabled: true
        },
        legend: {
            display: true,
            position: "top"
        },
        decimation: {
            enabled: true
        }
    },
    animation: false,
    scales: {
        x: {
            title: {
                display: true,
                text: "Year"
            },
            type: "timeseries",
            time: {
                unit: "year",
                displayFormats: {
                    year: "YYYY"
                },
                tooltipFormat: "YYYY"
            },
            grid: {
                display: false
            }
        },
        y: {
            type: "linear",
            display: true,
            position: "left",
            grid: {
                display: false
            }
        }
    },
    parsing: {
        xAxisKey: "date",
        yAxisKey: "value"
    }
};

const getDataAxisTypeOptions = (axisType: ERPChartAxisTabType): ChartOptions<"line"> => {
    return {
        scales: {
            y: {
                type: axisType as "linear" | "logarithmic",
                min: axisType === ERPChartAxisTabType.LOGARITHMIC ? 0.01 : null
            }
        }
    };
};
const ModelExploreNodeIntermediateYearlyChart = ({
    results,
    nodeTitle,
    yearlyPeriod
}: ModelExploreNodeIntermediateYearlyChartProps) => {
    const [axisTab, setAxisTab] = useState<TabSelection>({ index: 0, type: ERPChartAxisTabType.LINEAR });

    const chartOpts: ChartOptions<"line"> = useMemo(() => {
        return merge<ChartOptions<"line">, ChartOptions<"line">, ChartOptions<"line">>(
            {},
            CHART_OPTIONS,
            getDataAxisTypeOptions(axisTab.type)
        );
    }, [axisTab]);

    const chartData: ERPTimerseriesChartData = useMemo(() => {
        return formatYearlyIntermediateChartData(results, yearlyPeriod);
    }, [results, yearlyPeriod]);

    return (
        <>
            <h5>{nodeTitle}</h5>

            <div className="chart">
                <div className="chart-tools">
                    <div className="chart-options">
                        <ERPChartAxisTabs selectedTab={axisTab} onTabSelected={setAxisTab} />

                        <TooltipIcon
                            tooltip="Use shift+drag to zoom
                Double click to reset zoom
                Pan with the mouse"
                            icon={faQuestionCircle}
                            size={"2x"}
                            className="chart-tooltip"
                        />
                    </div>
                </div>

                <ERPLineTimeseriesChart
                    id={nodeTitle}
                    data={chartData}
                    options={chartOpts}
                    height={300}
                    canZoom={true}
                    persistLegendState={true}
                />
            </div>
        </>
    );
};

export default ModelExploreNodeIntermediateYearlyChart;
