import React from "react";
import { Attachment } from "../../types/models";
import FileUploaderModal, { AttachmentFileUploaderModalOptions } from "./AttachmentFileUploaderModal";

interface FileUploaderResult {
    success: boolean;
    data: Attachment;
}

const AttachmentFileUploaderModalContext = React.createContext<
    (options: AttachmentFileUploaderModalOptions) => Promise<FileUploaderResult>
>(Promise.reject);

export const useAttachmentFileUploader = () => React.useContext(AttachmentFileUploaderModalContext);

export const AttachmentFileUploaderModalProvider = ({ children }: { children: React.ReactNode }) => {
    const [options, setOptions] = React.useState<AttachmentFileUploaderModalOptions | null>(null);

    const awaitingPromiseRef = React.useRef<{
        resolve: (r: FileUploaderResult) => void;
        reject: () => void;
    }>();

    const openConfirmation = (opts: AttachmentFileUploaderModalOptions) => {
        setOptions(opts);

        return new Promise<FileUploaderResult>((resolve, reject) => {
            awaitingPromiseRef.current = { resolve, reject };
        });
    };

    const handleClose = () => {
        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.resolve({ success: false, data: null });
        }

        setOptions(null);
    };

    const handleFileUploaded = (data: Attachment) => {
        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.resolve({ success: true, data: data });
        }

        setOptions(null);
    };

    const isOpen = Boolean(options);

    return (
        <>
            <AttachmentFileUploaderModalContext.Provider value={openConfirmation}>
                {children}
            </AttachmentFileUploaderModalContext.Provider>

            {isOpen && (
                <FileUploaderModal show onFileUploaded={handleFileUploaded} onClose={handleClose} {...options} />
            )}
        </>
    );
};
