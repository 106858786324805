import React from "react";
import NumericInput from "../../Components/NumericInput";
import RadioButton from "../../Components/RadioButton";
import { Controller, useFormContext } from "react-hook-form";
import { FlowTypeOptions, FlowTypeTypes } from "../../types/plugin";
import { PluginFieldProps, PluginForm } from "./types";

interface PluginParameterFlowTypeProps extends PluginFieldProps {
    onChange?: (field: string, value: Record<string, string | number | boolean>) => void;
}

const PluginParameterFlowType = ({
    id,
    definition,
    field,
    control,
    disabled,
    onChange
}: PluginParameterFlowTypeProps) => {
    const { watch } = useFormContext<PluginForm>();

    const [flowType] = watch([`${definition.id}.${field.id}`]);

    const isFlowThreshold = flowType?.type === FlowTypeTypes.THRESHOLD;

    const handleFlowTypeThresholdChanged = (threshold: number) => {
        const currentType = flowType?.type;

        onChange("flow_type", { type: currentType, threshold: threshold });
    };

    const handleFlowTypeTypeChanged = (type: FlowTypeTypes) => {
        const currentThreshold = flowType?.threshold;

        onChange("flow_type", { type: type, threshold: currentThreshold });
    };

    const options: FlowTypeOptions = field.control.options as FlowTypeOptions;

    return (
        <div className="flow-type-parameter-field">
            <Controller
                control={control}
                name={`${definition.id}.${field.id}.type`}
                render={({ field: _field }) => {
                    return (
                        <div className="flow-type-radio-button-group">
                            <RadioButton
                                containerClassName="flow-type-radio-button"
                                label={`Use Median ${options.title}`}
                                checked={_field.value === FlowTypeTypes.MEDIAN}
                                disabled={disabled}
                                onChecked={() => handleFlowTypeTypeChanged(FlowTypeTypes.MEDIAN)}
                            />

                            <RadioButton
                                containerClassName="flow-type-radio-button"
                                label={`Use ${options.title} Threshold`}
                                checked={_field.value === FlowTypeTypes.THRESHOLD}
                                disabled={disabled}
                                onChecked={() => handleFlowTypeTypeChanged(FlowTypeTypes.THRESHOLD)}
                            />
                        </div>
                    );
                }}
            />

            <Controller
                control={control}
                name={`${definition.id}.${field.id}.threshold`}
                render={({ field: _field }) => {
                    return (
                        <NumericInput
                            id={id}
                            containerClassName="flow-type-input"
                            value={isFlowThreshold ? _field.value : null}
                            min={0}
                            disabled={disabled || !isFlowThreshold}
                            onChange={handleFlowTypeThresholdChanged}
                        />
                    );
                }}
            />
        </div>
    );
};

export default PluginParameterFlowType;
