import React from "react";
import { faCopy, faPencilAlt, faTrashAlt, faFileExport } from "@fortawesome/pro-regular-svg-icons";
import TooltipIcon from "../../Components/TooltipIcon";

interface FolderRowActionsProps {
    onEdit: () => void;
    onDelete: () => void;
    onCopy: () => void;
    onMove: () => void;
}

const FolderRowActions = ({ onEdit, onDelete, onCopy, onMove }: FolderRowActionsProps) => {
    return (
        <div className="action-container">
            <TooltipIcon icon={faPencilAlt} tooltip="Edit folder" onClick={onEdit} />
            <TooltipIcon icon={faTrashAlt} tooltip="Delete folder" onClick={onDelete} />
            <TooltipIcon icon={faCopy} tooltip="Copy folder" onClick={onCopy} />
            <TooltipIcon icon={faFileExport} tooltip="Move folder" onClick={onMove} />
        </div>
    );
};

export default FolderRowActions;
