import React from "react";
import Button from "../../Components/Button";
import { TaskModel } from "../../types/models";
import { isNil } from "lodash";
import TaskStatusDisplay from "../../Components/TaskStatusDisplay/TaskStatusDisplay";

interface PluginWaterholeRunToEmptyModalFooterProps {
    task: TaskModel;
    isRunning: boolean;
    onClose: () => void;
    onRun: () => void;
}

const PluginWaterholeRunToEmptyModalFooter = ({
    task,
    isRunning,
    onClose,
    onRun
}: PluginWaterholeRunToEmptyModalFooterProps) => {
    return (
        <div className="waterhole-optimisation-modal-footer">
            <div className="button-group">
                <Button className="control-button" onClick={onRun} disabled={isRunning}>
                    Run
                </Button>
                {!isNil(task) && <TaskStatusDisplay task={task} label="Computation" showProgress={true} />}
            </div>
            <div className="button-group">
                <Button variant="grey" onClick={onClose} disabled={isRunning}>
                    Close
                </Button>
            </div>
        </div>
    );
};

export default PluginWaterholeRunToEmptyModalFooter;
