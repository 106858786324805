import { ChartDataset, ChartOptions } from "chart.js";
import ERPChart from "../../Components/Charts/ERPChart";
import { merge } from "lodash";
import React, { useMemo, useState } from "react";
import { ERPChartData, ModelExploreScenario, SuccessType, SummaryResult } from "../../types/models";
import ModelExploreNodeTemporalSuccessChartLegend from "./ModelExploreNodeTemporalSuccessChartLegend";

interface ModelExploreNodeSuccessChartProps {
    selectedScenarios: ModelExploreScenario[];
    results: SummaryResult[];
    node: string;
}

const CHART_OPTIONS: ChartOptions = {
    maintainAspectRatio: false,
    interaction: {
        mode: "index"
    },
    indexAxis: "y",
    scales: {
        x: {
            grid: { display: false, drawBorder: false },
            ticks: {
                stepSize: 10,
                callback(tickValue) {
                    return tickValue;
                }
            },
            min: 0,
            max: 100,
            stacked: true
        },
        y: {
            grid: { display: false, drawBorder: false },
            stacked: true
        }
    },
    layout: {
        padding: 5
    },
    plugins: {
        tooltip: {
            enabled: true,
            position: "stackedBarPositioner",
            callbacks: {
                label: function (tooltipItem) {
                    return `${tooltipItem.dataset.label} ${tooltipItem.formattedValue} %`;
                }
            }
        },
        legend: {
            display: false
        },
        title: {
            display: true,
            position: "top",
            align: "start",
            text: "Default Title",
            font: {
                size: 14
            }
        }
    },
    hover: {
        mode: "index",
        intersect: false
    },
    animation: false
};

const ModelExploreNodeSuccessChart = ({ selectedScenarios, results, node }: ModelExploreNodeSuccessChartProps) => {
    const [selectedSuccess, setSelectedSuccess] = useState<SuccessType[]>([SuccessType.SUCCESS, SuccessType.FAILURE]);

    const options = useMemo(() => {
        return merge({}, CHART_OPTIONS, { plugins: { title: { text: node } } });
    }, [node, selectedScenarios]);

    const chartData: ERPChartData = useMemo(() => {
        const labels = selectedScenarios.map(s => s.name);

        const data: ChartDataset[] = [
            {
                label: "No Data",
                data: selectedScenarios.map(s => {
                    return results.find(r => r.scenarioId === s.id)?.results?.find(r => r.key === SuccessType.NONE)
                        ?.percent;
                }),
                backgroundColor: "#acacae",
                barThickness: 50
            },
            {
                label: "Success",
                data: selectedScenarios.map(s => {
                    return results.find(r => r.scenarioId === s.id)?.results?.find(r => r.key === SuccessType.SUCCESS)
                        ?.percent;
                }),
                backgroundColor: selectedSuccess.includes(SuccessType.SUCCESS) ? "#08bfdd" : "#efefef",
                barThickness: 50
            },
            {
                label: "Failure",
                data: selectedScenarios.map(s => {
                    return results.find(r => r.scenarioId === s.id)?.results?.find(r => r.key === SuccessType.FAILURE)
                        ?.percent;
                }),
                backgroundColor: selectedSuccess.includes(SuccessType.FAILURE) ? "#FF5349" : "#efefef",
                barThickness: 50
            }
        ];
        return { labels: labels, data: data };
    }, [selectedScenarios, selectedSuccess]);

    return (
        <div className="chart">
            <ModelExploreNodeTemporalSuccessChartLegend
                selectedSuccess={selectedSuccess}
                onSuccessChanged={setSelectedSuccess}
            />

            <ERPChart
                id={`success_chart_${node}`}
                type="bar"
                labels={chartData.labels}
                data={chartData.data}
                options={options}
                height={100 + 75 * selectedScenarios.length}
            />
        </div>
    );
};

export default ModelExploreNodeSuccessChart;
