import ERPSlider from "../../Components/Slider/Slider";
import { SliderValues } from "../../Components/Slider/types";
import React, { useEffect, useMemo, useState } from "react";
import {
    ModelExploreOpportunitiesResults,
    OpportunitiesSpatialResults,
    OpportunitiesNodeResult,
    ExplorePeriodModel,
    OpportunitiesResult
} from "../../types/models";
import {
    filterOpportunityResult,
    formatOpportunitiesNodeResults,
    formatOpportunitiesSpatialResults
} from "../../utils/explorer.utils";
import ModelExploreNodeTemporalSuccessCharts from "./ModelExploreNodeTemporalSuccessCharts";
import ModelExploreTemporalRiskChart from "./ModelExploreTemporalRiskChart";
import { useModel } from "./ModelContext";
import LoadingIndicator from "../../Components/LoadingIndicator";
import { isNil } from "lodash";

const ModelExploreOpportunities = () => {
    const { model, explorerFlags, explorerScenarios, loadExplorerAssessmentData } = useModel();

    const [selectedResultPeriod, setSelectedResultPeriod] = useState<ExplorePeriodModel>(null);

    const selectedScenarios = explorerScenarios.filter(s => s.isSelected);

    const hasLoadedAssessment = selectedScenarios.every(s => s.hasLoadedAssessment);

    const exploreData: ModelExploreOpportunitiesResults = useMemo(() => {
        if (!hasLoadedAssessment) {
            return;
        }

        const spatialResults: OpportunitiesSpatialResults = formatOpportunitiesSpatialResults(selectedScenarios);
        const nodeResults: OpportunitiesNodeResult[] = formatOpportunitiesNodeResults(
            selectedScenarios,
            spatialResults.resultPeriod
        );

        if (isNil(selectedResultPeriod)) {
            setSelectedResultPeriod(spatialResults.resultPeriod);
        }

        return {
            spatialResults: spatialResults.scenarioResults,
            nodeResults: nodeResults,
            resultPeriod: spatialResults.resultPeriod,
            model: model
        };
    }, [explorerScenarios, hasLoadedAssessment]);

    useEffect(() => {
        loadExplorerAssessmentData(selectedScenarios.map(s => s.id));
    }, [selectedScenarios]);

    const viewableExploreData: ModelExploreOpportunitiesResults = useMemo(() => {
        if (isNil(exploreData) || isNil(selectedResultPeriod)) {
            return;
        }

        const nextSpatialResults: OpportunitiesResult[] = exploreData.spatialResults.map(scenario => {
            return filterOpportunityResult(scenario, selectedResultPeriod);
        });

        const nextNodeResults: OpportunitiesNodeResult[] = exploreData.nodeResults.map(node => {
            return {
                ...node,
                results: node.results.map(s => {
                    return filterOpportunityResult(s, selectedResultPeriod);
                })
            };
        });

        const next = {
            ...exploreData,
            resultPeriod: selectedResultPeriod,
            spatialResults: nextSpatialResults,
            nodeResults: nextNodeResults
        };

        return next;
    }, [exploreData, selectedResultPeriod]);

    const hasResults = hasLoadedAssessment && !isNil(viewableExploreData);

    const handleSelectedPeriodChange = (newValues: SliderValues) => {
        setSelectedResultPeriod({ ...selectedResultPeriod, startYear: newValues.from, endYear: newValues.to });
    };

    return (
        <div className="explore-summary">
            {explorerFlags.isLoadingAssessment && <LoadingIndicator centered={true} />}

            {hasResults && (
                <>
                    <ERPSlider
                        containerClassName="explore-slider"
                        values={{ from: selectedResultPeriod.startYear, to: selectedResultPeriod.endYear }}
                        min={exploreData.resultPeriod.startYear}
                        max={exploreData.resultPeriod.endYear}
                        marks={exploreData.resultPeriod.sliderMarks}
                        title="Years"
                        onChange={handleSelectedPeriodChange}
                    />

                    <ModelExploreTemporalRiskChart
                        selectedScenarios={selectedScenarios}
                        resultsData={viewableExploreData}
                        title="Time series of risk across all locations"
                    />

                    <ModelExploreNodeTemporalSuccessCharts
                        selectedScenarios={selectedScenarios}
                        resultsData={viewableExploreData}
                        title="Time series of opportunities at nodes (or sites)"
                    />
                </>
            )}
        </div>
    );
};

export default ModelExploreOpportunities;
