import { ChartOptions } from "chart.js";
import ERPChart from "../../Components/Charts/ERPChart";
import { merge } from "lodash";
import React, { useMemo } from "react";
import {
    ERPChartData,
    ExploreDailyPeriodModel,
    ExplorePeriodModel,
    IntermediateNodeResult,
    IntermediateNodeScenarioResult
} from "../../types/models";
import { formatDailyIntermediateChartData } from "../../utils/explorer.utils";
import { isNilOrEmpty } from "../../utils/utils";
import RenderIfVisible from "react-render-if-visible";

interface ModelExploreNodeIntermediateDailyChartProps {
    results: IntermediateNodeResult;
    nodeTitle: string;
    minValue: number;
    maxValue: number;
    yearlyPeriod: ExplorePeriodModel;
    dailyPeriod: ExploreDailyPeriodModel;
}

const CHART_HEIGHT = 350;

const CHART_OPTIONS: ChartOptions = {
    maintainAspectRatio: false,
    interaction: {
        mode: "index"
    },
    scales: {
        x: {
            grid: { display: false, drawBorder: false },
            stacked: true,
            type: "timeseries",
            time: {
                unit: "month",
                displayFormats: {
                    month: "MMM"
                },
                tooltipFormat: "DD MMMM"
            },
            ticks: {
                minRotation: 0,
                maxRotation: 0,
                sampleSize: 1
            }
        },
        y: {
            grid: { display: false, drawBorder: false },
            display: true,
            stacked: true,
            ticks: {
                stepSize: 1
            }
        }
    },
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: true,
            position: "left",
            align: "center",
            font: {
                size: 20
            }
        },
        tooltip: {
            enabled: true,
            intersect: true,
            mode: "point",
            position: "stackedBarPositioner"
        }
    },
    hover: {
        mode: null,
        intersect: false
    },
    animation: false,
    normalized: true,
    layout: {
        padding: { top: 50 }
    }
};

interface ScenarioChartData {
    chartData: ERPChartData;
    scenarioName: string;
    result: IntermediateNodeScenarioResult;
}

const ModelExploreNodeIntermediateDailyChart = ({
    results,
    nodeTitle,
    minValue,
    maxValue,
    yearlyPeriod,
    dailyPeriod
}: ModelExploreNodeIntermediateDailyChartProps) => {
    const chartData: ScenarioChartData[] = useMemo(() => {
        return Object.keys(results).map(scenario => {
            const result = results[scenario];
            return {
                chartData: formatDailyIntermediateChartData(result, minValue, maxValue, yearlyPeriod, dailyPeriod),
                scenarioName: scenario,
                result: result
            };
        });
    }, [results, yearlyPeriod, dailyPeriod]);

    return (
        <>
            <h5>{nodeTitle}</h5>

            {chartData.map((d, i) => {
                return (
                    <div className="chart" key={i}>
                        <RenderIfVisible defaultHeight={CHART_HEIGHT}>
                            <ERPChart
                                id={`daily_intermediate_chart_${i}`}
                                type="bar"
                                labels={d.chartData.labels}
                                data={d.chartData.data}
                                options={merge({}, CHART_OPTIONS, {
                                    plugins: {
                                        title: {
                                            text: d.scenarioName
                                        },
                                        tooltip: {
                                            callbacks: {
                                                title: function (tooltipItems) {
                                                    const label = !isNilOrEmpty(tooltipItems)
                                                        ? tooltipItems[0]?.label
                                                        : null;
                                                    const year = !isNilOrEmpty(tooltipItems)
                                                        ? tooltipItems[0]?.dataset?.label
                                                        : null;
                                                    return `${label} ${year}`;
                                                },
                                                label: function (tooltipItem) {
                                                    const year = tooltipItem.dataset.label;
                                                    const dayIndex = tooltipItem.dataIndex;

                                                    return `Result: ${d.result[year][dayIndex]?.value ?? "None"}`;
                                                }
                                            }
                                        }
                                    },
                                    scales: {
                                        y: {
                                            ticks: {
                                                callback: function (_, index) {
                                                    const adjustedIndex = index - 1;

                                                    if (adjustedIndex === d.chartData.data.length - 1) {
                                                        return d.chartData.data[adjustedIndex]?.label;
                                                    }

                                                    if (index === 0) {
                                                        const year = +d.chartData.data[index]?.label;
                                                        return `${year + 1}`;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                })}
                                height={CHART_HEIGHT}
                            />
                        </RenderIfVisible>
                    </div>
                );
            })}
        </>
    );
};

export default ModelExploreNodeIntermediateDailyChart;
