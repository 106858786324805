import React, { useEffect } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UpdateSystemRequest } from "../../types/requests";
import SystemService from "../../Services/system.service";
import { sleep } from "../../utils/utils";
import FormInput from "../../Components/FormInput/FormInput";
import FormTextArea from "../../Components/FormTextArea/FormTextArea";
import { useSystem } from "./SystemContext";
import { debounce } from "lodash";

const schema = yup.object().shape({
    id: yup.string().required("Id is required"),
    name: yup.string().required("Name is required"),
    description: yup.string().optional().nullable()
});

interface SystemForm {
    id: string;
    name: string;
    description: string;
}

const DEBOUCE_TRIGGER_SAVE = 500;

const SystemGeneralInfo = () => {
    const { system, readOnly, setSaving, updateSystem } = useSystem();

    const {
        register,
        watch,
        setValue,
        formState: { errors }
    } = useForm<SystemForm>({
        resolver: yupResolver(schema),
        mode: "onBlur",
        reValidateMode: "onSubmit"
    });

    useEffect(() => {
        setValue("id", system.id);
        setValue("name", system.name);
        setValue("description", system.description);
    }, [system.id]);

    const saveSystem = async (form: SystemForm) => {
        try {
            setSaving(true);

            const request: UpdateSystemRequest = {
                name: form.name,
                description: form.description
            };

            const updatedSystem = await SystemService.updateSystem(form.id, request);

            updateSystem(updatedSystem);
        } finally {
            await sleep(250);

            setSaving(false);
        }
    };

    useEffect(() => {
        if (readOnly) {
            return;
        }

        const subscription = watch(debounce(saveSystem, DEBOUCE_TRIGGER_SAVE));

        return () => subscription.unsubscribe();
    }, [watch, readOnly]);

    return (
        <form>
            <div className="system-section system-info-container">
                <div className="system-info">
                    <FormInput
                        labelText="Name"
                        id="name"
                        name="name"
                        type="text"
                        register={register("name")}
                        error={errors?.name}
                        disabled={readOnly}
                    />

                    <FormTextArea
                        labelText="Description"
                        id="description"
                        name="description"
                        rows={5}
                        register={register("description")}
                        error={errors?.description}
                        disabled={readOnly}
                    />
                </div>
            </div>
        </form>
    );
};

export default SystemGeneralInfo;
