import React, { useEffect, useMemo, useState } from "react";
import FileSelectorDrag from "../../Components/FileSelector/FileSelectorDrag";
import ModelService from "../../Services/model.service";
import { DataType, ModelLocationSiteOptimisationDataModel } from "../../types/models";
import clsx from "clsx";
import DataFile from "../DataFile";
import { ObservedDataSettings } from "../FileSelector/types";
import { useFileUploader } from "../FileUploader/FileUploaderContext";

interface PluginWaterholeOptimisationDataProps {
    /**
     * Unique identifier to make sure this input is uniquely rendered amongst other plugin parameters.
     * This is important to avoid lose of focus on re-render caused by state change during parameter saving.
     */
    id: string;
    modelId: string;
    locationId: string;
    siteId: string;
    optimisationId: string;
    allowedDataTypes: DataType[];
    className?: string;
    disabled?: boolean;
}

const PluginWaterholeOptimisationData = ({
    modelId,
    locationId,
    siteId,
    optimisationId,
    allowedDataTypes,
    className,
    disabled
}: PluginWaterholeOptimisationDataProps) => {
    const [dataFiles, setDataFiles] = useState<ModelLocationSiteOptimisationDataModel[]>([]);

    const uploader = useFileUploader();

    useEffect(() => {
        const fetchData = async () => {
            const data = await ModelService.getModelLocationSiteOptimisationData(
                modelId,
                locationId,
                siteId,
                optimisationId
            );
            setDataFiles(data);
        };

        fetchData();
    }, [optimisationId]);

    const handleFileSelected = async (files: File[]) => {
        const work = files.map(f => {
            return (onProgress: (progress: number) => void) => {
                return ModelService.uploadModelLocationSiteOptimisationData(
                    modelId,
                    locationId,
                    siteId,
                    optimisationId,
                    f,
                    null,
                    false,
                    null,
                    onProgress
                );
            };
        });

        const result = await uploader({ fileUploads: work });

        if (!result.success) {
            return;
        }

        const next = [...dataFiles, ...(result.data as ModelLocationSiteOptimisationDataModel[])];
        setDataFiles(next);
    };

    const handleDataRemoved = async (dataFile: ModelLocationSiteOptimisationDataModel) => {
        await ModelService.deleteModelLocationSiteOptimisationData(
            modelId,
            locationId,
            siteId,
            optimisationId,
            dataFile.id
        );

        const next = dataFiles.filter(d => d.id !== dataFile.id);
        setDataFiles(next);
    };

    const handleDataTypeChanged = async (dataFile: ModelLocationSiteOptimisationDataModel, dataType: DataType) => {
        if (dataFile.dataType === dataType) {
            return;
        }

        await ModelService.updateModelLocationSiteOptimisationDataOptions(
            modelId,
            locationId,
            siteId,
            optimisationId,
            dataFile.id,
            dataType
        );

        const next = dataFiles.map(df => {
            if (df.id === dataFile.id) {
                return {
                    ...df,
                    dataType: dataType
                };
            }

            return df;
        });

        setDataFiles(next);
    };

    const handleObservedDataFileUpdated = async (
        _data: ModelLocationSiteOptimisationDataModel,
        file: File,
        connectionSettings: ObservedDataSettings
    ) => {
        const dataType = !dataFiles.some(d => d.id !== _data.id && d.dataType === connectionSettings.dataType)
            ? connectionSettings.dataType
            : null;

        const work = [file].map(f => {
            return (onProgress: (progress: number) => void) => {
                return ModelService.updateModelLocationSiteOptimisationDataFile(
                    modelId,
                    locationId,
                    siteId,
                    optimisationId,
                    _data.id,
                    f,
                    dataType,
                    true,
                    connectionSettings,
                    onProgress
                );
            };
        });

        const result = await uploader({ fileUploads: work });

        if (!result.success) {
            return;
        }

        const newData = result.data[0] as ModelLocationSiteOptimisationDataModel;

        const next = dataFiles.map(df => {
            if (df.id === newData.id) {
                return newData;
            }

            return df;
        });

        setDataFiles(next);
    };

    const handleObservedDataFileSelected = async (file: File, connectionSettings: ObservedDataSettings) => {
        const dataType = !dataFiles.some(d => d.dataType === connectionSettings.dataType)
            ? connectionSettings.dataType
            : null;

        const work = [file].map(f => {
            return (onProgress: (progress: number) => void) => {
                return ModelService.uploadModelLocationSiteOptimisationData(
                    modelId,
                    locationId,
                    siteId,
                    optimisationId,
                    f,
                    dataType,
                    true,
                    connectionSettings,
                    onProgress
                );
            };
        });

        const result = await uploader({ fileUploads: work });

        if (!result.success) {
            return;
        }

        const next = [...dataFiles, ...(result.data as ModelLocationSiteOptimisationDataModel[])];

        setDataFiles(next);
    };

    const avaliableDataTypes: DataType[] = useMemo(() => {
        const usedDataTypes = dataFiles.map(d => d.dataType);
        return allowedDataTypes.filter(d => !usedDataTypes.some(ud => ud === d));
    }, [dataFiles]);

    return (
        <div className={clsx("data-upload", className)}>
            <h5>Optimisation Data</h5>

            {dataFiles.map(dataFile => {
                return (
                    <DataFile
                        avaliableDataTypes={avaliableDataTypes}
                        key={dataFile.id}
                        data={dataFile}
                        disabled={disabled}
                        className="model-location-site-optimisation--data"
                        onDataTypeChanged={handleDataTypeChanged}
                        onDataRemoved={handleDataRemoved}
                        onObservedDataFileUpdated={handleObservedDataFileUpdated}
                    />
                );
            })}

            <FileSelectorDrag
                className="waterhole-optimisation-data-upload"
                allowMultiple={true}
                accept=".csv"
                disabled={disabled}
                onFileSelected={handleFileSelected}
                onObservedDataFileSelected={handleObservedDataFileSelected}
            />
        </div>
    );
};

export default PluginWaterholeOptimisationData;
