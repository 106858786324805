import { forEach, isNaN, isNil, round } from "lodash";
import moment from "moment";
import {
    ERPChartData,
    ExploreDailyPeriodModel,
    ExplorePeriodModel,
    IntermediateNodeResult,
    IntermediateNodeScenarioResult,
    ModelExploreIntermediateResultType,
    ModelExploreIntermediateResultTypeScale,
    ModelExploreIntermediateResults,
    ModelExploreScenario,
    OpportunitiesNodeResult,
    OpportunitiesResult,
    OpportunitiesSpatialResults,
    RiskLevel,
    ScenarioResultType,
    SuccessType,
    SummaryNodeResult,
    SummaryResult,
    TimeseriesDay,
    ModelExploreResult
} from "../types/models";
import { isNilOrEmpty } from "./utils";
import Humanize from "humanize-plus";

const UNSELECTABLE_INTERMEDIATE_RESULT_TYPES = [
    "node",
    "date",
    "year",
    "flow",
    "depth",
    "rainfall",
    "evaporation",
    "salinity",
    "temperature",
    "risk",
    "recruitment",
    "failure_reason",
    "failure_reasons",
    "prevailing_climate",
    "start_date",
    "end_date",
    "measure"
];

const HEATMAP_MIN_COLOUR = { r: 255, g: 83, b: 73 };
const HEATMAP_MID_COLOUR = { r: 253, g: 198, b: 7 };
const HEATMAP_MAX_COLOUR = { r: 8, g: 191, b: 221 };

interface ChartDataset {
    label: string;
    data: TimeseriesDay[];
    backgroundColor: string;
    pointRadius: number;
    borderWidth: number;
    borderColor: string;
    pointHitRadius: number;
}

const CHART_LEGEND_COLOUR_OPTIONS = [
    "#FF671B",
    "#08BFDD",
    "#E24999",
    "#FDCD06",
    "#77BC1F",
    "#D2272E",
    "#0076BB",
    "#8547AD",
    "#FFA400",
    "#00994D"
];

const padMissingDataResultsInplace = (results: OpportunitiesResult[], resultPeriod: ExplorePeriodModel) => {
    const range = resultPeriod.endYear - resultPeriod.startYear;

    forEach(results, function (result, idx) {
        for (let i = 0; i < range; i++) {
            const year = resultPeriod.startYear + i;
            if (isNil(result[year])) {
                result[year] = { label: null, data: [] };
                const data = new Array(results.length).fill(0);
                data[idx] = 1;
                result[year].data = data;
            }
        }
    });
};

const getResultPeriod = (results: OpportunitiesResult[]): ExplorePeriodModel => {
    const resultYears = results.map(r => Object.keys(r).map(yr => parseInt(yr))).flat();
    const uniqueYears = [...new Set(resultYears)].sort();

    const startYear = uniqueYears[0];
    const endYear = uniqueYears[uniqueYears.length - 1];

    return {
        startYear: startYear,
        endYear: endYear,
        years: uniqueYears,
        sliderMarks: uniqueYears.filter(y => y % 10 === 0)
    };
};

export const formatOpportunitiesSpatialResults = (scenarios: ModelExploreScenario[]): OpportunitiesSpatialResults => {
    const results: OpportunitiesResult[] = [];

    forEach(scenarios, function (scenario, i) {
        const scenarioResult: OpportunitiesResult = {};
        forEach(scenario.result.assessment.spatial.rows, function (result) {
            const year = parseInt(result.year);
            const label = !isNilOrEmpty(result.risk) ? result.risk : null;

            scenarioResult[year] = { label: label as RiskLevel, data: [] };
            const data = new Array(scenarios.length).fill(0);
            data[i] = 1;
            scenarioResult[year].data = data;
        });
        results.push(scenarioResult);
    });

    const resultPeriod: ExplorePeriodModel = getResultPeriod(results);
    padMissingDataResultsInplace(results, resultPeriod);
    return {
        scenarioResults: results,
        resultPeriod: resultPeriod
    };
};

export const formatDailyIntermediateResults = (
    scenarios: ModelExploreScenario[],
    intermediateResultType: ModelExploreIntermediateResultType
): ModelExploreIntermediateResults => {
    const nodeScenarioYearlyResults = {};
    const dates = buildYearTimeseriesDates();
    let minValue = null;
    let maxValue = null;
    const yearsSet = new Set<number>();

    forEach(scenarios, s => {
        forEach(s.result.intermediate[intermediateResultType.scale].rows, day => {
            // Get all required params
            const node = day.node;
            const scenario = s.name;
            const date = moment(day.date, "DD/MM/YYYY").toDate();
            const value = isNaN(parseFloat(day[intermediateResultType.id]))
                ? null
                : parseFloat(day[intermediateResultType.id]);
            const year = date.getFullYear();

            //Update min/max values
            minValue = isNil(minValue) || value < minValue ? value : minValue;
            maxValue = isNil(maxValue) || value > maxValue ? value : maxValue;

            //Add year
            yearsSet.add(year);

            // Build data structure
            if (isNil(nodeScenarioYearlyResults[node])) {
                nodeScenarioYearlyResults[node] = {};
            }

            if (isNil(nodeScenarioYearlyResults[node][scenario])) {
                nodeScenarioYearlyResults[node][scenario] = {};
            }

            if (isNil(nodeScenarioYearlyResults[node][scenario][year])) {
                nodeScenarioYearlyResults[node][scenario][year] = [...dates];
            }

            // Get matching date
            const dateIndex = nodeScenarioYearlyResults[node][scenario][year].findIndex(d =>
                areDatesEqual(date, d.date)
            );

            // Push result
            nodeScenarioYearlyResults[node][scenario][year][dateIndex] = { date: date, value: value };
        });
    });

    // Create results period
    const allYears = [...yearsSet].sort();
    const startYear = allYears[0];
    const endYear = allYears[allYears.length - 1];

    const allDays = Array.from({ length: 366 }, (_, i) => i + 1);

    minValue = minValue ?? 0;
    maxValue = maxValue ?? 1;

    if (0 <= minValue && minValue <= 1 && 0 <= maxValue && maxValue <= 1) {
        minValue = 0;
        maxValue = 1;
    }

    return {
        nodeResults: nodeScenarioYearlyResults,
        minValue: minValue,
        maxValue: maxValue,
        yearlyResultPeriod: {
            startYear: startYear,
            endYear: endYear,
            years: allYears,
            sliderMarks: allYears.filter(y => y % 10 === 0)
        },
        dailyResultPeriod: {
            startDay: 1,
            endDay: 366,
            days: allDays,
            sliderMarks: allDays.filter(d => d % 30 === 0)
        },
        resultsType: intermediateResultType
    };
};

export const formatYearlyIntermediateResults = (
    scenarios: ModelExploreScenario[],
    intermediateResultType: ModelExploreIntermediateResultType
): ModelExploreIntermediateResults => {
    const nodeScenarioYearlyResults = {};
    const yearsSet = new Set<number>();

    forEach(scenarios, s => {
        forEach(s.result.intermediate[intermediateResultType.scale].rows, yearRow => {
            // Get all required params
            const node = yearRow.node;
            const scenario = s.name;
            const date = moment(yearRow.year, "YYYY").toDate();
            const value = +yearRow[intermediateResultType.id];

            //Add year
            yearsSet.add(date.getFullYear());

            // Build data structure
            if (isNil(nodeScenarioYearlyResults[node])) {
                nodeScenarioYearlyResults[node] = {};
            }

            if (isNil(nodeScenarioYearlyResults[node][scenario])) {
                nodeScenarioYearlyResults[node][scenario] = [];
            }

            nodeScenarioYearlyResults[node][scenario].push({ date: date, value: value });
        });
    });

    // Create results period
    const allYears = [...yearsSet].sort();
    const startYear = allYears[0];
    const endYear = allYears[allYears.length - 1];

    return {
        nodeResults: nodeScenarioYearlyResults,
        yearlyResultPeriod: {
            startYear: startYear,
            endYear: endYear,
            years: allYears,
            sliderMarks: allYears.filter(y => y % 10 === 0)
        },
        resultsType: intermediateResultType
    };
};

export const formatOpportunitiesNodeResults = (
    scenarios: ModelExploreScenario[],
    resultPeriod: ExplorePeriodModel
): OpportunitiesNodeResult[] => {
    const results = [];
    const nodes: string[] = [];

    forEach(scenarios, function (scenario, i) {
        const scenarioResult: any = {};
        forEach(scenario.result.assessment.temporal.rows, function (result) {
            const node = result.node;
            const year = parseInt(result.year);
            const label = !isNilOrEmpty(result.success) ? formatSuccess(result.success) : null;

            if (isNil(scenarioResult[node])) {
                scenarioResult[node] = {};
                if (!nodes.includes(node)) {
                    nodes.push(node);
                }
            }

            scenarioResult[node][year] = { label: label, data: [] };
            const data = new Array(scenarios.length).fill(0);
            data[i] = 1;
            scenarioResult[node][year].data = data;
        });
        results.push(scenarioResult);
    });

    const nodeResults = nodes.map(n => {
        const nodeResult = {
            name: n,
            results: results.map(r => r[n])
        };
        padMissingDataResultsInplace(nodeResult.results, resultPeriod);
        return nodeResult;
    });

    return nodeResults;
};

const formatSuccess = (success: string) => {
    switch (success) {
        case "1":
            return SuccessType.SUCCESS;
        case "0":
            return SuccessType.FAILURE;
        default:
            return SuccessType.NONE;
    }
};

const formatRisk = (risk: string) => {
    switch (risk) {
        case "high":
            return RiskLevel.HIGH;
        case "moderate":
            return RiskLevel.MODERATE;
        case "low":
            return RiskLevel.LOW;
        default:
            return RiskLevel.NONE;
    }
};

export const formatSummarySpatialResults = (scenarios: ModelExploreScenario[]): SummaryResult[] => {
    const results = [];

    forEach(scenarios, function (scenario) {
        const scenarioResult = {
            scenarioId: scenario.id,
            results: []
        };

        const uniqueKeys = [...new Set(scenario.result.assessment.spatial.rows.map(yr => yr.risk))];
        const numYears = scenario.result.assessment.spatial.rows.length;

        scenarioResult.results = uniqueKeys.map(k => {
            return {
                key: formatRisk(k),
                percent: round(
                    (scenario.result.assessment.spatial.rows.filter(yr => yr.risk === k).length / numYears) * 100,
                    1
                )
            };
        });

        results.push(scenarioResult);
    });

    return results;
};

export const formatSummaryNodeResults = (scenarios: ModelExploreScenario[]): SummaryNodeResult[] => {
    const nodeResults = [];

    forEach(scenarios, function (scenario) {
        const _nodes = [...new Set(scenario.result.assessment.temporal.rows.map(yr => yr.node))];

        forEach(_nodes, function (node) {
            if (!nodeResults.some(r => r.name === node)) {
                nodeResults.push({
                    name: node,
                    results: []
                });
            }
            const scenarioResult = {
                scenarioId: scenario.id,
                results: []
            };

            const nodeResult = scenario.result.assessment.temporal.rows
                ?.filter(nr => nr.node === node)
                .map(r => {
                    return { ...r, success: formatSuccess(r.success) };
                });
            const uniqueKeys = [...new Set(nodeResult.map(yr => yr.success))];
            const numYears = nodeResult.length;

            scenarioResult.results = uniqueKeys.map(k => {
                return {
                    key: k,
                    percent: round((nodeResult.filter(yr => yr.success === k).length / numYears) * 100, 1)
                };
            });

            nodeResults.find(r => r.name === node).results.push(scenarioResult);
        });
    });

    return nodeResults;
};

export const calcPercentDiff = (baselineResult: SummaryResult, scenarioResult: SummaryResult, key: RiskLevel) => {
    const baselineResultPercent = baselineResult?.results?.find(r => r.key === key)?.percent ?? 0;
    const scenarioResultPercent = scenarioResult?.results?.find(r => r.key === key)?.percent ?? 0;

    return scenarioResultPercent - baselineResultPercent;
};

export const mapChartKeyToColour = (key: RiskLevel | SuccessType): string => {
    switch (key) {
        case RiskLevel.HIGH:
            return "#FF5349";
        case RiskLevel.MODERATE:
            return "#fdcd06";
        case RiskLevel.LOW:
            return "#08bfdd";
        case SuccessType.FAILURE:
            return "#FF5349";
        case SuccessType.SUCCESS:
            return "#08bfdd";
        default:
            return "#acacae";
    }
};

export const filterOpportunityResult = (result: OpportunitiesResult, period: ExplorePeriodModel) => {
    const nextScenario: OpportunitiesResult = {};
    for (const [year, data] of Object.entries(result)) {
        if (parseInt(year) >= period.startYear && parseInt(year) <= period.endYear) {
            nextScenario[year] = data;
        }
    }
    return nextScenario;
};

export const formatOpportunitiesChartData = (
    scenarios: ModelExploreScenario[],
    results: OpportunitiesResult[]
): ERPChartData => {
    const labels = scenarios.map(s => s.name);
    const _data = [];

    for (const scenarioResults of Object.values(results)) {
        const scenarioResult = [];
        for (const [year, yearResult] of Object.entries(scenarioResults)) {
            const yearAsNumber = parseInt(year);

            if (scenarioResult[scenarioResult.length - 1]?.label === yearResult.label) {
                scenarioResult[scenarioResult.length - 1].data = scenarioResult[scenarioResult.length - 1].data.map(
                    (num, i) => {
                        return num + yearResult.data[i];
                    }
                );
                scenarioResult[scenarioResult.length - 1].context.toYear = yearAsNumber;
            } else {
                scenarioResult.push({
                    label: yearResult.label,
                    data: yearResult.data,
                    backgroundColor: mapChartKeyToColour(yearResult.label),
                    context: { fromYear: yearAsNumber, toYear: yearAsNumber }
                });
            }
        }
        _data.push(...scenarioResult);
    }
    return { labels: labels, data: _data };
};

export const formatDailyIntermediateChartData = (
    result: IntermediateNodeScenarioResult,
    minValue: number,
    maxValue: number,
    yearlyPeriod: ExplorePeriodModel,
    dailyPeriod: ExploreDailyPeriodModel
): ERPChartData => {
    const data = [];
    const dailyPeriodLength = dailyPeriod.endDay - dailyPeriod.startDay + 1;

    forEach(Object.keys(result), year => {
        if (+year < yearlyPeriod.startYear || +year > yearlyPeriod.endYear) {
            return;
        }

        const adjustedYearResult = result[year].slice(dailyPeriod.startDay - 1, dailyPeriod.endDay);

        data.push({
            label: year,
            data: new Array(dailyPeriodLength).fill(1),
            backgroundColor: generateHeatmapColours(adjustedYearResult, minValue, maxValue),
            barPercentage: 1.0,
            categoryPercentage: 1.0
        });
    });

    return {
        labels: buildYearTimeseriesDates()
            .map(d => d.date)
            .slice(dailyPeriod.startDay - 1, dailyPeriod.endDay),
        data: data.reverse()
    };
};

const formatChartDataObject = (label: string, data: TimeseriesDay[], colour: string): ChartDataset => {
    return {
        label: label,
        data: data,
        backgroundColor: colour,
        pointRadius: 0,
        borderWidth: 2,
        borderColor: colour,
        pointHitRadius: 10
    };
};

export const formatYearlyIntermediateChartData = (
    result: IntermediateNodeResult,
    yearlyPeriod: ExplorePeriodModel
): ChartDataset[] => {
    return Object.keys(result).map((scenario, i) => {
        const scenarioResult = result[scenario] as TimeseriesDay[];

        return formatChartDataObject(
            scenario,
            scenarioResult.filter(
                d => d.date.getFullYear() >= yearlyPeriod.startYear && d.date.getFullYear() <= yearlyPeriod.endYear
            ),
            CHART_LEGEND_COLOUR_OPTIONS[i % CHART_LEGEND_COLOUR_OPTIONS.length]
        );
    });
};

export const buildYearTimeseriesDates = (): TimeseriesDay[] => {
    const year = 2020; // We don't care about the year for display purposes, but it must be a leap year to allow all dates to be created
    const dates = [];

    for (let month = 0; month < 12; month++) {
        for (let day = 1; day <= 31; day++) {
            const date = new Date(year, month, day);
            if (date.getFullYear() === year && date.getMonth() === month) {
                dates.push({ date: date, value: null });
            }
        }
    }

    return dates;
};

const generateHeatmapColours = (valuesArray: TimeseriesDay[], minValue: number, maxValue: number) => {
    return valuesArray.map(d => {
        return !isNil(d?.value) ? getHeatmapColour(+d?.value, minValue, maxValue) : "#acacae";
    });
};

const getHeatmapColour = (value: number, min: number, max: number) => {
    const range = max - min;
    const positionInRange = range > 0 ? (value - min) / range : 1;

    if (positionInRange < 0.5) {
        const adjustedPositionInRange = positionInRange / 0.5;

        const r = round(HEATMAP_MIN_COLOUR.r + adjustedPositionInRange * (HEATMAP_MID_COLOUR.r - HEATMAP_MIN_COLOUR.r));
        const g = round(HEATMAP_MIN_COLOUR.g + adjustedPositionInRange * (HEATMAP_MID_COLOUR.g - HEATMAP_MIN_COLOUR.g));
        const b = round(HEATMAP_MIN_COLOUR.b + adjustedPositionInRange * (HEATMAP_MID_COLOUR.b - HEATMAP_MIN_COLOUR.b));
        return `rgb(${r}, ${g}, ${b})`;
    }

    if (positionInRange > 0.5) {
        const adjustedPositionInRange = (positionInRange - 0.5) / 0.5;

        const r = round(HEATMAP_MID_COLOUR.r + adjustedPositionInRange * (HEATMAP_MAX_COLOUR.r - HEATMAP_MID_COLOUR.r));
        const g = round(HEATMAP_MID_COLOUR.g + adjustedPositionInRange * (HEATMAP_MAX_COLOUR.g - HEATMAP_MID_COLOUR.g));
        const b = round(HEATMAP_MID_COLOUR.b + adjustedPositionInRange * (HEATMAP_MAX_COLOUR.b - HEATMAP_MID_COLOUR.b));
        return `rgb(${r}, ${g}, ${b})`;
    }

    return `rgb(${HEATMAP_MID_COLOUR.r}, ${HEATMAP_MID_COLOUR.g}, ${HEATMAP_MID_COLOUR.b})`;
};

const areDatesEqual = (date1: Date, date2: Date) => {
    return date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
};

export function formatScenarioDate(scenario: ModelExploreScenario): string {
    const timestamp = moment(scenario.run.createdAt).format("DDMMYYYYHHMM");

    return timestamp;
}

export function formatFullDownloadName(result: ModelExploreResult): string {
    return `${result.modelName}_${result.scenarioName}_${result.date}_${result.name}`;
}

export function getComputationResultTypeDisplayName(type: ScenarioResultType): string {
    return mapComputationFileTypeToName(type) ?? type;
}

export function isComputationFileTypeCustom(type: ScenarioResultType): boolean {
    switch (type) {
        case ScenarioResultType.DAILY_RESULTS:
            return true;
        case ScenarioResultType.DAILY_INTERMEDIATE:
            return true;

        default:
            return false;
    }
}

export function isComputationFileTypeToBeCombined(type: ScenarioResultType): boolean {
    switch (type) {
        case ScenarioResultType.YEARLY_RESULTS:
            return true;
        case ScenarioResultType.YEARLY_INTERMEDIATE:
            return true;
        case ScenarioResultType.SUMMARY_INTERMEDIATE:
            return true;
        case ScenarioResultType.EVENTS_INTERMEDIATE:
            return true;
        case ScenarioResultType.SPATIAL_RESULTS:
            return true;
        case ScenarioResultType.TEMPORAL_RESULTS:
            return true;
        case ScenarioResultType.YEARLY_SPATIAL_INTERMEDIATE:
            return true;
        case ScenarioResultType.SUMMARY_SPATIAL_INTERMEDIATE:
            return true;
        case ScenarioResultType.EVENTS_SPATIAL_INTERMEDIATE:
            return true;

        default:
            return false;
    }
}

export function isComputationFileTypeToBeDisplayed(type: ScenarioResultType): boolean {
    switch (type) {
        case ScenarioResultType.RUN_SETTINGS_LOG:
            return false;

        default:
            return true;
    }
}

export function isComputationFileTypeDaily(resultType: ScenarioResultType): boolean {
    switch (resultType) {
        case ScenarioResultType.DAILY_RESULTS:
            return true;

        case ScenarioResultType.DAILY_INTERMEDIATE:
            return true;

        case ScenarioResultType.DAILY_SPATIAL_INTERMEDIATE:
            return true;

        default:
            return false;
    }
}

export function getAvaliableIntermediateResultTypes(
    scenarios: ModelExploreScenario[]
): ModelExploreIntermediateResultType[] {
    const dailyTypes = scenarios
        .flatMap(s => {
            return s.result.intermediate.daily.headers;
        })
        .filter(r => !UNSELECTABLE_INTERMEDIATE_RESULT_TYPES.includes(r))
        .map(r => {
            return {
                id: r,
                label: Humanize.capitalize(r.replace(/_/g, " ")),
                scale: ModelExploreIntermediateResultTypeScale.DAILY
            };
        });

    const yearlyTypes = scenarios
        .flatMap(s => {
            return s.result.intermediate.yearly.headers;
        })
        .filter(r => !UNSELECTABLE_INTERMEDIATE_RESULT_TYPES.includes(r))
        .map(r => {
            return {
                id: r,
                label: Humanize.capitalize(r.replace(/_/g, " ")),
                scale: ModelExploreIntermediateResultTypeScale.YEARLY
            };
        });

    const allTypes = [...dailyTypes, ...yearlyTypes].reduce(
        (typeAccumulator: ModelExploreIntermediateResultType[], checkType: ModelExploreIntermediateResultType) => {
            if (!typeAccumulator.some(t => t.id === checkType.id)) {
                typeAccumulator.push(checkType);
            }
            return typeAccumulator;
        },
        []
    );

    return allTypes;
}

const mapComputationFileTypeToName = (type: ScenarioResultType): string | null => {
    switch (type) {
        case ScenarioResultType.DAILY_RESULTS:
            return "Daily results";
        case ScenarioResultType.YEARLY_RESULTS:
            return "Yearly results";

        case ScenarioResultType.DAILY_INTERMEDIATE:
            return "Daily intermediate results";
        case ScenarioResultType.YEARLY_INTERMEDIATE:
            return "Yearly intermediate results";
        case ScenarioResultType.SUMMARY_INTERMEDIATE:
            return "Summary intermediate results";
        case ScenarioResultType.EVENTS_INTERMEDIATE:
            return "Events intermediate results";

        case ScenarioResultType.SPATIAL_RESULTS:
            return "Spatial results";
        case ScenarioResultType.TEMPORAL_RESULTS:
            return "Temporal results";

        case ScenarioResultType.DAILY_SPATIAL_INTERMEDIATE:
            return "Daily spatial intermediate results";
        case ScenarioResultType.YEARLY_SPATIAL_INTERMEDIATE:
            return "Yearly spatial intermediate results";
        case ScenarioResultType.SUMMARY_SPATIAL_INTERMEDIATE:
            return "Sumary spatial intermediate results";
        case ScenarioResultType.EVENTS_SPATIAL_INTERMEDIATE:
            return "Events spatial intermediate results";

        case ScenarioResultType.RUN_SETTINGS_LOG:
            return "Run settings log";

        case ScenarioResultType.CUSTOM_RESULTS:
            return "Custom results";

        default:
            return null;
    }
};
