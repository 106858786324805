import { isNil } from "lodash";
import { BaseDataModel, OptimisationResult, PluginResultData, ScenarioResultType } from "../types/models";
import { isNilOrEmpty } from "../utils/utils";
import AzureBlobService from "./azure.blob.service";
import { CSVParser } from "./csv-parser";
import ZipGenerator, { ZipFileInfo } from "./zip-generator";

export default class OptimisationDataService {
    private static readonly generator = new ZipGenerator();
    private static readonly csvParser = new CSVParser();

    static async zipFiles(files: ZipFileInfo[]): Promise<Blob> {
        return this.generator.generate(files);
    }

    static async fetchAndParseTimeseriesResult(result: OptimisationResult): Promise<PluginResultData> {
        if (isNil(result)) {
            return { rows: [], headers: [] };
        }

        const data = await AzureBlobService.downloadBlobAsString(result.blobId);

        if (isNilOrEmpty(data)) {
            return { rows: [], headers: [] };
        }

        const parsedData = this.csvParser.parse<string, string>(data);
        return parsedData;
    }

    static getOptimisationResultFileNameForDownload(result: OptimisationResult): string {
        return `${result.name}.${result.extension}`;
    }

    static async parseChartData(dataFiles: OptimisationResult[]): Promise<PluginResultData> {
        if (isNilOrEmpty(dataFiles)) {
            return { rows: [], headers: [] };
        }

        const timeseriesResult = dataFiles.find(r => r.resultType === ScenarioResultType.DAILY_INTERMEDIATE);

        const data = await this.fetchAndParseTimeseriesResult(timeseriesResult);

        return data;
    }

    static async fetchAndParseFile(file: BaseDataModel): Promise<Array<Array<string>>> {
        if (isNil(file)) {
            return [];
        }

        const data = await AzureBlobService.downloadBlobAsString(file.blobId);

        if (isNilOrEmpty(data)) {
            return [];
        }

        const parsedData = this.csvParser.parseToArray(data, true);
        return parsedData;
    }
}
