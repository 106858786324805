import React from "react";
import NumericInput from "../../Components/NumericInput";
import { Controller, useFormContext } from "react-hook-form";
import { PluginFieldProps, PluginForm } from "./types";
import Label from "../../Components/Label";

interface PluginParameterNumericRangeProps extends PluginFieldProps {
    onChange?: (field: string, value: Record<string, string | number | boolean>) => void;
}

const PluginParameterNumericRange = ({
    id,
    definition,
    field,
    control,
    disabled,
    onChange
}: PluginParameterNumericRangeProps) => {
    const { getValues } = useFormContext<PluginForm>();

    const handleMinValueChanged = (min: number) => {
        const currentMax = getValues(`${definition.id}.${field.id}.max`);

        onChange(field.id, { max: currentMax, min: min });
    };

    const handleMaxValueChanged = (max: number) => {
        const currentMin = getValues(`${definition.id}.${field.id}.min`);

        onChange(field.id, { max: max, min: currentMin });
    };

    return (
        <div className="numeric-range-parameter-field">
            <Label className="plugin-definition-field-label">{field.name}</Label>
            <div className="numeric-range-values">
                <div className="numeric-range-value-input">
                    <Label className="numeric-range-input-label">Min</Label>
                    <Controller
                        control={control}
                        name={`${definition.id}.${field.id}.min`}
                        render={({ field: _field }) => {
                            return (
                                <NumericInput
                                    id={id}
                                    unit={field.control?.unit}
                                    value={_field.value}
                                    disabled={disabled}
                                    max={getValues(`${definition.id}.${field.id}.max`)}
                                    onChange={handleMinValueChanged}
                                />
                            );
                        }}
                    />
                </div>

                <div className="numeric-range-value-input">
                    <Label className="numeric-range-input-label">Max</Label>
                    <Controller
                        control={control}
                        name={`${definition.id}.${field.id}.max`}
                        render={({ field: _field }) => {
                            return (
                                <NumericInput
                                    id={id}
                                    unit={field.control?.unit}
                                    value={_field.value}
                                    disabled={disabled}
                                    min={getValues(`${definition.id}.${field.id}.min`)}
                                    onChange={handleMaxValueChanged}
                                />
                            );
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default PluginParameterNumericRange;
