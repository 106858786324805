import React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faProjectDiagram } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";

interface HeaderLinkProps {
    to: string;
    label: string;
    isActive: boolean;
    icon: IconProp;
}

const HeaderLink = ({ to, label, isActive, icon }: HeaderLinkProps) => {
    return (
        <NavLink to={to} className="link">
            <FontAwesomeIcon icon={icon} size="lg" />

            <span>{label}</span>

            {isActive && <div className="link-indicator"></div>}
        </NavLink>
    );
};

interface HeaderLinksProps {
    pathname: string;
}

const HeaderLinks = ({ pathname }: HeaderLinksProps) => {
    return (
        <div className="links">
            <HeaderLink to="/explorer" label="Projects" isActive={pathname === "/explorer"} icon={faProjectDiagram} />
        </div>
    );
};

export default HeaderLinks;
