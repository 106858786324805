import { isNil } from "lodash";
import React, { useEffect, useMemo } from "react";
import LoadingIndicator from "../../Components/LoadingIndicator";
import { ModelExploreSummaryResults, SummaryNodeResult } from "../../types/models";
import { formatSummaryNodeResults, formatSummarySpatialResults } from "../../utils/explorer.utils";
import { useModel } from "./ModelContext";
import ModelExploreNodeSuccessCharts from "./ModelExploreNodeSuccessCharts";
import ModelExploreRiskTable from "./ModelExploreRiskTable";
import ModelExploreSpatialRiskChart from "./ModelExploreSpatialRiskChart";

const ModelExploreSummary = () => {
    const { model, explorerFlags, explorerScenarios, loadExplorerAssessmentData } = useModel();

    const selectedScenarios = explorerScenarios.filter(s => s.isSelected);
    const baselineScenario = explorerScenarios.find(s => s.isBaseline);

    const hasLoadedAssessment = selectedScenarios.every(s => s.hasLoadedAssessment);

    const exploreData: ModelExploreSummaryResults = useMemo(() => {
        if (!hasLoadedAssessment) {
            return;
        }

        const spatialResults = formatSummarySpatialResults(explorerScenarios);
        const nodeResults: SummaryNodeResult[] = formatSummaryNodeResults(selectedScenarios);

        return {
            spatialResults: spatialResults,
            nodeResults: nodeResults,
            model: model
        };
    }, [explorerScenarios, hasLoadedAssessment]);

    useEffect(() => {
        loadExplorerAssessmentData(selectedScenarios.map(s => s.id));
    }, [selectedScenarios]);

    const hasResults = hasLoadedAssessment && !isNil(exploreData);

    return (
        <div className="explore-summary">
            {explorerFlags.isLoadingAssessment && <LoadingIndicator centered={true} />}

            {hasResults && (
                <>
                    <ModelExploreRiskTable
                        title={`Risk summary - percent change from ${baselineScenario.name}`}
                        selectedScenarios={selectedScenarios}
                        baselineScenario={baselineScenario}
                        resultsData={exploreData}
                    />

                    <ModelExploreSpatialRiskChart
                        title="Percentage of years in each risk category"
                        selectedScenarios={selectedScenarios}
                        resultsData={exploreData}
                    />

                    <ModelExploreNodeSuccessCharts
                        title="Percentage of years in each risk category at nodes (or sites)"
                        selectedScenarios={selectedScenarios}
                        resultsData={exploreData}
                    />
                </>
            )}
        </div>
    );
};

export default ModelExploreSummary;
