import React from "react";
import Tooltip from "../../Components/Tooltip";
import { Placement } from "react-bootstrap/Overlay";
import ERPIcon, { ERPIconProps } from "../../Components/ERPIcon";

interface TooltipIconProps extends ERPIconProps {
    tooltip: string;
    placement?: Placement;
}

const TooltipIcon = ({ tooltip, placement, ...rest }: TooltipIconProps) => {
    return (
        <Tooltip tooltipContent={tooltip} placement={placement}>
            <ERPIcon {...rest} />
        </Tooltip>
    );
};

export default TooltipIcon;
