import { GroupModel, GroupMember, GroupPrivilege } from "../types/models";
import { CreateGroupRequest, UpdateGroupRequest, AddUserToGroupRequest } from "../types/requests";
import API from "../api/data.api";
import { URL } from "../Constants/api";

export default class GroupService {
    public static async createGroup(request: CreateGroupRequest): Promise<GroupModel> {
        const group = await API.post<GroupModel>(`${URL.GROUPS}`, request);
        return group;
    }

    public static async getGroupMembers(groupId: string): Promise<GroupMember[]> {
        const members = await API.get<GroupMember[]>(`${URL.GROUPS}/${groupId}/users`);
        return members;
    }

    public static async updateGroup(groupId: string, request: UpdateGroupRequest): Promise<GroupModel> {
        const group = await API.patch<GroupModel>(`${URL.GROUPS}/${groupId}`, request);
        return group;
    }

    public static async addUserToGroup(
        groupId: string,
        email: string,
        privilege: GroupPrivilege
    ): Promise<GroupMember> {
        const request: AddUserToGroupRequest = {
            user: email,
            privilege: privilege
        };

        const member = await API.patch<GroupMember>(`${URL.GROUPS}/${groupId}/users`, request);

        return member;
    }

    public static async removeGroupMember(groupId: string, memberId: string): Promise<void> {
        await API.delete<void>(`${URL.GROUPS}/${groupId}/users/${memberId}`);
    }
}
