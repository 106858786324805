import React from "react";
import { faProjectDiagram } from "@fortawesome/pro-solid-svg-icons";
import ERPIcon, { ERPIconProps } from ".";

type SystemIconProps = Omit<ERPIconProps, "icon">;

const SystemIcon = (props: SystemIconProps) => {
    return <ERPIcon className="system-icon" icon={faProjectDiagram} {...props} />;
};

export default SystemIcon;
