import React, { useState } from "react";
import { ModelScenarioModel, TaskModel, TaskStatus } from "../../types/models";
import Switch from "../../Components/Switch";
import ERPIcon from "../../Components/ERPIcon";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { isNilOrEmpty } from "../../utils/utils";
import ModelScenarioUpToDateDescription from "./ModelScenarioUpToDateDescription";
import ModelScenarioUpToDateIcon from "./ModelScenarioUpToDateIcon";
import { isNil } from "lodash";
import TaskStatusDisplay from "../../Components/TaskStatusDisplay/TaskStatusDisplay";

interface ModelScenarioProps {
    scenario: ModelScenarioModel;
    task: TaskModel;
    enabled: boolean;
    version: string;
    readOnly: boolean;
    onEnabledChanged: (scenario: ModelScenarioModel, enabled: boolean) => void;
}

const ModelScenario = ({ scenario, task, enabled, version, readOnly, onEnabledChanged }: ModelScenarioProps) => {
    const [expanded, setExpanded] = useState<boolean>(false);

    const handleScenarioStateChanged = async (enabled: boolean) => {
        onEnabledChanged(scenario, enabled);
    };

    const hasRun = !isNil(scenario.run) && task?.status !== TaskStatus.RUNNING && task?.status !== TaskStatus.PENDING;
    const isUpToDate =
        hasRun && version === scenario.run.modelVersion && scenario.version === scenario.run.scenarioVersion;

    return (
        <div className="model-scenario">
            <div className="model-scenario--header" onClick={() => setExpanded(!expanded)}>
                <div className="model-scenario--header-title">
                    {!readOnly && (
                        <div className="switch-container">
                            <Switch checked={enabled} onChecked={handleScenarioStateChanged} />
                        </div>
                    )}

                    <span className="header-title">{scenario.name}</span>
                </div>

                <div className="model-scenario--header-icons">
                    {!isNil(task) && <TaskStatusDisplay task={task} label="Computation" showProgress={true} />}

                    {hasRun && <ModelScenarioUpToDateIcon isUpToDate={isUpToDate} />}

                    <ERPIcon icon={expanded ? faChevronUp : faChevronDown} className="expand-icon" size="lg" />
                </div>
            </div>

            {expanded && (
                <div className="model-scenario--content">
                    {!isNilOrEmpty(scenario.description) ? scenario.description : "No description"}

                    {hasRun && <ModelScenarioUpToDateDescription isUpToDate={isUpToDate} />}
                </div>
            )}
        </div>
    );
};

export default ModelScenario;
