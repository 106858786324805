import { faCheckCircle, faExclamationCircle, faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import ERPIcon from "../../Components/ERPIcon";
import ERPProgressBar from "../../Components/ProgressBar";
import React from "react";
import { ConsoleMessageModel, ConsoleMessageTypeOption } from "./SystemBulkImportModal";

export interface SystemBulkImportModelProgressPanelProps {
    progressPercent: number;
    messageLog: ConsoleMessageModel[];
}

const SystemBulkImportModelProgressPanel = ({
    progressPercent,
    messageLog
}: SystemBulkImportModelProgressPanelProps) => {
    const getMessageIcon = (type: ConsoleMessageTypeOption) => {
        switch (type) {
            case ConsoleMessageTypeOption.SUCCESS:
                return <ERPIcon icon={faCheckCircle} className="success-icon" />;

            case ConsoleMessageTypeOption.WARN:
                return <ERPIcon icon={faExclamationTriangle} className="warn-icon"></ERPIcon>;

            case ConsoleMessageTypeOption.ERROR:
                return <ERPIcon icon={faExclamationCircle} className="error-icon"></ERPIcon>;

            default:
                return null;
        }
    };

    return (
        <div className="import-progress-panel">
            <ERPProgressBar progress={progressPercent} />

            <div className="import-console">
                {messageLog.map(m => {
                    return (
                        <div className="console-message" key={m.message}>
                            {getMessageIcon(m.type)}
                            <span>{m.message}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default SystemBulkImportModelProgressPanel;
