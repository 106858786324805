import { isNil } from "lodash";
import React, { useEffect, useMemo } from "react";
import Label from "../../Components/Label";
import LoadingIndicator from "../../Components/LoadingIndicator";
import { RiskLevel, SummaryResult } from "../../types/models";
import { calcPercentDiff, formatSummarySpatialResults } from "../../utils/explorer.utils";
import { useModel } from "./ModelContext";
import ModelExplorePreselectTable from "./ModelExplorePreselectTable";

export interface ModelExplorePreselectTableRowData {
    id: string;
    name: string;
    low: number;
    moderate: number;
    high: number;
}

const ModelExplorePreselect = () => {
    const { explorerFlags, explorerScenarios, loadExplorerAssessmentData } = useModel();

    const baselineScenario = explorerScenarios.find(s => s.isBaseline);

    const hasLoadedAssessment = explorerScenarios.every(s => s.hasLoadedAssessment);

    const exploreData: SummaryResult[] = useMemo(() => {
        if (!hasLoadedAssessment) {
            return;
        }

        return formatSummarySpatialResults(explorerScenarios);
    }, [explorerScenarios, hasLoadedAssessment]);

    useEffect(() => {
        loadExplorerAssessmentData(explorerScenarios.map(s => s.id));
    }, []);

    const hasResults = hasLoadedAssessment && !isNil(exploreData);

    const tableRows: ModelExplorePreselectTableRowData[] = useMemo(() => {
        if (!hasResults) {
            return;
        }

        const baselineResult = exploreData.find(r => r.scenarioId === baselineScenario.id);

        return explorerScenarios.map(s => {
            const scenarioResult = exploreData.find(r => r.scenarioId === s.id);

            return {
                id: s.id,
                name: s.name,
                low: calcPercentDiff(baselineResult, scenarioResult, RiskLevel.LOW),
                moderate: calcPercentDiff(baselineResult, scenarioResult, RiskLevel.MODERATE),
                high: calcPercentDiff(baselineResult, scenarioResult, RiskLevel.HIGH)
            };
        });
    }, [exploreData, baselineScenario]);

    return (
        <div className="explore-preselect">
            {explorerFlags.isLoadingAssessment && <LoadingIndicator centered={true} />}

            {hasResults && (
                <div className="scenarios-table-container">
                    <Label>Select scenarios to explore</Label>
                    <ModelExplorePreselectTable tableRows={tableRows} />
                </div>
            )}
        </div>
    );
};

export default ModelExplorePreselect;
