import React from "react";
import { Controller } from "react-hook-form";
import { PluginFieldProps } from "./types";
import { PluginFieldArrayValue } from "../../types/plugin";
import PredictorsSelect from "./PredictorsSelect";

interface PluginParameterPredictorsSelectProps extends PluginFieldProps {
    onChange?: (field: string, value: PluginFieldArrayValue) => void;
}

const PluginParameterPredictorsSelect = ({
    id,
    definition,
    field,
    control,
    disabled,
    onChange
}: PluginParameterPredictorsSelectProps) => {
    return (
        <Controller
            control={control}
            name={`${definition.id}.${field.id}`}
            render={({ field: _field }) => {
                return (
                    <PredictorsSelect
                        id={id}
                        label={field.name}
                        values={_field.value}
                        disabled={disabled}
                        onPredictorsChanged={predictors => {
                            onChange(field.id, predictors);
                        }}
                    />
                );
            }}
        />
    );
};

export default PluginParameterPredictorsSelect;
