import { KNOWN_HTTP_STATUS_CODES } from "../api/base.api";
import { TaskModel } from "../types/models";
import { ComputationResult, OptimisationComputationResult } from "../types/plugin";
import { ComputationRequest, OptimisationComputationRequest } from "../types/requests";
import API from "../api/data.api";
import { URL } from "../Constants/api";

export default class ComputationService {
    public static async compute(request: ComputationRequest): Promise<ComputationResult> {
        try {
            const tasks = await API.post<TaskModel[]>(URL.COMPUTATION, request);

            return { tasks: tasks, success: true, failure: null };
        } catch (error) {
            if (API.isAxiosError(error)) {
                if (error.response.status === KNOWN_HTTP_STATUS_CODES.BAD_REQUEST) {
                    const apiError = error.response.data.error;

                    return {
                        tasks: null,
                        success: false,
                        failure: { code: apiError.code, message: apiError.message }
                    };
                }
            } else {
                throw error;
            }
        }
    }

    public static async computeOptimisation(
        request: OptimisationComputationRequest
    ): Promise<OptimisationComputationResult> {
        try {
            const task = await API.post<TaskModel>(`${URL.COMPUTATION}/optimisation`, request);

            return { task: task, success: true, failure: null };
        } catch (error) {
            if (API.isAxiosError(error)) {
                if (error.response.status === KNOWN_HTTP_STATUS_CODES.BAD_REQUEST) {
                    const apiError = error.response.data.error;

                    return {
                        task: null,
                        success: false,
                        failure: { code: apiError.code, message: apiError.message }
                    };
                }
            } else {
                throw error;
            }
        }
    }
}
