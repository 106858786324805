import { faChevronLeft, faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import React, { useState } from "react";
import ERPIcon from "../../Components/ERPIcon";
import { DownloadsSettingsModel, useModel } from "./ModelContext";
import ModelExploreDownloadsSettingsOption from "./ModelExploreDownloadsSettingsOption";

const ModelExploreDownloadsSettings = () => {
    const { downloadsSettings, updateDownloadsSettings } = useModel();

    const [showSettings, setShowSettings] = useState<boolean>(false);

    const updateShouldSeperateDailyResults = (nextShouldSeperateDailyResults: boolean) => {
        const nextDownloadsSettings: DownloadsSettingsModel = {
            ...downloadsSettings,
            shouldSeperateDailyResults: nextShouldSeperateDailyResults
        };
        updateDownloadsSettings(nextDownloadsSettings);
    };

    const updateShouldCreateCombinedResults = (nextShouldCreateCombinedResults: boolean) => {
        const nextDownloadsSettings: DownloadsSettingsModel = {
            ...downloadsSettings,
            shouldCreateCombinedResults: nextShouldCreateCombinedResults
        };
        updateDownloadsSettings(nextDownloadsSettings);
    };

    const updateShouldIncludeDailyResults = (nextShouldIncludeDailyResults: boolean) => {
        const nextDownloadsSettings: DownloadsSettingsModel = {
            ...downloadsSettings,
            shouldIncludeDailyResults: nextShouldIncludeDailyResults
        };
        updateDownloadsSettings(nextDownloadsSettings);
    };

    const updateShouldUseFullDownloadNames = (nextShouldUseFullDownloadNames: boolean) => {
        const nextDownloadsSettings: DownloadsSettingsModel = {
            ...downloadsSettings,
            shouldUseFullDownloadNames: nextShouldUseFullDownloadNames
        };
        updateDownloadsSettings(nextDownloadsSettings);
    };

    return (
        <div className="downloads-settings-container">
            <div className="downloads-settings-header" onClick={() => setShowSettings(!showSettings)}>
                <span className="downloads-settings-header-title">Settings</span>
                <ERPIcon
                    icon={!showSettings ? faChevronRight : faChevronLeft}
                    size="lg"
                    className="downloads-settings-header-icon"
                />
            </div>

            {showSettings && (
                <div className="downloads-settings-body">
                    <ModelExploreDownloadsSettingsOption
                        isChecked={downloadsSettings.shouldUseFullDownloadNames}
                        tooltip="If checked, file names will always include all relevent result meta data (model name, scenario name, run date, result type)"
                        label="Use full download names"
                        onChecked={updateShouldUseFullDownloadNames}
                    />

                    <ModelExploreDownloadsSettingsOption
                        isChecked={downloadsSettings.shouldIncludeDailyResults}
                        tooltip="Not including daily results will significantly increase the download speed for very large models"
                        label="Include daily results in bulk downloads"
                        onChecked={updateShouldIncludeDailyResults}
                    />

                    <ModelExploreDownloadsSettingsOption
                        isChecked={downloadsSettings.shouldSeperateDailyResults}
                        tooltip="This will produce an individual file of daily results for each node, as well as a file with all nodes"
                        label="Seperate daily results by node"
                        onChecked={updateShouldSeperateDailyResults}
                    />

                    <ModelExploreDownloadsSettingsOption
                        isChecked={downloadsSettings.shouldCreateCombinedResults}
                        tooltip="This will create a combined file including all scenarios for each result type avaliable (not including daily)"
                        label="Create combined results"
                        onChecked={updateShouldCreateCombinedResults}
                    />
                </div>
            )}
        </div>
    );
};

export default ModelExploreDownloadsSettings;
