import React from "react";
import { LocationModel } from "../../types/models";
import Button from "../../Components/Button";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { guid } from "../../utils/utils";

interface ScenarioLocationCreateProps {
    disabled: boolean;
    onLocationAdded: (location: LocationModel) => void;
}

const ScenarioLocationCreate = ({ disabled, onLocationAdded }: ScenarioLocationCreateProps) => {
    const handleNewLocation = () => {
        onLocationAdded({ id: guid(), name: `New Location`, systemId: guid(), gaugeNumber: null });
    };

    return (
        <div className="scenario-location-card placeholder-card">
            <Button icon={faPlus} disabled={disabled} onClick={handleNewLocation}>
                New location
            </Button>
        </div>
    );
};

export default ScenarioLocationCreate;
