import { FolderModel, ModelModel, SystemModel } from "../../types/models";

export interface FileSystemView {
    folder: FolderModel;
    subFolders: FolderModel[];
    systems: SystemModel[];
    models: ModelModel[];
    index: number;
    isRoot: boolean;
    loaded: boolean;
}

export interface IStack<T> {
    push(item: T): void;
    pop(): T | undefined;
    peek(): T | undefined;
    size(): number;
}

export class Stack<T> implements IStack<T> {
    storage: T[] = [];

    constructor(init?: T) {
        if (init) {
            this.push(init);
        }
    }

    push(item: T): void {
        this.storage.push(item);
    }

    pop(): T | undefined {
        return this.storage.pop();
    }

    popTo(index: number): T[] {
        const poppedItems: T[] = [];

        while (this.size() !== index) {
            const item = this.pop();

            poppedItems.push(item);
        }

        return poppedItems;
    }

    peek(): T | undefined {
        return this.storage[this.size() - 1];
    }

    peekAt(index: number): T | null {
        if (index > this.size()) {
            return null;
        }

        return this.storage[index];
    }

    size(): number {
        return this.storage.length;
    }

    copy(): Stack<T> {
        const stack = new Stack<T>();

        stack.storage = [
            ...this.storage.map(item => {
                return { ...item };
            })
        ];

        return stack;
    }
}

export interface Breadcrumb {
    index: number;
    label: string;
}

export type Breadcrumbs = Breadcrumb[];
