import React from "react";
import { ModelExploreScenario, ModelExploreSummaryResults } from "../../types/models";
import ModelExploreNodeSuccessChart from "./ModelExploreNodeSuccessChart";

interface ModelExploreNodeSuccessChartsProps {
    selectedScenarios: ModelExploreScenario[];
    resultsData: ModelExploreSummaryResults;
    title: string;
}

const ModelExploreNodeSuccessCharts = ({
    selectedScenarios,
    resultsData,
    title
}: ModelExploreNodeSuccessChartsProps) => {
    return (
        <>
            <h5>{title}</h5>

            {resultsData.nodeResults.map((node, idx) => {
                return (
                    <ModelExploreNodeSuccessChart
                        key={`${node}_${idx}`}
                        selectedScenarios={selectedScenarios}
                        results={node.results}
                        node={node.name}
                    />
                );
            })}
        </>
    );
};

export default ModelExploreNodeSuccessCharts;
