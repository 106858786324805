import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { isNil } from "lodash";
import FileProgress from "./FileProgress";
import { sleep } from "../../utils/utils";
import { BaseDataModel } from "../../types/models";

import "./FileUploader.scss";

export interface ProgressContext {
    progress: number;
    label: string;
}

export interface FileUploaderModalOptions {
    fileUploads: ((onProgress: (progress: number) => void) => Promise<BaseDataModel>)[];
}

interface FileUploaderModalProps extends FileUploaderModalOptions {
    show: boolean;
    onClose: () => void;
    onFileUploaded: (data: BaseDataModel[]) => void;
}

const FileUploaderModal = ({ fileUploads, onFileUploaded }: FileUploaderModalProps) => {
    const [context, setContext] = useState<ProgressContext>({ progress: 0, label: "Uploading File" });

    useEffect(() => {
        const uploadFiles = async () => {
            const data: BaseDataModel[] = [];

            for (let i = 0; i < fileUploads.length; i++) {
                const fileUpload = fileUploads[i];
                const label = `Uploading File - ${i + 1}/${fileUploads.length}`;

                setContext({ progress: 0, label: label });

                await sleep(500);

                const fileData = await fileUpload(progress => {
                    setContext({ progress: progress, label: label });
                });

                setContext({ progress: 100, label: label });

                data.push(fileData);

                await sleep(500);
            }

            onFileUploaded(data);
        };

        uploadFiles();
    }, []);

    return (
        <Modal show={open} centered backdrop="static">
            <Modal.Body>
                {!isNil(context) && <FileProgress progress={context?.progress ?? 0} label={context?.label} />}
            </Modal.Body>
        </Modal>
    );
};

export default FileUploaderModal;
