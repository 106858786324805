import clsx from "clsx";
import Tooltip from "../../Components/Tooltip";
import React from "react";
import { Control } from "react-hook-form";
import {
    PluginControlTypes,
    PluginDefinition,
    PluginField,
    PluginFieldArrayValue,
    PluginFieldValue
} from "../../types/plugin";
import { isNilOrEmpty } from "../../utils/utils";
import PluginInput from "./PluginInput";
import PluginNumericInput from "./PluginNumericInput";
import PluginParameterARI from "./PluginParameterARI";
import PluginParameterCheckbox from "./PluginParameterCheckbox";
import PluginParameterCurve from "./PluginParameterCurve";
import PluginParameterMonthSelect from "./PluginParameterMonthSelect";
import PluginParameterMultiSelect from "./PluginParameterMultiSelect";
import PluginParameterPredictorsSelect from "./PluginParameterPredictorsSelect";
import PluginParameterRiskCategories from "./PluginParameterRiskCategories";
import PluginParameterTemporalAgg from "./PluginParameterTemporalAgg";
import PluginParameterSeasonSelect from "./PluginParameterSeasonSelect";
import PluginParameterSelect from "./PluginParameterSelect";
import { PluginForm } from "./types";
import PluginParameterYearlyAgg from "./PluginParameterYearlyAgg";
import PluginParameterDailyAgg from "./PluginParameterDailyAgg";
import PluginParameterFlowType from "./PluginParameterFlowType";
import PluginParameterSeasonType from "./PluginParameterSeasonType";
import PluginParameterYearlyConversion from "./PluginParameterYearlyConversion";
import PluginParameterVariableSeason from "./PluginParameterVariableSeason";
import PluginParameterCheckboxInput from "./PluginParameterCheckboxInput";
import PluginParameterSpellType from "./PluginParameterSpellType";
import PluginParameterDailyAggFailure from "./PluginParameterDailyAggFailure";
import PluginParameterYearlyAggFailure from "./PluginParameterYearlyAggFailure";
import PluginParameterTemporalAggFailure from "./PluginParameterTemporalAggFailure";
import PluginParameterNumericRange from "./PluginParameterNumericRange";
import PluginParameterVelocityCalculation from "./PluginParameterVelocityCalculation";
import PluginParameterVelocityAdjustment from "./PluginParameterVelocityAdjustment";

interface PluginParameterFieldProps {
    /**
     * Unique identifier to make sure this input is uniquely rendered amongst other plugin parameters.
     * This is important to avoid lose of focus on re-render caused by state change during parameter saving.
     */
    id: string;
    definition: PluginDefinition;
    field: PluginField;
    control: Control<PluginForm>;
    disabled?: boolean;
    onValueChanged: (field: string, value: PluginFieldValue) => void;
}

const PluginParameterField = ({
    id,
    definition,
    field,
    control,
    disabled,
    onValueChanged
}: PluginParameterFieldProps) => {
    const handleChange = (value: PluginFieldValue) => {
        onValueChanged(field.id, value);
    };

    const handleValueChanged = (field: string, value: PluginFieldValue) => {
        onValueChanged(field, value);
    };

    const handleObjectChanged = (field: string, value: Record<string, number | string>) => {
        onValueChanged(field, value);
    };

    const handleArrayChanged = (field: string, value: PluginFieldArrayValue) => {
        onValueChanged(field, value);
    };

    return (
        <Tooltip disabled={isNilOrEmpty(field.control.tooltip)} tooltipContent={field.control.tooltip} placement="top">
            <div
                className={clsx("plugin-definition-field", {
                    ari: field.control.type === PluginControlTypes.ARI,
                    "flow-type": field.control.type === PluginControlTypes.FLOW_TYPE,
                    "season-select": field.control.type === PluginControlTypes.SEASON_SELECT,
                    "season-type": field.control.type === PluginControlTypes.SEASON_TYPE,
                    "predictors-select": field.control.type === PluginControlTypes.PREDICTORS_SELECT,
                    "spell-type": field.control.type === PluginControlTypes.SPELL_TYPE,
                    "velocity-calculation": field.control.type === PluginControlTypes.VELOCITY_CALCULATION,
                    "velocity-adjustment": field.control.type === PluginControlTypes.VELOCITY_ADJUSTMENT,
                    curve:
                        field.control.type === PluginControlTypes.CURVE ||
                        field.control.type === PluginControlTypes.PARTIAL_TABLE ||
                        field.control.type === PluginControlTypes.VARIABLE_SEASON
                })}
            >
                {field.control.type === PluginControlTypes.TEXT_INPUT && (
                    <PluginInput
                        id={id}
                        control={control}
                        definition={definition}
                        field={field}
                        disabled={disabled}
                        onChange={handleChange}
                    />
                )}

                {field.control.type === PluginControlTypes.NUMERIC_INPUT && (
                    <PluginNumericInput
                        id={id}
                        control={control}
                        definition={definition}
                        field={field}
                        disabled={disabled}
                        onChange={handleChange}
                    />
                )}

                {field.control.type === PluginControlTypes.CHECKBOX && (
                    <PluginParameterCheckbox
                        id={id}
                        control={control}
                        definition={definition}
                        field={field}
                        disabled={disabled}
                        onChecked={handleChange}
                    />
                )}

                {field.control.type === PluginControlTypes.MONTH_SELECT && (
                    <PluginParameterMonthSelect
                        id={id}
                        control={control}
                        definition={definition}
                        field={field}
                        disabled={disabled}
                        onMonthChanged={handleChange}
                    />
                )}

                {field.control.type === PluginControlTypes.ARI && (
                    <PluginParameterARI
                        id={id}
                        control={control}
                        definition={definition}
                        field={field}
                        disabled={disabled}
                        onChange={handleObjectChanged}
                    />
                )}

                {field.control.type === PluginControlTypes.SEASON_TYPE && (
                    <PluginParameterSeasonType
                        id={id}
                        control={control}
                        definition={definition}
                        field={field}
                        disabled={disabled}
                        onObjectChange={handleObjectChanged}
                        onValueChange={handleValueChanged}
                    />
                )}

                {field.control.type === PluginControlTypes.FLOW_TYPE && (
                    <PluginParameterFlowType
                        id={id}
                        control={control}
                        definition={definition}
                        field={field}
                        disabled={disabled}
                        onChange={handleObjectChanged}
                    />
                )}

                {field.control.type === PluginControlTypes.SEASON_SELECT && (
                    <PluginParameterSeasonSelect
                        id={id}
                        control={control}
                        definition={definition}
                        field={field}
                        disabled={disabled}
                        onChange={handleObjectChanged}
                    />
                )}

                {field.control.type === PluginControlTypes.DROPDOWN && !field.control.multi && (
                    <PluginParameterSelect
                        id={id}
                        control={control}
                        definition={definition}
                        field={field}
                        disabled={disabled}
                        onChanged={handleChange}
                    />
                )}

                {field.control.type === PluginControlTypes.DROPDOWN && field.control.multi && (
                    <PluginParameterMultiSelect
                        id={id}
                        control={control}
                        definition={definition}
                        field={field}
                        disabled={disabled}
                        onChanged={handleArrayChanged}
                    />
                )}

                {field.control.type === PluginControlTypes.PARTIAL_TABLE && (
                    <PluginParameterCurve
                        id={id}
                        control={control}
                        definition={definition}
                        field={field}
                        disabled={disabled}
                        onChange={handleArrayChanged}
                    />
                )}

                {field.control.type === PluginControlTypes.CURVE && (
                    <PluginParameterCurve
                        id={id}
                        control={control}
                        definition={definition}
                        field={field}
                        disabled={disabled}
                        onChange={handleArrayChanged}
                    />
                )}

                {field.control.type === PluginControlTypes.VARIABLE_SEASON && (
                    <PluginParameterVariableSeason
                        id={id}
                        control={control}
                        definition={definition}
                        field={field}
                        disabled={disabled}
                        onChange={handleArrayChanged}
                    />
                )}

                {field.control.type === PluginControlTypes.RISK_CATEGORIES && (
                    <PluginParameterRiskCategories
                        id={id}
                        control={control}
                        definition={definition}
                        field={field}
                        disabled={disabled}
                        onChange={handleObjectChanged}
                    />
                )}

                {field.control.type === PluginControlTypes.CHECKBOX_INPUT && (
                    <PluginParameterCheckboxInput
                        id={id}
                        control={control}
                        definition={definition}
                        field={field}
                        disabled={disabled}
                        onChange={handleObjectChanged}
                    />
                )}

                {field.control.type === PluginControlTypes.SPELL_TYPE && (
                    <PluginParameterSpellType
                        id={id}
                        control={control}
                        definition={definition}
                        field={field}
                        disabled={disabled}
                        onChange={handleObjectChanged}
                    />
                )}

                {field.control.type === PluginControlTypes.TEMPORAL && (
                    <PluginParameterTemporalAgg
                        id={id}
                        control={control}
                        definition={definition}
                        field={field}
                        disabled={disabled}
                        onChange={handleObjectChanged}
                    />
                )}

                {field.control.type === PluginControlTypes.TEMPORAL_FAILURE && (
                    <PluginParameterTemporalAggFailure
                        id={id}
                        control={control}
                        definition={definition}
                        field={field}
                        disabled={disabled}
                        onChange={handleObjectChanged}
                    />
                )}

                {field.control.type === PluginControlTypes.YEARLY_AGG && (
                    <PluginParameterYearlyAgg
                        id={id}
                        control={control}
                        definition={definition}
                        field={field}
                        disabled={disabled}
                        onChange={handleObjectChanged}
                    />
                )}

                {field.control.type === PluginControlTypes.YEARLY_AGG_FAILURE && (
                    <PluginParameterYearlyAggFailure
                        id={id}
                        control={control}
                        definition={definition}
                        field={field}
                        disabled={disabled}
                        onChange={handleObjectChanged}
                    />
                )}

                {field.control.type === PluginControlTypes.YEARLY_CONVERSION && (
                    <PluginParameterYearlyConversion
                        id={id}
                        control={control}
                        definition={definition}
                        field={field}
                        disabled={disabled}
                        onChange={handleObjectChanged}
                    />
                )}

                {field.control.type === PluginControlTypes.DAILY && (
                    <PluginParameterDailyAgg
                        id={id}
                        control={control}
                        definition={definition}
                        field={field}
                        disabled={disabled}
                        onChange={handleObjectChanged}
                    />
                )}

                {field.control.type === PluginControlTypes.DAILY_FAILURE && (
                    <PluginParameterDailyAggFailure
                        id={id}
                        control={control}
                        definition={definition}
                        field={field}
                        disabled={disabled}
                        onChange={handleObjectChanged}
                    />
                )}

                {field.control.type === PluginControlTypes.PREDICTORS_SELECT && (
                    <PluginParameterPredictorsSelect
                        id={id}
                        control={control}
                        definition={definition}
                        field={field}
                        disabled={disabled}
                        onChange={handleArrayChanged}
                    />
                )}

                {field.control.type === PluginControlTypes.NUMERIC_RANGE && (
                    <PluginParameterNumericRange
                        id={id}
                        control={control}
                        definition={definition}
                        field={field}
                        disabled={disabled}
                        onChange={handleObjectChanged}
                    />
                )}

                {field.control.type === PluginControlTypes.VELOCITY_CALCULATION && (
                    <PluginParameterVelocityCalculation
                        id={id}
                        control={control}
                        definition={definition}
                        field={field}
                        disabled={disabled}
                        onObjectChange={handleObjectChanged}
                        onArrayChange={handleArrayChanged}
                    />
                )}

                {field.control.type === PluginControlTypes.VELOCITY_ADJUSTMENT && (
                    <PluginParameterVelocityAdjustment
                        id={id}
                        control={control}
                        definition={definition}
                        field={field}
                        disabled={disabled}
                        onChange={handleObjectChanged}
                    />
                )}
            </div>
        </Tooltip>
    );
};

export default PluginParameterField;
