import { WaterholeParameters } from "../../types/plugin-parameter-types";
import { PluginParameters } from "../../types/plugin";
import { BaseParameterMerger } from "./base-parameter-merger";

export class WaterholeParameterMerger extends BaseParameterMerger<WaterholeParameters> {
    public merge(source: PluginParameters, defaultParameters: WaterholeParameters): WaterholeParameters {
        return {
            evaporation: {
                scaling: this.defaultTo(source?.evaporation?.scaling, defaultParameters?.evaporation?.scaling)
            },
            seepage: {
                rate: this.defaultTo(source?.seepage?.rate, defaultParameters?.seepage?.rate)
            },
            rainfall: {
                area: this.defaultTo(source?.rainfall?.area, defaultParameters?.rainfall?.area),
                max_infiltration: this.defaultTo(
                    source?.rainfall?.max_infiltration,
                    defaultParameters?.rainfall?.max_infiltration
                )
            },
            groundwater: {
                area: this.defaultTo(source?.groundwater?.area, defaultParameters?.groundwater?.area),
                inflow: this.defaultTo(source?.groundwater?.inflow, defaultParameters?.groundwater?.inflow),
                loss_to_deep_drainage: this.defaultTo(
                    source?.groundwater?.loss_to_deep_drainage,
                    defaultParameters?.groundwater?.loss_to_deep_drainage
                )
            },
            extraction: {
                commence_pumping_depth: this.defaultTo(
                    source?.extraction?.commence_pumping_depth,
                    defaultParameters?.extraction?.commence_pumping_depth
                ),
                cease_pumping_depth: this.defaultTo(
                    source?.extraction?.cease_pumping_depth,
                    defaultParameters?.extraction?.cease_pumping_depth
                ),
                extraction_rate: this.defaultTo(
                    source?.extraction?.extraction_rate,
                    defaultParameters?.extraction?.extraction_rate
                ),
                max_annual_take: this.defaultTo(
                    source?.extraction?.max_annual_take,
                    defaultParameters?.extraction?.max_annual_take
                ),
                start_month: this.defaultTo(
                    source?.extraction?.start_month,
                    defaultParameters?.extraction?.start_month
                ),
                ctf: this.defaultTo(source?.extraction?.ctf, defaultParameters?.extraction?.ctf),
                lag: this.defaultTo(source?.extraction?.lag, defaultParameters?.extraction?.lag)
            },
            season: {
                season_start: this.defaultTo(source?.season?.season_start, defaultParameters?.season?.season_start),
                season_end: this.defaultTo(source?.season?.season_end, defaultParameters?.season?.season_end)
            },
            event: {
                spell_type: this.defaultTo(source?.event?.spell_type, defaultParameters?.event?.spell_type),
                independence: this.defaultTo(source?.event?.independence, defaultParameters?.event?.independence),
                min_spell_length: this.defaultTo(
                    source?.event?.min_spell_length,
                    defaultParameters?.event?.min_spell_length
                ),
                max_spell_length: this.defaultTo(
                    source?.event?.max_spell_length,
                    defaultParameters?.event?.max_spell_length
                )
            },
            assessment: {
                daily_agg: this.defaultTo(source?.assessment?.daily_agg, defaultParameters?.assessment?.daily_agg),
                yearly_agg: this.defaultTo(source?.assessment?.yearly_agg, defaultParameters?.assessment?.yearly_agg),
                temporal_agg: this.defaultTo(
                    source?.assessment?.temporal_agg,
                    defaultParameters?.assessment?.temporal_agg
                )
            },
            spatial_agg: {
                risk_categories: this.defaultTo(
                    source?.spatial_agg?.risk_categories,
                    defaultParameters?.spatial_agg?.risk_categories
                )
            }
        };
    }
}
