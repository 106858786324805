import { csvFormat } from "d3-dsv";
import moment from "moment";
import {
    DataType,
    ERPTimerseriesChartData,
    LocationModel,
    SystemDataPreviewScenarioData,
    TimeseriesDay
} from "../types/models";

const CHART_LEGEND_COLOUR_OPTIONS = [
    "#FF671B",
    "#08BFDD",
    "#E24999",
    "#FDCD06",
    "#77BC1F",
    "#D2272E",
    "#0076BB",
    "#8547AD",
    "#FFA400",
    "#00994D"
];

interface ChartDataset {
    label: string;
    data: TimeseriesDay[];
    backgroundColor: string;
    pointRadius: number;
    borderWidth: number;
    borderColor: string;
    pointHitRadius: number;
}

interface ParsedData {
    name: string;
    data: TimeseriesDay[];
}

export default class PreviewDataService {
    static formatChartDataObject(label: string, data: TimeseriesDay[], colour: string): ChartDataset {
        return {
            label: label,
            data: data,
            backgroundColor: colour,
            pointRadius: 0,
            borderWidth: 2,
            borderColor: colour,
            pointHitRadius: 5
        };
    }

    static buildChartDataSet(data: ParsedData[]): ChartDataset[] {
        return data.map((d, i) => {
            return this.formatChartDataObject(
                d.name,
                d.data,
                CHART_LEGEND_COLOUR_OPTIONS[i % CHART_LEGEND_COLOUR_OPTIONS.length]
            );
        });
    }

    static formatLocationData(
        systemScenariosData: SystemDataPreviewScenarioData[],
        locations: LocationModel[],
        scenarioId: string,
        dataType: DataType
    ): ERPTimerseriesChartData {
        const data: ParsedData[] = systemScenariosData
            .filter(s => s.scenarioId === scenarioId)
            .flatMap(s => {
                return s.locationsData;
            })
            .filter(l => l.dataType === dataType)
            .map(d => {
                return { name: locations.find(l => l.id === d.locationId)?.name, data: d.data };
            });

        const chartData = this.buildChartDataSet(data);

        return chartData;
    }

    static formatScenarioData(
        systemScenariosData: SystemDataPreviewScenarioData[],
        locationId: string,
        dataType: DataType
    ): ERPTimerseriesChartData {
        const data: ParsedData[] = systemScenariosData.flatMap(s => {
            return s.locationsData
                .filter(l => l.locationId === locationId && l.dataType === dataType)
                .map(d => {
                    return { name: s.scenarioName, data: d.data };
                });
        });

        const chartData = this.buildChartDataSet(data);

        return chartData;
    }

    static formatTimeseriesData(data: TimeseriesDay[], dataType: DataType, unit: string): ERPTimerseriesChartData {
        const chartData = this.buildChartDataSet([
            { name: `${this.getDataTypeLabel(dataType)} (${unit})`, data: data }
        ]);

        return chartData;
    }

    static getDataTypeLabel(dataType: DataType): string {
        switch (dataType) {
            case DataType.FLOW:
                return "Flow";
            case DataType.DEPTH:
                return "Depth";
            case DataType.COMPLIANCE:
                return "Compliance";
            case DataType.EVAPORATION:
                return "Evaporation";
            case DataType.RAINFALL:
                return "Rainfall";
            case DataType.SALINITY:
                return "Salinity";
            case DataType.TEMPERATURE:
                return "Temperature";

            default:
                return "Value";
        }
    }

    static formatTimeseriesAsFile(data: TimeseriesDay[], name: string, valueHeader: string): File {
        const formattedData = csvFormat(
            data.map(d => {
                const dayRecord = {};

                dayRecord["date"] = moment(d.date).format("DD/MM/YYYY");
                dayRecord[valueHeader] = d.value;

                return dayRecord;
            })
        );

        return new File([formattedData], name);
    }
}
