import ERPModal from "../../Components/Modals/ERPModal";
import React, { useEffect, useState } from "react";
import { DataType, SystemDataPreviewSettings } from "../../types/models";
import DataPreviewTabs, { DataPreviewTabType, TabSelection } from "./DataPreviewTabs";
import DataPreviewOptions from "./DataPreviewOptions";
import SystemDataPreviewModalBody from "./SystemDataPreviewModalBody";
import { useSystem } from "./SystemContext";

interface SystemDataPreviewModalProps {
    show: boolean;
    onClose: () => void;
}

const SystemDataPreviewModal = ({ show, onClose }: SystemDataPreviewModalProps) => {
    const { locations, selectedScenario, system } = useSystem();

    const [activeTab, setActiveTab] = useState<TabSelection>({ index: 0, type: DataPreviewTabType.LOCATIONS });
    const [settings, setSettings] = useState<SystemDataPreviewSettings>(null);

    useEffect(() => {
        setSettings({
            selectedScenario: selectedScenario,
            selectedLocation: locations[0],
            selectedDataType: DataType.FLOW
        });
    }, [locations, selectedScenario]);

    return (
        <ERPModal
            show={show}
            title={`Preview system data - ${system?.name}`}
            onClose={onClose}
            size="xl"
            scrollable
            backdrop={true}
            bodyClassName="data-preview-modal-body"
            className="data-preview-modal"
            dialogClassName="data-preview-modal-dialog"
            contentClassName="data-preview-modal-content"
            canClose={true}
        >
            <DataPreviewTabs selectedTab={activeTab} onTabSelected={setActiveTab} />

            <DataPreviewOptions previewOptions={settings} activeTab={activeTab.type} setPreviewOptions={setSettings} />

            <SystemDataPreviewModalBody settings={settings} activeTab={activeTab.type} />
        </ERPModal>
    );
};

export default SystemDataPreviewModal;
