import React, { useMemo } from "react";
import { ResourceType, SearchModel } from "../../types/models";
import { fullname, isNilOrEmpty } from "../../utils/utils";
import SearchResultRow from "./SearchResultRow";
import { useHistory } from "react-router-dom";
import LoadingRow from "./LoadingRow";
import { useSort } from "../../Components/SortableHeader/useSort";
import SortableHeader from "../../Components/SortableHeader";
import EmptyRow from "../../Components/EmptyRow";

interface SearchResultsTableProps {
    searchResults: SearchModel[];
    isLoading: boolean;
    onFolderClick: (folderId: string, ancestorIds: string[]) => void;
}

const SearchResultsTable = ({ searchResults, isLoading, onFolderClick }: SearchResultsTableProps) => {
    const history = useHistory();

    const handleResultNavigation = (result: SearchModel) => {
        if (result.type === ResourceType.FOLDER) {
            onFolderClick(result.id, result.ancestorIds);
        } else if (result.type === ResourceType.MODEL) {
            history.push(`/model/${result.id}`);
        } else if (result.type === ResourceType.SYSTEM) {
            history.push(`/system/${result.id}`);
        }
    };

    const results = useMemo(() => {
        const sortOrder = [ResourceType.FOLDER, ResourceType.MODEL, ResourceType.SYSTEM];

        return searchResults.sort(function (a, b) {
            return sortOrder.indexOf(a.type) - sortOrder.indexOf(b.type);
        });
    }, [searchResults]);

    const { sortedItems, handleSort, sortContext } = useSort(results);

    const isEmpty = !isLoading && isNilOrEmpty(searchResults);

    return (
        <div className="search--table">
            <table>
                <thead>
                    <tr>
                        <th style={{ width: 50 }}>{/* Row Icon */}</th>

                        <SortableHeader
                            label="Name"
                            sortKey="name"
                            context={sortContext}
                            style={{ width: "10%", textAlign: "left" }}
                            sorter={(sm: SearchModel) => sm.name?.toLowerCase() ?? ""}
                            onSort={handleSort}
                        />

                        <th style={{ width: 100 }}>Type</th>

                        <SortableHeader
                            label="Created by"
                            sortKey="createdBy"
                            context={sortContext}
                            style={{ width: 100 }}
                            sorter={(sm: SearchModel) => fullname(sm.createdBy)}
                            onSort={handleSort}
                            centered
                        />

                        <SortableHeader
                            label="Last modified"
                            sortKey="updatedAt"
                            context={sortContext}
                            style={{ width: 100 }}
                            sorter={(sm: SearchModel) => new Date(sm.updatedAt).getTime()}
                            onSort={handleSort}
                            centered
                        />

                        <th style={{ width: 210 }}>Location</th>
                    </tr>
                </thead>

                <tbody>
                    {isEmpty && <EmptyRow message="No results match your search" colSpan={7} />}

                    {isLoading && <LoadingRow collSpan={6} />}

                    {!isLoading &&
                        sortedItems.map(result => {
                            return (
                                <SearchResultRow
                                    key={result.id}
                                    result={result}
                                    onResultNavigation={handleResultNavigation}
                                />
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
};

export default SearchResultsTable;
