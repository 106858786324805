import React, { useMemo } from "react";
import { faProjectDiagram, faSlidersH, faUsers } from "@fortawesome/pro-solid-svg-icons";
import { useHistory, useLocation } from "react-router-dom";
import LabelHeaderContent from "./LabelHeaderContent";
import NavigationHeaderContent from "./NavigationHeaderContent";
import HeaderIcon from "./HeaderIcon";
import UserMenu from "./UserMenu";
import { startsWith } from "lodash";
import { useAuth0 } from "../../auth/react-auth0-spa";
import { GroupModel } from "../../types/models";

import "./Header.scss";

const Header = () => {
    const { isAuthenticated, erpUser, logout, setActiveGroup } = useAuth0();

    const history = useHistory();
    const location = useLocation();

    const handleGroupSelected = async (group: GroupModel) => {
        await setActiveGroup(group);

        history.push("/");
    };

    const HeaderContent = useMemo(() => {
        const pathname = location.pathname;

        //Custom Headers
        if (startsWith(pathname, "/system")) {
            return <LabelHeaderContent label="System" icon={faProjectDiagram} iconClassName="system-icon" />;
        }

        if (startsWith(pathname, "/model")) {
            return <LabelHeaderContent label="Model" icon={faSlidersH} iconClassName="model-icon" />;
        }

        if (startsWith(pathname, "/manage-account")) {
            return <LabelHeaderContent label="Manage Account" icon={faUsers} iconClassName="manage-account-icon" />;
        }

        //Default Header
        if (startsWith(pathname, "/explorer")) {
            return <NavigationHeaderContent />;
        }

        //No Header
        return null;
    }, [location.pathname]);

    if (isAuthenticated) {
        return (
            <div className="erp-header">
                <HeaderIcon />

                {HeaderContent}

                <UserMenu erpUser={erpUser} logout={logout} onGroupSelected={handleGroupSelected} />
            </div>
        );
    } else {
        return null;
    }
};

export default Header;
