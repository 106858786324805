import React, { useMemo } from "react";
import { RiskLevel } from "../../types/models";
import ChartLegendItem from "./ChartLegendItem";

interface ModelExploreTemporalRiskChartLegendProps {
    selectedRisks: RiskLevel[];
    onRiskChanged: (risks: RiskLevel[]) => void;
}

const ModelExploreTemporalRiskChartLegend = ({
    selectedRisks,
    onRiskChanged
}: ModelExploreTemporalRiskChartLegendProps) => {
    const risks = useMemo(() => {
        return [RiskLevel.LOW, RiskLevel.MODERATE, RiskLevel.HIGH].map(risk => {
            return {
                label: risk,
                isSelected: selectedRisks.includes(risk)
            };
        });
    }, [selectedRisks.length]);

    const handleRiskSelected = (risk: RiskLevel, active: boolean) => {
        if (!active) {
            onRiskChanged(selectedRisks.filter(r => r !== risk));
        } else {
            onRiskChanged([...selectedRisks, risk]);
        }
    };

    return (
        <div className="chart-legend">
            <ChartLegendItem key={`temporal_risk_chart_legend_no_data`} label="no-data" isSelectable={false} />
            {risks.map(risk => {
                return (
                    <ChartLegendItem
                        key={`temporal_risk_chart_legend_${risk.label}`}
                        label={risk.label}
                        isSelectable
                        isSelected={risk.isSelected}
                        onSelected={handleRiskSelected}
                    />
                );
            })}
        </div>
    );
};

export default ModelExploreTemporalRiskChartLegend;
