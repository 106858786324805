import RunPeriod from "../../Components/RunPeriod";
import React, { useEffect } from "react";
import { DataType, OptimisationResultsModel } from "../../types/models";
import PluginWaterholeRunToEmptyModalSettings from "./PluginWaterholeRunToEmptyModalSettings";
import { WaterholeRunToEmptyParameters, WaterholeRunToEmptyResults } from "./types";
import PluginWaterholeRunToEmptyModalResults from "./PluginWaterholeRunToEmptyModalResults";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { debounce, isNil } from "lodash";
import PluginWaterholeOptimisationData from "./PluginWaterholeOptimisationData";
import PluginWaterholeRunToEmptyModalChart from "./PluginWaterholeRunToEmptyModalChart";
import { Toast } from "../../Components/Toast";
import { numberToMonth } from "../../utils/utils";

interface PluginWaterholeRunToEmptyModalBodyProps {
    runToEmptyParameters: WaterholeRunToEmptyParameters;
    results: OptimisationResultsModel;
    modelId: string;
    locationId: string;
    siteId: string;
    optimisationId: string;
    disabled?: boolean;
    onParametersChanged: (parameters: WaterholeRunToEmptyParameters) => void;
}

const DEBOUCE_TRIGGER_SAVE = 500;

const schema = yup.object().shape({
    settings: yup.object().shape({}),
    season: yup.object().shape({})
});

const PluginWaterholeRunToEmptyModalBody = ({
    runToEmptyParameters,
    results,
    modelId,
    locationId,
    siteId,
    optimisationId,
    disabled,
    onParametersChanged
}: PluginWaterholeRunToEmptyModalBodyProps) => {
    const methods = useForm<WaterholeRunToEmptyParameters>({
        resolver: yupResolver(schema),
        mode: "onBlur",
        reValidateMode: "onBlur"
    });

    useEffect(() => {
        if (isNil(runToEmptyParameters)) {
            return;
        }

        methods.setValue(`settings`, runToEmptyParameters.settings ?? null);
        methods.setValue(`season`, runToEmptyParameters.season ?? null);
    }, []);

    useEffect(() => {
        const subscription = !isNil(onParametersChanged)
            ? methods.watch(debounce(onParametersChanged, DEBOUCE_TRIGGER_SAVE))
            : null;
        return () => {
            subscription?.unsubscribe();
        };
    }, []);

    const handleSetDate = (date: Date) => {
        const season = methods.getValues(`season`);

        if (!isNil(season?.start)) {
            Toast.error("Start date already set!");
            return;
        }

        const startDate = { day: date.getDate(), month: numberToMonth(date.getMonth() + 1), year: date.getFullYear() };
        const next = { ...season, start: startDate };
        methods.setValue(`season`, next);
        Toast.success("Start date set!");
    };

    return (
        <>
            <div className="run-to-empty-modal-body">
                <Controller
                    control={methods.control}
                    name="season"
                    render={({ field: _field }) => {
                        return (
                            <RunPeriod
                                runPeriod={_field.value}
                                onRunPeriodChanged={next => {
                                    methods.setValue("season", next);
                                }}
                                includeEnd={false}
                                className="run-period"
                                disabled={disabled}
                            />
                        );
                    }}
                />
                <Controller
                    control={methods.control}
                    name="settings"
                    render={({ field: _field }) => {
                        return (
                            <PluginWaterholeRunToEmptyModalSettings
                                settings={_field.value}
                                onSettingsChanged={next => {
                                    methods.setValue("settings", next);
                                }}
                                disabled={disabled}
                            />
                        );
                    }}
                />
                <PluginWaterholeRunToEmptyModalResults
                    results={results?.parameterResults as WaterholeRunToEmptyResults}
                />
            </div>
            <PluginWaterholeRunToEmptyModalChart
                id="optimisation-chart"
                resultFiles={results?.files}
                onSetDate={handleSetDate}
            />
            <PluginWaterholeOptimisationData
                id="run-to-empty-data"
                modelId={modelId}
                locationId={locationId}
                siteId={siteId}
                optimisationId={optimisationId}
                allowedDataTypes={[DataType.EVAPORATION, DataType.BATHYMETRY]}
                className="run-to-empty-data"
                disabled={disabled}
            />
        </>
    );
};

export default PluginWaterholeRunToEmptyModalBody;
