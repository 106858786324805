import React, { useEffect, useMemo } from "react";
import {
    PluginSchema,
    PluginParameters as PluginFieldParameters,
    PluginFieldValue,
    PluginDefinitionControlTypes,
    PluginScopes,
    PluginDefinition
} from "../../types/plugin";
import PluginParameterDefinition from "./PluginParameterDefinition";
import PluginWaterholeOptimisationParameterDefinition from "./PluginWaterholeOptimisationParameterDefinition";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { PluginForm } from "./types";
import { debounce, forEach, isNil } from "lodash";
import { registerField } from "./utils";
import { isNilOrEmpty } from "../../utils/utils";
import SiteDataParameterDefinition from "./SiteDataParameterDefinition";
import { ModelLocationSiteDataModel } from "../../types/models";
import PluginDefinitions from "./PluginDefinitions";

import "./Plugin.scss";
import { ValueChange } from "../../Scenes/Model/types";

interface PluginParametersProps {
    /**
     * Unique identifier to make sure this input is uniquely rendered amongst other plugin parameters.
     * This is important to avoid lose of focus on re-render caused by state change during parameter saving.
     */
    id: string;
    parameters: PluginFieldParameters;
    schema: PluginSchema;
    disabled?: boolean;
    scope: PluginScopes;
    /**
     * An optional object to hold context data to pass down to parameters
     */
    data?: Record<string, any>;
    onParametersChanged?: (parameters: PluginFieldParameters) => void;
    onValueChanged?: (definition: string, field: string, value: PluginFieldValue) => void;
    onValuesChanged?: (changedValues: ValueChange[]) => void;
    onFilesAdded?: (files: ModelLocationSiteDataModel[]) => void;
    onFileUpdated?: (file: ModelLocationSiteDataModel) => void;
    onFileRemoved?: (file: ModelLocationSiteDataModel) => void;
}

const DEBOUCE_TRIGGER_SAVE = 500;

const PluginParameters = ({
    id,
    parameters,
    schema,
    disabled,
    scope,
    data,
    onParametersChanged,
    onValueChanged,
    onValuesChanged,
    onFilesAdded,
    onFileUpdated,
    onFileRemoved
}: PluginParametersProps) => {
    const formSchema = useMemo(() => {
        let _formSchema: yup.AnyObjectSchema = yup.object().shape({});

        const definitions = [...schema.definition_groups, ...schema.assessment_groups];

        for (let i = 0; i < definitions.length; i++) {
            const group = definitions[i];
            const definition = schema.definitions[group] ?? schema.assessments[group];

            if (isNil(definition)) {
                console.log(`plugin:${schema.id} | definition:${group} | not_found`);
                continue;
            }

            let definitionSchema: yup.AnyObjectSchema = yup.object().shape({});

            for (let j = 0; j < definition.fields.length; j++) {
                const field = definition.fields[j];

                definitionSchema = registerField(field, definitionSchema);
            }

            _formSchema = _formSchema.shape({
                [definition.id]: definitionSchema.fields
            });
        }

        return _formSchema;
    }, [schema]);

    const methods = useForm<PluginForm>({
        resolver: yupResolver(formSchema),
        mode: "onBlur",
        reValidateMode: "onBlur"
    });

    useEffect(() => {
        if (isNil(parameters)) {
            return;
        }

        const definitions = [...schema.definition_groups, ...schema.assessment_groups];

        for (let i = 0; i < definitions.length; i++) {
            const group = definitions[i];
            const definition = schema.definitions[group] ?? schema.assessments[group];

            if (isNil(definition)) {
                continue;
            }

            const parametersDefinition = parameters[definition.id];

            if (isNil(parametersDefinition)) {
                continue;
            }

            forEach(Object.keys(parametersDefinition), field => {
                const fieldValue = parametersDefinition[field];

                methods.setValue(`${definition.id}.${field}`, fieldValue ?? null);
            });
        }
    }, [schema]);

    useEffect(() => {
        if (disabled) {
            return;
        }

        const subscription = !isNil(onParametersChanged)
            ? methods.watch(debounce(onParametersChanged, DEBOUCE_TRIGGER_SAVE))
            : null;

        return () => {
            subscription?.unsubscribe();
        };
    }, [disabled]);

    const groups = useMemo(() => {
        const definitions: PluginDefinition[] = [];

        for (let i = 0; i < schema.definition_groups.length; i++) {
            const key = schema.definition_groups[i];
            const definition = schema.definitions[key];

            if (isNil(definition) || !definition.scopes.includes(scope)) {
                continue;
            }

            definitions.push(definition);
        }
        return definitions;
    }, []);

    const assessments = useMemo(() => {
        const definitions: PluginDefinition[] = [];

        if (!isNilOrEmpty(schema.assessment_groups)) {
            for (let i = 0; i < schema.assessment_groups.length; i++) {
                const key = schema.assessment_groups[i];
                const definition = schema.assessments[key];

                if (isNil(definition) || !definition.scopes.includes(scope)) {
                    continue;
                }

                definitions.push(definition);
            }
        }

        return definitions;
    }, []);

    if (isNilOrEmpty(groups) && isNilOrEmpty(assessments)) {
        return null;
    }

    return (
        <FormProvider {...methods}>
            <div>
                {!isNilOrEmpty(groups) && (
                    <PluginDefinitions name="Parameters" useBreak={false}>
                        {groups.map(definition => {
                            const _id = `${id}-${definition.id}`;

                            if (!isNilOrEmpty(definition.control)) {
                                switch (definition.control) {
                                    case PluginDefinitionControlTypes.WATERHOLE_OPTIMISATION:
                                        return (
                                            <PluginWaterholeOptimisationParameterDefinition
                                                key={_id}
                                                id={_id}
                                                locationId={data?.locationId}
                                                siteId={data?.siteId}
                                                siteName={data?.siteName}
                                                definition={definition}
                                                disabled={disabled}
                                                onValueChanged={onValueChanged}
                                                onValuesChanged={onValuesChanged}
                                            />
                                        );
                                    case PluginDefinitionControlTypes.SITE_DATA:
                                        return (
                                            <SiteDataParameterDefinition
                                                key={_id}
                                                id={_id}
                                                definition={definition}
                                                data={data}
                                                disabled={disabled}
                                                onFilesAdded={onFilesAdded}
                                                onFileUpdated={onFileUpdated}
                                                onFileRemoved={onFileRemoved}
                                            />
                                        );
                                    default:
                                        return null;
                                }
                            }

                            return (
                                <PluginParameterDefinition
                                    key={_id}
                                    id={_id}
                                    definition={definition}
                                    disabled={disabled}
                                    onValueChanged={onValueChanged}
                                />
                            );
                        })}
                    </PluginDefinitions>
                )}

                {!isNilOrEmpty(assessments) && (
                    <PluginDefinitions name="Assessment" useBreak={scope === PluginScopes.MODEL}>
                        {assessments.map(definition => {
                            const _id = `${id}-${definition.id}`;

                            return (
                                <PluginParameterDefinition
                                    key={_id}
                                    id={_id}
                                    definition={definition}
                                    disabled={disabled}
                                    onValueChanged={onValueChanged}
                                />
                            );
                        })}
                    </PluginDefinitions>
                )}
            </div>
        </FormProvider>
    );
};

export default PluginParameters;
