import { isNil, orderBy } from "lodash";
import { FolderModel, BaseEntity } from "../../types/models";
import { isNilOrEmpty } from "../../utils/utils";
import { Stack, FileSystemView } from "./types";

export function setTimestamps(folders: FolderModel[]): void {
    let parent_folder: FolderModel = null;

    for (let i = folders.length - 1; i > 0; i--) {
        const folder = folders[i];

        if (isNil(parent_folder) || folder.parentId !== parent_folder.id) {
            parent_folder = folders.find(f => f.id === folder.parentId);
        }

        if (new Date(folder.updatedAt) > new Date(parent_folder.updatedAt)) {
            parent_folder.updatedAt = folder.updatedAt;
        }
    }
}

export function updateTimestamps(stack: Stack<FileSystemView>): void {
    for (let i = stack.size() - 1; i > 0; i--) {
        const view = stack.peekAt(i);

        if (!isNilOrEmpty(view.subFolders)) {
            const entities: BaseEntity[] = [
                ...view.subFolders.map(sf => sf),
                ...view.systems.map(s => s),
                ...view.models.map(m => m)
            ];

            const ordered_entities = orderBy(entities, e => new Date(e.updatedAt), "desc");

            const most_recent_entity = ordered_entities[0];

            view.folder.updatedAt = most_recent_entity.updatedAt;
        }
    }
}

export function shouldUpdateTimestamp(entity: BaseEntity, view: FileSystemView) {
    if (view.isRoot) {
        return false;
    }

    return entity.updatedAt >= view.folder.updatedAt;
}
