import clsx from "clsx";
import React, { useState } from "react";
import NumericInput from "../NumericInput";

interface CrossSectionCellProps {
    value: number;
    disabled: boolean;
    onChanged: (value: number) => void;
}

const CrossSectionCell = ({ value, disabled, onChanged }: CrossSectionCellProps) => {
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [currentVal, setCurrentVal] = useState<number>(value);

    const handleSetEdit = () => {
        if (disabled) {
            return;
        }

        setIsEdit(true);
    };

    const handleEdit = (newValue: number) => {
        setIsEdit(false);

        if (newValue === currentVal) {
            return;
        }

        setCurrentVal(newValue);
        onChanged(newValue);
    };

    return (
        <td className="cross-section--table-value">
            {!isEdit && (
                <span className={clsx({ "cross-section-editable-span": !disabled })} onClick={handleSetEdit}>
                    {value}
                </span>
            )}

            {isEdit && (
                <div className="cross-section--table-value-edit-container">
                    <NumericInput
                        containerClassName="cross-section--table-value-input-container"
                        value={currentVal}
                        onLoseFocus={handleEdit}
                        className="cross-section--table-value-input"
                        autoFocus
                        min={0}
                    />
                </div>
            )}
        </td>
    );
};

export default CrossSectionCell;
