import { ChartDataset, ChartOptions, Point } from "chart.js";
import ERPChart from "../Charts/ERPChart";
import React, { useMemo } from "react";
import { isNilOrEmpty } from "../../utils/utils";

interface CrossSectionChartProps {
    values: number[][];
}

const CHART_OPTIONS: ChartOptions = {
    maintainAspectRatio: false,
    interaction: {
        mode: "index"
    },
    scales: {
        x: {
            grid: { display: true, drawBorder: false },
            ticks: {
                display: false
            },
            type: "linear",
            title: { text: "Width (m)", display: true }
        },
        y: {
            grid: { display: true, drawBorder: false },
            ticks: {
                display: false
            },
            type: "linear",
            title: { text: "Depth (m)", display: true }
        }
    },

    plugins: {
        tooltip: {
            enabled: true,
            position: "average",
            callbacks: {
                title: function (tooltipItems) {
                    if (isNilOrEmpty(tooltipItems)) {
                        return;
                    }

                    const tooltipItem = tooltipItems[0];

                    return `${tooltipItem.label}, ${-tooltipItem.formattedValue}`;
                },
                label: function () {
                    return null;
                }
            }
        },
        legend: {
            display: false
        }
    },
    hover: {
        mode: "index",
        intersect: false
    }
};

const CrossSectionCurveChart = ({ values }: CrossSectionChartProps) => {
    const chartData: ChartDataset[] = useMemo(() => {
        const points: Point[] = values.map(record => {
            return {
                x: record[0],
                y: -record[1]
            };
        });

        return [
            {
                label: "",
                data: points,
                borderColor: "#08bfdd",
                backgroundColor: "#08bfdd",
                clip: 5
            }
        ];
    }, [values]);

    return (
        <div className="cross-section-graph-container">
            <ERPChart type="line" data={chartData} options={CHART_OPTIONS} height="100%" />
        </div>
    );
};

export default CrossSectionCurveChart;
