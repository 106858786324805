import { SummaryParameters } from "../../types/plugin-parameter-types";
import { PluginParameters } from "../../types/plugin";
import { BaseParameterMerger } from "./base-parameter-merger";

export class SummaryParameterMerger extends BaseParameterMerger<SummaryParameters> {
    public merge(source: PluginParameters, defaultParameters: SummaryParameters): SummaryParameters {
        return {
            data: {
                data_type: this.defaultTo(source?.data?.data_type, defaultParameters?.data?.data_type),
                season_start: this.defaultTo(source?.data?.season_start, defaultParameters?.data?.season_start),
                season_end: this.defaultTo(source?.data?.season_end, defaultParameters?.data?.season_end)
            },
            stats: {
                yearly: this.defaultTo(source?.stats?.yearly, defaultParameters?.stats?.yearly),
                summary: this.defaultTo(source?.stats?.summary, defaultParameters?.stats?.summary)
            }
        };
    }
}
