import Button from "../../Components/Button";
import React, { useState } from "react";
import { OptimisationResult } from "../../types/models";
import PluginWaterholeCalibrateModalBathymetry from "./PluginWaterholeCalibrationModalBathymetry";
import PluginWaterholeCalibrationModalChart from "./PluginWaterholeCalibrationModalChart";

interface PluginWaterholeCalibrateModalChartsProps {
    modelId: string;
    locationId: string;
    siteId: string;
    optimisationId: string;
    resultFiles: OptimisationResult[];
    onSetDate: (date: Date) => void;
}

const PluginWaterholeCalibrateModalCharts = ({
    modelId,
    locationId,
    siteId,
    optimisationId,
    resultFiles,
    onSetDate
}: PluginWaterholeCalibrateModalChartsProps) => {
    const [showBathymetry, setShowBathymetry] = useState<boolean>(false);

    return (
        <div className="calibration-charts">
            <Button
                className="toggle-button"
                onClick={() => {
                    setShowBathymetry(!showBathymetry);
                }}
            >
                Toggle View
            </Button>

            {!showBathymetry && (
                <PluginWaterholeCalibrationModalChart
                    id="optimisation-chart"
                    resultFiles={resultFiles}
                    onSetDate={onSetDate}
                />
            )}

            {showBathymetry && (
                <PluginWaterholeCalibrateModalBathymetry
                    modelId={modelId}
                    locationId={locationId}
                    siteId={siteId}
                    optimisationId={optimisationId}
                />
            )}
        </div>
    );
};

export default PluginWaterholeCalibrateModalCharts;
