import React, { useState } from "react";
import { LocationModel, ScenarioModel } from "../../types/models";
import ScenarioPicker from "./ScenarioPicker";
import ScenarioInfo from "./ScenarioInfo";
import ScenarioModal, { ScenarioModalContext } from "./ScenarioModal";
import { isNil } from "lodash";
import SystemService from "../../Services/system.service";
import { isNilOrEmpty } from "../../utils/utils";
import { UpdateScenarioRequest } from "../../types/requests";
import LocationModal, { LocationModalContext } from "./LocationModal";
import { useConfirmation } from "../../Components/ConfirmDialog/ConfirmationContext";
import { Toast } from "../../Components/Toast";
import Button from "../../Components/Button";
import { faDownload, faEye, faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import SystemDataPreviewModal from "./SystemDataPreviewModal";
import SystemBulkImportModal from "./SystemBulkImportModal";
import { useSystem } from "./SystemContext";
import ZipGenerator, { ZipFileInfo } from "../../Services/zip-generator";
import AzureBlobService from "../../Services/azure.blob.service";
import LoadingButton from "../../Components/LoadingButton";

const ScenarioAndLocations = () => {
    const {
        system,
        readOnly,
        scenarios,
        locations,
        selectedScenario,
        data,
        updateSelectedScenario,
        updateScenarios,
        updateLocations
    } = useSystem();

    const [scenarioModalContext, setScenarioModalContext] = useState<ScenarioModalContext>(null);
    const [locationModalContext, setLocationModalContext] = useState<LocationModalContext>(null);

    const [showDataPreviewModal, setShowDataPreviewModal] = useState<boolean>(false);
    const [showBulkImportModal, setShowBulkImportModal] = useState<boolean>(false);

    const [isDownloading, setIsDownloading] = useState<boolean>(false);

    const confirm = useConfirmation();

    const handleScenarioCreated = (scenario: ScenarioModel) => {
        const next = [...scenarios, scenario];

        if (scenario.isDefault) {
            for (let i = 0; i < next.length; i++) {
                const nextScenario = next[i];

                if (nextScenario.id !== scenario.id && nextScenario.isDefault) {
                    nextScenario.isDefault = false;
                    break;
                }
            }
        }

        updateSelectedScenario(scenario);
        updateScenarios(next);
    };

    const handleScenarioQuickUpdate = async (update: ScenarioModel) => {
        handleScenarioUpdated(update);

        const request: UpdateScenarioRequest = {
            name: update.name,
            description: update.description,
            isDefault: update.isDefault
        };

        await SystemService.updateScenario(update.systemId, update.id, request);
    };

    const handleScenarioUpdated = async (update: ScenarioModel) => {
        const next = scenarios.map(s => {
            if (s.id === update.id) {
                return update;
            }

            if (s.isDefault && update.isDefault) {
                return {
                    ...s,
                    isDefault: false
                };
            }

            return s;
        });

        updateScenarios(next);
        updateSelectedScenario(update);
    };

    const handleScenarioDelete = async (scenario: ScenarioModel) => {
        const result = await confirm({
            title: "Warning",
            description:
                "You are about to delete a scenario. All associated results will be lost, do you wish to proceed?"
        });

        if (!result.success) {
            return;
        }

        const next = scenarios.filter(s => s.id !== scenario.id);

        updateScenarios(next);

        if (scenario.id === selectedScenario?.id) {
            updateSelectedScenario(!isNilOrEmpty(next) ? next[0] : null);
        }

        await SystemService.deleteScenario(scenario.systemId, scenario.id);

        Toast.success(`Successfuly deleted scenario ${scenario.name}`);
    };

    const handleLocationCreated = (location: LocationModel) => {
        const next = [...locations, location];

        updateLocations(next);
    };

    const handleLocationUpdated = (location: LocationModel) => {
        const next = locations.map(l => {
            if (l.id === location.id) {
                return location;
            }

            return l;
        });

        updateLocations(next);
    };

    const handleLocationDelete = async (location: LocationModel) => {
        const result = await confirm({
            title: "Warning",
            description: "You are about to delete a location. This operation cannot be undone, do you wish to proceed?"
        });

        if (!result.success) {
            return;
        }

        const next = locations.filter(s => s.id !== location.id);

        updateLocations(next);

        await SystemService.deleteLocation(location.systemId, location.id);

        Toast.success(`Successfuly deleted location ${location.name}`);
    };

    const handleDownloadScenarioData = async () => {
        if (data.length < 1) {
            Toast.error("System has no data");
            return;
        }

        try {
            const generator = new ZipGenerator();

            setIsDownloading(true);

            const files: ZipFileInfo[] = await Promise.all(
                data.map(async d => {
                    const data = await AzureBlobService.downloadBlobAsFile(d.blobId, d.name);
                    return { name: data.name, file: data };
                })
            );

            const blob = await generator.generate(files);

            AzureBlobService.downloadToDisk(blob, `${system.name}_data.zip`);
        } finally {
            setIsDownloading(false);
        }
    };

    return (
        <div className="system-section locations-and-scenarios-containers">
            <h5>Locations and scenarios</h5>

            <div className="action-button-group">
                <LoadingButton isLoading={isDownloading} icon={faDownload} onClick={handleDownloadScenarioData}>
                    Download All Data
                </LoadingButton>

                <Button icon={faPlusCircle} disabled={readOnly} onClick={() => setShowBulkImportModal(true)}>
                    Bulk Import
                </Button>

                <Button
                    icon={faEye}
                    disabled={scenarios.length === 0 || locations.length === 0}
                    onClick={() => setShowDataPreviewModal(true)}
                >
                    Preview Data
                </Button>
            </div>

            <div className="locations-and-scenarios">
                <ScenarioPicker
                    onScenarioSelected={updateSelectedScenario}
                    onScenarioAdded={() => setScenarioModalContext({ system: system })}
                    onScenarioEdit={scenario => setScenarioModalContext({ system: system, scenario: scenario })}
                    onScenarioDelete={handleScenarioDelete}
                />

                <ScenarioInfo
                    scenario={selectedScenario}
                    onLocationAdded={() => setLocationModalContext({ system: system })}
                    onLocationUpdate={location => setLocationModalContext({ system: system, location: location })}
                    onLocationDelete={handleLocationDelete}
                    onScenarioUpdated={handleScenarioQuickUpdate}
                />
            </div>

            {!isNil(scenarioModalContext) && (
                <ScenarioModal
                    show
                    context={scenarioModalContext}
                    onClose={() => setScenarioModalContext(null)}
                    onScenarioCreated={handleScenarioCreated}
                    onScenarioUpdated={handleScenarioUpdated}
                />
            )}

            {!isNil(locationModalContext) && (
                <LocationModal
                    show
                    context={locationModalContext}
                    onClose={() => setLocationModalContext(null)}
                    onLocationCreated={handleLocationCreated}
                    onLocationUpdated={handleLocationUpdated}
                />
            )}

            <SystemDataPreviewModal show={showDataPreviewModal} onClose={() => setShowDataPreviewModal(false)} />

            {showBulkImportModal && (
                <SystemBulkImportModal show={showBulkImportModal} onClose={() => setShowBulkImportModal(false)} />
            )}
        </div>
    );
};

export default ScenarioAndLocations;
