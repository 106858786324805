import React from "react";
import Label from "../Label";
import NumericInput from "../NumericInput";
import { SILOConnectionSettings } from "./types";

interface ObservedDataModalSILOOptionsProps {
    settings: SILOConnectionSettings;
    onSettingsChanged: (nextSettings: SILOConnectionSettings) => void;
}

const ObservedDataModalSILOOptions = ({ settings, onSettingsChanged }: ObservedDataModalSILOOptionsProps) => {
    const handleLaitudeChanged = (nextLatitude: number) => {
        if (nextLatitude !== settings?.latitude) {
            onSettingsChanged({ ...settings, latitude: nextLatitude });
        }
    };

    const handleLongitudeChanged = (nextLongitude: number) => {
        if (nextLongitude !== settings?.longitude) {
            onSettingsChanged({ ...settings, longitude: nextLongitude });
        }
    };

    return (
        <>
            <div className="option-field">
                <Label>Latitude</Label>
                <NumericInput value={settings?.latitude} onChange={handleLaitudeChanged} />
            </div>

            <div className="option-field">
                <Label>Longitude</Label>
                <NumericInput value={settings?.longitude} onChange={handleLongitudeChanged} />
            </div>
        </>
    );
};

export default ObservedDataModalSILOOptions;
