import { useState, useEffect, useMemo } from "react";
import { isNil } from "lodash";
import { FolderModel, ModelModel, SystemModel } from "../../types/models";
import { Stack, FileSystemView, Breadcrumbs } from "./types";
import { isNilOrEmpty, sleep } from "../../utils/utils";
import { setTimestamps, updateTimestamps, shouldUpdateTimestamp } from "./utils";
import UserService from "../../Services/user.service";
import FolderService from "../../Services/folder.service";

interface FileSystemOptions {
    alwaysLoadCollectionsOnBackNavigation?: boolean;
    trackTimestamps?: boolean;
    fromStack?: Stack<FileSystemView>;
}

const DEFAULT_OTPIONS: FileSystemOptions = {
    alwaysLoadCollectionsOnBackNavigation: true,
    trackTimestamps: false,
    fromStack: null
};

export interface FileSystem {
    folders: FolderModel[];
    stack: Stack<FileSystemView>;
    view: FileSystemView;
    breadcrumbs: Breadcrumbs;
    isLoading: boolean;
    updateStack: (stack: Stack<FileSystemView>) => void;
    navigateToFolder: (folder: FolderModel) => void;
    navigateBack: () => void;
    navigateBackToFolder: (index: number) => void;
    addFolder: (folder: FolderModel) => void;
    addFolderToView: (folder: FolderModel) => void;
    addFolders: (folders: FolderModel[]) => void;
    addFoldersToView: (folders: FolderModel[]) => void;
    updateFolder: (folder: FolderModel) => void;
    updateFolders: (folders: FolderModel[]) => void;
    removeFolder: (folder: FolderModel) => void;
    removeFolderFromView: (folder: FolderModel) => void;
    removeFolderByIds: (folderIds: string[]) => void;
    removeFolderFromViewByIds: (folderIds: string[]) => void;
    addModel: (model: ModelModel) => void;
    updateModel: (model: ModelModel) => void;
    addModels: (models: ModelModel[]) => void;
    removeModel: (model: ModelModel) => void;
    removeModelByIds: (modelIds: string[]) => void;
    resetStack: (folders: FolderModel[]) => void;
    addSystem: (system: SystemModel) => void;
    addSystems: (systems: SystemModel[]) => void;
    updateSystem: (system: SystemModel) => void;
    removeSystem: (system: SystemModel) => void;
    removeSystemByIds: (systemIds: string[]) => void;
    buildNewStackToFolder: (folderId: string, ancestorIds: string[]) => void;
}

export function useFileSystem(defaultFolders: FolderModel[] = [], options?: FileSystemOptions) {
    const [folders, setFolders] = useState<FolderModel[]>([]);
    const [isLoadingFolders, setIsLoadingFolders] = useState<boolean>(false);
    const [isLoadingModelsAndAsystems, setIsLoadingModelsAndAsystems] = useState<boolean>(false);

    const { fromStack, alwaysLoadCollectionsOnBackNavigation, trackTimestamps } = { ...DEFAULT_OTPIONS, ...options };

    const initialStack = !isNil(fromStack)
        ? fromStack.copy()
        : new Stack<FileSystemView>({
              index: 0,
              folder: null,
              subFolders: [],
              systems: [],
              models: [],
              isRoot: true,
              loaded: false
          });

    const [stack, setStack] = useState<Stack<FileSystemView>>(initialStack);

    const view = stack.peek();

    useEffect(() => {
        if (!isNil(fromStack)) {
            setFolders(defaultFolders);
            return;
        }

        const getFolders = async () => {
            try {
                setIsLoadingFolders(true);

                const _folders = await UserService.getFolders();

                if (trackTimestamps) {
                    setTimestamps(_folders);
                }

                setFolders(_folders);
                resetStack(_folders);
            } finally {
                setIsLoadingFolders(false);
            }
        };

        if (isNilOrEmpty(defaultFolders)) {
            getFolders();
        } else {
            setFolders(defaultFolders);
            resetStack(defaultFolders);
        }
    }, []);

    useEffect(() => {
        const getSystemsAndModels = async (folderId: string) => {
            try {
                const view = stack.peek();

                setIsLoadingModelsAndAsystems(!view.loaded);

                const [systems, models] = await Promise.all([
                    FolderService.getSystems(folderId),
                    FolderService.getModels(folderId)
                ]);

                view.systems = systems;
                view.models = models;
                view.loaded = true;

                updateStack(stack);
            } finally {
                await sleep(100);

                setIsLoadingModelsAndAsystems(false);
            }
        };

        if (!isNilOrEmpty(view?.folder?.id)) {
            //TODO: Flag view as loaded to avoid fetching collections when collections is empty
            if (alwaysLoadCollectionsOnBackNavigation || isNilOrEmpty(view?.systems) || isNilOrEmpty(view.models)) {
                getSystemsAndModels(view?.folder?.id);
            }
        }
    }, [view?.folder?.id]);

    const resetStack = (folders: FolderModel[]) => {
        setStack(getStackFromRoot(folders));
    };

    const getStackFromRoot = (folders: FolderModel[]) => {
        const rootFolder = folders[0];
        const subFolders = folders.filter(f => f.parentId === rootFolder.id);

        const stack = new Stack<FileSystemView>({
            index: 0,
            folder: rootFolder,
            subFolders: subFolders,
            systems: [],
            models: [],
            isRoot: true,
            loaded: false
        });

        return stack;
    };

    const updateStack = (stack: Stack<FileSystemView>, updateTimestamp = false) => {
        const newStack = stack.copy();

        if (trackTimestamps && updateTimestamp) {
            updateTimestamps(newStack);
        }

        setStack(newStack);
    };

    const navigateToFolder = (folder: FolderModel) => {
        const subFolders = folders.filter(f => f.parentId === folder.id) ?? [];

        const nextView: FileSystemView = {
            index: stack.size(),
            folder: folder,
            subFolders: subFolders,
            systems: [],
            models: [],
            isRoot: false,
            loaded: false
        };

        stack.push(nextView);

        updateStack(stack);
    };

    const navigateBackToFolder = (index: number) => {
        stack.popTo(index + 1);

        refreshCurrentView();
    };

    const navigateBack = () => {
        const view = stack.peek();

        if (view.isRoot) {
            return;
        }

        navigateBackToFolder(view.index - 1);
    };

    const refreshCurrentView = () => {
        const view = stack.pop();
        const folder = folders.find(f => f.id === view.folder.id);
        const subFolders = !isNil(folder) ? folders?.filter(f => f.parentId === folder.id) ?? [] : [];

        const refreshedView: FileSystemView = {
            ...view,
            folder: folder,
            subFolders: subFolders
        };

        stack.push(refreshedView);

        updateStack(stack);
    };

    const addFolder = (folder: FolderModel) => {
        const nextFolders = [...folders, folder];
        setFolders(nextFolders);
    };

    const addFolderToView = (folder: FolderModel) => {
        const view = stack.peek();

        view.subFolders = [...view.subFolders, folder];

        updateStack(stack, true);
    };

    const addFolders = (newFolders: FolderModel[]) => {
        const nextFolders = [...folders, ...newFolders];

        setFolders(nextFolders);
    };

    const addFoldersToView = (newFolders: FolderModel[]) => {
        const view = stack.peek();

        view.subFolders = [...view.subFolders, ...newFolders];

        updateStack(stack, true);
    };

    const updateFolder = (folder: FolderModel) => {
        const next = folders.map(f => {
            if (f.id === folder.id) {
                return folder;
            }

            return f;
        });

        const view = stack.peek();

        view.subFolders = view.subFolders.map(f => {
            if (f.id === folder.id) {
                return folder;
            }

            return f;
        });

        setFolders(next);
        updateStack(stack, shouldUpdateTimestamp(folder, view));
    };

    const updateFolders = (updatedFolders: FolderModel[]) => {
        const next = folders.map(f => {
            const updatedFolder = updatedFolders.find(x => x.id === f.id);
            if (!isNil(updatedFolder)) {
                return updatedFolder;
            }
            return f;
        });

        const view = stack.peek();

        view.subFolders = view.subFolders.map(f => {
            const updatedFolder = updatedFolders.find(x => x.id === f.id);
            if (!isNil(updatedFolder)) {
                return updatedFolder;
            }
            return f;
        });

        setFolders(next);
        updateStack(stack, true);
    };

    const removeFolder = (folder: FolderModel) => {
        const next: FolderModel[] = [];

        for (let i = 0; i < folders.length; i++) {
            const f = folders[i];

            if (f.id !== folder.id && f.parentId !== folder.id) {
                next.push(f);
            }
        }

        setFolders(next);
    };

    const removeFolderFromView = (folder: FolderModel) => {
        const view = stack.peek();

        view.subFolders = view.subFolders.filter(f => f.id !== folder.id);

        updateStack(stack, true);
    };

    const removeFolderByIds = (folderIds: string[]) => {
        const next: FolderModel[] = [];

        for (let i = 0; i < folders.length; i++) {
            const f = folders[i];

            if (!folderIds.includes(f.id) && !folderIds.includes(f.parentId)) {
                next.push(f);
            }
        }

        setFolders(next);
    };

    const removeFolderFromViewByIds = (folderIds: string[]) => {
        const view = stack.peek();

        view.subFolders = view.subFolders.filter(f => !folderIds.includes(f.id));

        updateStack(stack, true);
    };

    const addModel = (model: ModelModel) => {
        const view = stack.peek();

        view.models = [model, ...view.models];

        if (model.updatedAt > view.folder.updatedAt) {
            view.folder.updatedAt = model.updatedAt;
        }

        updateStack(stack, true);
    };

    const addModels = (models: ModelModel[]) => {
        const view = stack.peek();

        view.models = [...models, ...view.models];

        updateStack(stack, true);
    };

    const updateModel = (model: ModelModel) => {
        const view = stack.peek();

        view.models = view.models.map(m => {
            if (m.id === model.id) {
                return model;
            }

            return m;
        });

        updateStack(stack, true);
    };

    const removeModel = (model: ModelModel) => {
        const view = stack.peek();

        view.models = view.models.filter(m => m.id !== model.id);

        updateStack(stack, true);
    };

    const removeModelByIds = (modelIds: string[]) => {
        const view = stack.peek();

        view.models = view.models.filter(c => !modelIds.includes(c.id));

        updateStack(stack, true);
    };

    const addSystem = (system: SystemModel) => {
        const view = stack.peek();

        view.systems = [system, ...view.systems];

        if (system.updatedAt > view.folder.updatedAt) {
            view.folder.updatedAt = system.updatedAt;
        }

        updateStack(stack, true);
    };

    const addSystems = (systems: SystemModel[]) => {
        const view = stack.peek();

        view.systems = [...systems, ...view.systems];

        updateStack(stack, true);
    };

    const updateSystem = (system: SystemModel) => {
        const view = stack.peek();

        view.systems = view.systems.map(s => {
            if (s.id === system.id) {
                return system;
            }

            return s;
        });

        updateStack(stack, true);
    };

    const removeSystem = (system: SystemModel) => {
        const view = stack.peek();

        view.systems = view.systems.filter(s => s.id !== system.id);

        updateStack(stack, true);
    };

    const removeSystemByIds = (systmeIds: string[]) => {
        const view = stack.peek();

        view.systems = view.systems.filter(s => !systmeIds.includes(s.id));

        updateStack(stack, true);
    };

    const buildNewStackToFolder = (folderId: string, ancestorIds: string[]) => {
        const newStack: Stack<FileSystemView> = new Stack<FileSystemView>();

        for (let depth = 0; depth < ancestorIds.length; depth++) {
            const nextView: FileSystemView = {
                index: depth,
                folder: folders.find(f => f.id === ancestorIds[depth]),
                subFolders: folders.filter(f => f.parentId === ancestorIds[depth]) ?? [],
                systems: [],
                models: [],
                isRoot: depth === 0,
                loaded: false
            };
            newStack.push(nextView);
        }

        const selectedView: FileSystemView = {
            index: newStack.size(),
            folder: folders.find(f => f.id === folderId),
            subFolders: folders.filter(f => f.parentId === folderId) ?? [],
            systems: [],
            models: [],
            isRoot: newStack.size() === 0,
            loaded: false
        };

        newStack.push(selectedView);

        updateStack(newStack);
    };

    const breadcrumbs = useMemo(() => {
        const breadcrumbs: Breadcrumbs = [];

        for (let i = 0; i < stack.size(); i++) {
            const view = stack.peekAt(i);

            breadcrumbs.push({ index: i, label: view.isRoot ? "My eco risk projects" : view.folder?.name });
        }

        return breadcrumbs;
    }, [stack.storage.length]);

    return {
        folders,
        stack,
        view,
        isLoading: isLoadingFolders || isLoadingModelsAndAsystems,
        updateStack,
        navigateToFolder,
        navigateBack,
        navigateBackToFolder,
        addFolder,
        addFolderToView,
        addFolders,
        addFoldersToView,
        updateFolder,
        updateFolders,
        removeFolder,
        removeFolderFromView,
        removeFolderByIds,
        removeFolderFromViewByIds,
        breadcrumbs,
        resetStack,
        addModel,
        addModels,
        updateModel,
        removeModel,
        removeModelByIds,
        addSystem,
        addSystems,
        updateSystem,
        removeSystem,
        removeSystemByIds,
        buildNewStackToFolder
    } as FileSystem;
}
