import { PluginModel } from "../types/models";
import { isNilOrEmpty } from "../utils/utils";
import API from "../api/data.api";
import { URL } from "../Constants/api";

class PluginCache {
    private plugins: PluginModel[] = [];

    set(plugins: PluginModel[]): void {
        this.plugins = plugins;
    }

    get(): PluginModel[] {
        return this.plugins;
    }

    isEmpty(): boolean {
        return isNilOrEmpty(this.plugins);
    }
}

export default class PluginService {
    private static readonly cache = new PluginCache();

    public static async getPlugins(): Promise<PluginModel[]> {
        if (!this.cache.isEmpty()) {
            return this.cache.get();
        }

        const plugins = await API.get<PluginModel[]>(`${URL.PLUGINS}`);

        this.cache.set(plugins);

        return plugins;
    }
}
