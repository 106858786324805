import { isNil } from "lodash";
import { BulkImportSettings } from "../Scenes/System/SystemBulkImportModal";
import { DataType, LocationModel, ScenarioLocationDataModel, ScenarioModel, SystemModel } from "../types/models";
import { CreateLocationRequest, CreateScenarioRequest } from "../types/requests";
import { FileNameParser } from "./file-name-parser.service";
import SystemService from "./system.service";

export enum DuplicateEntryStratergyOption {
    SKIP = "skip",
    REPLACE = "replace"
}

export interface ImportSuccessResult {
    success: boolean;
    warning?: string;
    error?: string;
}

export class BulkImportService {
    system: SystemModel;
    scenarios: ScenarioModel[];
    locations: LocationModel[];
    data: ScenarioLocationDataModel[];
    settings: BulkImportSettings;

    constructor(
        system: SystemModel,
        scenarios: ScenarioModel[],
        locations: LocationModel[],
        data: ScenarioLocationDataModel[],
        settings: BulkImportSettings
    ) {
        this.system = system;
        this.scenarios = scenarios;
        this.locations = locations;
        this.data = data;
        this.settings = settings;
    }

    async importFile(file: File): Promise<ImportSuccessResult> {
        const fileInfo = FileNameParser.parseFileName(
            file.name,
            this.settings.seperator,
            this.settings.namingConvention
        );

        if (!fileInfo.isValid) {
            return {
                success: false,
                error: fileInfo.error
            };
        }

        const scenario = await this.getScenario(fileInfo.scenario);
        const location = await this.getLocation(fileInfo.location);

        const existingData = this.getExistingData(scenario, location, fileInfo.datatype);

        if (!isNil(existingData)) {
            switch (this.settings.duplicateEntryStratergy) {
                case DuplicateEntryStratergyOption.SKIP:
                    return {
                        success: true,
                        warning: `${file.name} - Data already exists with these details, skipping file`
                    };

                case DuplicateEntryStratergyOption.REPLACE:
                    return this.replaceDataFile(file, existingData, scenario);

                default:
                    return {
                        success: false,
                        error: `${file.name} - Unknown duplicate entry stratergy`
                    };
            }
        }

        return this.uploadDataFile(file, scenario, location, fileInfo.datatype);
    }

    private async getScenario(name: string): Promise<ScenarioModel> {
        const existingScenario = this.scenarios.find(s => s.name === name);

        if (!isNil(existingScenario)) {
            return existingScenario;
        }

        const request: CreateScenarioRequest = {
            name: name,
            description: null,
            isDefault: this.scenarios.length > 0 ? false : true
        };

        const newScenario = await SystemService.createScenario(this.system.id, request);

        this.scenarios.push(newScenario);

        return newScenario;
    }

    private async getLocation(name: string): Promise<LocationModel> {
        const existingLocation = this.locations.find(l => l.name === name);

        if (!isNil(existingLocation)) {
            return existingLocation;
        }

        const request: CreateLocationRequest = {
            name: name,
            gaugeNumber: null
        };

        const result = await SystemService.createLocation(this.system.id, request);
        const newLocation = result.data;

        this.locations.push(newLocation);

        return newLocation;
    }

    private async uploadDataFile(
        file: File,
        scenario: ScenarioModel,
        location: LocationModel,
        datatype: DataType
    ): Promise<ImportSuccessResult> {
        const data = await SystemService.uploadScenarioLocationFile(
            this.system.id,
            scenario.id,
            location.id,
            file,
            datatype
        );
        this.data.push(data);

        return {
            success: true
        };
    }

    private async replaceDataFile(
        file: File,
        existingData: ScenarioLocationDataModel,
        scenario: ScenarioModel
    ): Promise<ImportSuccessResult> {
        const newData = await SystemService.updateScenarioLocationDataFile(
            this.system.id,
            scenario.id,
            existingData.id,
            file
        );

        const nextData = [...this.data.filter(d => d.id !== existingData.id), newData];
        this.data = nextData;

        return {
            success: true,
            warning: `${file.name} - Data already exists with these details, replacing with new data`
        };
    }

    private getExistingData(
        scenario: ScenarioModel,
        location: LocationModel,
        dataType: DataType
    ): ScenarioLocationDataModel {
        return this.data.find(
            d => d.scenarioId === scenario.id && d.locationId === location.id && d.dataType === dataType
        );
    }
}
