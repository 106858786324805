import Label from "../../Components/Label";
import NumericInput from "../../Components/NumericInput";
import React from "react";
import { WaterholeRunToEmptyResults } from "./types";

interface PluginWaterholeRunToEmptyModalCalibrationProps {
    results: WaterholeRunToEmptyResults;
}

const PluginWaterholeRunToEmptyModalCalibration = ({ results }: PluginWaterholeRunToEmptyModalCalibrationProps) => {
    return (
        <div className="result">
            <div className="field">
                <Label className="field-label">Days</Label>
                <NumericInput value={results?.days} disabled />
            </div>
        </div>
    );
};

export default PluginWaterholeRunToEmptyModalCalibration;
