import NumericInput from "../../Components/NumericInput";
import Select, { SelectOption } from "../../Components/Select/Select";
import { MONTHS } from "../../Constants/months";
import React, { useMemo } from "react";
import { PluginFieldValue } from "../../types/plugin";
import { WaterholeCalibrationFieldTypes } from "./types";

interface PluginWaterholeCalibrateModalFieldInputProps {
    id: string;
    section: string;
    field: string;
    value: PluginFieldValue;
    disabled: boolean;
    unit?: string;
    onValueChanged: (section: string, field: string, value: PluginFieldValue) => void;
}

const FIELD_TYPE_MAPING: WaterholeCalibrationFieldTypes[] = [
    {
        id: "evaporation-scaling",
        type: "numericInput"
    },
    {
        id: "seepage-rate",
        type: "numericInput"
    },
    {
        id: "rainfall-area",
        type: "numericInput"
    },
    {
        id: "rainfall-max_infiltration",
        type: "numericInput"
    },
    {
        id: "groundwater-area",
        type: "numericInput"
    },
    {
        id: "groundwater-inflow",
        type: "numericInput"
    },
    {
        id: "groundwater-loss_to_deep_drainage",
        type: "numericInput"
    },
    {
        id: "extraction-commence_pumping_depth",
        type: "numericInput"
    },
    {
        id: "extraction-cease_pumping_depth",
        type: "numericInput"
    },
    {
        id: "extraction-extraction_rate",
        type: "numericInput"
    },
    {
        id: "extraction-max_annual_take",
        type: "numericInput"
    },
    {
        id: "extraction-start_month",
        type: "select"
    },
    {
        id: "extraction-ctf",
        type: "numericInput"
    },
    {
        id: "extraction-lag",
        type: "numericInput"
    }
];

const PluginWaterholeCalibrateModalFieldInput = ({
    id,
    section,
    field,
    value,
    disabled,
    unit,
    onValueChanged
}: PluginWaterholeCalibrateModalFieldInputProps) => {
    const type = useMemo(() => {
        const _id = `${section}-${field}`;
        return FIELD_TYPE_MAPING.find(m => m.id === _id)?.type;
    }, [section, field]);

    const values = useMemo(() => {
        return type === "select"
            ? MONTHS.map(m => {
                  return {
                      value: m.toLowerCase(),
                      label: m
                  };
              })
            : null;
    }, []);

    const handleValueChanged = (newValue: PluginFieldValue) => {
        if (newValue === value) {
            return;
        }

        onValueChanged(section, field, newValue);
    };

    switch (type) {
        case "numericInput":
            return (
                <NumericInput
                    className="field"
                    value={value as number}
                    disabled={disabled}
                    id={id}
                    unit={unit}
                    onChange={handleValueChanged}
                />
            );
        case "select":
            return (
                <Select
                    id={id}
                    menuClassName="field"
                    values={values}
                    value={values.find(v => v.value === value)}
                    disabled={disabled}
                    onSelected={(option: SelectOption) => {
                        handleValueChanged(option.value);
                    }}
                />
            );
    }
};

export default PluginWaterholeCalibrateModalFieldInput;
