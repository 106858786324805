import React, { useMemo } from "react";
import { faCheckCircle, faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import LoadingIndicator from "../../Components/LoadingIndicator";
import { useModel } from "./ModelContext";
import { TaskStatus } from "../../types/models";
import TooltipIcon from "../../Components/TooltipIcon";

interface ComputationStatusModel {
    isRunning: boolean;
    hasResults: boolean;
    numScenariosSuccessful: number;
    numScenariosFailed: number;
}

const ModelHeaderComputationStatusIcon = () => {
    const { tasks } = useModel();

    const computationStatus: ComputationStatusModel = useMemo(() => {
        return {
            isRunning: tasks.some(t => t?.status === TaskStatus.RUNNING || t?.status === TaskStatus.PENDING),
            hasResults: tasks.length > 0,
            numScenariosSuccessful: tasks.filter(t => t?.status === TaskStatus.COMPLETED).length,
            numScenariosFailed: tasks.filter(t => t?.status === TaskStatus.FAILED).length
        };
    }, [tasks]);

    return (
        <div className="computation-status">
            {computationStatus.isRunning && <LoadingIndicator size="2x" />}
            {!computationStatus.isRunning && computationStatus.hasResults && (
                <>
                    {computationStatus.numScenariosFailed === 0 && (
                        <TooltipIcon
                            tooltip="All scenarios sucessfully computed"
                            icon={faCheckCircle}
                            className="completed-icon"
                            size="2x"
                        />
                    )}

                    {computationStatus.numScenariosFailed > 0 && computationStatus.numScenariosSuccessful > 0 && (
                        <TooltipIcon
                            tooltip={`${computationStatus.numScenariosSuccessful} scenarios sucessfully computed, ${computationStatus.numScenariosFailed} failed`}
                            icon={faExclamationTriangle}
                            className="warning-icon"
                            size="2x"
                        />
                    )}

                    {computationStatus.numScenariosFailed > 0 && computationStatus.numScenariosSuccessful === 0 && (
                        <TooltipIcon
                            tooltip="All scenarios failed"
                            icon={faExclamationTriangle}
                            className="failed-icon"
                            size="2x"
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default ModelHeaderComputationStatusIcon;
