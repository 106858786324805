import { ChartDataset, ChartOptions } from "chart.js";
import ERPChart from "../../Components/Charts/ERPChart";
import { isNil, merge } from "lodash";
import React, { useMemo, useState } from "react";
import {
    ERPChartData,
    ExplorePeriodModel,
    ModelExploreScenario,
    OpportunitiesResult,
    SuccessType
} from "../../types/models";
import ModelExploreNodeTemporalSuccessChartLegend from "./ModelExploreNodeTemporalSuccessChartLegend";
import Humanize from "humanize-plus";
import { formatOpportunitiesChartData } from "../../utils/explorer.utils";
import { CHART_BASE_HEIGHT, CHART_SCENARIO_HEIGHT, STEP_SIZE } from "./constants";

interface ModelExploreNodeTemporalSuccessChartProps {
    selectedScenarios: ModelExploreScenario[];
    results: OpportunitiesResult[];
    resultPeriod: ExplorePeriodModel;
    node: string;
}

const CHART_OPTIONS: ChartOptions = {
    maintainAspectRatio: false,
    interaction: {
        mode: "index"
    },
    indexAxis: "y",
    scales: {
        x: {
            grid: { display: false, drawBorder: false },
            stacked: true
        },
        y: {
            grid: { display: false, drawBorder: false },
            stacked: true
        }
    },
    layout: {
        padding: { bottom: 5, left: 5, right: 5, top: 20 }
    },
    plugins: {
        tooltip: {
            enabled: true,
            intersect: true,
            mode: "point",
            position: "stackedBarPositioner",
            callbacks: {
                label: function (tooltipItem) {
                    const numberOfYears = parseInt(tooltipItem.formattedValue);
                    const risk = !isNil(tooltipItem.dataset.label) ? `${tooltipItem.dataset.label}` : "no data";

                    const _label = `${numberOfYears} ${Humanize.pluralize(numberOfYears, "year", "years")} - ${risk}`;

                    return _label;
                },
                footer: function (tooltipItems) {
                    const toolTipItem = tooltipItems[0].dataset as any;
                    if (toolTipItem.context.fromYear === toolTipItem.context.toYear) {
                        return `${toolTipItem.context.fromYear}`;
                    } else {
                        return `${toolTipItem.context.fromYear} to ${toolTipItem.context.toYear}`;
                    }
                }
            }
        },
        legend: {
            display: false
        },
        title: {
            display: true,
            position: "top",
            align: "start",
            text: "Default Title",
            font: {
                size: 14
            }
        }
    },
    hover: {
        mode: "index",
        intersect: false
    },
    animation: false
};

const ModelExploreNodeTemporalSuccessChart = ({
    selectedScenarios,
    results,
    resultPeriod,
    node
}: ModelExploreNodeTemporalSuccessChartProps) => {
    const [selectedSuccess, setSelectedSuccess] = useState<SuccessType[]>([SuccessType.SUCCESS, SuccessType.FAILURE]);

    const options = useMemo(() => {
        return merge({}, CHART_OPTIONS, {
            plugins: { title: { text: node } },
            scales: {
                x: {
                    min: 0,
                    max: resultPeriod.endYear - resultPeriod.startYear + 1,
                    ticks: {
                        stepSize: STEP_SIZE,
                        callback: function (_, index) {
                            const year = resultPeriod.startYear + STEP_SIZE * index;
                            return year;
                        }
                    }
                }
            }
        });
    }, [node, resultPeriod]);

    const chartData: ERPChartData = useMemo(() => {
        return formatOpportunitiesChartData(selectedScenarios, results);
    }, [selectedScenarios, results]);

    const filteredChartData: ERPChartData = useMemo(() => {
        const data = chartData.data.map(d => {
            const isSelected = !isNil(d.label) ? selectedSuccess.includes(d.label as SuccessType) : true;

            return {
                ...d,
                backgroundColor: isSelected ? d.backgroundColor : "#efefef",
                barThickness: 50
            } as ChartDataset;
        });
        return { ...chartData, data: data };
    }, [selectedSuccess.length, chartData]);

    return (
        <div className="chart">
            <ModelExploreNodeTemporalSuccessChartLegend
                selectedSuccess={selectedSuccess}
                onSuccessChanged={setSelectedSuccess}
            />

            <ERPChart
                id={`temporal_success_chart_${node}`}
                type="bar"
                labels={filteredChartData.labels}
                data={filteredChartData.data}
                options={options}
                height={CHART_BASE_HEIGHT + CHART_SCENARIO_HEIGHT * selectedScenarios.length}
            />
        </div>
    );
};

export default ModelExploreNodeTemporalSuccessChart;
