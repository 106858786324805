import { BananaPrawnGrowthParameters } from "../../types/plugin-parameter-types";
import { PluginParameters } from "../../types/plugin";
import { BaseParameterMerger } from "./base-parameter-merger";

export class BananaPrawnGrowthParameterMerger extends BaseParameterMerger<BananaPrawnGrowthParameters> {
    public merge(
        source: PluginParameters,
        defaultParameters: BananaPrawnGrowthParameters
    ): BananaPrawnGrowthParameters {
        return {
            flow: {
                flow0_dur: this.defaultTo(source?.flow?.flow0_dur, defaultParameters?.flow?.flow0_dur),
                flow4_dur: this.defaultTo(source?.flow?.flow4_dur, defaultParameters?.flow?.flow4_dur)
            },
            constants: {
                days: this.defaultTo(source?.constants?.days, defaultParameters?.constants?.days),
                length_1: this.defaultTo(source?.constants?.length_1, defaultParameters?.constants?.length_1),
                L: this.defaultTo(source?.constants?.L, defaultParameters?.constants?.L),
                a: this.defaultTo(source?.constants?.a, defaultParameters?.constants?.a),
                b: this.defaultTo(source?.constants?.b, defaultParameters?.constants?.b),
                kc: this.defaultTo(source?.constants?.kc, defaultParameters?.constants?.kc),
                kd: this.defaultTo(source?.constants?.kd, defaultParameters?.constants?.kd),
                ke: this.defaultTo(source?.constants?.ke, defaultParameters?.constants?.ke),
                kf: this.defaultTo(source?.constants?.kf, defaultParameters?.constants?.kf),
                kg: this.defaultTo(source?.constants?.kg, defaultParameters?.constants?.kg)
            },
            assessment: {
                daily_agg: this.defaultTo(source?.assessment?.daily_agg, defaultParameters?.assessment?.daily_agg),
                yearly_agg: this.defaultTo(source?.assessment?.yearly_agg, defaultParameters?.assessment?.yearly_agg),
                temporal_agg: this.defaultTo(
                    source?.assessment?.temporal_agg,
                    defaultParameters?.assessment?.temporal_agg
                )
            },
            spatial_agg: {
                risk_categories: this.defaultTo(
                    source?.spatial_agg?.risk_categories,
                    defaultParameters?.spatial_agg?.risk_categories
                )
            }
        };
    }
}
