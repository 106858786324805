import React from "react";
import TooltipIcon from "../TooltipIcon";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";

interface CrossSectionAddRowProps {
    onRowAdded: () => void;
}

const CrossSectionAddRow = ({ onRowAdded }: CrossSectionAddRowProps) => {
    return (
        <tr className="cross-section-add-row">
            <td colSpan={2}>
                <TooltipIcon icon={faPlusCircle} tooltip="Add Row" onClick={onRowAdded} />
            </td>
        </tr>
    );
};

export default CrossSectionAddRow;
