import React from "react";
import Tooltip from "../../Components/Tooltip";
import { RiskLevel } from "../../types/models";
import { mapChartKeyToColour } from "../../utils/explorer.utils";

export interface ModelExplorePreselectTableChartProps {
    riskLevel: RiskLevel;
    score: number;
}

const ModelExplorePreselectTableChart = ({ riskLevel, score }: ModelExplorePreselectTableChartProps) => {
    return (
        <div className="preselect-table-chart">
            <div className="container">
                <Tooltip tooltipContent={`${score} %`}>
                    <div
                        className="bar"
                        style={{
                            backgroundColor: mapChartKeyToColour(riskLevel),
                            width: `${Math.abs(score / 2)}%`,
                            marginLeft: `calc(${score / 2}% ${score > 0 ? "+" : "-"} 1px)`
                        }}
                    />
                </Tooltip>
            </div>
        </div>
    );
};

export default ModelExplorePreselectTableChart;
