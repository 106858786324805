import React from "react";
import clsx from "clsx";

interface ModelScenarioUpToDateDescriptionProps {
    isUpToDate: boolean;
}

const ModelScenarioUpToDateDescription = ({ isUpToDate }: ModelScenarioUpToDateDescriptionProps) => {
    const description = isUpToDate
        ? "This scenario has not changed since your last run. There is no need to re-run it. Selecting it here will force recalculation of this scenario."
        : "This scenario has been edited since your last run. By selecting it, you will recalculate this scenario with the new data or settings. If you wish to archive the existing results, make a copy of the model and do not re-run the archived copy.";

    return (
        <div
            className={clsx("scenario-state-description", {
                "up-to-date-description": isUpToDate,
                "outdated-description": !isUpToDate
            })}
        >
            {description}
        </div>
    );
};

export default ModelScenarioUpToDateDescription;
