import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import SystemHeader from "./SystemHeader";
import { ResourceLockErrors, ResourceLockModel, ResourceType } from "../../types/models";
import LoadingIndicator from "../../Components/LoadingIndicator";
import { isNil } from "lodash";
import ScenarioAndLocations from "./ScenarioAndLocations";
import { useResourceLocker } from "../../Hooks/useResourceLocker";
import { useAuth0 } from "../../auth/react-auth0-spa";
import ResourceLockedWarning from "../../Components/ResourceLockedWarning";
import { Toast } from "../../Components/Toast";
import SystemContextProvider from "./SystemContext";
import SystemGeneralInfo from "./SystemGeneralInfo";

import "./System.scss";
import { FileUploaderModalProvider } from "../../Components/FileUploader/FileUploaderContext";

const System = () => {
    const { id } = useParams<{ id: string }>();
    const { lock, unlock } = useResourceLocker();
    const { erpUser } = useAuth0();
    const [resourceLock, setResourceLock] = useState<ResourceLockModel>(null);
    const history = useHistory();

    useEffect(() => {
        const doInit = async (systemId: string) => {
            const lock_result = await lock(systemId, ResourceType.SYSTEM);

            if (!lock_result.success) {
                if (lock_result.error?.code === ResourceLockErrors.ENTITY_NOT_FOUND) {
                    Toast.error("System could not be found");
                    history.push("/");
                }

                return;
            }

            setResourceLock(lock_result.lock);
        };

        doInit(id);

        return () => {
            unlock(id);
        };
    }, [id]);

    const isLockOwner = useMemo(() => {
        if (isNil(resourceLock?.owner)) {
            return false;
        }

        return resourceLock.owner.id === erpUser.id;
    }, [resourceLock]);

    if (isNil(resourceLock)) {
        return <LoadingIndicator className="system-loading" centered />;
    }

    return (
        <FileUploaderModalProvider>
            <SystemContextProvider systemId={id} readOnly={!isLockOwner}>
                <div className="system-container">
                    <SystemHeader />

                    {!isLockOwner && <ResourceLockedWarning resourceType="system" owner={resourceLock.owner} />}

                    <SystemGeneralInfo />

                    <ScenarioAndLocations />
                </div>
            </SystemContextProvider>
        </FileUploaderModalProvider>
    );
};

export default System;
