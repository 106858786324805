import React from "react";
import ERPIcon from "../../Components/ERPIcon";
import { faCheckCircle, faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";

interface ModelScenarioUpToDateIconProps {
    isUpToDate: boolean;
}

const ModelScenarioUpToDateIcon = ({ isUpToDate }: ModelScenarioUpToDateIconProps) => {
    return isUpToDate ? (
        <ERPIcon icon={faCheckCircle} className="up-to-date-icon" size="lg" />
    ) : (
        <ERPIcon icon={faExclamationTriangle} className="outdated-icon" size="lg" />
    );
};

export default ModelScenarioUpToDateIcon;
