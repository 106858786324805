import { KingThreadfinSalmonParameters } from "../../types/plugin-parameter-types";
import { PluginParameters } from "../../types/plugin";
import { BaseParameterMerger } from "./base-parameter-merger";

export class KingThreadfinSalmonParameterMerger extends BaseParameterMerger<KingThreadfinSalmonParameters> {
    public merge(
        source: PluginParameters,
        defaultParameters: KingThreadfinSalmonParameters
    ): KingThreadfinSalmonParameters {
        return {
            movement_parameters: {
                season_start: this.defaultTo(
                    source?.movement_parameters?.season_start,
                    defaultParameters?.movement_parameters?.season_start
                ),
                season_end: this.defaultTo(
                    source?.movement_parameters?.season_end,
                    defaultParameters?.movement_parameters?.season_end
                )
            },
            constants: {
                a: this.defaultTo(source?.constants?.a, defaultParameters?.constants?.a),
                b: this.defaultTo(source?.constants?.b, defaultParameters?.constants?.b)
            },
            intermediate_assessment: {
                annual_recruitment_strength: this.defaultTo(
                    source?.intermediate_assessment?.annual_recruitment_strength,
                    defaultParameters?.intermediate_assessment?.annual_recruitment_strength
                ),
                temporal_risk: this.defaultTo(
                    source?.intermediate_assessment?.temporal_risk,
                    defaultParameters?.intermediate_assessment?.temporal_risk
                )
            },
            assessment: {
                temporal_agg: this.defaultTo(
                    source?.assessment?.temporal_agg,
                    defaultParameters?.assessment?.temporal_agg
                )
            },
            spatial_agg: {
                risk_categories: this.defaultTo(
                    source?.spatial_agg?.risk_categories,
                    defaultParameters?.spatial_agg?.risk_categories
                )
            }
        };
    }
}
