import React, { useMemo } from "react";
import Select, { SelectProps } from "../../Components/Select/Select";
import { DataType } from "../../types/models";
import { isNilOrEmpty } from "../../utils/utils";
import clsx from "clsx";
import { isNil } from "lodash";

export interface DataTypeSelectOption {
    label: string;
    value: DataType;
}

const DATA_TYPES: DataTypeSelectOption[] = [
    { label: "Depth", value: DataType.DEPTH },
    { label: "Flow", value: DataType.FLOW },
    { label: "Rainfall", value: DataType.RAINFALL },
    { label: "Temperature", value: DataType.TEMPERATURE },
    { label: "Climate", value: DataType.CLIMATE },
    { label: "Bathymetry", value: DataType.BATHYMETRY },
    { label: "Evaporation", value: DataType.EVAPORATION },
    { label: "Compliance", value: DataType.COMPLIANCE },
    { label: "Salinity", value: DataType.SALINITY },
    { label: "Other Timeseries", value: DataType.OTHER_TIMESERIES }
];

type DataTypeSelectProps = {
    dataType: DataType;
    allowedDataTypes?: DataType[];
    onChanged: (dataType: DataType) => void;
} & Omit<SelectProps, "id" | "name" | "value" | "values" | "placeholder" | "initialValue">;

const DataTypeSelect = ({
    dataType,
    allowedDataTypes,
    disabled,
    menuClassName,
    isClearable,
    onChanged
}: DataTypeSelectProps) => {
    const dataTypes = useMemo(() => {
        if (isNil(allowedDataTypes)) {
            return DATA_TYPES;
        }

        return DATA_TYPES.filter(dt => allowedDataTypes.includes(dt.value) || dt.value === dataType);
    }, [allowedDataTypes]);

    const value = useMemo(() => {
        if (isNilOrEmpty(dataType)) {
            return null;
        }

        return dataTypes.find(dt => dt.value === dataType);
    }, [dataType, dataTypes]);

    return (
        <Select
            id="data-site-data-type-select"
            name="data-site-data-type-select"
            menuClassName={clsx("data-site-data-type-select-menu", menuClassName)}
            value={value}
            values={dataTypes}
            onSelected={(option: DataTypeSelectOption) => {
                onChanged(option?.value ?? null);
            }}
            isClearable={isClearable}
            isSearchable={false}
            disabled={disabled}
        />
    );
};

export default DataTypeSelect;
