import { AustralianBassParameters } from "../../types/plugin-parameter-types";
import { PluginParameters } from "../../types/plugin";
import { BaseParameterMerger } from "./base-parameter-merger";

export class AustralianBassParameterMerger extends BaseParameterMerger<AustralianBassParameters> {
    public merge(source: PluginParameters, defaultParameters: AustralianBassParameters): AustralianBassParameters {
        return {
            season: {
                season_start: this.defaultTo(source?.season?.season_start, defaultParameters?.season?.season_start),
                season_end: this.defaultTo(source?.season?.season_end, defaultParameters?.season?.season_end)
            },
            migration: {
                season_start: this.defaultTo(
                    source?.migration?.season_start,
                    defaultParameters?.migration?.season_start
                ),
                season_end: this.defaultTo(source?.migration?.season_end, defaultParameters?.migration?.season_end),
                flow_threshold: this.defaultTo(
                    source?.migration?.flow_threshold,
                    defaultParameters?.migration?.flow_threshold
                ),
                duration: this.defaultTo(source?.migration?.duration, defaultParameters?.migration?.duration)
            },
            spawning: {
                season_start: this.defaultTo(
                    source?.migration?.season_start,
                    defaultParameters?.migration?.season_start
                ),
                season_end: this.defaultTo(source?.migration?.season_end, defaultParameters?.migration?.season_end),
                temperature_range: this.defaultTo(
                    source?.spawning?.temperature_range,
                    defaultParameters?.spawning?.temperature_range
                ),
                salinity_range: this.defaultTo(
                    source?.spawning?.salinity_range,
                    defaultParameters?.spawning?.salinity_range
                ),
                duration: this.defaultTo(source?.spawning?.duration, defaultParameters?.spawning?.duration)
            },
            hatching: {
                temperature_range: this.defaultTo(
                    source?.hatching?.temperature_range,
                    defaultParameters?.hatching?.temperature_range
                ),
                salinity_range: this.defaultTo(
                    source?.hatching?.salinity_range,
                    defaultParameters?.hatching?.salinity_range
                ),
                time_since_spawning_range: this.defaultTo(
                    source?.hatching?.time_since_spawning_range,
                    defaultParameters?.hatching?.time_since_spawning_range
                )
            },
            recruitment: {
                time_since_hatching: this.defaultTo(
                    source?.recruitment?.time_since_hatching,
                    defaultParameters?.recruitment?.time_since_hatching
                ),
                flow_threshold: this.defaultTo(
                    source?.recruitment?.flow_threshold,
                    defaultParameters?.recruitment?.flow_threshold
                )
            },
            assessment: {
                yearly_agg: this.defaultTo(source?.assessment?.yearly_agg, defaultParameters?.assessment?.yearly_agg),
                temporal_agg: this.defaultTo(
                    source?.assessment?.temporal_agg,
                    defaultParameters?.assessment?.temporal_agg
                )
            },
            spatial_agg: {
                risk_categories: this.defaultTo(
                    source?.spatial_agg?.risk_categories,
                    defaultParameters?.spatial_agg?.risk_categories
                )
            }
        };
    }
}
