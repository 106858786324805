import React, { useMemo } from "react";
import Checkbox from "../../Components/Checkbox";
import { FolderModel, ModelModel, SystemModel, User } from "../../types/models";
import { fullname, isNilOrEmpty } from "../../utils/utils";
import FolderRow from "./FolderRow";
import SystemRow from "./SystemRow";
import ModelRow from "./ModelRow";
import ExplorerTableBulkActions from "./ExplorerTableBulkActions";
import { ExplorerEntity, ExplorerItem, SelectionState } from "./types";
import { isNil } from "lodash";
import LoadingRow from "./LoadingRow";
import SortableHeader, { SortFunction } from "../../Components/SortableHeader";
import { HeaderSortContext } from "../../Components/SortableHeader/types";
import EmptyRow from "../../Components/EmptyRow";

interface ExplorerTableProps {
    items: ExplorerItem<ExplorerEntity>[];
    selection: SelectionState;
    user: User;
    isLoading?: boolean;
    onSort: SortFunction;
    sortContext: HeaderSortContext<ExplorerItem<ExplorerEntity>>;
    onFolderNavigation: (folder: FolderModel) => void;
    onFolderSelected: (folder: FolderModel, selected: boolean) => void;
    onFolderMove: (folder: FolderModel) => void;
    onFolderEdit: (folder: FolderModel) => void;
    onFolderDelete: (folder: FolderModel) => void;
    onFolderCopy: (folder: FolderModel) => void;
    onSystemSelected: (system: SystemModel, selected: boolean) => void;
    onSystemMove: (system: SystemModel) => void;
    onSystemDelete: (system: SystemModel) => void;
    onSystemCopy: (system: SystemModel) => void;
    onSystemEdit: (system: SystemModel) => void;
    onModelSelected: (model: ModelModel, selected: boolean) => void;
    onModelMove: (model: ModelModel) => void;
    onModelDelete: (model: ModelModel) => void;
    onModelCopy: (model: ModelModel) => void;
    onModelEdit: (model: ModelModel) => void;
    onAllSelected: (selected: boolean) => void;
    onBulkDelete: (selection: SelectionState) => void;
    onBulkCopy: (selection: SelectionState) => void;
    onBulkMove: (selection: SelectionState) => void;
}

const ExplorerTable = ({
    items,
    selection,
    user,
    isLoading,
    onFolderNavigation,
    onFolderSelected,
    onFolderMove,
    onFolderEdit,
    onFolderDelete,
    onFolderCopy,
    onSystemSelected,
    onSystemMove,
    onSystemDelete,
    onSystemCopy,
    onSystemEdit,
    onModelSelected,
    onModelMove,
    onModelDelete,
    onModelCopy,
    onModelEdit,
    onAllSelected,
    onBulkDelete,
    onBulkCopy,
    onBulkMove,
    onSort,
    sortContext
}: ExplorerTableProps) => {
    const isEmpty = isNilOrEmpty(items);

    const allSelected = useMemo(() => {
        if (isNilOrEmpty(items)) {
            return false;
        }

        const total = selection.models.size + selection.systems.size + selection.folders.size;

        return total === items.length;
    }, [items.length, selection]);

    return (
        <div className="explorer--table">
            <ExplorerTableBulkActions
                selection={selection}
                onDelete={onBulkDelete}
                onCopy={onBulkCopy}
                onMove={onBulkMove}
            />

            <table>
                <thead>
                    <tr>
                        <th style={{ width: 50 }} className="selection-cell">
                            <Checkbox checked={allSelected} onChecked={onAllSelected} />
                        </th>

                        <th style={{ width: 50 }}>{/* Row Icon */}</th>

                        <SortableHeader
                            label="Name"
                            sortKey="name"
                            context={sortContext}
                            style={{ width: "30%", textAlign: "left" }}
                            sorter={(e: ExplorerItem<ExplorerEntity>) => e.item.name?.toLowerCase() ?? ""}
                            onSort={onSort}
                        />

                        <th style={{ width: 100 }}>Type</th>

                        <SortableHeader
                            label="Created by"
                            sortKey="createdBy"
                            context={sortContext}
                            style={{ width: 200 }}
                            sorter={(e: ExplorerItem<ExplorerEntity>) => fullname(e.item.createdBy)}
                            onSort={onSort}
                            centered
                        />

                        <SortableHeader
                            label="Last modified"
                            sortKey="updatedAt"
                            context={sortContext}
                            style={{ width: 200 }}
                            sorter={(e: ExplorerItem<ExplorerEntity>) => new Date(e.item.updatedAt).getTime()}
                            onSort={onSort}
                            centered
                        />

                        <th style={{ width: 210 }}>{/* Hover Actions */}</th>
                    </tr>
                </thead>

                <tbody>
                    {isLoading && <LoadingRow collSpan={7} />}

                    {!isLoading && (
                        <>
                            {isEmpty && <EmptyRow message="This folder is empty" colSpan={7} />}

                            {items.map(item => {
                                switch (item.type) {
                                    case "folder": {
                                        const folder = item.item as FolderModel;

                                        return (
                                            <FolderRow
                                                key={folder.id}
                                                folder={folder}
                                                isSelected={selection.folders.has(folder.id)}
                                                onFolderNavigation={onFolderNavigation}
                                                onFolderMove={onFolderMove}
                                                onFolderEdit={onFolderEdit}
                                                onFolderDelete={onFolderDelete}
                                                onFolderCopy={onFolderCopy}
                                                onSelected={onFolderSelected}
                                            />
                                        );
                                    }

                                    case "system": {
                                        const system = item.item as SystemModel;
                                        const isLocked = !isNil(system.lock) && system.lock.owner?.id !== user.id;

                                        return (
                                            <SystemRow
                                                key={system.id}
                                                system={system}
                                                isSelected={selection.systems.has(system.id)}
                                                isLocked={isLocked}
                                                onSelected={onSystemSelected}
                                                onSystemMove={onSystemMove}
                                                onSystemDelete={onSystemDelete}
                                                onSystemCopy={onSystemCopy}
                                                onSystemEdit={onSystemEdit}
                                            />
                                        );
                                    }

                                    case "model": {
                                        const model = item.item as ModelModel;
                                        const isLocked = !isNil(model.lock) && model.lock.owner?.id !== user.id;

                                        return (
                                            <ModelRow
                                                key={model.id}
                                                model={model}
                                                isSelected={selection.models.has(model.id)}
                                                isLocked={isLocked}
                                                onSelected={onModelSelected}
                                                onModelMove={onModelMove}
                                                onModelDelete={onModelDelete}
                                                onModelCopy={onModelCopy}
                                                onModelEdit={onModelEdit}
                                            />
                                        );
                                    }

                                    default:
                                        return null;
                                }
                            })}
                        </>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default ExplorerTable;
