import FormNumericInput from "../../Components/FormInput/FormNumericInput";
import Label from "../../Components/Label";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { RiskCategoriesOptions } from "../../types/plugin";
import { PluginFieldProps, PluginForm } from "./types";

interface PluginParameterRiskCategoriesProps extends PluginFieldProps {
    onChange: (field: string, value: Record<string, number>) => void;
}

const PluginParameterRiskCategories = ({
    definition,
    field,
    control,
    disabled,
    onChange
}: PluginParameterRiskCategoriesProps) => {
    const { getValues } = useFormContext<PluginForm>();

    const handleLowRiskChanged = (value: number) => {
        const high = getValues(`${definition.id}.${field.id}.high`);

        onChange(field.id, { high: high, low: value });
    };

    const handleHighRiskChanged = (value: number) => {
        const low = getValues(`${definition.id}.${field.id}.low`);

        onChange(field.id, { high: value, low: low });
    };

    const options: RiskCategoriesOptions = field.control.options as RiskCategoriesOptions;

    return (
        <div className="risk-categories-parameter-field">
            <Label className="plugin-definition-field-label">{field.name}</Label>

            <Controller
                control={control}
                name={`${definition.id}.${field.id}.high`}
                render={({ field: _field }) => {
                    return (
                        <FormNumericInput
                            id={`${definition.id}.${field.id}.high`}
                            labelText={options?.high_label}
                            labelClass="plugin-definition-field-label"
                            value={_field.value}
                            max={field.control.range?.max}
                            unit=">="
                            containerClassName="risk-categories-input"
                            disabled={disabled}
                            onChange={handleHighRiskChanged}
                        />
                    );
                }}
            />

            <Controller
                control={control}
                name={`${definition.id}.${field.id}.low`}
                render={({ field: _field }) => {
                    return (
                        <FormNumericInput
                            id={`${definition.id}.${field.id}.low`}
                            labelText={options?.low_label}
                            labelClass="plugin-definition-field-label"
                            value={_field.value}
                            min={field.control.range?.min}
                            unit="<="
                            containerClassName="risk-categories-input risk-categories-low"
                            disabled={disabled}
                            onChange={handleLowRiskChanged}
                        />
                    );
                }}
            />
        </div>
    );
};

export default PluginParameterRiskCategories;
