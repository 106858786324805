import Label from "../../Components/Label";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { PluginFieldProps, PluginForm } from "./types";
import NumericInput from "../../Components/NumericInput";
import ComparisonSelect from "../../Components/ComparisonSelect";
import MonthSelect from "../../Components/MonthSelect";

interface PluginParameterYearlyAggProps extends PluginFieldProps {
    onChange: (field: string, value: Record<string, string | number>) => void;
}

const PluginParameterYearlyAgg = ({
    definition,
    field,
    control,
    disabled,
    onChange
}: PluginParameterYearlyAggProps) => {
    const { getValues } = useFormContext<PluginForm>();

    const handleComparisonChanged = (value: string) => {
        const num_successes = getValues(`${definition.id}.${field.id}.num_successes`);
        const month = getValues(`${definition.id}.${field.id}.start_month`);

        onChange(field.id, { comparison: value, num_successes: num_successes, start_month: month });
    };

    const handleNumSuccessesChanged = (value: number) => {
        const comparison = getValues(`${definition.id}.${field.id}.comparison`);
        const month = getValues(`${definition.id}.${field.id}.start_month`);

        onChange(field.id, {
            num_successes: value ?? null,
            comparison: comparison,
            start_month: month
        });
    };

    const handleStartMonthChanged = (month: string) => {
        const num_successes = getValues(`${definition.id}.${field.id}.num_successes`);
        const comparison = getValues(`${definition.id}.${field.id}.comparison`);

        onChange(field.id, { comparison: comparison, num_successes: num_successes, start_month: month });
    };

    return (
        <div className="assessment-parameter-field">
            <Label className="plugin-definition-field-label">{field.name}</Label>
            <div className="assessment-params">
                Annual success in year starting{" "}
                <Controller
                    control={control}
                    name={`${definition.id}.${field.id}.start_month`}
                    render={({ field: _field }) => {
                        return (
                            <MonthSelect
                                id={`${definition.id}.${field.id}.start_month`}
                                value={_field.value}
                                className="assessment-select-month"
                                disabled={disabled}
                                onMonthChanged={handleStartMonthChanged}
                            />
                        );
                    }}
                />
                if{" "}
                <Controller
                    control={control}
                    name={`${definition.id}.${field.id}.comparison`}
                    render={({ field: _field }) => {
                        return (
                            <ComparisonSelect
                                id={`${definition.id}.${field.id}.comparison`}
                                value={_field.value}
                                onComparisonChanged={handleComparisonChanged}
                                className="assessment-select"
                                disabled={disabled}
                            />
                        );
                    }}
                />
                <Controller
                    control={control}
                    name={`${definition.id}.${field.id}.num_successes`}
                    render={({ field: _field }) => {
                        return (
                            <NumericInput
                                value={_field.value ?? null}
                                onChange={handleNumSuccessesChanged}
                                containerClassName="assessment-select"
                                disabled={disabled}
                            />
                        );
                    }}
                />
                daily successes
            </div>
        </div>
    );
};

export default PluginParameterYearlyAgg;
