import { MacrophyteGrowthParameters } from "../../types/plugin-parameter-types";
import { PluginParameters } from "../../types/plugin";
import { BaseParameterMerger } from "./base-parameter-merger";

export class MacrophyteGrowthParameterMerger extends BaseParameterMerger<MacrophyteGrowthParameters> {
    public merge(source: PluginParameters, defaultParameters: MacrophyteGrowthParameters): MacrophyteGrowthParameters {
        return {
            data: {
                type: this.defaultTo(source?.data?.type, defaultParameters?.data?.type),
                magnitude_curve: this.defaultTo(source?.data?.magnitude_curve, defaultParameters?.data?.magnitude_curve)
            },
            assessment: {
                yearly_conversion: this.defaultTo(
                    source?.assessment?.yearly_conversion,
                    defaultParameters?.assessment?.yearly_conversion
                ),
                temporal_agg: this.defaultTo(
                    source?.assessment?.temporal_agg,
                    defaultParameters?.assessment?.temporal_agg
                )
            },
            spatial_agg: {
                risk_categories: this.defaultTo(
                    source?.spatial_agg?.risk_categories,
                    defaultParameters?.spatial_agg?.risk_categories
                )
            }
        };
    }
}
