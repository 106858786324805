import React from "react";

interface ScenarioInformationProps {
    label: string;
    value: string;
}

const ScenarioInformation = ({ label, value }: ScenarioInformationProps) => {
    return (
        <span className="scenario-info-label">
            {label}: <span className="scenario-info-value">{value}</span>
        </span>
    );
};

export default ScenarioInformation;
