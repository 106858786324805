import React, { useMemo } from "react";
import FileSelectorDrag from "../../Components/FileSelector/FileSelectorDrag";
import { DataType, LocationModel, ScenarioLocationDataModel } from "../../types/models";
import { useContextMenu } from "react-contexify";
import LocationContextMenu, { menuId } from "./LocationContextMenu";
import { isNilOrEmpty } from "../../utils/utils";
import { ObservedDataSettings } from "../../Components/FileSelector/types";
import DataFile from "../../Components/DataFile";

interface ScenarioLocationProps {
    location: LocationModel;
    data: ScenarioLocationDataModel[];
    disabled: boolean;
    onLocationUpdate: (location: LocationModel) => void;
    onLocationDelete: (location: LocationModel) => void;
    onFileSelected: (location: LocationModel, files: File[]) => void;
    onObservedDataFileSelected: (location: LocationModel, file: File, connectionSettings: ObservedDataSettings) => void;
    onDataRemoved: (data: ScenarioLocationDataModel) => void;
    onDataTypeChanged: (data: ScenarioLocationDataModel, dataType: DataType) => void;
    onObservedDataFileUpdated: (
        data: ScenarioLocationDataModel,
        file: File,
        connectionSettings: ObservedDataSettings
    ) => void;
}

const DATA_TYPES: DataType[] = [
    DataType.DEPTH,
    DataType.FLOW,
    DataType.RAINFALL,
    DataType.TEMPERATURE,
    DataType.CLIMATE,
    DataType.BATHYMETRY,
    DataType.EVAPORATION,
    DataType.COMPLIANCE,
    DataType.OTHER_TIMESERIES,
    DataType.SALINITY
];

const ScenarioLocation = ({
    location,
    data,
    disabled,
    onLocationUpdate,
    onLocationDelete,
    onFileSelected,
    onObservedDataFileSelected,
    onDataRemoved,
    onDataTypeChanged,
    onObservedDataFileUpdated
}: ScenarioLocationProps) => {
    const { show } = useContextMenu({
        id: menuId(location)
    });

    const handleLocationUpdate = () => {
        onLocationUpdate(location);
    };

    const handleLocationDelete = () => {
        onLocationDelete(location);
    };

    const handleFileSelected = (files: File[]) => {
        onFileSelected(location, files);
    };

    const handleObservedDataFileSelected = (file: File, connectionSettings: ObservedDataSettings) => {
        onObservedDataFileSelected(location, file, connectionSettings);
    };

    const handleDataRemoved = (data: ScenarioLocationDataModel) => {
        onDataRemoved(data);
    };

    const locationName = useMemo(() => {
        if (isNilOrEmpty(location.gaugeNumber)) {
            return location.name;
        }

        return `${location.name} (${location.gaugeNumber})`;
    }, [location.name, location.gaugeNumber]);

    const avaliableDataTypes: DataType[] = useMemo(() => {
        const usedDataTypes = data.map(d => d.dataType);
        return DATA_TYPES.filter(d => !usedDataTypes.some(ud => ud === d));
    }, [data]);

    return (
        <div className="scenario-location-card scenario-location">
            <div className="location-name" onContextMenu={show}>
                <span>{locationName}</span>
            </div>

            {data.map(d => {
                return (
                    <DataFile
                        key={`${d.scenarioId}-${d.locationId}-${d.id}`}
                        data={d}
                        disabled={disabled}
                        avaliableDataTypes={avaliableDataTypes}
                        onDataTypeChanged={onDataTypeChanged}
                        onDataRemoved={handleDataRemoved}
                        onObservedDataFileUpdated={onObservedDataFileUpdated}
                    />
                );
            })}

            {!disabled && (
                <FileSelectorDrag
                    className="scenario-location--dnd"
                    allowMultiple
                    accept=".csv"
                    onFileSelected={handleFileSelected}
                    onObservedDataFileSelected={handleObservedDataFileSelected}
                />
            )}

            <LocationContextMenu
                location={location}
                disabled={disabled}
                onEdit={handleLocationUpdate}
                onDelete={handleLocationDelete}
            />
        </div>
    );
};

export default ScenarioLocation;
