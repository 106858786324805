import { faDownload } from "@fortawesome/pro-solid-svg-icons";
import React, { useMemo, useState } from "react";
import ExploreDataService from "../../Services/explore.data.service";
import { ModelExploreResult } from "../../types/models";
import { formatFullDownloadName, getComputationResultTypeDisplayName } from "../../utils/explorer.utils";
import { useModel } from "./ModelContext";
import LoadingButton from "../../Components/LoadingButton";
import { isNilOrEmpty } from "../../utils/utils";
import AzureBlobService from "../../Services/azure.blob.service";

interface ScenarioResultDownloadButtonProps {
    result: ModelExploreResult;
}

const ScenarioResultDownloadButton = ({ result }: ScenarioResultDownloadButtonProps) => {
    const { downloadsSettings } = useModel();

    const [isDownloading, setIsDownloading] = useState<boolean>(false);

    const fileName = useMemo(() => {
        return getComputationResultTypeDisplayName(result.type);
    }, [result.type]);

    const handleDownload = async () => {
        try {
            setIsDownloading(true);

            const resultName = downloadsSettings.shouldUseFullDownloadNames
                ? formatFullDownloadName(result)
                : result.name;

            const files = await ExploreDataService.fetchResultFiles(result, resultName, downloadsSettings, true);

            if (isNilOrEmpty(files)) {
                return;
            }

            if (files.length > 1) {
                const blob = await ExploreDataService.zipFiles(
                    files.map(f => {
                        return { name: f.name, file: f };
                    })
                );

                AzureBlobService.downloadToDisk(blob, `${resultName}.zip`);
            } else {
                const result = files[0];

                AzureBlobService.downloadToDisk(new Blob([result]), result.name);
            }
        } finally {
            setIsDownloading(false);
        }
    };

    return (
        <LoadingButton
            className="scenario-result-download-button"
            variant="none"
            icon={faDownload}
            iconClassName="scenario-result-download-icon"
            onClick={handleDownload}
            isLoading={isDownloading}
            loadingIconClassName="scenario-result-download-icon-loader"
        >
            {fileName}
        </LoadingButton>
    );
};

export default ScenarioResultDownloadButton;
