import React from "react";
import ERPProgressBar from "../../Components/ProgressBar";

interface ProgressProps {
    progress: number;
    label: string;
}

const FileProgress = ({ progress, label }: ProgressProps) => {
    return (
        <div className="progress-modal--body">
            <div className="progress-modal--label">
                <span>{label}</span>
            </div>

            <ERPProgressBar progress={progress} className="file-upload-progress" />
        </div>
    );
};

export default FileProgress;
