import { BaseflowSeparationParameters } from "../../types/plugin-parameter-types";
import { PluginParameters } from "../../types/plugin";
import { BaseParameterMerger } from "./base-parameter-merger";

export class BaseflowSeparationParameterMerger extends BaseParameterMerger<BaseflowSeparationParameters> {
    public merge(
        source: PluginParameters,
        defaultParameters: BaseflowSeparationParameters
    ): BaseflowSeparationParameters {
        return {
            data: {
                data_type: this.defaultTo(source?.data?.data_type, defaultParameters?.data?.data_type)
            },
            season: {
                season_start: this.defaultTo(source?.season?.season_start, defaultParameters?.season?.season_start),
                season_end: this.defaultTo(source?.season?.season_end, defaultParameters?.season?.season_end)
            },
            method: {
                alpha: this.defaultTo(source?.method?.alpha, defaultParameters?.method?.alpha)
            }
        };
    }
}
