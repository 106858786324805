import React, { useMemo } from "react";
import { Controller } from "react-hook-form";
import { PluginFieldProps } from "./types";
import Select, { SelectOption } from "../../Components/Select/Select";
import { PluginDropdownValue } from "../../types/plugin";

interface PluginParameterSelectProps extends PluginFieldProps {
    onChanged: (value: string) => void;
}

const PluginParameterSelect = ({ id, definition, field, control, disabled, onChanged }: PluginParameterSelectProps) => {
    const values = useMemo(() => {
        const _values = field.control.values as PluginDropdownValue[];

        return (
            _values?.map((val: PluginDropdownValue) => {
                const option: SelectOption = {
                    value: val.value,
                    label: val.label
                };

                return option;
            }) ?? []
        );
    }, []);

    return (
        <Controller
            control={control}
            name={`${definition.id}.${field.id}`}
            render={({ field: _field }) => {
                const value = values.find(v => v.value === _field.value);

                return (
                    <Select
                        id={id}
                        labelText={field.name}
                        labelClassName="plugin-definition-field-label"
                        value={value}
                        values={values}
                        onSelected={(option: SelectOption) => {
                            if (value?.value !== option?.value) {
                                onChanged(option?.value ?? null);
                            }
                        }}
                        isClearable={false}
                        isSearchable={true}
                        disabled={disabled}
                    />
                );
            }}
        />
    );
};

export default PluginParameterSelect;
