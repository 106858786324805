import React, { useMemo, useState } from "react";
import { RunPeriodModel } from "../../types/models";
import Checkbox from "../../Components/Checkbox";
import Divider from "../../Components/Divider";
import RunPeriod from "../../Components/RunPeriod";
import { DataInfillSettingsModel, DataInfillType } from "../../types/requests";
import Tooltip from "../../Components/Tooltip";
import Select from "../../Components/Select/Select";
import { isNil } from "lodash";
import ERPIcon from "../../Components/ERPIcon";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";

interface ModelRunModalRunSettingsProps {
    runPeriod: RunPeriodModel;
    dataInfillSettings: DataInfillSettingsModel;
    readOnly: boolean;
    handleSetRunPeriod: (runPeriod: RunPeriodModel) => void;
    handleSetInfillSettings: (dataInfillSettings: DataInfillSettingsModel) => void;
}

type InfillSelectOption = {
    value: DataInfillType;
    label: string;
};

const ModelRunModalRunSettings = ({
    runPeriod,
    dataInfillSettings,
    readOnly,
    handleSetRunPeriod,
    handleSetInfillSettings
}: ModelRunModalRunSettingsProps) => {
    const [isRunPeriodExpanded, setIsRunPeriodExpanded] = useState<boolean>(!isNil(runPeriod) ? true : false);

    const infillMethods: InfillSelectOption[] = useMemo(() => {
        return [
            {
                value: DataInfillType.EMPTY,
                label: "Infill missing days with no value"
            },
            {
                value: DataInfillType.PREVIOUS_VALUE,
                label: "Infill missing days with previous value"
            }
        ];
    }, []);

    const infillMethod = useMemo(() => {
        if (!isNil(dataInfillSettings.infill_method)) {
            return infillMethods.find(m => m.value === dataInfillSettings.infill_method);
        }

        return infillMethods.find(m => m.value === DataInfillType.EMPTY);
    }, [dataInfillSettings]);

    const handleShouldInfillChecked = (isChecked: boolean) => {
        handleSetInfillSettings({ ...dataInfillSettings, should_infill_data: isChecked });
    };

    const handleInfillMethodChanged = (infillMethod: DataInfillType) => {
        handleSetInfillSettings({ ...dataInfillSettings, infill_method: infillMethod });
    };

    return (
        <div>
            <Divider />
            <div className="run-period-container">
                <div className="run-period-header" onClick={() => setIsRunPeriodExpanded(!isRunPeriodExpanded)}>
                    <span className="run-period-title">Run Period (Optional)</span>
                    <ERPIcon
                        icon={isRunPeriodExpanded ? faChevronUp : faChevronDown}
                        className="run-period-icon"
                        size="lg"
                    />
                </div>
                {isRunPeriodExpanded && (
                    <RunPeriod runPeriod={runPeriod} onRunPeriodChanged={handleSetRunPeriod} disabled={readOnly} />
                )}
            </div>
            <Divider />
            <Tooltip
                tooltipContent="The computation will return an error if days are missing from the data.               
                Check this to infill the missing days and still attempt the run."
                placement="left"
            >
                <div className="infill-settings">
                    <Checkbox
                        label="Attempt to run with incomplete data"
                        checked={dataInfillSettings.should_infill_data}
                        onChecked={(checked: boolean) => {
                            if (checked !== dataInfillSettings.should_infill_data) {
                                handleShouldInfillChecked(checked);
                            }
                        }}
                    />
                    <Select
                        id="infill-select"
                        menuClassName="infill-type-select"
                        value={infillMethod}
                        values={infillMethods}
                        onSelected={(option: InfillSelectOption) => {
                            if (option.value !== dataInfillSettings.infill_method) {
                                handleInfillMethodChanged(option.value);
                            }
                        }}
                        disabled={readOnly || !dataInfillSettings.should_infill_data}
                    />
                </div>
            </Tooltip>
        </div>
    );
};

export default ModelRunModalRunSettings;
