import { faFilter } from "@fortawesome/pro-solid-svg-icons";
import { round } from "lodash";
import React, { useState } from "react";
import Checkbox from "../../Components/Checkbox";
import Input from "../../Components/Input";
import SortableHeader from "../../Components/SortableHeader";
import { useSort } from "../../Components/SortableHeader/useSort";
import { RiskLevel } from "../../types/models";
import { isNilOrEmpty } from "../../utils/utils";
import { useModel } from "./ModelContext";
import { ModelExplorePreselectTableRowData } from "./ModelExplorePreselect";
import ModelExplorePreselectTableChart from "./ModelExplorePreselectTableChart";

export interface ModelExplorePreselectTableProps {
    tableRows: ModelExplorePreselectTableRowData[];
}

const ModelExplorePreselectTable = ({ tableRows }: ModelExplorePreselectTableProps) => {
    const { explorerScenarios, updateExplorerScenarios } = useModel();
    const { sortedItems, handleSort, sortContext } = useSort(tableRows);

    const [search, setSearch] = useState<string>("");

    const selectedScenarios = explorerScenarios.filter(s => s.isSelected);
    const allSelected = selectedScenarios.length === explorerScenarios.length;

    const handleScenarioSelected = (scenarioId: string, isChecked: boolean) => {
        const nextScenarios = [
            ...explorerScenarios.map(s => {
                if (s.id !== scenarioId) {
                    return s;
                }

                return { ...s, isSelected: isChecked };
            })
        ];

        updateExplorerScenarios(nextScenarios);
    };

    const handleSelectAll = (isChecked: boolean) => {
        const nextScenarios = [
            ...explorerScenarios.map(s => {
                return { ...s, isSelected: isChecked };
            })
        ];

        updateExplorerScenarios(nextScenarios);
    };

    const doesMatchSearch = (scenarioName: string) => {
        if (isNilOrEmpty(search)) {
            return true;
        }

        const normalisedName = scenarioName.toLowerCase();
        const normalisedSearch = search.toLowerCase();

        return normalisedName.includes(normalisedSearch);
    };

    return (
        <table className="preselect-table">
            <thead>
                <tr>
                    <th style={{ width: 300 }}>
                        <div className="scenario-search-container">
                            <span>Scenario</span>
                            <Input
                                icon={faFilter}
                                placeholder="Filter by scenario name"
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                            />
                        </div>
                    </th>

                    <SortableHeader
                        label="% Change from low risk"
                        sortKey="low-risk"
                        context={sortContext}
                        sorter={(g: ModelExplorePreselectTableRowData) => g.low}
                        onSort={handleSort}
                        centered={true}
                    />

                    <SortableHeader
                        label="% Change from moderate risk"
                        sortKey="moderate-risk"
                        context={sortContext}
                        sorter={(g: ModelExplorePreselectTableRowData) => g.moderate}
                        onSort={handleSort}
                        centered={true}
                    />

                    <SortableHeader
                        label="% Change from high risk"
                        sortKey="high-risk"
                        context={sortContext}
                        sorter={(g: ModelExplorePreselectTableRowData) => g.high}
                        onSort={handleSort}
                        centered={true}
                    />

                    <th style={{ width: 100 }}>
                        <div className="explore-select-container">
                            <span>Explore</span>
                            <Checkbox checked={allSelected} onChecked={handleSelectAll} />
                        </div>
                    </th>
                </tr>
            </thead>

            <tbody>
                {sortedItems
                    .filter(r => doesMatchSearch(r.name))
                    .map(r => {
                        return (
                            <tr key={r.id}>
                                <td>{r.name}</td>

                                <td>
                                    <ModelExplorePreselectTableChart
                                        riskLevel={RiskLevel.LOW}
                                        score={round(r.low, 1)}
                                    />
                                </td>

                                <td>
                                    <ModelExplorePreselectTableChart
                                        riskLevel={RiskLevel.MODERATE}
                                        score={round(r.moderate, 1)}
                                    />
                                </td>

                                <td>
                                    <ModelExplorePreselectTableChart
                                        riskLevel={RiskLevel.HIGH}
                                        score={round(r.high, 1)}
                                    />
                                </td>

                                <td>
                                    <div className="row-checkbox-container">
                                        <Checkbox
                                            checked={selectedScenarios.some(s => s.id === r.id)}
                                            onChecked={isChecked => handleScenarioSelected(r.id, isChecked)}
                                        />
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
            </tbody>
        </table>
    );
};

export default ModelExplorePreselectTable;
