import React, { useEffect, useState } from "react";
import ERPModal from "../../Components/Modals/ERPModal";
import { ModalProps } from "../../Components/Modals/types";
import { ModelModel, ModelScenarioModel, RunPeriodModel, TaskStatus } from "../../types/models";
import ModelRunModalFooter from "./ModelRunModalFooter";
import ModelScenario from "./ModelScenario";
import { DataInfillSettingsModel, DataInfillType, ComputationRequest } from "../../types/requests";
import ComputationService from "../../Services/computation.service";
import { Toast } from "../../Components/Toast";
import { isNilOrEmpty } from "../../utils/utils";
import ModelRunScenarioEmpty from "./ModelRunScenarioEmpty";
import { isNil } from "lodash";
import { useModel } from "./ModelContext";
import ModelService from "../../Services/model.service";
import LoadingIndicator from "../../Components/LoadingIndicator";
import ModelRunModalRunSettings from "./ModelRunModalRunSettings";
import Button from "../../Components/Button";

interface ModelRunModalProps extends ModalProps {
    model: ModelModel;
    readOnly: boolean;
}

const ModelRunModal = ({ model, show, readOnly, onClose }: ModelRunModalProps) => {
    const { tasks, scenarios, updateTasks, updateModel } = useModel();

    const [scenariosToRun, setScenariosToRun] = useState<Set<string>>(new Set<string>());
    const [runPeriod, setRunPeriod] = useState<RunPeriodModel>(model.runPeriod);
    const [dataInfillSettings, setDataInfillSettings] = useState<DataInfillSettingsModel>(
        !isNil(model.dataInfillSettings)
            ? model.dataInfillSettings
            : { should_infill_data: false, infill_method: DataInfillType.PREVIOUS_VALUE }
    );
    const [isRunning, setIsRunning] = useState<boolean>(false);
    const [version, setVersion] = useState<string>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleRun = async () => {
        const parsedRunPeriod = checkNullRunPeriod(runPeriod) ? null : runPeriod;

        const updatedModel = await ModelService.updateModelRunSettings(model.id, {
            runPeriod: parsedRunPeriod,
            dataInfillSettings: dataInfillSettings
        });

        updateModel(updatedModel);

        try {
            setIsRunning(true);

            const request: ComputationRequest = {
                modelId: model.id,
                scenarios: Array.from(scenariosToRun.values()),
                runPeriod: parsedRunPeriod,
                dataInfillSettings: dataInfillSettings
            };

            const result = await ComputationService.compute(request);

            if (!result.success) {
                Toast.error(result.failure.message);
                return;
            }

            const newTasks = result.tasks.filter(t => !tasks.some(tt => tt.id === t.id));

            const nextTasks = [
                ...tasks.map(t => {
                    const nextTask = result.tasks.find(rt => rt.id === t.id);

                    return !isNil(nextTask) ? nextTask : t;
                }),
                ...newTasks
            ];

            updateTasks(nextTasks);
        } finally {
            setIsRunning(false);
        }
    };

    const handleScenarioEnabledChanged = (scenario: ModelScenarioModel, enabled: boolean) => {
        const next = new Set<string>([...scenariosToRun.values()]);

        if (enabled) {
            next.add(scenario.id);
        } else {
            next.delete(scenario.id);
        }

        setScenariosToRun(next);
    };

    const handleAllScenarioEnabledChanged = (selectAll: boolean) => {
        if (selectAll) {
            const nextScenariosToRun = new Set<string>(scenarios.map(s => s.id));
            setScenariosToRun(nextScenariosToRun);

            return;
        }

        const nextScenariosToRun = new Set<string>();
        setScenariosToRun(nextScenariosToRun);
    };

    const checkNullRunPeriod = (period: RunPeriodModel): boolean => {
        if (isNil(period?.start) && isNil(period?.end)) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        const getVersion = async () => {
            try {
                setIsLoading(true);

                const _version = await ModelService.getVersion(model.id);

                setVersion(_version.version);
            } finally {
                setIsLoading(false);
            }
        };

        getVersion();
    }, []);

    const isComputing =
        tasks
            .filter(t => t?.status === TaskStatus.RUNNING || t?.status === TaskStatus.PENDING)
            .some(t => scenariosToRun.has(t.resourceId)) || isRunning;

    const allSelected = scenariosToRun.size === scenarios.length;

    return (
        <ERPModal
            show={show}
            title={`Run - ${model.name}`}
            onClose={onClose}
            size="lg"
            footer={
                <ModelRunModalFooter
                    canRun={!readOnly && scenariosToRun.size > 0}
                    isRunning={isComputing}
                    onRun={handleRun}
                />
            }
        >
            <div className="run-model-modal">
                {isLoading && <LoadingIndicator centered />}

                {!isLoading && (
                    <>
                        <Button
                            className="toggle-all-button"
                            onClick={() => handleAllScenarioEnabledChanged(!allSelected)}
                        >{`${allSelected ? "Disable" : "Enable"} All`}</Button>

                        <div className="scenarios-list">
                            {scenarios.map(scenario => {
                                const scenarioTask = tasks.find(t => t.resourceId === scenario.id) ?? null;

                                return (
                                    <ModelScenario
                                        key={scenario.id}
                                        scenario={scenario}
                                        task={scenarioTask}
                                        enabled={scenariosToRun.has(scenario.id)}
                                        version={version}
                                        readOnly={readOnly}
                                        onEnabledChanged={handleScenarioEnabledChanged}
                                    />
                                );
                            })}
                        </div>

                        {isNilOrEmpty(scenarios) && <ModelRunScenarioEmpty />}

                        {!isNilOrEmpty(scenarios) && (
                            <ModelRunModalRunSettings
                                runPeriod={runPeriod}
                                dataInfillSettings={dataInfillSettings}
                                readOnly={readOnly}
                                handleSetRunPeriod={setRunPeriod}
                                handleSetInfillSettings={setDataInfillSettings}
                            />
                        )}
                    </>
                )}
            </div>
        </ERPModal>
    );
};

export default ModelRunModal;
