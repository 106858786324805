import React, { useMemo } from "react";
import Select, { SelectOption } from "../../Components/Select/Select";
import { isNilOrEmpty } from "../../utils/utils";
import { isNil } from "lodash";

interface ParameterSelectProps {
    id: string;
    label: string;
    className?: string;
    disabled?: boolean;
    values: string[];
    dropdownValues: SelectOption[];
    onChanged: (values: string[]) => void;
}

const ParameterSelect = ({ id, label, disabled, values, dropdownValues, onChanged }: ParameterSelectProps) => {
    const selectValues = useMemo(() => {
        if (isNilOrEmpty(values)) {
            return null;
        }

        const _selectValue: SelectOption[] = [];

        for (let i = 0; i < values.length; i++) {
            const value = values[i];

            const matching = dropdownValues.find(m => m.value === value);

            if (!isNil(matching)) {
                _selectValue.push(matching);
            }
        }

        return _selectValue;
    }, [values]);

    return (
        <Select
            id={id}
            labelText={label}
            labelClassName="plugin-definition-field-label"
            value={selectValues}
            disabled={disabled}
            isMulti
            isClearable={true}
            isSearchable={true}
            values={dropdownValues}
            onSelected={(options: SelectOption[]) => {
                const selectedValues = options?.map(o => o.value) ?? [];

                onChanged(selectedValues);
            }}
        />
    );
};

export default ParameterSelect;
