import { faLock } from "@fortawesome/pro-solid-svg-icons";
import TooltipIcon from "../../Components/TooltipIcon";
import React from "react";
import { ResourceLockOwnerModel } from "../../types/models";
import { fullname } from "../../utils/utils";

interface LockedIconProps {
    type: string;
    owner: ResourceLockOwnerModel;
}

export const LockedIcon = ({ type, owner }: LockedIconProps) => {
    return (
        <TooltipIcon className="locked-icon" icon={faLock} tooltip={`${type} is locked by user ${fullname(owner)}`} />
    );
};

export default LockedIcon;
