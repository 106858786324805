import React from "react";
import {
    ExploreDailyPeriodModel,
    ExplorePeriodModel,
    ModelExploreIntermediateResultTypeScale,
    ModelExploreIntermediateResults
} from "../../types/models";
import ModelExploreNodeIntermediateDailyChart from "./ModelExploreNodeIntermediateDailyChart";
import ModelExploreNodeIntermediateGradientBar from "./ModelExploreIntermediateGradientBar";
import ModelExploreNodeIntermediateYearlyChart from "./ModelExploreNodeIntermediateYearlyChart";

interface ModelExploreNodeIntermediateChartsProps {
    resultsData: ModelExploreIntermediateResults;
    yearlyPeriod: ExplorePeriodModel;
    dailyPeriod: ExploreDailyPeriodModel;
}

const ModelExploreNodeIntermediateCharts = ({
    resultsData,
    yearlyPeriod,
    dailyPeriod
}: ModelExploreNodeIntermediateChartsProps) => {
    return (
        <>
            <h5 className="title">{resultsData.resultsType.label}</h5>

            {resultsData.resultsType.scale === ModelExploreIntermediateResultTypeScale.DAILY && (
                <ModelExploreNodeIntermediateGradientBar min={resultsData.minValue} max={resultsData.maxValue} />
            )}

            {resultsData.resultsType.scale === ModelExploreIntermediateResultTypeScale.DAILY &&
                Object.keys(resultsData.nodeResults).map((node, idx) => {
                    return (
                        <ModelExploreNodeIntermediateDailyChart
                            key={`${node}_${idx}`}
                            results={resultsData.nodeResults[node]}
                            nodeTitle={node}
                            minValue={resultsData.minValue}
                            maxValue={resultsData.maxValue}
                            yearlyPeriod={yearlyPeriod}
                            dailyPeriod={dailyPeriod}
                        />
                    );
                })}

            {resultsData.resultsType.scale === ModelExploreIntermediateResultTypeScale.YEARLY &&
                Object.keys(resultsData.nodeResults).map((node, idx) => {
                    return (
                        <ModelExploreNodeIntermediateYearlyChart
                            key={`${node}_${idx}`}
                            results={resultsData.nodeResults[node]}
                            nodeTitle={node}
                            minValue={resultsData.minValue}
                            maxValue={resultsData.maxValue}
                            yearlyPeriod={yearlyPeriod}
                        />
                    );
                })}
        </>
    );
};

export default ModelExploreNodeIntermediateCharts;
