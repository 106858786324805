import React from "react";
import Button from "../../Components/Button";
import { faUpload } from "@fortawesome/pro-solid-svg-icons";
import Dropzone from "react-dropzone";
import clsx from "clsx";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import "./FileSelector.scss";
import Tooltip from "../Tooltip";
import { isNilOrEmpty } from "../../utils/utils";

interface FileSelectorButtonProps {
    label: string;
    accept?: string | string[];
    disabled?: boolean;
    className?: string;
    icon?: IconProp;
    allowMultiple?: boolean;
    tooltip?: string;
    onFileSelected: (file: File | File[]) => void;
}

const FileSelectorButton = ({
    label,
    accept,
    disabled,
    className,
    icon = faUpload,
    allowMultiple = false,
    tooltip,
    onFileSelected
}: FileSelectorButtonProps) => {
    const handleFileSelected = (files: File[]) => {
        if (files?.length === 0) {
            return;
        }

        if (allowMultiple) {
            onFileSelected(files);
        } else {
            onFileSelected(files[0]);
        }
    };

    return (
        <Dropzone noClick noKeyboard noDrag onDrop={handleFileSelected} accept={accept}>
            {({ getInputProps, open }) => (
                <div>
                    {!isNilOrEmpty(tooltip) && (
                        <Tooltip tooltipContent={tooltip}>
                            <Button
                                className={clsx("file-selector-btn", className)}
                                disabled={disabled}
                                onClick={open}
                                icon={icon}
                            >
                                {label}
                            </Button>
                        </Tooltip>
                    )}

                    {isNilOrEmpty(tooltip) && (
                        <Button
                            className={clsx("file-selector-btn", className)}
                            disabled={disabled}
                            onClick={open}
                            icon={icon}
                        >
                            {label}
                        </Button>
                    )}

                    <input {...getInputProps()} />
                </div>
            )}
        </Dropzone>
    );
};

export default FileSelectorButton;
