import RunPeriod from "../../Components/RunPeriod";
import React, { useEffect } from "react";
import { DataType, OptimisationResultsModel } from "../../types/models";
import PluginWaterholeCalibrateModalFields from "./PluginWaterholeCalibrateModalFields";
import PluginWaterholeCalibrateModalResults from "./PluginWaterholeCalibrationModalResults";
import { WaterholeCalibrationParameters, WaterholeCalibrationResults } from "./types";
import PluginWaterholeCalibrateModalSettings from "./PluginWaterholeCalibrationModalSettings";
import { Controller, useForm } from "react-hook-form";
import { debounce, isNil } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { WaterholeParameters } from "../../types/plugin-parameter-types";
import PluginWaterholeOptimisationData from "./PluginWaterholeOptimisationData";
import { numberToMonth } from "../../utils/utils";
import { Toast } from "../../Components/Toast";
import PluginWaterholeCalibrationModalCharts from "./PluginWaterholeCalibrationModalCharts";

interface PluginWaterholeCalibrationModalBodyProps {
    siteValues: Partial<WaterholeParameters>;
    baseParameterValues: Partial<WaterholeParameters>;
    calibrationParamaters: WaterholeCalibrationParameters;
    results: OptimisationResultsModel;
    modelId: string;
    locationId: string;
    siteId: string;
    optimisationId: string;
    disabled?: boolean;
    onParametersChanged: (parameters: WaterholeCalibrationParameters) => void;
    onBaseParameterValuesChanged: (parameters: Partial<WaterholeParameters>) => void;
}

const DEBOUCE_TRIGGER_SAVE = 500;

const schema = yup.object().shape({
    settings: yup.object().shape({}),
    season: yup.object().shape({}),
    predictors: yup.object().shape({})
});

const PluginWaterholeCalibrationModalBody = ({
    siteValues,
    baseParameterValues,
    calibrationParamaters,
    results,
    modelId,
    locationId,
    siteId,
    optimisationId,
    disabled,
    onParametersChanged,
    onBaseParameterValuesChanged
}: PluginWaterholeCalibrationModalBodyProps) => {
    const methods = useForm<WaterholeCalibrationParameters>({
        resolver: yupResolver(schema),
        mode: "onBlur",
        reValidateMode: "onBlur"
    });

    useEffect(() => {
        if (isNil(calibrationParamaters)) {
            return;
        }

        methods.setValue(`settings`, calibrationParamaters.settings ?? null);
        methods.setValue(`season`, calibrationParamaters.season ?? null);
        methods.setValue(`predictors`, calibrationParamaters.predictors ?? null);
    }, []);

    useEffect(() => {
        const subscription = !isNil(onParametersChanged)
            ? methods.watch(debounce(onParametersChanged, DEBOUCE_TRIGGER_SAVE))
            : null;
        return () => {
            subscription?.unsubscribe();
        };
    }, []);

    const handleSetDate = (date: Date) => {
        const season = methods.getValues(`season`);

        if (!isNil(season?.start) && !isNil(season?.end)) {
            Toast.error("Start and end dates already set!");
            return;
        }

        if (isNil(season?.start)) {
            const startDate = {
                day: date.getDate(),
                month: numberToMonth(date.getMonth() + 1),
                year: date.getFullYear()
            };
            const next = { ...season, start: startDate };
            methods.setValue(`season`, next);
            Toast.success("Start date set!");
            return;
        }

        if (isNil(season?.end)) {
            const endDate = {
                day: date.getDate(),
                month: numberToMonth(date.getMonth() + 1),
                year: date.getFullYear()
            };
            const next = { ...season, end: endDate };
            methods.setValue(`season`, next);
            Toast.success("End date set!");
            return;
        }
    };

    return (
        <>
            <div className="calibration-modal-body">
                <Controller
                    control={methods.control}
                    name="season"
                    render={({ field: _field }) => {
                        return (
                            <RunPeriod
                                runPeriod={_field.value}
                                onRunPeriodChanged={next => {
                                    methods.setValue("season", next);
                                }}
                                className="run-period"
                                disabled={disabled}
                            />
                        );
                    }}
                />
                <Controller
                    control={methods.control}
                    name="settings"
                    render={({ field: _field }) => {
                        return (
                            <PluginWaterholeCalibrateModalSettings
                                settings={_field.value}
                                onSettingUpdate={next => {
                                    methods.setValue("settings", next);
                                }}
                                disabled={disabled}
                            />
                        );
                    }}
                />
            </div>
            <div className="calibration-optimisation-fields">
                <Controller
                    control={methods.control}
                    name="predictors"
                    render={({ field: _field }) => {
                        return (
                            <PluginWaterholeCalibrateModalFields
                                siteValues={siteValues}
                                baseParameterValues={baseParameterValues}
                                predictors={_field.value}
                                onPredictorsChanged={next => {
                                    methods.setValue("predictors", next);
                                }}
                                onBaseParameterValuesChanged={onBaseParameterValuesChanged}
                            />
                        );
                    }}
                />

                <div className="results">
                    <PluginWaterholeCalibrateModalResults
                        results={results?.parameterResults as WaterholeCalibrationResults}
                    />

                    <PluginWaterholeCalibrationModalCharts
                        resultFiles={results?.files}
                        modelId={modelId}
                        locationId={locationId}
                        siteId={siteId}
                        optimisationId={optimisationId}
                        onSetDate={handleSetDate}
                    />
                </div>
            </div>
            <PluginWaterholeOptimisationData
                id="calibration-data"
                modelId={modelId}
                locationId={locationId}
                siteId={siteId}
                optimisationId={optimisationId}
                allowedDataTypes={[
                    DataType.FLOW,
                    DataType.RAINFALL,
                    DataType.EVAPORATION,
                    DataType.BATHYMETRY,
                    DataType.DEPTH
                ]}
                disabled={disabled}
            />
        </>
    );
};

export default PluginWaterholeCalibrationModalBody;
