import React, { useMemo } from "react";
import { ModelExploreScenarioResult } from "../../types/models";
import {
    formatScenarioDate,
    getComputationResultTypeDisplayName,
    isComputationFileTypeToBeDisplayed
} from "../../utils/explorer.utils";
import { useModel } from "./ModelContext";
import ModelExplorerDownloadsHeader from "./ModelExplorerDownloadsHeader";
import ModelExplorerDownloadsScenario from "./ModelExplorerDownloadsScenario";
import ModelExploreDownloadsSettings from "./ModelExplorerDownloadsSettings";

const ModelExplorerDownloads = () => {
    const { model, explorerScenarios } = useModel();

    const scenarioResults = useMemo(() => {
        const _results: ModelExploreScenarioResult[] = [];
        for (let i = 0; i < explorerScenarios.length; i++) {
            const scenario = explorerScenarios[i];
            const scenarioResult: ModelExploreScenarioResult = { scenario: scenario, results: [] };

            for (let j = 0; j < scenario.files.length; j++) {
                const file = scenario.files[j];

                const downloadUrl = `${scenario.run.id}/${file.blobId}`;

                scenarioResult.results.push({
                    type: file.type,
                    downloadUrl: downloadUrl,
                    shouldDisplay: isComputationFileTypeToBeDisplayed(file.type),
                    scenarioName: scenario.name,
                    modelName: model.name,
                    date: formatScenarioDate(scenario),
                    extension: file.extension,
                    name: getComputationResultTypeDisplayName(file.type)
                });
            }

            _results.push(scenarioResult);
        }

        return _results;
    }, [explorerScenarios]);

    return (
        <div className="explore-downloads">
            <ModelExplorerDownloadsHeader model={model} scenarioResults={scenarioResults} />

            <ModelExploreDownloadsSettings />

            {scenarioResults.map(result => {
                return (
                    <ModelExplorerDownloadsScenario key={result.scenario.id} model={model} scenarioResult={result} />
                );
            })}
        </div>
    );
};

export default ModelExplorerDownloads;
