import React from "react";
import RadioButton from "../../Components/RadioButton";
import { Controller, useFormContext } from "react-hook-form";
import { ComparisonType, PluginFieldValue, SeasonTypeOptions, SeasonTypeTypes } from "../../types/plugin";
import { PluginFieldProps, PluginForm } from "./types";
import NumericInput from "../../Components/NumericInput";
import DaySelect from "../../Components/DaySelect";
import MonthSelect from "../../Components/MonthSelect";
import Label from "../../Components/Label";
import Checkbox from "../../Components/Checkbox";
import ComparisonSelect from "../../Components/ComparisonSelect";

interface PluginParameterSeasonTypeProps extends PluginFieldProps {
    onObjectChange?: (field: string, value: Record<string, string | number | boolean>) => void;
    onValueChange?: (field: string, value: PluginFieldValue) => void;
}

const PluginParameterSeasonType = ({
    id,
    definition,
    field,
    control,
    disabled,
    onObjectChange,
    onValueChange
}: PluginParameterSeasonTypeProps) => {
    const { watch, getValues } = useFormContext<PluginForm>();

    const [seasonType] = watch([`${definition.id}.${field.id}`]);

    const isDateSeason = seasonType?.type === SeasonTypeTypes.DATE;

    const options: SeasonTypeOptions = field.control.options as SeasonTypeOptions;

    const handleSeasonTypeTypeChanged = (type: SeasonTypeTypes) => {
        const allow_partial_seasons = getValues(`${definition.id}.${field.id}.allow_partial_seasons`);
        const use_moving_temperature_average = getValues(`${definition.id}.${field.id}.use_moving_temperature_average`);
        const temperature_moving_average_duration = getValues(
            `${definition.id}.${field.id}.temperature_moving_average_duration`
        );
        const temperature_comparison = getValues(`${definition.id}.${field.id}.temperature_comparison`);

        onObjectChange("season_type", {
            type: type,
            allow_partial_seasons: allow_partial_seasons,
            use_moving_temperature_average: use_moving_temperature_average,
            temperature_moving_average_duration: temperature_moving_average_duration,
            temperature_comparison: temperature_comparison
        });
    };

    const handleTemperatureThresholdChanged = (threshold: number) => {
        onValueChange("temperature_threshold", threshold);
    };

    const handleStartMonthChanged = (month: string) => {
        const currentDay = getValues(`${definition.id}.season_start.day`);

        onObjectChange("season_start", { month: month, day: currentDay });
    };

    const handleStartDayChanged = (day: number) => {
        const currentMonth = getValues(`${definition.id}.season_start.month`);

        onObjectChange("season_start", { month: currentMonth, day: day });
    };

    const handleEndMonthChanged = (month: string) => {
        const currentDay = getValues(`${definition.id}.season_end.day`);

        onObjectChange("season_end", { month: month, day: currentDay });
    };

    const handleEndDayChanged = (day: number) => {
        const currentMonth = getValues(`${definition.id}.season_end.month`);

        onObjectChange("season_end", { month: currentMonth, day: day });
    };

    const handleAllowPartialSeasonChanged = (isChecked: boolean) => {
        const type = getValues(`${definition.id}.${field.id}.type`);
        const temperature_moving_average_duration = getValues(
            `${definition.id}.${field.id}.temperature_moving_average_duration`
        );
        const use_moving_temperature_average = getValues(`${definition.id}.${field.id}.use_moving_temperature_average`);
        const temperature_comparison = getValues(`${definition.id}.${field.id}.temperature_comparison`);

        onObjectChange("season_type", {
            type: type,
            allow_partial_seasons: isChecked,
            temperature_moving_average_duration: temperature_moving_average_duration,
            use_moving_temperature_average: use_moving_temperature_average,
            temperature_comparison: temperature_comparison
        });
    };

    const handleUseTemperatureMovingAverageChanged = (isChecked: boolean) => {
        const type = getValues(`${definition.id}.${field.id}.type`);
        const allow_partial_seasons = getValues(`${definition.id}.${field.id}.allow_partial_seasons`);
        const temperature_moving_average_duration = getValues(
            `${definition.id}.${field.id}.temperature_moving_average_duration`
        );
        const temperature_comparison = getValues(`${definition.id}.${field.id}.temperature_comparison`);

        onObjectChange("season_type", {
            type: type,
            allow_partial_seasons: allow_partial_seasons,
            temperature_moving_average_duration: temperature_moving_average_duration,
            temperature_comparison: temperature_comparison,
            use_moving_temperature_average: isChecked
        });
    };

    const handleUseTemperatureMovingAverageDurationChanged = (duration: number) => {
        const type = getValues(`${definition.id}.${field.id}.type`);
        const allow_partial_seasons = getValues(`${definition.id}.${field.id}.allow_partial_seasons`);
        const use_moving_temperature_average = getValues(`${definition.id}.${field.id}.use_moving_temperature_average`);
        const temperature_comparison = getValues(`${definition.id}.${field.id}.temperature_comparison`);

        onObjectChange("season_type", {
            type: type,
            allow_partial_seasons: allow_partial_seasons,
            use_moving_temperature_average: use_moving_temperature_average,
            temperature_comparison: temperature_comparison,
            temperature_moving_average_duration: duration
        });
    };

    const handleUseTemperatureComparisonChanged = (comparison: ComparisonType) => {
        const type = getValues(`${definition.id}.${field.id}.type`);
        const allow_partial_seasons = getValues(`${definition.id}.${field.id}.allow_partial_seasons`);
        const use_moving_temperature_average = getValues(`${definition.id}.${field.id}.use_moving_temperature_average`);
        const temperature_moving_average_duration = getValues(
            `${definition.id}.${field.id}.temperature_moving_average_duration`
        );

        onObjectChange("season_type", {
            type: type,
            allow_partial_seasons: allow_partial_seasons,
            use_moving_temperature_average: use_moving_temperature_average,
            temperature_moving_average_duration: temperature_moving_average_duration,
            temperature_comparison: comparison
        });
    };

    return (
        <div className="season-type-parameter-field">
            <Controller
                control={control}
                name={`${definition.id}.${field.id}.type`}
                render={({ field: _field }) => {
                    return (
                        <div className="season-type-radio-button-group">
                            <RadioButton
                                id={`${id}.date`}
                                containerClassName="season-type-radio-button"
                                label="Use Date Season"
                                checked={_field.value === SeasonTypeTypes.DATE}
                                disabled={disabled}
                                onChecked={() => handleSeasonTypeTypeChanged(SeasonTypeTypes.DATE)}
                            />

                            <RadioButton
                                id={`${id}.temperature`}
                                containerClassName="season-type-radio-button"
                                label="Use Temperature"
                                checked={_field.value === SeasonTypeTypes.TEMPERATURE}
                                disabled={disabled}
                                onChecked={() => handleSeasonTypeTypeChanged(SeasonTypeTypes.TEMPERATURE)}
                            />
                        </div>
                    );
                }}
            />

            {!isDateSeason && (
                <div className="temperature-select-group">
                    <div className="temperature-threshold-group">
                        {options?.allow_temperature_comparison && (
                            <Controller
                                control={control}
                                name={`${definition.id}.${field.id}.temperature_comparison`}
                                render={({ field: _field }) => {
                                    return (
                                        <ComparisonSelect
                                            id={`${definition.id}.${field.id}.temperature_comparison`}
                                            value={_field.value}
                                            onComparisonChanged={handleUseTemperatureComparisonChanged}
                                            className="temperature-comparison-select"
                                            disabled={disabled}
                                        />
                                    );
                                }}
                            />
                        )}

                        <Controller
                            control={control}
                            name={`${definition.id}.temperature_threshold`}
                            render={({ field: _field }) => {
                                return (
                                    <NumericInput
                                        id={id}
                                        containerClassName="temperature-threshold"
                                        value={!isDateSeason ? _field.value : null}
                                        min={0}
                                        unit="°C"
                                        disabled={disabled || isDateSeason}
                                        onChange={handleTemperatureThresholdChanged}
                                    />
                                );
                            }}
                        />
                    </div>

                    {options?.allow_temperature_moving_average && (
                        <div className="temperature-moving-average-group">
                            <Controller
                                control={control}
                                name={`${definition.id}.${field.id}.use_moving_temperature_average`}
                                render={({ field: _field }) => {
                                    return (
                                        <Checkbox
                                            id={id}
                                            label="Use moving average"
                                            containerClassName="temperature-use-moving-average-checkbox"
                                            checked={_field.value}
                                            disabled={disabled}
                                            onChecked={handleUseTemperatureMovingAverageChanged}
                                        />
                                    );
                                }}
                            />

                            <Controller
                                control={control}
                                name={`${definition.id}.${field.id}.temperature_moving_average_duration`}
                                render={({ field: _field }) => {
                                    return (
                                        <NumericInput
                                            id={id}
                                            containerClassName="temperature-moving-average-input"
                                            value={
                                                getValues(`${definition.id}.${field.id}.use_moving_temperature_average`)
                                                    ? _field.value
                                                    : null
                                            }
                                            min={1}
                                            unit="Day(s)"
                                            disabled={
                                                disabled ||
                                                !getValues(
                                                    `${definition.id}.${field.id}.use_moving_temperature_average`
                                                )
                                            }
                                            onChange={handleUseTemperatureMovingAverageDurationChanged}
                                        />
                                    );
                                }}
                            />
                        </div>
                    )}
                </div>
            )}

            {isDateSeason && (
                <div className="season-select-group">
                    <div className="season-select">
                        <Label className="season-select-label">{"Start"}</Label>
                        <div className="season-select-inputs">
                            <Controller
                                control={control}
                                name={`${definition.id}.season_start.day`}
                                render={({ field: _field }) => {
                                    return (
                                        <DaySelect
                                            id={`${id}.day`}
                                            value={isDateSeason ? _field.value : null}
                                            className="season-select-day"
                                            disabled={disabled || !isDateSeason}
                                            onDayChanged={handleStartDayChanged}
                                        />
                                    );
                                }}
                            />

                            <Controller
                                control={control}
                                name={`${definition.id}.season_start.month`}
                                render={({ field: _field }) => {
                                    return (
                                        <MonthSelect
                                            id={`${id}.month`}
                                            value={isDateSeason ? _field.value : null}
                                            className="season-select-month"
                                            disabled={disabled || !isDateSeason}
                                            onMonthChanged={handleStartMonthChanged}
                                        />
                                    );
                                }}
                            />
                        </div>
                    </div>

                    <div className="season-select">
                        <Label className="season-select-label">{"End"}</Label>
                        <div className="season-select-inputs">
                            <Controller
                                control={control}
                                name={`${definition.id}.season_end.day`}
                                render={({ field: _field }) => {
                                    return (
                                        <DaySelect
                                            id={`${id}.day`}
                                            value={isDateSeason ? _field.value : null}
                                            className="season-select-day"
                                            disabled={disabled || !isDateSeason}
                                            onDayChanged={handleEndDayChanged}
                                        />
                                    );
                                }}
                            />

                            <Controller
                                control={control}
                                name={`${definition.id}.season_end.month`}
                                render={({ field: _field }) => {
                                    return (
                                        <MonthSelect
                                            id={`${id}.end_month`}
                                            value={isDateSeason ? _field.value : null}
                                            className="season-select-month"
                                            disabled={disabled || !isDateSeason}
                                            onMonthChanged={handleEndMonthChanged}
                                        />
                                    );
                                }}
                            />
                        </div>
                    </div>

                    {options?.allow_partial_seasons && (
                        <Controller
                            control={control}
                            name={`${definition.id}.${field.id}.allow_partial_seasons`}
                            render={({ field: _field }) => {
                                return (
                                    <Checkbox
                                        id={id}
                                        label="Analyse incomplete seasons"
                                        containerClassName="allow-partial-seasons"
                                        checked={_field.value}
                                        disabled={disabled || !isDateSeason}
                                        onChecked={handleAllowPartialSeasonChanged}
                                    />
                                );
                            }}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default PluginParameterSeasonType;
