import Button, { ButtonProps } from "../../Components/Button";
import React from "react";
import { faCircleNotch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type LoadingButtonProps = {
    isLoading: boolean;
    loadingIconClassName?: string;
} & ButtonProps;

const LoadingButton = ({ isLoading, loadingIconClassName, children, disabled, icon, ...rest }: LoadingButtonProps) => {
    return (
        <Button disabled={disabled || isLoading} icon={isLoading ? null : icon} {...rest}>
            {isLoading ? (
                <FontAwesomeIcon style={{ margin: 0 }} icon={faCircleNotch} spin className={loadingIconClassName} />
            ) : (
                children
            )}
        </Button>
    );
};

export default LoadingButton;
