import React from "react";
import { FolderModel } from "../../types/models";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";

import "./FileSystem.scss";
import clsx from "clsx";

interface FileSystemModalFolderProps {
    folder: FolderModel;
    disabled: boolean;
    onSelected: (folder: FolderModel) => void;
}

const FileSystemModalFolder = ({ folder, disabled, onSelected }: FileSystemModalFolderProps) => {
    const handleClick = () => {
        if (disabled) {
            return;
        }

        onSelected(folder);
    };

    return (
        <div className={clsx("fs-modal-folder", { disabled: disabled === true })} onClick={handleClick}>
            <FontAwesomeIcon icon={faFolder} />
            <span>{folder.name}</span>
        </div>
    );
};

export default FileSystemModalFolder;
