import React from "react";
import FormError, { ErrorMessage } from "../../Components/FormError/FormError";
import Label from "../../Components/Label";
import clsx from "clsx";
import NumericInput, { NumericInputProps } from "../../Components/NumericInput";
import TooltipIcon from "../TooltipIcon";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { isNil } from "lodash";
import { isNilOrEmpty } from "../../utils/utils";

export type FormNumericInputProps = {
    labelText?: string;
    labelClass?: string;
    error?: ErrorMessage;
    errorClassName?: string;
    containerClassName?: string;
    icon?: IconProp;
    iconClassName?: string;
    iconTooltip?: string;
} & NumericInputProps;

const FormNumericInput = (props: FormNumericInputProps) => {
    const {
        labelText,
        labelClass,
        error,
        errorClassName,
        name,
        containerClassName,
        icon,
        iconTooltip,
        iconClassName,
        ...rest
    } = props;

    return (
        <div className={clsx("form-group", containerClassName)}>
            {labelText && (
                <div>
                    <Label className={labelClass} htmlFor={name}>
                        {labelText}
                    </Label>

                    {!isNil(icon) && !isNilOrEmpty(iconTooltip) && (
                        <TooltipIcon
                            icon={icon}
                            tooltip={iconTooltip}
                            className={iconClassName}
                            style={{ marginLeft: 5 }}
                        />
                    )}
                </div>
            )}

            <NumericInput name={name} {...rest}></NumericInput>

            <FormError className={errorClassName} error={error} />
        </div>
    );
};

export default FormNumericInput;
