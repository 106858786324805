import React, { useMemo } from "react";
import { ModelExploreScenarioResult, ModelModel, ScenarioResultType } from "../../types/models";
import ScenarioDownloadIcon from "./ScenarioDownloadIcon";
import ScenarioDownloadInformation from "./ScenarioDownloadInformation";
import ScenarioResultDownloadButton from "./ScenarioResultDownloadButton";

interface ModelExplorerDownloadsScenarioProps {
    model: ModelModel;
    scenarioResult: ModelExploreScenarioResult;
}

const ModelExplorerDownloadsScenario = ({ model, scenarioResult }: ModelExplorerDownloadsScenarioProps) => {
    const customDownloadSortOrder = [
        ScenarioResultType.DAILY_RESULTS,
        ScenarioResultType.YEARLY_RESULTS,
        ScenarioResultType.TEMPORAL_RESULTS,
        ScenarioResultType.SPATIAL_RESULTS,
        ScenarioResultType.DAILY_INTERMEDIATE,
        ScenarioResultType.YEARLY_INTERMEDIATE,
        ScenarioResultType.EVENTS_INTERMEDIATE,
        ScenarioResultType.SUMMARY_INTERMEDIATE,
        ScenarioResultType.DAILY_SPATIAL_INTERMEDIATE,
        ScenarioResultType.YEARLY_SPATIAL_INTERMEDIATE,
        ScenarioResultType.SUMMARY_SPATIAL_INTERMEDIATE,
        ScenarioResultType.EVENTS_SPATIAL_INTERMEDIATE,
        ScenarioResultType.CUSTOM_RESULTS,
        ScenarioResultType.PARAMETER_RESULTS,
        ScenarioResultType.RUN_SETTINGS_LOG
    ];

    const displayableSortedResults = useMemo(() => {
        return scenarioResult.results
            .filter(r => r.shouldDisplay)
            .sort((a, b) => {
                const indexA = customDownloadSortOrder.indexOf(a.type);
                const indexB = customDownloadSortOrder.indexOf(b.type);
                return indexA - indexB;
            });
    }, [scenarioResult.results]);

    return (
        <div className="explore-downloads-scenario">
            <div className="scenario-header">
                {scenarioResult.scenario.name}

                <ScenarioDownloadIcon model={model} scenarioResult={scenarioResult} className="download-all-icon" />
            </div>

            <div className="scenario-results-container">
                <div className="scenario-results">
                    {displayableSortedResults.map(result => {
                        return <ScenarioResultDownloadButton key={result.type} result={result} />;
                    })}
                </div>

                <ScenarioDownloadInformation scenario={scenarioResult.scenario} />
            </div>
        </div>
    );
};

export default ModelExplorerDownloadsScenario;
