import { FishResilienceParameters } from "../../types/plugin-parameter-types";
import { PluginParameters } from "../../types/plugin";
import { BaseParameterMerger } from "./base-parameter-merger";

export class FishResilienceParameterMerger extends BaseParameterMerger<FishResilienceParameters> {
    public merge(source: PluginParameters, defaultParameters: FishResilienceParameters): FishResilienceParameters {
        return {
            flow_parameters: {
                flow_type: this.defaultTo(
                    source?.flow_parameters?.flow_type,
                    defaultParameters?.flow_parameters?.flow_type
                ),
                water_stress_time: this.defaultTo(
                    source?.flow_parameters?.water_stress_time,
                    defaultParameters?.flow_parameters?.water_stress_time
                ),
                reset_water_stress_after_flow: this.defaultTo(
                    source?.flow_parameters?.reset_water_stress_after_flow,
                    defaultParameters?.flow_parameters?.reset_water_stress_after_flow
                ),
                drying_time: this.defaultTo(
                    source?.flow_parameters?.drying_time,
                    defaultParameters?.flow_parameters?.drying_time
                )
            },
            movement_parameters: {
                flow_threshold: this.defaultTo(
                    source?.movement_parameters?.flow_threshold,
                    defaultParameters?.movement_parameters?.flow_threshold
                ),
                duration: this.defaultTo(
                    source?.movement_parameters?.duration,
                    defaultParameters?.movement_parameters?.duration
                ),
                temperature_threshold: this.defaultTo(
                    source?.movement_parameters?.temperature_threshold,
                    defaultParameters?.movement_parameters?.temperature_threshold
                ),
                season_type: this.defaultTo(
                    source?.movement_parameters?.season_type,
                    defaultParameters?.movement_parameters?.season_type
                ),
                season_start: this.defaultTo(
                    source?.movement_parameters?.season_start,
                    defaultParameters?.movement_parameters?.season_start
                ),
                season_end: this.defaultTo(
                    source?.movement_parameters?.season_end,
                    defaultParameters?.movement_parameters?.season_end
                )
            }
        };
    }
}
