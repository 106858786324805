import { isNil, round } from "lodash";
import React, { useEffect, useState } from "react";
import ModelService from "../../Services/model.service";
import OptimisationDataService from "../../Services/optimisation.data.service";
import { BaseDataModel, DataType, WaterholeCalibrationBathymetryDataPoint } from "../../types/models";
import { isNilOrEmpty } from "../../utils/utils";
import PluginWaterholeCalibrationModalBathymetryChart from "./PluginWaterholeCalibrationModalBathymetryChart";

interface PluginWaterholeCalibrateModalBathymetryProps {
    modelId: string;
    locationId: string;
    siteId: string;
    optimisationId: string;
}

const PluginWaterholeCalibrateModalBathymetry = ({
    modelId,
    locationId,
    siteId,
    optimisationId
}: PluginWaterholeCalibrateModalBathymetryProps) => {
    const [bathymetryData, setBathymetryData] = useState<WaterholeCalibrationBathymetryDataPoint[]>([]);

    const findFileOfType = async (type: DataType): Promise<BaseDataModel> => {
        const optimisationDataFiles = await ModelService.getModelLocationSiteOptimisationData(
            modelId,
            locationId,
            siteId,
            optimisationId
        );
        const optimisationDataFile = optimisationDataFiles.find(f => f.dataType === type);
        if (!isNil(optimisationDataFile)) {
            return optimisationDataFile;
        }

        const siteDataFiles = await ModelService.getModelLocationSiteData(modelId, locationId, siteId);
        const siteDataFile = siteDataFiles.find(f => f.dataType === type);
        if (!isNil(siteDataFile)) {
            return siteDataFile;
        }

        const scenarioDataFiles = await ModelService.getModelDefaultScenarioData(modelId);
        const scenarioDataFile = scenarioDataFiles.find(f => f.dataType === type);
        if (!isNil(scenarioDataFile)) {
            return scenarioDataFile;
        }

        return null;
    };

    useEffect(() => {
        const findAndParseBathymetryData = async () => {
            const data = await findFileOfType(DataType.BATHYMETRY);
            const parsedData = await OptimisationDataService.fetchAndParseFile(data);
            const mappedData: WaterholeCalibrationBathymetryDataPoint[] = parsedData.map(r => {
                return {
                    depth: +r[0],
                    height: +r[1],
                    volume: round(+r[2], 0),
                    area: round(+r[3], 0)
                };
            });
            setBathymetryData(mappedData);
        };

        findAndParseBathymetryData();
    }, []);

    return (
        <div className="bathymetry">
            <table className="bathymetry-table">
                <tr>
                    <th>Depth</th>
                    <th>Height</th>
                    <th>Volume</th>
                    <th>Area</th>
                </tr>
                {!isNilOrEmpty(bathymetryData) &&
                    bathymetryData.map((r, idx) => {
                        return (
                            <tr key={idx}>
                                <td>{r.depth}</td>
                                <td>{r.height}</td>
                                <td>{r.volume}</td>
                                <td>{r.area}</td>
                            </tr>
                        );
                    })}
            </table>

            <PluginWaterholeCalibrationModalBathymetryChart id="bathymetry-chart" chartData={bathymetryData} />
        </div>
    );
};

export default PluginWaterholeCalibrateModalBathymetry;
