import Button from "../../Components/Button";
import { isNil } from "lodash";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { ValueChange } from "../../Scenes/Model/types";
import { PluginDefinition, PluginFieldValue, PluginParameters } from "../../types/plugin";
import PluginWaterholeCalibrateModal from "./PluginWaterholeCalibrateModal";
import PluginWaterholeRunToEmptyModal from "./PluginWaterholeRunToEmptyModal";
import { PluginForm } from "./types";

interface PluginWaterholeOptimisationParameterDefinitionProps {
    /**
     * Unique identifier to make sure this input is uniquely rendered amongst other plugin parameters.
     * This is important to avoid lose of focus on re-render caused by state change during parameter saving.
     */
    id: string;
    definition: PluginDefinition;
    disabled: boolean;
    locationId: string;
    siteId: string;
    siteName: string;
    onValueChanged?: (definition: string, field: string, value: PluginFieldValue) => void;
    onValuesChanged?: (changedValues: ValueChange[]) => void;
}

const PluginWaterholeOptimisationParameterDefinition = ({
    id,
    disabled,
    locationId,
    siteId,
    siteName,
    onValuesChanged
}: PluginWaterholeOptimisationParameterDefinitionProps) => {
    const { setValue } = useFormContext<PluginForm>();

    const [showCalibrateModal, setShowCalibrateModal] = useState<boolean>(false);
    const [showRunToEmptyModal, setShowRunToEmptyModal] = useState<boolean>(false);

    const handleValuesChanged = (values: PluginParameters) => {
        const changedValues = [];
        for (const section of Object.keys(values)) {
            for (const field of Object.keys(values[section])) {
                setValue(`${section}.${field}`, values[section][field]);
                changedValues.push({
                    definition: section,
                    field: field,
                    value: values[section][field]
                });
            }
        }

        if (!isNil(onValuesChanged)) {
            onValuesChanged(changedValues);
        }
    };

    return (
        <div className="plugin-definition waterhole-calibrate-definition">
            <Button disabled={disabled} onClick={() => setShowCalibrateModal(true)}>
                Calibrate
            </Button>

            {showCalibrateModal && (
                <PluginWaterholeCalibrateModal
                    id={id}
                    show={showCalibrateModal}
                    locationId={locationId}
                    siteId={siteId}
                    siteName={siteName}
                    disabled={disabled}
                    onClose={() => setShowCalibrateModal(false)}
                    onValuesChanged={handleValuesChanged}
                />
            )}

            <Button disabled={disabled} onClick={() => setShowRunToEmptyModal(true)}>
                Run To Empty
            </Button>

            {showRunToEmptyModal && (
                <PluginWaterholeRunToEmptyModal
                    id={id}
                    show={showRunToEmptyModal}
                    locationId={locationId}
                    siteId={siteId}
                    siteName={siteName}
                    disabled={disabled}
                    onClose={() => setShowRunToEmptyModal(false)}
                />
            )}
        </div>
    );
};

export default PluginWaterholeOptimisationParameterDefinition;
