import { CarpRecruitmentParameters } from "../../types/plugin-parameter-types";
import { PluginParameters } from "../../types/plugin";

import { BaseParameterMerger } from "./base-parameter-merger";

export class CarpRecruitmentParameterMerger extends BaseParameterMerger<CarpRecruitmentParameters> {
    public merge(source: PluginParameters, defaultParameters: CarpRecruitmentParameters): CarpRecruitmentParameters {
        return {
            spawning_conditions: {
                ari: this.defaultTo(source?.spawning_conditions?.ari, defaultParameters?.spawning_conditions?.ari),
                recruitment_period: this.defaultTo(
                    source?.spawning_conditions?.recruitment_period,
                    defaultParameters?.spawning_conditions?.recruitment_period
                ),
                drying_time: this.defaultTo(
                    source?.spawning_conditions?.drying_time,
                    defaultParameters?.spawning_conditions?.drying_time
                )
            },
            season: {
                season_type: this.defaultTo(source?.season?.season_type, defaultParameters?.season?.season_type),
                temperature_threshold: this.defaultTo(
                    source?.season?.temperature_threshold,
                    defaultParameters?.season?.temperature_threshold
                ),
                season_start: this.defaultTo(source?.season?.season_start, defaultParameters?.season?.season_start),
                season_end: this.defaultTo(source?.season?.season_end, defaultParameters?.season?.season_end)
            },
            assessment: {
                yearly_agg: this.defaultTo(source?.assessment?.yearly_agg, defaultParameters?.assessment?.yearly_agg),
                temporal_agg: this.defaultTo(
                    source?.assessment?.temporal_agg,
                    defaultParameters?.assessment?.temporal_agg
                )
            },
            spatial_agg: {
                risk_categories: this.defaultTo(
                    source?.spatial_agg?.risk_categories,
                    defaultParameters?.spatial_agg?.risk_categories
                )
            }
        };
    }
}
