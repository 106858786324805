import React, { ReactElement } from "react";
import { OverlayTrigger, Tooltip as ReactTooltip } from "react-bootstrap";
import { Placement } from "react-bootstrap/Overlay";
import { guid } from "../../utils/utils";

type TooltipProps = {
    children: ReactElement<any>;
    tooltipContent: string | ReactElement<any>;
    placement?: Placement;
    disabled?: boolean;
    tooltipContainerClassName?: string;
};

const Tooltip = ({
    children,
    placement,
    tooltipContent,
    disabled = false,
    tooltipContainerClassName
}: TooltipProps) => {
    if (disabled) {
        return children;
    }

    return (
        <OverlayTrigger
            key={placement}
            placement={placement}
            overlay={
                <ReactTooltip className={tooltipContainerClassName} id={guid()}>
                    {tooltipContent}
                </ReactTooltip>
            }
        >
            {children}
        </OverlayTrigger>
    );
};

export default Tooltip;
