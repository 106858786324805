import React, { useMemo } from "react";
import Button from "../Button";
import { isNil } from "lodash";
import LoadingIndicator from "../LoadingIndicator";
import { faCheckCircle, faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { isNilOrEmpty } from "../../utils/utils";
import ERPIcon from "../ERPIcon";
import { BOMConnectionSettings, ObservedDataSettings, ObservedDataSourceTypes, SILOConnectionSettings } from "./types";

interface ObservedDataModelFooterProps {
    connectionSettings: ObservedDataSettings;
    isLoading: boolean;
    hasFetchedData: boolean;
    dataLength: number;
    error?: string;
    onFetchData: () => void;
    onSave: () => void;
}

const ObservedDataModelFooter = ({
    connectionSettings,
    isLoading,
    hasFetchedData,
    dataLength,
    error,
    onFetchData,
    onSave
}: ObservedDataModelFooterProps) => {
    const areSettingsValid = useMemo(() => {
        if (connectionSettings.source === ObservedDataSourceTypes.SILO) {
            const settings = connectionSettings?.settings as SILOConnectionSettings;

            return !isNil(settings?.latitude) && !isNil(settings?.longitude) && !isNil(connectionSettings?.dataType);
        }

        if (connectionSettings.source === ObservedDataSourceTypes.BOM) {
            const settings = connectionSettings?.settings as BOMConnectionSettings;

            return !isNil(settings?.gauge) && !isNil(connectionSettings?.dataType);
        }

        return false;
    }, [connectionSettings]);

    const canSave = hasFetchedData && dataLength > 0;

    return (
        <div className="data-connection-modal-footer">
            <div className="test-connection-container">
                <Button disabled={!areSettingsValid || isLoading} onClick={() => onFetchData()}>
                    Fetch data
                </Button>

                {isLoading && <LoadingIndicator size="2x" />}

                {hasFetchedData && (
                    <>
                        {!isLoading && isNilOrEmpty(error) && (
                            <>
                                <ERPIcon icon={faCheckCircle} size="2x" className="success-icon" />

                                <span>{`${dataLength} records found`}</span>
                            </>
                        )}

                        {!isLoading && !isNilOrEmpty(error) && (
                            <>
                                <ERPIcon icon={faExclamationTriangle} size="2x" className="error-icon" />
                                <span>{error}</span>
                            </>
                        )}
                    </>
                )}
            </div>

            <Button disabled={!canSave} onClick={() => onSave()}>
                Save
            </Button>
        </div>
    );
};

export default ObservedDataModelFooter;
