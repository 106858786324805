import { TandanusParameters } from "../../types/plugin-parameter-types";
import { PluginParameters } from "../../types/plugin";
import { BaseParameterMerger } from "./base-parameter-merger";

export class TandanusParameterMerger extends BaseParameterMerger<TandanusParameters> {
    public merge(source: PluginParameters, defaultParameters: TandanusParameters): TandanusParameters {
        return {
            season: {
                temperature_threshold: this.defaultTo(
                    source?.season?.temperature_threshold,
                    defaultParameters?.season?.temperature_threshold
                ),
                season_type: this.defaultTo(source?.season?.season_type, defaultParameters?.season?.season_type),
                season_start: this.defaultTo(source?.season?.season_start, defaultParameters?.season?.season_start),
                season_end: this.defaultTo(source?.season?.season_end, defaultParameters?.season?.season_end)
            },
            flow_parameters: {
                flow_type: this.defaultTo(
                    source?.flow_parameters?.flow_type,
                    defaultParameters?.flow_parameters?.flow_type
                ),
                duration: this.defaultTo(
                    source?.flow_parameters?.duration,
                    defaultParameters?.flow_parameters?.duration
                ),
                depth_bound_upper: this.defaultTo(
                    source?.flow_parameters?.depth_bound_upper,
                    defaultParameters?.flow_parameters?.depth_bound_upper
                ),
                depth_bound_lower: this.defaultTo(
                    source?.flow_parameters?.depth_bound_lower,
                    defaultParameters?.flow_parameters?.depth_bound_lower
                ),
                depth_duration: this.defaultTo(
                    source?.flow_parameters?.depth_duration,
                    defaultParameters?.flow_parameters?.depth_duration
                )
            },
            assessment: {
                yearly_agg: this.defaultTo(source?.assessment?.yearly_agg, defaultParameters?.assessment?.yearly_agg),
                temporal_agg: this.defaultTo(
                    source?.assessment?.temporal_agg,
                    defaultParameters?.assessment?.temporal_agg
                )
            },
            spatial_agg: {
                risk_categories: this.defaultTo(
                    source?.spatial_agg?.risk_categories,
                    defaultParameters?.spatial_agg?.risk_categories
                )
            }
        };
    }
}
