import React, { useMemo } from "react";
import { LocationModel } from "../../types/models";
import { Item, Menu } from "react-contexify";
import { useSystem } from "./SystemContext";
import ZipGenerator, { ZipFileInfo } from "../../Services/zip-generator";
import { Toast } from "../../Components/Toast";
import AzureBlobService from "../../Services/azure.blob.service";

interface LocationContextMenuProps {
    location: LocationModel;
    disabled: boolean;
    onEdit: () => void;
    onDelete: () => void;
}

export function menuId(location: LocationModel): string {
    return `location-menu-${location.id}`;
}

//For now we are creating a menu for each location, if for some reason this impact
// performance then we could instead only ever show 1 menu and pass the location as props before showing the menu
const LocationContextMenu = ({ location, disabled, onEdit, onDelete }: LocationContextMenuProps) => {
    const { data } = useSystem();

    const _menuId = useMemo(() => {
        return menuId(location);
    }, [location?.id]);

    const handleDownloadLocationData = async () => {
        const locationData = data.filter(d => d.locationId === location.id);

        if (locationData.length < 1) {
            Toast.error("Location has no data");
            return;
        }

        const generator = new ZipGenerator();

        const files: ZipFileInfo[] = await Promise.all(
            locationData.map(async d => {
                const data = await AzureBlobService.downloadBlobAsFile(d.blobId, d.name);
                return { name: data.name, file: data };
            })
        );

        const blob = await generator.generate(files);

        AzureBlobService.downloadToDisk(blob, `${location.name}_data.zip`);
    };

    return (
        <Menu id={_menuId} animation={false}>
            <Item disabled={disabled} onClick={onEdit}>
                Edit location
            </Item>

            <Item disabled={disabled} onClick={onDelete}>
                Delete location
            </Item>

            <Item onClick={handleDownloadLocationData}>Download location data</Item>
        </Menu>
    );
};

export default LocationContextMenu;
