import React, { useEffect, useMemo, useState } from "react";
import {
    ERPTimerseriesChartData,
    SystemDataPreviewLocationData,
    SystemDataPreviewScenarioData,
    SystemDataPreviewSettings
} from "../../types/models";
import { DataPreviewTabType } from "./DataPreviewTabs";
import AzureBlobService from "../../Services/azure.blob.service";
import { CSVParser } from "../../Services/csv-parser";
import LoadingIndicator from "../../Components/LoadingIndicator";
import PreviewDataService from "../../Services/preview.data.service";
import SystemDataPreviewModalMagnitudeDurationChart from "./SystemDataPreviewModalMagnitudeDurationChart";
import { useSystem } from "./SystemContext";
import ERPTimeseriesChart from "../../Components/Charts/ERPTimeseriesChart";

interface SystemDataPreviewModalBodyProps {
    settings: SystemDataPreviewSettings;
    activeTab: DataPreviewTabType;
}

const SystemDataPreviewModalBody = ({ settings, activeTab }: SystemDataPreviewModalBodyProps) => {
    const { scenarios, locations, data } = useSystem();

    const csvParser = new CSVParser();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [systemScenariosData, setSystemScenariosData] = useState<SystemDataPreviewScenarioData[]>([]);

    const loadData = async () => {
        try {
            setIsLoading(true);

            const scenarioDataFiles: SystemDataPreviewScenarioData[] = await Promise.all(
                scenarios.map(async s => {
                    const scenarioLocationsData = data.filter(d => d.scenarioId === s.id);
                    const parsedScenarioLocationsData: SystemDataPreviewLocationData[] = await Promise.all(
                        scenarioLocationsData.map(async l => {
                            const data = await AzureBlobService.downloadBlobAsString(l.blobId);
                            const parsedData = csvParser.parseTimeseries(data, true);
                            return { locationId: l.locationId, dataType: l.dataType, data: parsedData };
                        })
                    );
                    return { scenarioId: s.id, scenarioName: s.name, locationsData: parsedScenarioLocationsData };
                })
            );

            setSystemScenariosData(scenarioDataFiles);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const chartData: ERPTimerseriesChartData = useMemo(() => {
        switch (activeTab) {
            case DataPreviewTabType.LOCATIONS:
                return PreviewDataService.formatLocationData(
                    systemScenariosData,
                    locations,
                    settings.selectedScenario.id,
                    settings.selectedDataType
                );
            case DataPreviewTabType.SCENARIOS:
                return PreviewDataService.formatScenarioData(
                    systemScenariosData,
                    settings.selectedLocation.id,
                    settings.selectedDataType
                );

            default:
                return [];
        }
    }, [systemScenariosData, activeTab, settings]);

    return (
        <div>
            {isLoading && <LoadingIndicator centered />}
            {!isLoading && (
                <>
                    {chartData.length > 0 && (
                        <>
                            <ERPTimeseriesChart
                                id="timeseries-chart"
                                chartData={chartData}
                                dataType={settings.selectedDataType}
                                height={700}
                            />
                            <SystemDataPreviewModalMagnitudeDurationChart
                                id="magnitude-duration-chart"
                                chartData={chartData}
                                dataType={settings.selectedDataType}
                            />
                        </>
                    )}
                    {chartData.length === 0 && (
                        <div className="no-data-message">
                            The selected configuration has no data, add data or change your configuration to preview
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default SystemDataPreviewModalBody;
