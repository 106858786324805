import React from "react";
import { FolderModel } from "../../types/models";
import ERPModal from "../../Components/Modals/ERPModal";
import { ModalProps } from "../../Components/Modals/types";
import { isNil } from "lodash";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import FormActions from "../../Components/FormActions";
import Button from "../../Components/Button";
import LoadingButton from "../../Components/LoadingButton";
import FormInput from "../../Components/FormInput/FormInput";
import { CreateFolderRequest, UpdateFolderRequest } from "../../types/requests";
import FolderService from "../../Services/folder.service";
import { Toast } from "../../Components/Toast";

interface FolderModalProps extends ModalProps {
    context: FolderModalContext;
    onFolderCreated: (folder: FolderModel) => void;
    onFolderUpdated: (folder: FolderModel) => void;
}

export interface FolderModalContext {
    parentFolder: FolderModel;
    folder?: FolderModel;
}

const schema = yup.object().shape({
    name: yup.string().required("Name is required")
});

interface FolderForm {
    name: string;
}

const FolderModal = ({ context, show, onClose, onFolderCreated, onFolderUpdated }: FolderModalProps) => {
    const isEdit = !isNil(context.folder);
    const title = isEdit ? "Edit folder" : "Create folder";

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty, isSubmitting }
    } = useForm<FolderForm>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: context.folder?.name ?? ""
        }
    });

    const handleFormSubmit = async (data: FolderForm) => {
        if (!isEdit) {
            const request: CreateFolderRequest = {
                parentFolderId: context.parentFolder.id,
                name: data.name
            };

            const newFolder = await FolderService.createFolder(request);

            Toast.success(`Successfully created folder ${data.name}`);

            onFolderCreated(newFolder);
        } else {
            const request: UpdateFolderRequest = {
                name: data.name
            };

            const updatedFolder = await FolderService.updateFolder(context.folder.id, request);

            Toast.success(`Successfully updated folder ${data.name}`);

            onFolderUpdated(updatedFolder);
        }

        onClose();
    };

    return (
        <ERPModal title={title} show={show} onClose={onClose}>
            <div>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <FormInput
                        labelText="Name"
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Folder name"
                        register={register("name")}
                        error={errors?.name}
                        disabled={isSubmitting}
                    />

                    <FormActions>
                        <Button variant="secondary" disabled={isSubmitting} onClick={onClose}>
                            Cancel
                        </Button>

                        <LoadingButton disabled={!isDirty} isLoading={isSubmitting} variant="primary" type="submit">
                            {!isEdit ? "Create" : "Update"}
                        </LoadingButton>
                    </FormActions>
                </form>
            </div>
        </ERPModal>
    );
};

export default FolderModal;
