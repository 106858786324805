import { ChartOptions, Point } from "chart.js";
import ERPChart from "../../Components/Charts/ERPChart";
import { maxBy, merge, minBy } from "lodash";
import React, { useMemo } from "react";
import { CurveOptions } from "../../types/plugin";
import { ParameterCurveChartInfo } from "./types";

interface PluginParameterCurveChartProps {
    options: CurveOptions;
    values: number[][];
}

const CHART_OPTIONS: ChartOptions = {
    maintainAspectRatio: false,
    interaction: {
        mode: "index"
    },
    scales: {
        x: {
            grid: { display: false, drawBorder: false },
            ticks: {
                display: false
            }
        },
        y: {
            grid: { display: false, drawBorder: false },
            ticks: {
                display: false
            }
        }
    },

    plugins: {
        tooltip: {
            enabled: true,
            position: "average"
        },
        legend: {
            display: false
        }
    },
    hover: {
        mode: "index",
        intersect: false
    }
};

const PluginParameterCurveChart = ({ options, values }: PluginParameterCurveChartProps) => {
    const chartInfo: ParameterCurveChartInfo = useMemo(() => {
        const points: Point[] = values.map(record => {
            return {
                x: record[0],
                y: record[1]
            };
        });

        const info: ParameterCurveChartInfo = {
            minX: 0,
            maxX: 0,
            minY: 0,
            maxY: 0,
            labels: points.map(p => p.x),
            dataset: {
                label: "",
                data: points,
                borderColor: "#08bfdd",
                backgroundColor: "#08bfdd",
                clip: 5
            },
            options: null
        };

        info.minX = minBy(points, d => d.x)?.x ?? 0;
        info.maxX = maxBy(points, d => d.x)?.x ?? 0;
        info.minY = minBy(points, d => d.y)?.y ?? 0;
        info.maxY = maxBy(points, d => d.y)?.y ?? 0;

        info.options = merge<ChartOptions, ChartOptions, ChartOptions>({}, CHART_OPTIONS, {
            scales: {
                x: { min: info.minX, max: info.maxX, title: { text: options?.x_label, display: true } },
                y: { min: info.minY, max: info.maxY, title: { text: options?.y_label, display: true } }
            }
        });

        return info;
    }, [values]);

    return (
        <div className="curve-parameter-graph-container">
            <ERPChart
                type="line"
                labels={chartInfo?.labels}
                data={[chartInfo?.dataset]}
                options={chartInfo?.options}
                height="100%"
            />
        </div>
    );
};

export default PluginParameterCurveChart;
