import React, { useMemo } from "react";
import Humanize from "humanize-plus";
import PluginWaterholeCalibrateModalFieldInput from "./PluginWaterholeCalibrateModalInput";
import { PluginFieldValue } from "../../types/plugin";
import Switch from "../../Components/Switch";

interface PluginWaterholeCalibrateModalFieldProps {
    id: string;
    section: string;
    field: string;
    name?: string;
    siteValue: PluginFieldValue;
    baseParameterValue: PluginFieldValue;
    canOptimise: boolean;
    isOptimised: boolean;
    unit?: string;
    onValueChanged: (section: string, field: string, value: PluginFieldValue) => void;
    onOptimiseChanged: (section: string, field: string) => void;
}

const PluginWaterholeCalibrateModalField = ({
    id,
    section,
    field,
    name,
    siteValue,
    baseParameterValue,
    canOptimise,
    isOptimised,
    unit,
    onValueChanged,
    onOptimiseChanged
}: PluginWaterholeCalibrateModalFieldProps) => {
    const fieldName = useMemo(() => {
        return name ?? Humanize.capitalize(field.replaceAll("_", " "));
    }, [field]);

    return (
        <tr key={id}>
            <td className="field-label">{fieldName ?? field}</td>
            <td>
                <PluginWaterholeCalibrateModalFieldInput
                    id={`${id}-original`}
                    section={section}
                    field={field}
                    value={siteValue}
                    unit={unit}
                    disabled
                    onValueChanged={onValueChanged}
                />
            </td>
            <td>
                <PluginWaterholeCalibrateModalFieldInput
                    id={`${id}-modified`}
                    section={section}
                    field={field}
                    value={baseParameterValue}
                    disabled={false}
                    onValueChanged={onValueChanged}
                />
            </td>
            <td>
                {canOptimise && (
                    <Switch
                        id={`${id}-optimise`}
                        checked={isOptimised}
                        onChecked={() => {
                            onOptimiseChanged(section, field);
                        }}
                    />
                )}
            </td>
        </tr>
    );
};

export default PluginWaterholeCalibrateModalField;
