import { csvParse, csvFormat, csvParseRows } from "d3-dsv";
import { CSV } from "../types/global";
import { TimeseriesDay } from "../types/models";
import moment from "moment";

/**
 * Service handling csv parsing operations
 */
export class CSVParser {
    /**
     * Parse the given csv string into a list of object representing each csv rows
     * @param csvString The csv as string
     * @returns An array of rows
     */
    parse<THeader extends string, TValues = string>(csvString: string): CSV<THeader, TValues> {
        const parsedCsv = csvParse(csvString) ?? ([] as Array<Record<THeader, TValues>>);

        const headers: THeader[] = [];

        if (parsedCsv.length > 0) {
            const first = parsedCsv[0];
            headers.push(...(Object.keys(first) as THeader[]));
        }

        return {
            rows: parsedCsv as Array<Record<THeader, TValues>>,
            headers: headers
        };
    }

    parseToArray(csvString: string, shouldRemoveHeader: boolean): Array<Array<string>> {
        const parsedCsv = csvParseRows(csvString) ?? [];

        if (shouldRemoveHeader && parsedCsv.length > 0) {
            parsedCsv.shift();
        }

        return parsedCsv;
    }

    parseTimeseries(csvString: string, shouldRemoveHeader: boolean): TimeseriesDay[] {
        const parsedCsv =
            csvParseRows(csvString, d => {
                return {
                    date: moment(d[0], "DD/MM/YYYY").toDate(),
                    value: +d[1]
                };
            }) ?? [];

        if (shouldRemoveHeader && parsedCsv.length > 0) {
            parsedCsv.shift();
        }

        return parsedCsv;
    }

    formatToString<THeader extends string, TValues = string>(csvObject: CSV<THeader, TValues>): string {
        const formattedString = csvFormat(csvObject.rows, csvObject.headers) ?? "";
        return formattedString;
    }
}
