import React from "react";
import Checkbox from "../../Components/Checkbox";
import { PluginFieldProps } from "./types";
import { Controller } from "react-hook-form";

interface PluginParameterCheckboxProps extends PluginFieldProps {
    onChecked: (value: boolean) => void;
}

const PluginParameterCheckbox = ({
    id,
    definition,
    field,
    control,
    disabled,
    onChecked
}: PluginParameterCheckboxProps) => {
    return (
        <Controller
            control={control}
            name={`${definition.id}.${field.id}`}
            render={({ field: _field }) => {
                return (
                    <Checkbox
                        id={id}
                        containerClassName="plugin-parameter-checkbox"
                        label={field.name}
                        checked={_field.value}
                        disabled={disabled}
                        onChecked={(checked: boolean) => {
                            if (checked !== _field.value) {
                                onChecked(checked);
                            }
                        }}
                    />
                );
            }}
        />
    );
};

export default PluginParameterCheckbox;
