import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Button from "../../Components/Button";
import React from "react";

interface ResultsWarningProps {
    message: string;
    buttonText: string;
    icon?: IconProp;
    onUpdate: () => void;
}

const ResultsWarning = ({ message, buttonText, icon, onUpdate }: ResultsWarningProps) => {
    return (
        <div className="results-warning">
            <span>{message}</span>

            <Button variant="secondary" onClick={onUpdate} icon={icon}>
                {buttonText}
            </Button>
        </div>
    );
};

export default ResultsWarning;
