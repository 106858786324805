import FileSelectorDrag from "../../Components/FileSelector/FileSelectorDrag";
import ERPModal from "../../Components/Modals/ERPModal";
import { isNil } from "lodash";
import React, { useState } from "react";
import { BulkImportService, DuplicateEntryStratergyOption } from "../../Services/bulk-import.service";
import SystemBulkImportModalNamingConventionSettings from "./SystemBulkImportModalNamingConventionSettings";
import SystemBulkImportModelInfoPanel from "./SystemBulkImportModelInfoPanel";
import SystemBulkImportModelProgressPanel from "./SystemBulkImportModelProgressPanel";
import { useSystem } from "./SystemContext";
import { NamingConventionOption, SeperatorOption } from "../../Services/file-name-parser.service";

interface SystemBulkImportModalProps {
    show: boolean;
    onClose: () => void;
}

export interface BulkImportSettings {
    seperator: SeperatorOption;
    namingConvention: NamingConventionOption[];
    duplicateEntryStratergy: DuplicateEntryStratergyOption;
}

const DEFAULT_SETTINGS: BulkImportSettings = {
    seperator: SeperatorOption.UNDERSCORE,
    namingConvention: [
        NamingConventionOption.LOCATION,
        NamingConventionOption.DATATYPE,
        NamingConventionOption.SCENARIO
    ],
    duplicateEntryStratergy: DuplicateEntryStratergyOption.SKIP
};

export enum ConsoleMessageTypeOption {
    SUCCESS = "success",
    WARN = "warn",
    ERROR = "error"
}

export interface ConsoleMessageModel {
    type: ConsoleMessageTypeOption;
    message: string;
}

const SystemBulkImportModal = ({ show, onClose }: SystemBulkImportModalProps) => {
    const { scenarios, locations, system, data, updateData, updateScenarios, updateLocations, updateSelectedScenario } =
        useSystem();

    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [progressPercent, setProgressPercent] = useState<number>(0);
    const [consoleMessages, setConsoleMessages] = useState<ConsoleMessageModel[]>([]);
    const [settings, setSettings] = useState<BulkImportSettings>(DEFAULT_SETTINGS);

    const handleNewConsoleMessage = (newMessage: ConsoleMessageModel) => {
        setConsoleMessages(existingConsoleMessages => [...existingConsoleMessages, newMessage]);
    };

    const handleFileSelected = async (files: File[]) => {
        setIsUploading(true);

        const bulkImportContext = new BulkImportService(system, scenarios, locations, data, settings);

        try {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];

                const result = await bulkImportContext.importFile(file);

                setProgressPercent(((i + 1) / files.length) * 100);

                if (!result.success) {
                    handleNewConsoleMessage({
                        type: ConsoleMessageTypeOption.ERROR,
                        message: result.error
                    });
                    continue;
                }

                if (!isNil(result.warning)) {
                    handleNewConsoleMessage({
                        type: ConsoleMessageTypeOption.WARN,
                        message: result.warning
                    });
                    continue;
                }

                handleNewConsoleMessage({
                    type: ConsoleMessageTypeOption.SUCCESS,
                    message: `${file.name} - successfully imported`
                });
            }
        } finally {
            const defaultScenario = bulkImportContext.scenarios.find(s => s.isDefault);
            updateScenarios(bulkImportContext.scenarios);
            updateSelectedScenario(defaultScenario);

            updateLocations(bulkImportContext.locations);

            updateData(bulkImportContext.data);
        }
    };

    const isNamingConventionValid = !settings.namingConvention.some(v => isNil(v));

    return (
        <ERPModal
            show={show}
            title={`Bulk import - ${system?.name}`}
            onClose={onClose}
            size="xl"
            scrollable
            backdrop={true}
            bodyClassName="bulk-import-modal-body"
            canClose={true}
        >
            <SystemBulkImportModelInfoPanel />

            <SystemBulkImportModalNamingConventionSettings settings={settings} onSettingsChanged={setSettings} />

            {!isUploading && (
                <FileSelectorDrag
                    className="file-uploader"
                    allowMultiple
                    fileOnly={true}
                    accept=".csv"
                    onFileSelected={handleFileSelected}
                    disabled={!isNamingConventionValid}
                />
            )}

            {isUploading && (
                <SystemBulkImportModelProgressPanel progressPercent={progressPercent} messageLog={consoleMessages} />
            )}
        </ERPModal>
    );
};

export default SystemBulkImportModal;
