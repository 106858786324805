import React, { useMemo } from "react";
import LoadingIndicator from "../../Components/LoadingIndicator";
import moment from "moment";
import { isNil } from "lodash";
import TooltipIcon from "../../Components/TooltipIcon";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { useSystem } from "./SystemContext";
import PageTitle from "../../Components/PageTitle";

const SystemHeader = () => {
    const { system, isSaving } = useSystem();

    const lastSaved = useMemo(() => {
        return moment(system.updatedAt).format("DD MMM, YYYY hh:mm A");
    }, [system.updatedAt]);

    return (
        <div className="system-section system-header">
            <PageTitle title={system.name} />

            <h5>{system.name}</h5>

            {isSaving && <LoadingIndicator size="lg" className="saving-indicator" />}

            {!isSaving && !isNil(system.updatedAt) && (
                <TooltipIcon
                    icon={faCheckCircle}
                    className="saved-status-icon"
                    tooltip={`Last saved - ${lastSaved}`}
                    size="lg"
                />
            )}
        </div>
    );
};

export default SystemHeader;
