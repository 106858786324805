import React from "react";
import Label from "../Label";
import FileSelectorButton from "../FileSelector/FileSelectorButton";
import { CSVParser } from "../../Services/csv-parser";
import { isNilOrEmpty } from "../../utils/utils";
import CrossSectionRow from "./CrossSectionRow";
import CrossSectionAddRow from "./CrossSectionAddRow";
import CrossSectionCurveChart from "./CrossSectionChart";
import clsx from "clsx";

import "./CrossSection.scss";
import { max, round } from "lodash";
import TooltipIcon from "../TooltipIcon";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";

interface CrossSectionProps {
    id: string;
    values: number[][];
    label: string;
    containerClassName?: string;
    disabled?: boolean;
    onChange: (value: number[][]) => void;
}

const KNOWN_HEIGHT_NAMES = ["height", "ahd"];

const CrossSection = ({ id, values, label, containerClassName, disabled, onChange }: CrossSectionProps) => {
    const handleRowAdded = () => {
        const next = [...(values ?? [])];

        next.push([0, 0]);

        onChange(next);
    };

    const handleValueChanged = (rowIndex: number, index: number, value: number) => {
        const next = [...(values ?? [])];

        next[rowIndex][index] = value;

        onChange(next);
    };

    const handleRowRemoved = (rowIndex: number) => {
        const next = [...(values ?? [])];

        next.splice(rowIndex, 1);

        onChange(next);
    };

    const handleImportCurve = async (file: File) => {
        const data = await file.text();

        const csvParser = new CSVParser();

        const parsedData = csvParser.parseToArray(data, false);

        if (parsedData.length === 0) {
            return;
        }

        const headers = parsedData.shift();

        const isHeight =
            headers.length >= 2 ? KNOWN_HEIGHT_NAMES.some(n => n.includes(headers[1].toLowerCase())) : false;

        const rows = parsedData.map(r => [Math.abs(parseFloat(r[0])), Math.abs(parseFloat(r[1]))]);

        if (isNilOrEmpty(rows)) {
            return;
        }

        if (!isHeight) {
            onChange(rows);
            return;
        }

        const rowsMax = max(rows.map(r => r[1]));
        const adjustedRows = rows.map(r => {
            return [r[0], round(Math.abs(r[1] - rowsMax), 5)];
        });

        onChange(adjustedRows);
    };

    return (
        <div className={clsx("cross-section-container", containerClassName)}>
            <div className="cross-section">
                <div>
                    <Label className="plugin-definition-field-label">{label}</Label>

                    <TooltipIcon
                        icon={faQuestionCircle}
                        tooltip="Height (AHD) data will be automatically converted to depth when uploaded using the curve import tool"
                        style={{ marginLeft: 5 }}
                    />
                </div>

                <table className="cross-section--table">
                    <thead>
                        <tr>
                            <td>Width (m)</td>

                            <td>Depth (m)</td>
                        </tr>
                    </thead>

                    <tbody>
                        {values.map((row, idx) => {
                            return (
                                <CrossSectionRow
                                    key={`${id}-curve-row-${idx}`}
                                    id={id}
                                    values={row}
                                    disabled={disabled}
                                    disableRemove={false}
                                    disableX={false}
                                    disableY={false}
                                    onChanged={(index, val) => handleValueChanged(idx, index, val)}
                                    onRemoved={() => handleRowRemoved(idx)}
                                />
                            );
                        })}

                        <CrossSectionAddRow onRowAdded={handleRowAdded} />
                    </tbody>
                </table>

                <FileSelectorButton
                    className="cross-section-import-button"
                    label="Import curve from file"
                    disabled={disabled}
                    onFileSelected={handleImportCurve}
                    accept=".csv"
                    tooltip="If using height, ensure column name includes height or AHD for automatic conversion"
                />
            </div>

            <CrossSectionCurveChart values={values} />
        </div>
    );
};

export default CrossSection;
