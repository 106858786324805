import React, { useEffect, useMemo } from "react";
import { ModalProps } from "../../Components/Modals/types";
import { useState } from "react";
import { PluginForm, WaterholeRunToEmptyParameters } from "./types";
import { isNil } from "lodash";
import {
    ModelLocationSiteOptimisationModel,
    OptimisationPluginId,
    RunPeriodModel,
    TaskModel,
    TaskStatus
} from "../../types/models";
import { PluginParameters } from "../../types/plugin";
import { useModel } from "../../Scenes/Model/ModelContext";
import { OptimisationComputationRequest } from "../../types/requests";
import ComputationService from "../../Services/computation.service";
import { Toast } from "../../Components/Toast";
import ModelService from "../../Services/model.service";
import PluginWaterholeRunToEmptyModalBody from "./PluginWaterholeRunToEmptyModalBody";
import WaterholeOptimisationModal from "../../Components/Modals/WaterholeOptisationModal";
import { useFormContext } from "react-hook-form";
import { WaterholeParameters } from "../../types/plugin-parameter-types";
import PluginWaterholeRunToEmptyModalFooter from "./PluginWaterholeRunToEmptyModalFooter";
import { useInterval } from "../../Hooks/useInterval";

interface PluginWaterholeRunToEmptyModalProps extends ModalProps {
    id: string;
    locationId: string;
    siteId: string;
    siteName: string;
    disabled?: boolean;
}

const POLLING_TIME_MS = 500;

const PluginWaterholeRunToEmptyModal = ({
    show,
    locationId,
    siteId,
    siteName,
    disabled,
    onClose
}: PluginWaterholeRunToEmptyModalProps) => {
    const { getValues } = useFormContext<PluginForm>();
    const { model } = useModel();
    const [waterholeRunToEmptyParameters, setWaterholeRunToEmptyParameters] =
        useState<WaterholeRunToEmptyParameters>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [optimisation, setOptimisation] = useState<ModelLocationSiteOptimisationModel>(null);
    const [computationTask, setComputationTask] = useState<TaskModel>(null);

    const isTaskActive =
        computationTask?.status === TaskStatus.RUNNING || computationTask?.status === TaskStatus.PENDING;

    const fetchOptimisation = async () => {
        try {
            const _optimisation = await ModelService.getModelLocationSiteOptimisationByOptimisationPluginId(
                model.id,
                locationId,
                siteId,
                OptimisationPluginId.WATERHOLE_RUN_TO_EMPTY
            );

            setOptimisation(_optimisation);

            const optimisationTask = await ModelService.getModelLocationSiteOptimisationStatus(
                model.id,
                locationId,
                siteId,
                _optimisation.id
            );

            setComputationTask(optimisationTask);

            const runToEmptyParamaters: WaterholeRunToEmptyParameters = {
                settings: {
                    depth: _optimisation.parameters?.settings?.depth,
                    evaporation_type: _optimisation.parameters?.settings?.evaporation_type,
                    evaporation_value: _optimisation.parameters?.settings?.evaporation_value
                },
                season: {
                    start: _optimisation.parameters?.season?.start,
                    end: null
                }
            };

            setWaterholeRunToEmptyParameters(runToEmptyParamaters);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        fetchOptimisation();
    }, []);

    const siteValues: Partial<WaterholeParameters> = useMemo(() => {
        const values: Partial<WaterholeParameters> = {
            evaporation: {
                scaling: getValues("evaporation.scaling")
            },
            seepage: {
                rate: getValues("seepage.rate")
            },
            rainfall: {
                area: getValues("rainfall.area"),
                max_infiltration: getValues("rainfall.max_infiltration")
            },
            groundwater: {
                area: getValues("groundwater.area"),
                inflow: getValues("groundwater.inflow"),
                loss_to_deep_drainage: getValues("groundwater.loss_to_deep_drainage")
            },
            extraction: {
                commence_pumping_depth: getValues("extraction.commence_pumping_depth"),
                cease_pumping_depth: getValues("extraction.cease_pumping_depth"),
                extraction_rate: getValues("extraction.extraction_rate"),
                max_annual_take: getValues("extraction.max_annual_take"),
                start_month: getValues("extraction.start_month"),
                ctf: getValues("extraction.ctf"),
                lag: getValues("extraction.lag")
            }
        };

        return values;
    }, []);

    const checkNullRunPeriod = (period: RunPeriodModel): boolean => {
        if (isNil(period?.start) && isNil(period?.end)) {
            return true;
        }
        return false;
    };

    const handleRun = async () => {
        const modifiedParameters: PluginParameters = { ...siteValues };

        modifiedParameters["optimisation"] = {
            depth: waterholeRunToEmptyParameters.settings.depth,
            evaporation_type: waterholeRunToEmptyParameters.settings.evaporation_type,
            evaporation_value: waterholeRunToEmptyParameters.settings.evaporation_value,
            season: checkNullRunPeriod(waterholeRunToEmptyParameters.season)
                ? null
                : waterholeRunToEmptyParameters.season
        };

        const request: OptimisationComputationRequest = {
            modelId: model.id,
            parameters: modifiedParameters,
            locationId: locationId,
            siteId: siteId,
            optimisationId: optimisation.id
        };

        const result = await ComputationService.computeOptimisation(request);

        if (!result.success) {
            Toast.error(result.failure.message);
            return;
        }

        setComputationTask(result.task);
    };

    useInterval(
        async () => {
            const nextTask = await ModelService.getModelLocationSiteOptimisationStatus(
                model.id,
                locationId,
                siteId,
                optimisation.id
            );

            switch (nextTask.status) {
                case TaskStatus.COMPLETED: {
                    fetchOptimisation();
                    Toast.success("Successfully computed optimisation");
                    break;
                }

                case TaskStatus.FAILED: {
                    Toast.error(nextTask.error.message);
                    break;
                }
            }

            setComputationTask(nextTask);
        },
        isTaskActive ? POLLING_TIME_MS : null
    );

    const handleParametersChanged = async (parameters: WaterholeRunToEmptyParameters) => {
        const result = await ModelService.updateModelLocationSiteOptimisationParameters(
            model.id,
            locationId,
            optimisation.modelLocationSiteId,
            optimisation.id,
            parameters
        );

        if (!result.success) {
            Toast.error(result.failure.message);
            return;
        }

        setWaterholeRunToEmptyParameters(parameters);
    };

    return (
        <WaterholeOptimisationModal
            footer={
                <PluginWaterholeRunToEmptyModalFooter
                    task={computationTask}
                    isRunning={isTaskActive}
                    onClose={onClose}
                    onRun={handleRun}
                />
            }
            show={show}
            title={`${siteName} - Run To Empty`}
            task={computationTask}
            handleClose={onClose}
            isLoading={isLoading}
        >
            <PluginWaterholeRunToEmptyModalBody
                runToEmptyParameters={waterholeRunToEmptyParameters}
                results={optimisation?.result}
                modelId={model.id}
                locationId={locationId}
                siteId={siteId}
                optimisationId={optimisation?.id}
                disabled={disabled}
                onParametersChanged={handleParametersChanged}
            />
        </WaterholeOptimisationModal>
    );
};

export default PluginWaterholeRunToEmptyModal;
