import { useConfirmation } from "../../Components/ConfirmDialog/ConfirmationContext";
import LoadingIndicator from "../../Components/LoadingIndicator";
import PluginParameters from "../../Components/Plugin/PluginParameters";
import Select, { SelectOption } from "../../Components/Select/Select";
import { Toast } from "../../Components/Toast";
import { isNil } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import ModelService from "../../Services/model.service";
import { PluginModel } from "../../types/models";
import { PluginParameters as PluginFieldParameters, PluginScopes } from "../../types/plugin";
import { isNilOrEmpty, sleep } from "../../utils/utils";
import { useModel } from "./ModelContext";
import PluginUpdate from "./PluginUpdate";

interface PluginOption extends SelectOption {
    plugin: PluginModel;
}

const ModelSettings = () => {
    const { model, readOnly, plugins, setPlugin, setSaving, updateParameters } = useModel();

    const confirm = useConfirmation();
    const [isSettingPlugin, setIsSettingPlugin] = useState<boolean>(false);
    const [selectedPlugin, setSelectedPlugin] = useState<PluginOption>(null);

    useEffect(() => {
        if (isNilOrEmpty(model?.plugin?.id) || isNilOrEmpty(plugins)) {
            return;
        }

        if (!isNil(model.plugin)) {
            setSelectedPlugin({ value: model.plugin.id, label: model.plugin.name, plugin: model.plugin });
        }
    }, [model?.plugin?.id]);

    const handlePluginSelected = async (option: PluginOption) => {
        try {
            if (model.plugin?.id === option.value) {
                return;
            }

            if (!isNil(model.plugin)) {
                const result = await confirm({
                    title: "Warning",
                    description: "All location parameters and computation results will be lost. Do you wish to proceed?"
                });

                if (!result.success) {
                    return;
                }
            }

            setIsSettingPlugin(true);

            const parameters = await ModelService.setPlugin(model.id, option.value);

            setPlugin(option.plugin, parameters);

            setSelectedPlugin(option);
        } finally {
            await sleep(250);

            setIsSettingPlugin(false);
        }
    };

    const handleParametersChanged = async (parameters: PluginFieldParameters) => {
        try {
            setSaving(true);

            const result = await ModelService.updateParameters(model.id, parameters);

            if (!result.success) {
                Toast.error(result.failure.message);
                return;
            }

            updateParameters(parameters);
        } finally {
            await sleep(250);

            setSaving(false);
        }
    };

    const handlePluginUpdate = async () => {
        try {
            setIsSettingPlugin(true);

            const parameters = await ModelService.updatePlugin(model.id);

            const currentPlugin = plugins.find(p => p.identifier === model.plugin.identifier);

            setPlugin(currentPlugin, parameters);

            setSelectedPlugin({ label: currentPlugin.name, value: currentPlugin.id, plugin: currentPlugin });
        } finally {
            setIsSettingPlugin(false);
        }
    };

    const pluginOptions = useMemo(() => {
        return plugins?.map(s => {
            const option: PluginOption = {
                value: s.id,
                label: s.name,
                plugin: s
            };

            return option;
        });
    }, [plugins.length]);

    const hasPlugin = !isNil(selectedPlugin);
    const canShowPlugin = !isSettingPlugin && hasPlugin;

    return (
        <div className="model-section-content model-settings">
            <Select
                labelText="Select plugin to use as base model"
                placeholder="Select a plugin"
                menuClassName="plugin-select"
                value={selectedPlugin}
                values={pluginOptions}
                disabled={readOnly}
                onSelected={handlePluginSelected}
            />

            {isSettingPlugin && <LoadingIndicator centered className="plugin-loader" />}

            {!isSettingPlugin && (
                <>
                    {hasPlugin && !selectedPlugin.plugin.isCurrent && <PluginUpdate onUpdate={handlePluginUpdate} />}

                    {canShowPlugin && (
                        <PluginParameters
                            id={model.id}
                            parameters={model?.parameters}
                            schema={selectedPlugin.plugin.schema}
                            disabled={readOnly}
                            scope={PluginScopes.MODEL}
                            onParametersChanged={handleParametersChanged}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default ModelSettings;
