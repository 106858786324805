import React from "react";
import Label from "../../Components/Label";
import { round } from "lodash";

interface ModelExploreNodeIntermediateGradientBarProps {
    min: number;
    max: number;
}

const ModelExploreNodeIntermediateGradientBar = ({ min, max }: ModelExploreNodeIntermediateGradientBarProps) => {
    return (
        <div className="gradient-bar-holder">
            <Label className="gradient-label">{round(min, 0)}</Label>
            <div className="gradient-bar"></div>
            <Label className="gradient-label">{round(max, 0)}</Label>
        </div>
    );
};

export default ModelExploreNodeIntermediateGradientBar;
