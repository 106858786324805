import React from "react";
import { SystemModel } from "../../types/models";
import clsx from "clsx";

import "./FileSystem.scss";
import SystemIcon from "../../Components/ERPIcon/SystemIcon";

interface FileSystemModalSystemProps {
    system: SystemModel;
    disabled: boolean;
}

const FileSystemModalSystem = ({ system, disabled }: FileSystemModalSystemProps) => {
    return (
        <div className={clsx("fs-modal-system", { disabled: disabled === true })}>
            <SystemIcon />

            <span>{system.name}</span>
        </div>
    );
};

export default FileSystemModalSystem;
