import Label from "../../Components/Label";
import Select, { SelectOption } from "../../Components/Select/Select";
import React from "react";
import { DuplicateEntryStratergyOption } from "../../Services/bulk-import.service";
import { BulkImportSettings } from "./SystemBulkImportModal";
import { NamingConventionOption, SeperatorOption } from "../../Services/file-name-parser.service";

const NAMING_CONVENTION_SELECT_OPTIONS: SelectOption[] = [
    { label: "Scenario name", value: NamingConventionOption.SCENARIO },
    { label: "Location name", value: NamingConventionOption.LOCATION },
    { label: "Data type", value: NamingConventionOption.DATATYPE }
];

const SEPERATOR_OPTIONS: SelectOption[] = [
    { label: ". (dot)", value: SeperatorOption.DOT },
    { label: "_ (underscore)", value: SeperatorOption.UNDERSCORE },
    { label: "- (hyphen)", value: SeperatorOption.HYPHEN }
];

const DUPLICATE_STRATERGY_OPTIONS: SelectOption[] = [
    { label: "Replace", value: DuplicateEntryStratergyOption.REPLACE },
    { label: "Skip", value: DuplicateEntryStratergyOption.SKIP }
];

export interface SystemBulkImportModalNamingConventionSettingsProps {
    settings: BulkImportSettings;
    onSettingsChanged: (next: BulkImportSettings) => void;
}

const SystemBulkImportModalNamingConventionSettings = ({
    settings,
    onSettingsChanged
}: SystemBulkImportModalNamingConventionSettingsProps) => {
    const handleNamingConventionChanged = (position: number, value: NamingConventionOption) => {
        const nextNamingConvention = [...settings.namingConvention];

        nextNamingConvention[position] = value;

        onSettingsChanged({ ...settings, namingConvention: nextNamingConvention });
    };

    const handleSeperatorChanged = (value: SeperatorOption) => {
        if (value === settings.seperator) {
            return;
        }

        onSettingsChanged({ ...settings, seperator: value });
    };

    const handleDuplicateStratergyChanged = (next: SelectOption) => {
        if (next.value === settings.duplicateEntryStratergy) {
            return;
        }

        onSettingsChanged({ ...settings, duplicateEntryStratergy: next.value as DuplicateEntryStratergyOption });
    };

    return (
        <div className="naming-convention-container">
            <div>
                <Label>Seperator</Label>
                <Select
                    values={SEPERATOR_OPTIONS}
                    value={SEPERATOR_OPTIONS.find(s => s.value === settings.seperator)}
                    onSelected={(next: SelectOption) => handleSeperatorChanged(next.value as SeperatorOption)}
                    menuClassName="select"
                />
            </div>

            <div>
                <Label>File naming convention</Label>
                <div className="naming-convention-menu">
                    <Select
                        values={NAMING_CONVENTION_SELECT_OPTIONS.filter(
                            o => !settings.namingConvention.includes(o.value as NamingConventionOption)
                        )}
                        value={NAMING_CONVENTION_SELECT_OPTIONS.find(o => o.value === settings.namingConvention[0])}
                        onSelected={(next: SelectOption) =>
                            handleNamingConventionChanged(0, next?.value as NamingConventionOption)
                        }
                        menuClassName="select"
                        isClearable={true}
                    />

                    <span className="naming-convention-text">{settings.seperator}</span>

                    <Select
                        values={NAMING_CONVENTION_SELECT_OPTIONS.filter(
                            o => !settings.namingConvention.includes(o.value as NamingConventionOption)
                        )}
                        value={NAMING_CONVENTION_SELECT_OPTIONS.find(o => o.value === settings.namingConvention[1])}
                        onSelected={(next: SelectOption) =>
                            handleNamingConventionChanged(1, next?.value as NamingConventionOption)
                        }
                        menuClassName="select"
                        isClearable={true}
                    />

                    <span className="naming-convention-text">{settings.seperator}</span>

                    <Select
                        values={NAMING_CONVENTION_SELECT_OPTIONS.filter(
                            o => !settings.namingConvention.includes(o.value as NamingConventionOption)
                        )}
                        value={NAMING_CONVENTION_SELECT_OPTIONS.find(o => o.value === settings.namingConvention[2])}
                        onSelected={(next: SelectOption) =>
                            handleNamingConventionChanged(2, next?.value as NamingConventionOption)
                        }
                        menuClassName="select"
                        isClearable={true}
                    />

                    <span className="naming-convention-text">.csv</span>
                </div>
            </div>

            <div className="additional-settings">
                <Select
                    labelText="Duplicate entry stratergy"
                    values={DUPLICATE_STRATERGY_OPTIONS}
                    value={DUPLICATE_STRATERGY_OPTIONS.find(o => o.value === settings.duplicateEntryStratergy)}
                    onSelected={handleDuplicateStratergyChanged}
                    menuClassName="select"
                />
            </div>
        </div>
    );
};

export default SystemBulkImportModalNamingConventionSettings;
