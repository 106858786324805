import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import ERPIcon from "../../Components/ERPIcon";
import React from "react";

const SystemBulkImportModelInfoPanel = () => {
    return (
        <div className="info-panel">
            <ERPIcon icon={faInfoCircle} className="info-icon" size="2x" />
            <div className="info-text">
                Use this tool to quickly generate large systems containing many scenarios, locations and data types. Do
                this by using a distinct naming convention on your data, containing the scenario name, location name and
                data type that the data file belongs to. Use the selection below to define your naming convention,
                ensuring it matches your file naming convention. You can then use the file uploader below to drag and
                drop (or select) a large group of files. The tool will upload the files, sorting them into their correct
                scenario, location and data type.
            </div>
        </div>
    );
};

export default SystemBulkImportModelInfoPanel;
