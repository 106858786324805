export const URL = {
    ME: "/me",
    FOLDERS: "/folders",
    SYSTEMS: "/systems",
    MODELS: "/models",
    PLUGINS: "/plugins",
    GROUPS: "/groups",
    REGISTER: "/register",
    COMPUTATION: "/computation"
};
