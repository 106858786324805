import React, { useState } from "react";
import { faDownload } from "@fortawesome/pro-solid-svg-icons";
import { ModelExploreScenarioResult, ModelModel } from "../../types/models";
import LoadingButton from "../../Components/LoadingButton";
import AzureBlobService from "../../Services/azure.blob.service";
import ZipGenerator, { ZipFileInfo } from "../../Services/zip-generator";
import { forEach } from "lodash";
import ExploreDataService from "../../Services/explore.data.service";
import { useModel } from "./ModelContext";
import { formatFullDownloadName, formatScenarioDate } from "../../utils/explorer.utils";

interface ModelExplorerDownloadsHeaderProps {
    model: ModelModel;
    scenarioResults: ModelExploreScenarioResult[];
}

const ModelExplorerDownloadsHeader = ({ model, scenarioResults }: ModelExplorerDownloadsHeaderProps) => {
    const { downloadsSettings } = useModel();
    const [isDownloading, setIsDownloading] = useState<boolean>(false);

    const handleDownloadAll = async () => {
        try {
            setIsDownloading(true);

            const generator = new ZipGenerator();

            const files: ZipFileInfo[] = [];

            for (let i = 0; i < scenarioResults.length; i++) {
                const scenarioResult = scenarioResults[i];
                const scenario = scenarioResult.scenario;
                const results = scenarioResult.results;

                for (let j = 0; j < results.length; j++) {
                    const result = results[j];

                    const resultName = downloadsSettings.shouldUseFullDownloadNames
                        ? formatFullDownloadName(result)
                        : result.name;

                    const resultFiles = await ExploreDataService.fetchResultFiles(
                        result,
                        resultName,
                        downloadsSettings
                    );

                    forEach(resultFiles, file => {
                        files.push({
                            name: `${scenario.name}_${formatScenarioDate(scenarioResult.scenario)}/${file.name}`,
                            file: file
                        });
                    });
                }
            }

            const resultName = downloadsSettings.shouldUseFullDownloadNames ? `${model.name}_Combined` : null;

            const combinedResults = await ExploreDataService.createCombinedResultFiles(
                scenarioResults,
                resultName,
                downloadsSettings
            );

            forEach(combinedResults, file => {
                files.push({ name: `${file.name}`, file: file });
            });

            const blob = await generator.generate(files);

            AzureBlobService.downloadToDisk(blob, `${model.name}_results.zip`);
        } finally {
            setIsDownloading(false);
        }
    };

    return (
        <div className="downloads-header">
            <h5>Download scenario results</h5>

            <LoadingButton
                isLoading={isDownloading}
                icon={faDownload}
                variant="none"
                className="download-all-button"
                onClick={handleDownloadAll}
            >
                Download all
            </LoadingButton>
        </div>
    );
};

export default ModelExplorerDownloadsHeader;
