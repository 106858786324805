import React from "react";
import { ModelModel } from "../../types/models";
import clsx from "clsx";
import ModelIcon from "../../Components/ERPIcon/ModelIcon";

import "./FileSystem.scss";

interface FileSystemModalModelProps {
    model: ModelModel;
    disabled: boolean;
}

const FileSystemModalModel = ({ model, disabled }: FileSystemModalModelProps) => {
    return (
        <div className={clsx("fs-modal-model", { disabled: disabled === true })}>
            <ModelIcon />

            <span>{model.name}</span>
        </div>
    );
};

export default FileSystemModalModel;
