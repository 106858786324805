import React, { useMemo } from "react";
import ActivableButton from "../../Components/Button/ActivableButton";
import { faEye, faProjectDiagram, faSlidersH, faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Button from "../../Components/Button";
import { faArrowLeft, faArrowRight, faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { guid, isNilOrEmpty } from "../../utils/utils";
import TooltipIcon from "../../Components/TooltipIcon";
import { useModel } from "./ModelContext";

export enum ModelTabType {
    GENERAL = "general",
    MODEL_SETTINGS = "model_settings",
    LOCATION_SETTINGS = "location_settings",
    EXPLORE = "explorer"
}

interface ModelTabProps {
    type: ModelTabType;
    isActive: boolean;
    label: string;
    icon: IconProp;
    warning?: string;
    onSelected: (type: ModelTabType) => void;
}

interface ModelTabsProps {
    selectedTab: TabSelection;
    onTabSelected: (selection: TabSelection) => void;
}

interface TabInfo {
    type: ModelTabType;
    icon: IconProp;
    label: string;
}

export interface TabSelection {
    type: ModelTabType;
    index: number;
    warning?: string;
}

const TAB_INFO: TabInfo[] = [
    {
        label: "General",
        type: ModelTabType.GENERAL,
        icon: faInfoCircle
    },
    {
        label: "Model settings",
        type: ModelTabType.MODEL_SETTINGS,
        icon: faSlidersH
    },
    {
        label: "Location settings",
        type: ModelTabType.LOCATION_SETTINGS,
        icon: faProjectDiagram
    },
    {
        label: "Explore",
        type: ModelTabType.EXPLORE,
        icon: faEye
    }
];

const ModelTab = ({ type, isActive, label, icon, warning, onSelected }: ModelTabProps) => {
    const handleSelected = () => {
        onSelected(type);
    };

    return (
        <div className="model--tab">
            <ActivableButton onClick={handleSelected} isActive={isActive} icon={icon} iconPlacement="before">
                {label}

                {!isNilOrEmpty(warning) && (
                    <TooltipIcon className="model--tab-warning" icon={faExclamationTriangle} tooltip={warning} />
                )}
            </ActivableButton>
        </div>
    );
};

const ModelTabs = ({ selectedTab, onTabSelected }: ModelTabsProps) => {
    const { model } = useModel();

    const canGoNext = selectedTab.index < TAB_INFO.length - 1;
    const canGoPrevious = selectedTab.index > 0;

    const handleTabSelected = (selection: TabSelection) => {
        if (selection.type === selectedTab.type) {
            return;
        }

        onTabSelected(selection);
    };

    const nextTab = () => {
        if (!canGoNext) {
            return;
        }

        const nextIndex = selectedTab.index + 1;
        const nextTab = TAB_INFO[nextIndex];

        onTabSelected({ index: nextIndex, type: nextTab.type });
    };

    const previousTab = () => {
        if (!canGoPrevious) {
            return;
        }

        const previousIndex = selectedTab.index - 1;
        const previousTab = TAB_INFO[previousIndex];

        onTabSelected({ index: previousIndex, type: previousTab.type });
    };

    const warnings = useMemo(() => {
        const _warnings = new Map<string, string>();

        if (model.plugin?.isCurrent === false) {
            _warnings.set(ModelTabType.MODEL_SETTINGS, "Plugin is out of date");
        }

        return _warnings;
    }, [model]);

    return (
        <div className="model--navigation">
            <div className="model--tabs">
                {TAB_INFO.map((info, index) => {
                    return (
                        <ModelTab
                            key={guid()}
                            type={info.type}
                            label={info.label}
                            isActive={selectedTab.type === info.type}
                            icon={info.icon}
                            warning={warnings.get(info.type) ?? null}
                            onSelected={type => handleTabSelected({ type: type, index: index })}
                        />
                    );
                })}
            </div>

            <div className="model--btn-navigation">
                <Button icon={faArrowLeft} iconPlacement="before" onClick={previousTab} disabled={!canGoPrevious}>
                    Previous
                </Button>

                <Button icon={faArrowRight} onClick={nextTab} disabled={!canGoNext}>
                    Next
                </Button>
            </div>
        </div>
    );
};

export default ModelTabs;
