import { ColwellsIndexParameters } from "../../types/plugin-parameter-types";
import { PluginParameters } from "../../types/plugin";
import { BaseParameterMerger } from "./base-parameter-merger";

export class ColwellsIndexParameterMerger extends BaseParameterMerger<ColwellsIndexParameters> {
    public merge(source: PluginParameters, defaultParameters: ColwellsIndexParameters): ColwellsIndexParameters {
        return {
            data: {
                data_type: this.defaultTo(source?.data?.data_type, defaultParameters?.data?.data_type)
            },
            classes: {
                time_period: this.defaultTo(source?.classes?.time_period, defaultParameters?.classes?.time_period),
                summary_method: this.defaultTo(
                    source?.classes?.summary_method,
                    defaultParameters?.classes?.summary_method
                ),
                number_of_classes: this.defaultTo(
                    source?.classes?.number_of_classes,
                    defaultParameters?.classes?.number_of_classes
                ),
                class_boundary_method: this.defaultTo(
                    source?.classes?.class_boundary_method,
                    defaultParameters?.classes?.class_boundary_method
                ),
                log_base: this.defaultTo(source?.classes?.log_base, defaultParameters?.classes?.log_base),
                gan_from: this.defaultTo(source?.classes?.gan_from, defaultParameters?.classes?.gan_from),
                gan_by: this.defaultTo(source?.classes?.gan_by, defaultParameters?.classes?.gan_by)
            }
        };
    }
}
