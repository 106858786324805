import LoadingIndicator from "../../Components/LoadingIndicator";
import { isNil } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import SystemService from "../../Services/system.service";
import { LocationModel, ScenarioLocationDataModel, ScenarioModel, SystemModel } from "../../types/models";
import { isNilOrEmpty } from "../../utils/utils";

interface SystemContextProps {
    system: SystemModel;
    scenarios: ScenarioModel[];
    locations: LocationModel[];
    data: ScenarioLocationDataModel[];
    selectedScenario: ScenarioModel;
    readOnly: boolean;
    isSaving: boolean;
    setSaving: (saving: boolean) => void;
    updateSystem: (update: SystemModel) => void;
    updateSelectedScenario: (nextScenario: ScenarioModel) => void;
    updateScenarios: (nextScenarios: ScenarioModel[]) => void;
    updateLocations: (nextLocations: LocationModel[]) => void;
    updateData: (nextData: ScenarioLocationDataModel[]) => void;
}

interface SystemContextOptions {
    systemId: string;
    readOnly: boolean;
    children: React.ReactNode;
}

export const SystemContext = React.createContext<SystemContextProps | null>(null);

export const useSystem = () => useContext(SystemContext);

const SystemContextProvider = ({ systemId, readOnly, children }: SystemContextOptions) => {
    const [system, setSystem] = useState<SystemModel>(null);
    const [scenarios, setScenarios] = useState<ScenarioModel[]>([]);
    const [locations, setLocations] = useState<LocationModel[]>([]);
    const [selectedScenario, setSelectedScenario] = useState<ScenarioModel>(null);
    const [data, setData] = useState<ScenarioLocationDataModel[]>([]);
    const [isSaving, setSaving] = useState<boolean>(false);

    useEffect(() => {
        const getSystem = async (id: string) => {
            const getSystem = SystemService.getSystem(id);
            const getScenarios = SystemService.getScenarios(id);
            const getLocations = SystemService.getLocations(id);
            const getData = SystemService.getData(id);

            const [_system, _scenarios, _locations, _data] = await Promise.all([
                getSystem,
                getScenarios,
                getLocations,
                getData
            ]);

            setSystem(_system);
            setScenarios(_scenarios);
            setLocations(_locations);
            setData(_data);

            if (!isNilOrEmpty(_scenarios)) {
                setSelectedScenario(_scenarios[0]);
            }
        };

        getSystem(systemId);
    }, [systemId]);

    const updateSystem = (system: SystemModel) => {
        setSystem({ ...system });
    };

    const updateSelectedScenario = (nextSelectedScenario: ScenarioModel) => {
        setSelectedScenario({ ...nextSelectedScenario });
    };

    const updateScenarios = (nextScenarios: ScenarioModel[]) => {
        setScenarios([...nextScenarios]);
    };

    const updateLocations = (nextLocations: LocationModel[]) => {
        setLocations([...nextLocations]);
    };

    const updateData = (nextData: ScenarioLocationDataModel[]) => {
        setData([...nextData]);
    };

    return (
        <SystemContext.Provider
            value={{
                system: system,
                scenarios: scenarios,
                locations: locations,
                data: data,
                selectedScenario: selectedScenario,
                readOnly: readOnly,
                isSaving: isSaving,
                setSaving: setSaving,
                updateSystem: updateSystem,
                updateSelectedScenario: updateSelectedScenario,
                updateScenarios: updateScenarios,
                updateLocations: updateLocations,
                updateData: updateData
            }}
        >
            {isNil(system) && <LoadingIndicator className="system-loading" centered />}

            {!isNil(system) && children}
        </SystemContext.Provider>
    );
};

export default SystemContextProvider;
