import React from "react";
import { faUserCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import GroupMenuItem from "./GroupMenuItem";
import { GroupModel, GroupPrivilege, User } from "../../types/models";
import { LogoutOptions } from "@auth0/auth0-spa-js";
import { fullname } from "../../utils/utils";

interface UserMenuProps {
    erpUser: User;
    logout: (o?: LogoutOptions) => void;
    onGroupSelected: (group: GroupModel) => void;
}

const UserMenu = ({ erpUser, logout, onGroupSelected }: UserMenuProps) => {
    const usableGroups = erpUser?.groups?.filter(g => g.privilege !== GroupPrivilege.CAN_MANAGE_USERS) ?? [];

    return (
        <Dropdown className="user-menu">
            <Dropdown.Toggle className="user-menu-dropdown-toggle">
                <FontAwesomeIcon icon={faUserCircle} size="2x" />
            </Dropdown.Toggle>

            <Dropdown.Menu align="right" className="user-menu-dropdown-menu">
                <span className="name">{fullname(erpUser)}</span>

                <Dropdown.Divider />

                <div className="title">
                    <h6>YOUR GROUPS</h6>
                </div>

                <div className="groups">
                    {usableGroups.map(group => {
                        return (
                            <GroupMenuItem
                                key={group.id}
                                label={group.name}
                                selected={erpUser.currentGroupId === group.id}
                                onSelected={() => {
                                    onGroupSelected(group);
                                }}
                            />
                        );
                    })}
                </div>

                <Dropdown.Divider />

                <Dropdown.Item as={Link} to="/manage-account" eventKey="1">
                    Manage Account
                </Dropdown.Item>

                <Dropdown.Item onClick={() => logout()} eventKey="3">
                    Logout
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default UserMenu;
