import React from "react";
import ERPLogo from "../../Assets/images/ecorisk-projector-logo.svg";
import { Link } from "react-router-dom";

const HeaderIcon = () => {
    return (
        <Link to="/explorer" className="header-icon">
            <img src={ERPLogo} id="erp-logo" alt="Eco Risk Projector" />
        </Link>
    );
};

export default HeaderIcon;
