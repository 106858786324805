import React from "react";
import { ModelModel } from "../../types/models";
import ERPModal from "../../Components/Modals/ERPModal";
import { ModalProps } from "../../Components/Modals/types";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import FormActions from "../../Components/FormActions";
import Button from "../../Components/Button";
import LoadingButton from "../../Components/LoadingButton";
import FormInput from "../../Components/FormInput/FormInput";
import { UpdateModelNameRequest } from "../../types/requests";
import { Toast } from "../../Components/Toast";
import ModelService from "../../Services/model.service";

interface ModelModalProps extends ModalProps {
    context: ModelModalContext;
    onModelUpdated: (model: ModelModel) => void;
}

export interface ModelModalContext {
    model: ModelModel;
}

const schema = yup.object().shape({
    name: yup.string().required("Name is required")
});

interface ModelForm {
    name: string;
}

const ModelModal = ({ context, show, onClose, onModelUpdated }: ModelModalProps) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isDirty, isSubmitting }
    } = useForm<ModelForm>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: context.model.name ?? ""
        }
    });

    const handleFormSubmit = async (data: ModelForm) => {
        const request: UpdateModelNameRequest = {
            name: data.name
        };

        const updatedModel = await ModelService.updateModelName(context.model.id, request);

        Toast.success(`Successfully updated model ${data.name}`);

        onModelUpdated(updatedModel);

        onClose();
    };

    return (
        <ERPModal title="Edit model" show={show} onClose={onClose}>
            <div>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <FormInput
                        labelText="Name"
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Folder name"
                        register={register("name")}
                        error={errors?.name}
                        disabled={isSubmitting}
                    />

                    <FormActions>
                        <Button variant="secondary" disabled={isSubmitting} onClick={onClose}>
                            Cancel
                        </Button>

                        <LoadingButton disabled={!isDirty} isLoading={isSubmitting} variant="primary" type="submit">
                            Update
                        </LoadingButton>
                    </FormActions>
                </form>
            </div>
        </ERPModal>
    );
};

export default ModelModal;
