import React from "react";
import { SystemModel } from "../../types/models";
import ERPModal from "../../Components/Modals/ERPModal";
import { ModalProps } from "../../Components/Modals/types";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import FormActions from "../../Components/FormActions";
import Button from "../../Components/Button";
import LoadingButton from "../../Components/LoadingButton";
import FormInput from "../../Components/FormInput/FormInput";
import { UpdateSystemNameRequest } from "../../types/requests";
import { Toast } from "../../Components/Toast";
import SystemService from "../../Services/system.service";

interface SystemModalProps extends ModalProps {
    context: SystemModalContext;
    onSystemUpdated: (system: SystemModel) => void;
}

export interface SystemModalContext {
    system: SystemModel;
}

const schema = yup.object().shape({
    name: yup.string().required("Name is required")
});

interface SystemForm {
    name: string;
}

const ModelModal = ({ context, show, onClose, onSystemUpdated }: SystemModalProps) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isDirty, isSubmitting }
    } = useForm<SystemForm>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: context.system.name ?? ""
        }
    });

    const handleFormSubmit = async (data: SystemForm) => {
        const request: UpdateSystemNameRequest = {
            name: data.name
        };

        const updatedSystem = await SystemService.updateSystemName(context.system.id, request);

        Toast.success(`Successfully updated system ${data.name}`);

        onSystemUpdated(updatedSystem);

        onClose();
    };

    return (
        <ERPModal title="Edit system" show={show} onClose={onClose}>
            <div>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <FormInput
                        labelText="Name"
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Folder name"
                        register={register("name")}
                        error={errors?.name}
                        disabled={isSubmitting}
                    />

                    <FormActions>
                        <Button variant="secondary" disabled={isSubmitting} onClick={onClose}>
                            Cancel
                        </Button>

                        <LoadingButton disabled={!isDirty} isLoading={isSubmitting} variant="primary" type="submit">
                            Update
                        </LoadingButton>
                    </FormActions>
                </form>
            </div>
        </ERPModal>
    );
};

export default ModelModal;
