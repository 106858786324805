import { SimultaneousSpellAnalysisParameters } from "../../types/plugin-parameter-types";
import { PluginParameters } from "../../types/plugin";
import { BaseParameterMerger } from "./base-parameter-merger";

export class SimultaneousSpellAnalysisParameterMerger extends BaseParameterMerger<SimultaneousSpellAnalysisParameters> {
    public merge(
        source: PluginParameters,
        defaultParameters: SimultaneousSpellAnalysisParameters
    ): SimultaneousSpellAnalysisParameters {
        return {
            data: {
                data_type: this.defaultTo(source?.data?.data_type, defaultParameters?.data?.data_type)
            },
            season: {
                season_start: this.defaultTo(source?.season?.season_start, defaultParameters?.season?.season_start),
                season_end: this.defaultTo(source?.season?.season_end, defaultParameters?.season?.season_end)
            },
            event: {
                spell_type: this.defaultTo(source?.event?.spell_type, defaultParameters?.event?.spell_type),
                independence: this.defaultTo(source?.event?.independence, defaultParameters?.event?.independence),
                min_spell_length: this.defaultTo(
                    source?.event?.min_spell_length,
                    defaultParameters?.event?.min_spell_length
                ),
                max_spell_length: this.defaultTo(
                    source?.event?.max_spell_length,
                    defaultParameters?.event?.max_spell_length
                ),
                lag: this.defaultTo(source?.event?.lag, defaultParameters?.event?.lag)
            }
        };
    }
}
