import React from "react";
import ActivableButton from "../../Components/Button/ActivableButton";
import { guid } from "../../utils/utils";

export enum DataPreviewTabType {
    LOCATIONS = "locations",
    SCENARIOS = "scenarios"
}

interface DataPreviewTabProps {
    type: DataPreviewTabType;
    isActive: boolean;
    label: string;
    onSelected: (type: DataPreviewTabType) => void;
}

interface DataPreviewTabsProps {
    selectedTab: TabSelection;
    onTabSelected: (selection: TabSelection) => void;
}

interface TabInfo {
    type: DataPreviewTabType;
    label: string;
}

export interface TabSelection {
    type: DataPreviewTabType;
    index: number;
}

const TAB_INFO: TabInfo[] = [
    {
        label: "Compare locations",
        type: DataPreviewTabType.LOCATIONS
    },
    {
        label: "Compare scenarios",
        type: DataPreviewTabType.SCENARIOS
    }
];

const DataPreviewTab = ({ type, isActive, label, onSelected }: DataPreviewTabProps) => {
    const handleSelected = () => {
        onSelected(type);
    };

    return (
        <div className="data-preview--tab">
            <ActivableButton onClick={handleSelected} isActive={isActive}>
                {label}
            </ActivableButton>
        </div>
    );
};

const DataPreviewTabs = ({ selectedTab, onTabSelected }: DataPreviewTabsProps) => {
    const handleTabSelected = (selection: TabSelection) => {
        if (selection.type === selectedTab.type) {
            return;
        }

        onTabSelected(selection);
    };

    return (
        <div className="data-preview--navigation">
            <div className="data-preview--tabs">
                {TAB_INFO.map((info, index) => {
                    return (
                        <DataPreviewTab
                            key={guid()}
                            type={info.type}
                            label={info.label}
                            isActive={selectedTab.type === info.type}
                            onSelected={type => handleTabSelected({ type: type, index: index })}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default DataPreviewTabs;
