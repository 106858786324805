import React from "react";
import { GroupModel } from "../../types/models";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ERPModal from "../../Components/Modals/ERPModal";
import { ModalProps } from "../../Components/Modals/types";
import FormInput from "../../Components/FormInput/FormInput";
import Button from "../../Components/Button";
import LoadingButton from "../../Components/LoadingButton";
import { isNil } from "lodash";
import { CreateGroupRequest, UpdateGroupRequest } from "../../types/requests";
import GroupService from "../../Services/group.service";
import { Toast } from "../../Components/Toast";
import FormActions from "../../Components/FormActions";

interface GroupModalProps extends ModalProps {
    groups: GroupModel[];
    group?: GroupModel;
    onGroupCreated: (program: GroupModel) => void;
    onGroupUpdated: (program: GroupModel) => void;
}

const createSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    owner: yup.string().email("Email address is invalid (e.g: something@email.com)").required("Owner Email is required")
});

const updateSchema = yup.object().shape({
    name: yup.string().required("Name is required")
});

interface GroupForm {
    name: string;
    owner: string;
}

const GroupModal = ({ group, show, onClose, onGroupCreated, onGroupUpdated }: GroupModalProps) => {
    const isEditing = !isNil(group);
    const modalTitle = !isEditing ? "Create Group" : "Edit Group";

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty, isSubmitting }
    } = useForm<GroupForm>({
        resolver: yupResolver(!isEditing ? createSchema : updateSchema),
        defaultValues: {
            name: group?.name ?? ""
        }
    });

    const handleFormSubmit = async (data: GroupForm) => {
        if (!isEditing) {
            const request: CreateGroupRequest = {
                name: data.name,
                owner: data.owner
            };

            const newGroup = await GroupService.createGroup(request);

            Toast.success(`Successfully created group ${data.name}`);

            onGroupCreated(newGroup);
        } else {
            const request: UpdateGroupRequest = {
                name: data.name
            };

            const updatedGroup = await GroupService.updateGroup(group.id, request);

            Toast.success(`Successfully updated group ${data.name}`);

            onGroupUpdated(updatedGroup);
        }

        onClose();
    };

    return (
        <ERPModal show={show} title={modalTitle} onClose={onClose} size="lg" backdropClassName="group-modal--backdrop">
            <div className="group-modal">
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <FormInput
                        labelText="Name"
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Give the group a name"
                        register={register("name")}
                        error={errors?.name}
                        disabled={isSubmitting}
                    />

                    {!isEditing && (
                        <FormInput
                            labelText="Owner Email"
                            id="owner"
                            name="owner"
                            type="text"
                            placeholder="Add the owner / administrator's email address"
                            register={register("owner")}
                            error={errors.owner}
                            disabled={isSubmitting}
                        />
                    )}

                    <FormActions>
                        <Button variant="grey" disabled={isSubmitting} onClick={onClose}>
                            Cancel
                        </Button>

                        <LoadingButton disabled={!isDirty} isLoading={isSubmitting} variant="primary" type="submit">
                            {!isEditing ? "Create Group" : "Save"}
                        </LoadingButton>
                    </FormActions>
                </form>
            </div>
        </ERPModal>
    );
};

export default GroupModal;
