import { PluginParameters } from "./plugin";
import {
    DataType,
    GroupPrivilege,
    ModelLocationSiteDataModel,
    ModelLocationSiteModel,
    ModelLocationSiteOptimisationDataModel,
    PluginModel,
    ResourceType,
    RunPeriodModel
} from "./models";
import { ApiError } from "../api/base.api";
import { OptimisationParameters } from "../Components/Plugin/types";

export interface CreateFolderRequest {
    parentFolderId: string;
    name: string;
}

export interface UpdateFolderRequest {
    name: string;
}

export interface CreateSystemRequest {
    name: string;
    description: string;
}

export interface UpdateSystemRequest {
    name: string;
    description: string;
}

export interface UpdateSystemNameRequest {
    name: string;
}

export interface CreateScenarioRequest {
    name: string;
    description: string;
    isDefault: boolean;
}

export interface UpdateScenarioRequest {
    name: string;
    description: string;
    isDefault: boolean;
}

export interface CreateLocationRequest {
    name: string;
    gaugeNumber: string;
}

export interface UpdateLocationRequest {
    name: string;
    gaugeNumber: string;
}

export interface CreateGroupRequest {
    name: string;
    owner: string;
}

export interface UpdateGroupRequest {
    name: string;
}

export interface AddUserToGroupRequest {
    user: string;
    privilege: GroupPrivilege;
}

export interface ChangePasswordRequest {
    currentPassword: string;
    newPassword: string;
}

export interface ChangePasswordResult {
    success: boolean;
    error: ApiError;
}

export interface CreateModelRequest {
    name: string;
    description: string;
    authors: string;
    assessmentEndpoint: string;
    measurementEndpoint: string;
    caveats: string;
}

export interface UpdateModelRequest {
    name: string;
    description: string;
    authors: string;
    assessmentEndpoint: string;
    measurementEndpoint: string;
    caveats: string;
}

export interface UpdateModelNameRequest {
    name: string;
}

export interface UpdateModelRunSettingsRequest {
    runPeriod: RunPeriodModel;
    dataInfillSettings: DataInfillSettingsModel;
}

export interface SetModelSystemRequest {
    systemId: string;
}

export interface SetModelSystemResponse {
    success: boolean;
    version: string;
}

export interface SetModelPluginRequest {
    pluginId: string;
}

export interface SetModelPluginResponse {
    version: string;
    parameters: PluginParameters;
}

export interface UpdateModelPluginResponse {
    plugin: PluginModel;
    version: string;
    parameters: PluginParameters;
}

export interface UpdateParametersRequest {
    parameters: PluginParameters;
}

export interface UpdateOptimisationParametersRequest {
    parameters: OptimisationParameters;
}

export interface UpdateParametersResponse {
    version: string;
    parameters: PluginParameters;
}

export interface RegisterRequest {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    token: string;
}

export interface BulkModelDeleteRequest {
    models: string[];
}

export interface BulkSystemDeleteRequest {
    systems: string[];
}

export interface BulkFolderDeleteRequest {
    folders: string[];
}

export interface CopyFolderRequest {
    folderId: string;
    destinationFolderId: string;
}

export interface BulkCopyFolderRequest {
    folderIds: string[];
    destinationFolderId: string;
}

export interface CopySystemRequest {
    systemId: string;
    toFolder: string;
}

export interface MoveSystemRequest {
    systemId: string;
    toFolder: string;
}

export interface BulkCopySystemRequest {
    systemIds: string[];
    toFolder: string;
}

export interface CopyModelRequest {
    modelId: string;
    toFolder: string;
}

export interface BulkCopyModelRequest {
    modelIds: string[];
    toFolder: string;
}

export interface MoveModelRequest {
    modelId: string;
    toFolder: string;
}

export interface GenerateBlobUploadRequest {
    fileName: string;
}

export interface GenerateBlobUploadResponse {
    url: string;
}

export interface GenerateBlobDownloadRequest {
    fileName: string;
}

export interface GenerateBlobDownloadResponse {
    url: string;
}

export interface MoveFolderRequest {
    folderId: string;
    destinationFolderId: string;
}

export interface BulkMoveSystemRequest {
    systemIds: string[];
    toFolder: string;
}

export interface UpdateDataFileRequest {
    dataType: DataType;
}

export interface BulkMoveModelRequest {
    modelIds: string[];
    toFolder: string;
}

export interface BulkMoveFolderRequest {
    folderIds: string[];
    destinationFolderId: string;
}

export interface CreateModelLocationSiteRequest {
    name: string;
    description: string;
}

export interface CreateModelLocationSiteResponse {
    site: ModelLocationSiteModel;
    version: string;
}

export interface UpdateModelLocationSiteRequest {
    name: string;
    description: string;
}

export interface ResourceLockRequest {
    id: string;
    type: ResourceType;
}

export interface ResourceUnLockRequest {
    id: string;
}

export interface ApiErrorResult {
    code: string;
    message: string;
}

export interface FolderDeleteResult {
    success: boolean;
    failure: ApiErrorResult;
}

export interface BaseComputationRequest {
    modelId: string;
    runPeriod?: RunPeriodModel;
    dataInfillSettings?: DataInfillSettingsModel;
}

export enum DataInfillType {
    EMPTY = "empty",
    PREVIOUS_VALUE = "previous_value"
}

export interface DataInfillSettingsModel {
    should_infill_data: boolean;
    infill_method: DataInfillType;
}

export enum WaterholeComputationType {
    RUN_ONCE = "run_once",
    OPTIMISE = "optimise"
}

export interface OptimisationComputationRequest extends BaseComputationRequest {
    locationId: string;
    siteId: string;
    optimisationId: string;
    parameters: PluginParameters;
}

export interface ComputationRequest extends BaseComputationRequest {
    scenarios: string[];
}

export interface LocationStateChangedResponse {
    success: boolean;
    version: string;
}

export interface CreateModelLocationSiteDataResponse {
    data: ModelLocationSiteDataModel;
    version: string;
}

export interface CreateModelLocationSiteOptimisationDataResponse {
    data: ModelLocationSiteOptimisationDataModel;
    version: string;
}

export interface UpdateModelLocationSiteDataResponse {
    data: ModelLocationSiteDataModel;
    version: string;
}

export interface UpdateModelLocationSiteOptimisationDataResponse {
    data: ModelLocationSiteOptimisationDataModel;
    version: string;
}

export interface ModelLocationSiteDataDeleteResponse {
    success: boolean;
    version: string;
}

export interface ModelLocationSiteOptimisationDataDeleteResponse {
    success: boolean;
    version: string;
}

export interface ModelLocationSiteDeleteResponse {
    version: string;
}
