import React, { useMemo, useState } from "react";
import LoadingIndicator from "../../Components/LoadingIndicator";
import Select, { SelectOption } from "../../Components/Select/Select";
import { isNil } from "lodash";
import ModelService from "../../Services/model.service";
import { sleep } from "../../utils/utils";
import { useModel } from "./ModelContext";
import ModelSystemLocations from "./ModelSystemLocations";
import NoPluginWarning from "./NoPluginWarning";
import SystemEditLink from "./SystemEditLink";
import { useConfirmation } from "../../Components/ConfirmDialog/ConfirmationContext";

const ModelLocationSettings = () => {
    const { model, readOnly, systems, setSystem } = useModel();

    const confirm = useConfirmation();
    const [isSettingSystem, setIsSettingSystem] = useState<boolean>(false);
    const [selectedSystem, setSelectedSystem] = useState<SelectOption>(
        !isNil(model.system) ? { value: model.system.id, label: model.system.name } : null
    );

    const handleSystemSelected = async (option: SelectOption) => {
        try {
            if (model.system?.id === option.value) {
                return;
            }

            if (!isNil(model.system)) {
                const result = await confirm({
                    title: "Warming",
                    description: "All locations and computation results will be deleted. Do you wish to proceed ?"
                });

                if (!result.success) {
                    return;
                }
            }

            setIsSettingSystem(true);

            await ModelService.setSystem(model.id, option.value);

            setSystem({ id: option.value, name: option.label });

            setSelectedSystem(option);
        } finally {
            await sleep(250);

            setIsSettingSystem(false);
        }
    };

    const systemOptions = useMemo(() => {
        return systems?.map(s => {
            const option: SelectOption = {
                value: s.id,
                label: s.name
            };

            return option;
        });
    }, [systems.length]);

    const canShowSystem = !isSettingSystem && !isNil(model.system);
    const hasPlugin = !isNil(model.plugin);

    return (
        <div className="model-section-content model-location-settings">
            <div>
                {!hasPlugin && <NoPluginWarning />}

                <Select
                    labelText="Select system to apply to model"
                    placeholder="Select a system"
                    value={selectedSystem}
                    values={systemOptions}
                    onSelected={handleSystemSelected}
                    disabled={readOnly || isSettingSystem || !hasPlugin}
                />

                {canShowSystem && <SystemEditLink systemId={model.system?.id} />}
            </div>

            {isSettingSystem && <LoadingIndicator centered className="system-loader" />}

            {canShowSystem && <ModelSystemLocations model={model} readOnly={readOnly} />}
        </div>
    );
};

export default ModelLocationSettings;
