import React from "react";
import FormActions from "../../Components/FormActions";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";
import LoadingButton from "../../Components/LoadingButton";

interface ModelRunModalFooterProps {
    canRun: boolean;
    isRunning: boolean;
    onRun: () => void;
}

const ModelRunModalFooter = ({ canRun, isRunning, onRun }: ModelRunModalFooterProps) => {
    return (
        <FormActions>
            <LoadingButton isLoading={isRunning} disabled={!canRun} variant="secondary" icon={faPlay} onClick={onRun}>
                Run
            </LoadingButton>
        </FormActions>
    );
};

export default ModelRunModalFooter;
