import React, { useMemo } from "react";
import Select, { SelectOption } from "../../Components/Select/Select";
import { isNilOrEmpty } from "../../utils/utils";
import { isNil } from "lodash";

const PREDICTORS: SelectOption[] = [
    { label: "Evaportation - Scaling", value: "evaporation|scaling" },
    { label: "Seepage - Rate (mm/d)", value: "seepage|rate_mm_d" },
    { label: "Rainfall - Area (m2)", value: "rainfall|area_m2" },
    { label: "Rainfall - Max infiltration (mm/d)", value: "rainfall|max_infiltration_mm_d" },
    { label: "Groundwater - Area (m2)", value: "groundwater|area_m2" },
    { label: "Groundwater - Inflow (%)", value: "groundwater|inflow_percent" },
    { label: "Groundwater - Loss to deep drainage (%)", value: "groundwater|loss_to_deep_drainage_percent" }
];

interface PredictorsSelectProps {
    id: string;
    label: string;
    className?: string;
    disabled?: boolean;
    values: string[];
    onPredictorsChanged: (predictors: string[]) => void;
}

const PredictorsSelect = ({ id, label, values, disabled, onPredictorsChanged }: PredictorsSelectProps) => {
    const predictors = useMemo(() => {
        if (isNilOrEmpty(values)) {
            return null;
        }

        const _predictors: SelectOption[] = [];

        for (let i = 0; i < values.length; i++) {
            const value = values[i];

            const matching = PREDICTORS.find(m => m.value === value);

            if (!isNil(matching)) {
                _predictors.push(matching);
            }
        }

        return _predictors;
    }, [values]);

    return (
        <Select
            id={id}
            labelText={label}
            value={predictors}
            disabled={disabled}
            isMulti
            values={PREDICTORS}
            onSelected={(options: SelectOption[]) => {
                const predictors = options?.map(o => o.value) ?? [];

                onPredictorsChanged(predictors);
            }}
        />
    );
};

export default PredictorsSelect;
