import Label from "../../Components/Label";
import NumericInput from "../../Components/NumericInput";
import Select, { SelectOption } from "../../Components/Select/Select";
import React, { useMemo } from "react";
import { WaterholeRunToEmptyEvaporationType, WaterholeRunToEmptySettings } from "./types";

interface PluginWaterholeRunToEmptyModalSettingsProps {
    settings: WaterholeRunToEmptySettings;
    disabled?: boolean;
    onSettingsChanged: (next: WaterholeRunToEmptySettings) => void;
}

const EVAPORATION_SELECT_OPTIONS: SelectOption[] = [
    { value: WaterholeRunToEmptyEvaporationType.DATA, label: "Data" },
    { value: WaterholeRunToEmptyEvaporationType.MEAN, label: "Mean" },
    { value: WaterholeRunToEmptyEvaporationType.VALUE, label: "Value" },
    { value: WaterholeRunToEmptyEvaporationType.DATA_PLUS_PERCENT, label: "Data + %" },
    { value: WaterholeRunToEmptyEvaporationType.DATA_PLUS_VALUE, label: "Data + Value" }
];

const PluginWaterholeRunToEmptyModalSettings = ({
    settings,
    disabled,
    onSettingsChanged
}: PluginWaterholeRunToEmptyModalSettingsProps) => {
    const handleDepthChanged = (next: number) => {
        onSettingsChanged({ ...settings, depth: next });
    };

    const handleEvaporationValueChanged = (next: number) => {
        onSettingsChanged({ ...settings, evaporation_value: next });
    };

    const handleEvaporationTypeChanged = (next: string) => {
        onSettingsChanged({
            ...settings,
            evaporation_type: next as WaterholeRunToEmptyEvaporationType
        });
    };

    const enableValue = useMemo(() => {
        switch (settings?.evaporation_type) {
            case WaterholeRunToEmptyEvaporationType.VALUE:
                return true;
            case WaterholeRunToEmptyEvaporationType.DATA_PLUS_PERCENT:
                return true;
            case WaterholeRunToEmptyEvaporationType.DATA_PLUS_VALUE:
                return true;
            default:
                return false;
        }
    }, [settings]);

    return (
        <div className="settings">
            <div className="evaporation">
                <Label>Run To Empty Evaporation Type</Label>

                <div className="type">
                    <Select
                        values={EVAPORATION_SELECT_OPTIONS}
                        value={EVAPORATION_SELECT_OPTIONS.find(o => o.value === settings?.evaporation_type)}
                        onSelected={(option: SelectOption) => {
                            handleEvaporationTypeChanged(option.value);
                        }}
                        menuClassName="select"
                        isSearchable={false}
                        disabled={disabled}
                    />
                    <NumericInput
                        className="value-input"
                        value={enableValue ? settings?.evaporation_value : null}
                        disabled={!enableValue}
                        onChange={handleEvaporationValueChanged}
                    />
                </div>
            </div>
            <div className="depth">
                <Label>Run To Empty Depth</Label>
                <NumericInput
                    className="depth-input"
                    value={settings?.depth}
                    onChange={handleDepthChanged}
                    unit="m"
                    min={0}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};

export default PluginWaterholeRunToEmptyModalSettings;
