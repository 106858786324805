import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import FileSelectorButton from "../../Components/FileSelector/FileSelectorButton";
import { useAttachmentFileUploader } from "../../Components/FileUploader/AttachmentFileUploaderContext";
import React, { useEffect, useState } from "react";
import ModelService from "../../Services/model.service";
import AzureBlobService from "../../Services/azure.blob.service";
import { Attachment, ModelModel } from "../../types/models";
import { isNilOrEmpty } from "../../utils/utils";
import ModelAttachment from "./ModelAttachment";
import ModelAttachmentEmpty from "./ModelAttachmentEmpty";
import { useConfirmation } from "../../Components/ConfirmDialog/ConfirmationContext";

interface ModelAttachmentProps {
    model: ModelModel;
    readOnly: boolean;
}

const ModelAttachments = ({ model, readOnly }: ModelAttachmentProps) => {
    const [attatchments, setAttatchments] = useState<Attachment[]>([]);
    const confirm = useConfirmation();

    const uploader = useAttachmentFileUploader();

    const handleFileUpload = async (file: File) => {
        const result = await uploader({
            modelId: model.id,
            file: file
        });

        if (!result.success) {
            return;
        }

        const next: Attachment[] = [...attatchments, result.data];

        setAttatchments(next);
    };

    const handleAttachmentDeleted = async (attatchment: Attachment) => {
        const result = await confirm({
            title: "Delete Attachment",
            description: "You are about to delete an Attachment. Do you wish to continue?"
        });

        if (!result.success) {
            return;
        }

        await ModelService.deleteAttachment(model.id, attatchment.id);

        const next = attatchments.filter(a => a.id !== attatchment.id);

        setAttatchments(next);
    };

    const handleAttatchmentDownload = async (attatchment: Attachment) => {
        await AzureBlobService.downloadBlob(attatchment.blobId, attatchment.name);
    };

    useEffect(() => {
        const getAttachments = async () => {
            const attachments = await ModelService.getAttachments(model.id);

            setAttatchments(attachments);
        };

        if (!isNilOrEmpty(model?.id)) {
            getAttachments();
        }
    }, [model?.id]);

    return (
        <div className="model-attachments">
            <div className="attachments-header">
                <h5>Attachments</h5>

                <FileSelectorButton
                    className="button"
                    label="New Attatchment"
                    icon={faPlus}
                    disabled={readOnly}
                    onFileSelected={handleFileUpload}
                />
            </div>

            {isNilOrEmpty(attatchments) && <ModelAttachmentEmpty />}

            {attatchments.map(attachment => {
                return (
                    <ModelAttachment
                        key={attachment.id}
                        attachment={attachment}
                        readOnly={readOnly}
                        onAttatchmentDelete={handleAttachmentDeleted}
                        onAttatchmentDownload={handleAttatchmentDownload}
                    />
                );
            })}
        </div>
    );
};

export default ModelAttachments;
