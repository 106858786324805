import React from "react";
import { GroupPrivilege } from "../../types/models";
import GroupUserPrivilegePicker from "./GroupUserPrivilegePicker";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Controller, useForm } from "react-hook-form";
import FormInput from "../../Components/FormInput/FormInput";
import { guid } from "../../utils/utils";

interface InviteUserForm {
    email: string;
}

const schema = yup.object().shape({
    email: yup.string().email("Email address is invalid (e.g: something@email.com)").required("Email is required")
});

interface GroupUserInviteInputeProps {
    onMemberAdded: (email: string, privilege: GroupPrivilege) => void;
}

const GroupUserInviteInput = ({ onMemberAdded }: GroupUserInviteInputeProps) => {
    const {
        formState: { errors, isSubmitting, isValid },
        setValue,
        getValues,
        control,
        reset
    } = useForm<InviteUserForm>({
        resolver: yupResolver(schema),
        defaultValues: {
            email: ""
        }
    });

    const handlePrivilegeSelected = async (privilege: GroupPrivilege) => {
        if (!isValid) {
            return;
        }

        const email = getValues("email");

        onMemberAdded(email, privilege);

        reset();
    };

    return (
        <form className="group-users-input--container">
            <Controller
                control={control}
                name="email"
                render={({ field }) => {
                    return (
                        <FormInput
                            id={guid()}
                            type="text"
                            placeholder="Enter email of new user"
                            error={errors?.email}
                            disabled={isSubmitting}
                            containerClassName="email-input"
                            value={field.value}
                            onChange={e => {
                                setValue("email", e.target.value, { shouldDirty: true, shouldValidate: true });
                            }}
                        />
                    );
                }}
            />

            <GroupUserPrivilegePicker onPrivilegeSelected={handlePrivilegeSelected} />
        </form>
    );
};

export default GroupUserInviteInput;
