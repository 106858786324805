import { DataType } from "../types/models";
import { isEnum, isNilOrEmpty } from "../utils/utils";

interface ParsedFileInfo {
    isValid: boolean;
    error?: string;
    scenario?: string;
    location?: string;
    datatype?: DataType;
}

export enum NamingConventionOption {
    SCENARIO = "scenario",
    LOCATION = "location",
    DATATYPE = "datatype"
}

export enum SeperatorOption {
    DOT = ".",
    UNDERSCORE = "_",
    HYPHEN = "-"
}

export class FileNameParser {
    public static parseFileName(
        name: string,
        seperator: SeperatorOption,
        namingConvention: NamingConventionOption[]
    ): ParsedFileInfo {
        const nameWithoutExtention = name.replace(/\.csv$/i, "");
        const splitName = nameWithoutExtention.split(seperator);

        if (splitName.length !== 3) {
            return {
                isValid: false,
                error: `${name} - doesn't match the specified convention`
            };
        }

        const scenario = splitName[namingConvention.findIndex(v => v === NamingConventionOption.SCENARIO)];
        if (isNilOrEmpty(scenario)) {
            return {
                isValid: false,
                error: `${name} - scenario is not valid`
            };
        }

        const location = splitName[namingConvention.findIndex(v => v === NamingConventionOption.LOCATION)];
        if (isNilOrEmpty(location)) {
            return {
                isValid: false,
                error: `${name} - location is not valid`
            };
        }

        const datatype = splitName[namingConvention.findIndex(v => v === NamingConventionOption.DATATYPE)]
            .toLowerCase()
            .replace(" ", "_");
        if (isNilOrEmpty(datatype) || !isEnum(DataType, datatype)) {
            return {
                isValid: false,
                error: `${name} - data type is not valid`
            };
        }

        return {
            isValid: true,
            scenario: scenario,
            location: location,
            datatype: datatype as DataType
        };
    }
}
