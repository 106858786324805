import { MultiYearFreshesPartialSeasonParameters } from "../../types/plugin-parameter-types";
import { PluginParameters } from "../../types/plugin";
import { BaseParameterMerger } from "./base-parameter-merger";

export class MultiYearFreshesPartialSeasonParameterMerger extends BaseParameterMerger<MultiYearFreshesPartialSeasonParameters> {
    public merge(
        source: PluginParameters,
        defaultParameters: MultiYearFreshesPartialSeasonParameters
    ): MultiYearFreshesPartialSeasonParameters {
        return {
            data: {
                variable_season: this.defaultTo(
                    source?.data?.variable_season,
                    defaultParameters?.data?.variable_season
                ),
                start_month: this.defaultTo(source?.data?.start_month, defaultParameters?.data?.start_month),
                lag: this.defaultTo(source?.data?.lag, defaultParameters?.data?.lag)
            },
            multiyear: {
                return_period: this.defaultTo(
                    source?.multiyear?.return_period,
                    defaultParameters?.multiyear?.return_period
                ),
                max_time: this.defaultTo(source?.multiyear?.max_time, defaultParameters?.multiyear?.max_time)
            },
            rules_dry: {
                count: this.defaultTo(source?.rules_dry?.count, defaultParameters?.rules_dry?.count),
                duration: this.defaultTo(source?.rules_dry?.duration, defaultParameters?.rules_dry?.duration),
                magnitude: this.defaultTo(source?.rules_dry?.magnitude, defaultParameters?.rules_dry?.magnitude),
                independence: this.defaultTo(
                    source?.rules_dry?.independence,
                    defaultParameters?.rules_dry?.independence
                )
            },
            rules_wet: {
                count: this.defaultTo(source?.rules_wet?.count, defaultParameters?.rules_wet?.count),
                duration: this.defaultTo(source?.rules_wet?.duration, defaultParameters?.rules_wet?.duration),
                magnitude: this.defaultTo(source?.rules_wet?.magnitude, defaultParameters?.rules_wet?.magnitude),
                independence: this.defaultTo(
                    source?.rules_wet?.independence,
                    defaultParameters?.rules_wet?.independence
                )
            },
            rules_average: {
                count: this.defaultTo(source?.rules_average?.count, defaultParameters?.rules_average?.count),
                duration: this.defaultTo(source?.rules_average?.duration, defaultParameters?.rules_average?.duration),
                magnitude: this.defaultTo(
                    source?.rules_average?.magnitude,
                    defaultParameters?.rules_average?.magnitude
                ),
                independence: this.defaultTo(
                    source?.rules_average?.independence,
                    defaultParameters?.rules_average?.independence
                )
            },
            rules_drought: {
                count: this.defaultTo(source?.rules_drought?.count, defaultParameters?.rules_drought?.count),
                duration: this.defaultTo(source?.rules_drought?.duration, defaultParameters?.rules_drought?.duration),
                magnitude: this.defaultTo(
                    source?.rules_drought?.magnitude,
                    defaultParameters?.rules_drought?.magnitude
                ),
                independence: this.defaultTo(
                    source?.rules_drought?.independence,
                    defaultParameters?.rules_drought?.independence
                )
            },
            rules_very_dry: {
                count: this.defaultTo(source?.rules_very_dry?.count, defaultParameters?.rules_very_dry?.count),
                duration: this.defaultTo(source?.rules_very_dry?.duration, defaultParameters?.rules_very_dry?.duration),
                magnitude: this.defaultTo(
                    source?.rules_very_dry?.magnitude,
                    defaultParameters?.rules_very_dry?.magnitude
                ),
                independence: this.defaultTo(
                    source?.rules_very_dry?.independence,
                    defaultParameters?.rules_very_dry?.independence
                )
            },
            partial_tables: {
                count: this.defaultTo(source?.partial_tables?.count, defaultParameters?.partial_tables?.count),
                duration: this.defaultTo(source?.partial_tables?.duration, defaultParameters?.partial_tables?.duration),
                magnitude: this.defaultTo(
                    source?.partial_tables?.magnitude,
                    defaultParameters?.partial_tables?.magnitude
                ),
                independence: this.defaultTo(
                    source?.partial_tables?.independence,
                    defaultParameters?.partial_tables?.independence
                ),
                max_time: this.defaultTo(source?.partial_tables?.max_time, defaultParameters?.partial_tables?.max_time)
            },
            assessment: {
                yearly_conversion: this.defaultTo(
                    source?.assessment?.yearly_conversion,
                    defaultParameters?.assessment?.yearly_conversion
                ),
                temporal_agg: this.defaultTo(
                    source?.assessment?.temporal_agg,
                    defaultParameters?.assessment?.temporal_agg
                )
            },
            spatial_agg: {
                risk_categories: this.defaultTo(
                    source?.spatial_agg?.risk_categories,
                    defaultParameters?.spatial_agg?.risk_categories
                )
            }
        };
    }
}
