import LoadingIndicator from "../../Components/LoadingIndicator";
import React from "react";
import { TaskModel, TaskStatus } from "../../types/models";
import ERPModal from "./ERPModal";

interface WaterholeOptimisationModalProps {
    children: React.ReactNode;
    footer: React.ReactNode;
    isLoading: boolean;
    show: boolean;
    task: TaskModel;
    title: string;
    handleClose: () => void;
}

const WaterholeOptimisationModal = ({
    children,
    footer,
    isLoading,
    show,
    task,
    title,
    handleClose
}: WaterholeOptimisationModalProps) => {
    return (
        <ERPModal
            show={show}
            title={title}
            onClose={handleClose}
            size="xl"
            scrollable
            backdrop={task?.status === TaskStatus.RUNNING ? "static" : true}
            bodyClassName="waterhole-optimisation-modal-body"
            className="waterhole-optimisation-modal"
            dialogClassName="waterhole-optimisation-modal-dialog"
            contentClassName="waterhole-optimisation-modal-content"
            canClose={!(task?.status === TaskStatus.RUNNING)}
            footer={footer}
        >
            {isLoading && <LoadingIndicator centered />}

            {!isLoading && children}
        </ERPModal>
    );
};

export default WaterholeOptimisationModal;
