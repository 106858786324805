import { TurtleParameters } from "../../types/plugin-parameter-types";
import { PluginParameters } from "../../types/plugin";
import { BaseParameterMerger } from "./base-parameter-merger";

export class TurtleParameterMerger extends BaseParameterMerger<TurtleParameters> {
    public merge(source: PluginParameters, defaultParameters: TurtleParameters): TurtleParameters {
        return {
            flow_parameters: {
                ari: this.defaultTo(source?.flow_parameters?.ari, defaultParameters?.flow_parameters?.ari),
                drying_period: this.defaultTo(
                    source?.flow_parameters?.drying_period,
                    defaultParameters?.flow_parameters?.drying_period
                ),
                aestivation_period: this.defaultTo(
                    source?.flow_parameters?.aestivation_period,
                    defaultParameters?.flow_parameters?.aestivation_period
                )
            },
            assessment: {
                daily_agg: this.defaultTo(source?.assessment?.daily_agg, defaultParameters?.assessment?.daily_agg),
                yearly_agg: this.defaultTo(source?.assessment?.yearly_agg, defaultParameters?.assessment?.yearly_agg),
                temporal_agg: this.defaultTo(
                    source?.assessment?.temporal_agg,
                    defaultParameters?.assessment?.temporal_agg
                )
            },
            spatial_agg: {
                risk_categories: this.defaultTo(
                    source?.spatial_agg?.risk_categories,
                    defaultParameters?.spatial_agg?.risk_categories
                )
            }
        };
    }
}
