import React, { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { PluginDropdownValue, SpellTypeTypes, ARIThresholdSettings, SpellTypeOptions } from "../../types/plugin";
import { PluginFieldProps, PluginForm } from "./types";
import Select, { SelectOption } from "../../Components/Select/Select";
import PluginParameterARIThreshold from "./PluginParameterARIThreshold";

interface PluginParameterSpellTypeProps extends PluginFieldProps {
    onChange?: (field: string, value: Record<string, string | number | boolean>) => void;
}

const spellTypeValues: PluginDropdownValue[] = [
    { label: "Above Threshold", value: SpellTypeTypes.ABOVE_THRESHOLD },
    { label: "Below Threshold", value: SpellTypeTypes.BELOW_THRESHOLD },
    { label: "Range", value: SpellTypeTypes.RANGE }
];

const PluginParameterSpellType = ({
    id,
    definition,
    field,
    control,
    disabled,
    onChange
}: PluginParameterSpellTypeProps) => {
    const { watch, getValues } = useFormContext<PluginForm>();

    const [watchedValues] = watch([`${definition.id}.${field.id}`]);

    const options: SpellTypeOptions = field.control.options as SpellTypeOptions;

    const singleThreshold =
        watchedValues?.type === SpellTypeTypes.ABOVE_THRESHOLD ||
        watchedValues?.type === SpellTypeTypes.BELOW_THRESHOLD;

    const upperThresholdLabel = singleThreshold ? "Threshold" : "Upper Threshold";

    const handleSpellTypeTypeChanged = (type: SpellTypeTypes) => {
        const currentUpperType = getValues(`${definition.id}.${field.id}.upper_threshold_type`);
        const currentUpperValue = getValues(`${definition.id}.${field.id}.upper_threshold_value`);
        const currentUpperUseDefaultData = getValues(`${definition.id}.${field.id}.upper_threshold_use_default_data`);
        const currentLowerType = getValues(`${definition.id}.${field.id}.lower_threshold_type`);
        const currentLowerValue = getValues(`${definition.id}.${field.id}.lower_threshold_value`);
        const currentLowerUseDefaultData = getValues(`${definition.id}.${field.id}.lower_threshold_use_default_data`);

        onChange("spell_type", {
            type: type,
            upper_threshold_type: currentUpperType,
            upper_threshold_value: currentUpperValue,
            upper_threshold_use_default_data: currentUpperUseDefaultData,
            lower_threshold_type: currentLowerType,
            lower_threshold_value: currentLowerValue,
            lower_threshold_use_default_data: currentLowerUseDefaultData
        });
    };

    const handleUpperThresholdChanged = (thresholdSettings: ARIThresholdSettings) => {
        const currentSpellType = getValues(`${definition.id}.${field.id}`);

        const nextSpellType = {
            ...currentSpellType,
            upper_threshold_type: thresholdSettings.type,
            upper_threshold_value: thresholdSettings.value,
            upper_threshold_use_default_data: thresholdSettings.use_default_data
        };

        onChange("spell_type", nextSpellType);
    };

    const handleLowerThresholdChanged = (thresholdSettings: ARIThresholdSettings) => {
        const currentSpellType = getValues(`${definition.id}.${field.id}`);

        const nextSpellType = {
            ...currentSpellType,
            lower_threshold_type: thresholdSettings.type,
            lower_threshold_value: thresholdSettings.value,
            lower_threshold_use_default_data: thresholdSettings.use_default_data
        };

        onChange("spell_type", nextSpellType);
    };

    const upperThresholdSettings: ARIThresholdSettings = useMemo(() => {
        return {
            type: watchedValues?.upper_threshold_type,
            value: watchedValues?.upper_threshold_value,
            use_default_data: watchedValues?.upper_threshold_use_default_data
        };
    }, [
        watchedValues?.upper_threshold_type,
        watchedValues?.upper_threshold_value,
        watchedValues?.upper_threshold_use_default_data
    ]);

    const lowerThresholdSettings: ARIThresholdSettings = useMemo(() => {
        return {
            type: watchedValues?.lower_threshold_type,
            value: watchedValues?.lower_threshold_value,
            use_default_data: watchedValues?.lower_threshold_use_default_data
        };
    }, [
        watchedValues?.lower_threshold_type,
        watchedValues?.lower_threshold_value,
        watchedValues?.lower_threshold_use_default_data
    ]);

    return (
        <div className="spell-type-parameter-field">
            <Controller
                control={control}
                name={`${definition.id}.${field.id}.type`}
                render={({ field: _field }) => {
                    return (
                        <div className="spell-type-select">
                            <Select
                                id={id}
                                labelText={field.name}
                                labelClassName="plugin-definition-field-label"
                                value={spellTypeValues.find(v => v.value === _field.value)}
                                values={spellTypeValues}
                                onSelected={(option: SelectOption) => {
                                    handleSpellTypeTypeChanged(option.value as SpellTypeTypes);
                                }}
                                isClearable={false}
                                isSearchable={false}
                                disabled={disabled}
                            />
                        </div>
                    );
                }}
            />

            <PluginParameterARIThreshold
                thresholdSettings={upperThresholdSettings}
                allowAri={options.allow_ari}
                thresholdUnit={options.threshold_unit}
                label={upperThresholdLabel}
                disabled={disabled}
                onChange={handleUpperThresholdChanged}
            />

            {!singleThreshold && (
                <PluginParameterARIThreshold
                    thresholdSettings={lowerThresholdSettings}
                    allowAri={options.allow_ari}
                    thresholdUnit={options.threshold_unit}
                    label="Lower Threshold"
                    disabled={disabled}
                    onChange={handleLowerThresholdChanged}
                />
            )}
        </div>
    );
};

export default PluginParameterSpellType;
