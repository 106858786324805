import React from "react";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";
import Button from "../../Components/Button";
import LoadingIndicator from "../../Components/LoadingIndicator";
import { useModel } from "./ModelContext";
import PageTitle from "../../Components/PageTitle";
import ModelRunModal from "./ModelRunModal";
import { useInterval } from "../../Hooks/useInterval";
import ModelService from "../../Services/model.service";
import { Toast } from "../../Components/Toast";
import { TaskStatus } from "../../types/models";
import { isNil, isNull } from "lodash";
import ModelHeaderComputationStatusIcon from "./ModelHeaderComputationStatusIcon";

const POLLING_TIME_MS = 2500;

const ModelHeader = () => {
    const { model, isSaving, readOnly, tasks, scenarios, showRunModal, updateTasks, updateScenarios, setShowRunModal } =
        useModel();

    useInterval(
        async () => {
            const nextTasks = await ModelService.getComputationStatus(model.id);

            let haveTasksCompleted = false;

            nextTasks.forEach(nextTask => {
                const oldTask = tasks.find(t => t.id === nextTask.id);

                if (isNil(oldTask) || oldTask.status === nextTask.status) {
                    return;
                }

                const scenario = scenarios.find(s => s.id === nextTask.resourceId);

                switch (nextTask.status) {
                    case TaskStatus.COMPLETED:
                        Toast.success(`${scenario.name} successfully computed`);
                        haveTasksCompleted = true;
                        break;

                    case TaskStatus.FAILED:
                        Toast.error(`${scenario.name}: ${nextTask.error.message}`);
                        break;
                }
            });

            if (haveTasksCompleted) {
                const runs = await ModelService.getComputationRuns(model.id);

                const next = scenarios.map(s => {
                    const run = runs.find(r => r.scenarioId === s.id);
                    if (!isNull(run)) {
                        return {
                            ...s,
                            run: run
                        };
                    }

                    return s;
                });

                updateScenarios(next);
            }

            updateTasks(nextTasks);
        },
        tasks.some(t => t?.status === TaskStatus.RUNNING || t?.status === TaskStatus.PENDING) ? POLLING_TIME_MS : null
    );

    return (
        <div className="model-header">
            <PageTitle title={model.name} />

            <h5>{model.name}</h5>

            <div className="model-header-actions">
                <ModelHeaderComputationStatusIcon />

                {isSaving && <LoadingIndicator size="lg" className="saving-indicator" />}

                <Button variant="secondary" icon={faPlay} disabled={isSaving} onClick={() => setShowRunModal(true)}>
                    Run this configuration
                </Button>
            </div>

            {showRunModal && (
                <ModelRunModal show model={model} readOnly={readOnly} onClose={() => setShowRunModal(false)} />
            )}
        </div>
    );
};

export default ModelHeader;
