import React, { useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import FormInput from "../../Components/FormInput/FormInput";
import FormTextArea from "../../Components/FormTextArea/FormTextArea";
import { debounce } from "lodash";
import ModelService from "../../Services/model.service";
import { UpdateModelRequest } from "../../types/requests";
import { useModel } from "./ModelContext";
import { sleep } from "../../utils/utils";
import ModelAttachments from "./ModelAttachments";

const schema = yup.object().shape({
    id: yup.string().required("Id is required"),
    name: yup.string().required("Name is required"),
    authors: yup.string().optional().nullable(),
    description: yup.string().optional().nullable(),
    assessmentEndpoint: yup.string().optional().nullable(),
    measurementEndpoint: yup.string().optional().nullable(),
    caveats: yup.string().optional().nullable()
});

interface ModelForm {
    id: string;
    name: string;
    authors: string;
    description: string;
    assessmentEndpoint: string;
    measurementEndpoint: string;
    caveats: string;
}

const DEBOUCE_TRIGGER_SAVE = 500;

const ModelGeneralInfo = () => {
    const { model, readOnly, setSaving, updateModel } = useModel();

    const {
        register,
        watch,
        setValue,
        formState: { errors }
    } = useForm<ModelForm>({
        resolver: yupResolver(schema),
        mode: "onBlur",
        reValidateMode: "onSubmit",
        defaultValues: {
            id: model.id,
            name: model.name ?? "",
            authors: model.authors ?? "",
            description: model.description ?? "",
            assessmentEndpoint: model.assessmentEndpoint ?? "",
            measurementEndpoint: model.measurementEndpoint ?? "",
            caveats: model.caveats ?? ""
        }
    });

    useEffect(() => {
        setValue("id", model.id);
        setValue("name", model.name);
        setValue("authors", model.authors);
        setValue("description", model.description);
        setValue("assessmentEndpoint", model.assessmentEndpoint);
        setValue("measurementEndpoint", model.measurementEndpoint);
        setValue("caveats", model.caveats);
    }, [model.id]);

    const saveModel = async (form: ModelForm) => {
        try {
            const isValid = await schema.isValid(form);

            if (!isValid) {
                return;
            }

            setSaving(true);

            const request: UpdateModelRequest = {
                name: form.name,
                description: form.description,
                assessmentEndpoint: form.assessmentEndpoint,
                measurementEndpoint: form.measurementEndpoint,
                authors: form.authors,
                caveats: form.caveats
            };

            const updatedModel = await ModelService.updateModel(form.id, request);

            updateModel(updatedModel);
        } finally {
            await sleep(250);
            setSaving(false);
        }
    };

    useEffect(() => {
        if (readOnly) {
            return;
        }

        const subscription = watch(debounce(saveModel, DEBOUCE_TRIGGER_SAVE));

        return () => subscription.unsubscribe();
    }, [watch, readOnly]);

    return (
        <div className="model-section-content model-general-info">
            <form>
                <FormInput
                    labelText="Name"
                    id="name"
                    name="name"
                    type="text"
                    register={register("name")}
                    error={errors?.name}
                    disabled={readOnly}
                />

                <FormInput
                    labelText="Author(s)"
                    id="authors"
                    name="authors"
                    type="text"
                    register={register("authors")}
                    error={errors?.authors}
                    disabled={readOnly}
                />

                <FormTextArea
                    labelText="Description"
                    id="description"
                    name="description"
                    rows={6}
                    register={register("description")}
                    error={errors?.description}
                    disabled={readOnly}
                />

                <FormTextArea
                    labelText="Assessment endpoint"
                    id="assessmentEndpoint"
                    name="assessmentEndpoint"
                    rows={3}
                    register={register("assessmentEndpoint")}
                    error={errors?.assessmentEndpoint}
                    disabled={readOnly}
                />

                <FormTextArea
                    labelText="Measurement endpoint"
                    id="measurementEndpoint"
                    name="measurementEndpoint"
                    rows={3}
                    register={register("measurementEndpoint")}
                    error={errors?.measurementEndpoint}
                    disabled={readOnly}
                />

                <FormTextArea
                    labelText="Caveats"
                    id="caveats"
                    name="caveats"
                    rows={5}
                    register={register("caveats")}
                    error={errors?.caveats}
                    disabled={readOnly}
                />
            </form>

            <ModelAttachments model={model} readOnly={readOnly} />
        </div>
    );
};

export default ModelGeneralInfo;
