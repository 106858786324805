import Select, { SelectOption } from "../../Components/Select/Select";
import React, { useMemo } from "react";
import { ComparisonType } from "../../types/plugin";
import { isNilOrEmpty } from "../../utils/utils";

interface ComparisonSelectProps {
    id: string;
    label?: string;
    value: string;
    className?: string;
    labelClassName?: string;
    disabled?: boolean;
    isClearable?: boolean;
    onComparisonChanged: (comparison: string) => void;
}

const ComparisonSelect = ({
    id,
    label,
    labelClassName,
    value,
    className,
    disabled,
    isClearable,
    onComparisonChanged
}: ComparisonSelectProps) => {
    const comparisonValues = useMemo(() => {
        return [
            {
                value: ComparisonType.GREATER_EQUAL,
                label: ">="
            },
            {
                value: ComparisonType.LESS_EQUAL,
                label: "<="
            }
        ];
    }, []);

    const comparison = useMemo(() => {
        if (isNilOrEmpty(value)) {
            return null;
        }

        const matching = comparisonValues.find(m => m.value === value.toLowerCase());

        return matching;
    }, [value]);

    return (
        <Select
            id={id}
            labelText={label}
            labelClassName={labelClassName}
            value={comparison}
            values={comparisonValues}
            onSelected={(option: SelectOption) => {
                const value = !isNilOrEmpty(option?.value) ? option.value : null;

                if (value !== comparison?.value) {
                    onComparisonChanged(option?.value ?? null);
                }
            }}
            isClearable={isClearable}
            isSearchable={false}
            menuClassName={className}
            disabled={disabled}
        />
    );
};

export default ComparisonSelect;
