import React from "react";
import TooltipIcon from "../../Components/TooltipIcon";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";

interface PluginParameterCurveAddRowProps {
    onRowAdded: () => void;
}

const PluginParameterCurveAddRow = ({ onRowAdded }: PluginParameterCurveAddRowProps) => {
    return (
        <tr className="partial-tables-parameter-add-row">
            <td colSpan={2}>
                <TooltipIcon icon={faPlusCircle} tooltip="Add Row" onClick={onRowAdded} />
            </td>
        </tr>
    );
};

export default PluginParameterCurveAddRow;
