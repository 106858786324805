import { MaryRiverCodParameters } from "../../types/plugin-parameter-types";
import { PluginParameters } from "../../types/plugin";
import { BaseParameterMerger } from "./base-parameter-merger";

export class MaryRiverCodParameterMerger extends BaseParameterMerger<MaryRiverCodParameters> {
    public merge(source: PluginParameters, defaultParameters: MaryRiverCodParameters): MaryRiverCodParameters {
        return {
            connectivity_event_1: {
                season_start: this.defaultTo(
                    source?.connectivity_event_1?.season_start,
                    defaultParameters?.connectivity_event_1?.season_start
                ),
                season_end: this.defaultTo(
                    source?.connectivity_event_1?.season_end,
                    defaultParameters?.connectivity_event_1?.season_end
                ),
                ctf: this.defaultTo(source?.connectivity_event_1?.ctf, defaultParameters?.connectivity_event_1?.ctf),
                amount_above_ctf: this.defaultTo(
                    source?.connectivity_event_1?.amount_above_ctf,
                    defaultParameters?.connectivity_event_1?.amount_above_ctf
                )
            },
            connectivity_event_2: {
                season_start: this.defaultTo(
                    source?.connectivity_event_2?.season_start,
                    defaultParameters?.connectivity_event_2?.season_start
                ),
                season_end: this.defaultTo(
                    source?.connectivity_event_2?.season_end,
                    defaultParameters?.connectivity_event_2?.season_end
                ),
                temperature_range_min: this.defaultTo(
                    source?.connectivity_event_2?.temperature_range_min,
                    defaultParameters?.connectivity_event_2?.temperature_range_min
                ),
                temperature_range_max: this.defaultTo(
                    source?.connectivity_event_2?.temperature_range_max,
                    defaultParameters?.connectivity_event_2?.temperature_range_max
                ),
                ctf: this.defaultTo(source?.connectivity_event_2?.ctf, defaultParameters?.connectivity_event_2?.ctf),
                amount_above_ctf: this.defaultTo(
                    source?.connectivity_event_2?.amount_above_ctf,
                    defaultParameters?.connectivity_event_2?.amount_above_ctf
                )
            },
            period_without_disturbance: {
                ari: this.defaultTo(
                    source?.period_without_disturbance?.ari,
                    defaultParameters?.period_without_disturbance?.ari
                ),
                duration: this.defaultTo(
                    source?.period_without_disturbance?.duration,
                    defaultParameters?.period_without_disturbance?.duration
                )
            },
            assessment: {
                yearly_agg: this.defaultTo(source?.assessment?.yearly_agg, defaultParameters?.assessment?.yearly_agg),
                temporal_agg: this.defaultTo(
                    source?.assessment?.temporal_agg,
                    defaultParameters?.assessment?.temporal_agg
                )
            },
            spatial_agg: {
                risk_categories: this.defaultTo(
                    source?.spatial_agg?.risk_categories,
                    defaultParameters?.spatial_agg?.risk_categories
                )
            }
        };
    }
}
