import { faDownload, faTrash } from "@fortawesome/pro-solid-svg-icons";
import TooltipIcon from "../../Components/TooltipIcon";
import moment from "moment";
import React from "react";
import { Attachment } from "../../types/models";
import ModelAttachmentThumbnail from "./ModelAttachmentThumbnail";

interface ModelAttachmentProps {
    attachment: Attachment;
    readOnly: boolean;
    onAttatchmentDelete: (attatchment: Attachment) => void;
    onAttatchmentDownload: (attachment: Attachment) => void;
}

const ModelAttachment = ({
    attachment,
    readOnly,
    onAttatchmentDelete,
    onAttatchmentDownload
}: ModelAttachmentProps) => {
    const handleDelete = () => {
        onAttatchmentDelete(attachment);
    };

    const handleDownload = () => {
        onAttatchmentDownload(attachment);
    };

    return (
        <div className="model-attachment">
            <div className="attachment-info">
                <ModelAttachmentThumbnail attachment={attachment} />

                <div className="attachment-labels">
                    <span className="attachment-info--name">{attachment.name}</span>
                    <span className="attachment-info--date">
                        Added {moment(attachment.createdAt).format("DD MMM, YYYY hh:mm A")}
                    </span>
                </div>
            </div>

            <div className="attachment-actions">
                <TooltipIcon tooltip="Download attachment" icon={faDownload} onClick={handleDownload} size="lg" />

                <TooltipIcon
                    tooltip="Delete attachment"
                    icon={faTrash}
                    disabled={readOnly}
                    onClick={handleDelete}
                    size="lg"
                />
            </div>
        </div>
    );
};

export default ModelAttachment;
