import JSZip from "jszip";

export interface ZipFileInfo {
    name: string;
    file: File;
}

export default class ZipGenerator {
    public async generate(files: ZipFileInfo[]): Promise<Blob> {
        const zip = new JSZip();

        for (let j = 0; j < files.length; j++) {
            const fileInfo = files[j];

            zip.file(fileInfo.name, fileInfo.file);
        }

        const blob = await zip.generateAsync({ type: "blob" });

        return blob;
    }
}
