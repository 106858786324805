import Label from "../../Components/Label";
import NumericInput from "../../Components/NumericInput";
import Tooltip from "../../Components/Tooltip";
import { isNil } from "lodash";
import React from "react";

interface PluginWaterholeCalibrateModalResultsFieldProps {
    value: number;
    name: string;
    tooltipLabel: string;
    tooltipRange: string;
    tooltipAdditionalInfo?: string;
    tooltipPerformance: string[];
}

const PluginWaterholeCalibrateModalResultsField = ({
    value,
    name,
    tooltipLabel,
    tooltipRange,
    tooltipAdditionalInfo,
    tooltipPerformance
}: PluginWaterholeCalibrateModalResultsFieldProps) => {
    return (
        <Tooltip
            tooltipContent={
                <span>
                    {tooltipLabel}
                    <br /> Range: {tooltipRange}
                    {!isNil(tooltipAdditionalInfo) && (
                        <span>
                            <br /> {tooltipAdditionalInfo}
                        </span>
                    )}
                    <br /> <br /> Performance
                    {tooltipPerformance.map((t, index) => {
                        return (
                            <span key={index}>
                                <br /> {t}
                            </span>
                        );
                    })}
                </span>
            }
            placement="bottom"
        >
            <div className="field">
                <Label className="field-label">{name}</Label>
                <NumericInput value={value} disabled className="value" />
            </div>
        </Tooltip>
    );
};

export default PluginWaterholeCalibrateModalResultsField;
