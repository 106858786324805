import React from "react";
import { LocationModel, SystemModel } from "../../types/models";
import ERPModal from "../../Components/Modals/ERPModal";
import { ModalProps } from "../../Components/Modals/types";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import FormActions from "../../Components/FormActions";
import Button from "../../Components/Button";
import LoadingButton from "../../Components/LoadingButton";
import FormInput from "../../Components/FormInput/FormInput";
import { CreateLocationRequest, UpdateLocationRequest } from "../../types/requests";
import { Toast } from "../../Components/Toast";
import SystemService from "../../Services/system.service";
import { isNil } from "lodash";

interface LocationModalProps extends ModalProps {
    context: LocationModalContext;
    onLocationCreated: (location: LocationModel) => void;
    onLocationUpdated: (location: LocationModel) => void;
}

export interface LocationModalContext {
    system: SystemModel;
    location?: LocationModel;
}

const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    gaugeNumber: yup.string().optional().nullable()
});

interface LocationForm {
    name: string;
    gaugeNumber: string;
}

const LocationModal = ({ context, show, onClose, onLocationCreated, onLocationUpdated }: LocationModalProps) => {
    const isEdit = !isNil(context.location);
    const title = isEdit ? "Edit location" : "Create location";

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty, isSubmitting }
    } = useForm<LocationForm>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: context.location?.name ?? "",
            gaugeNumber: context.location?.gaugeNumber ?? ""
        }
    });

    const handleFormSubmit = async (data: LocationForm) => {
        if (!isEdit) {
            const request: CreateLocationRequest = {
                name: data.name,
                gaugeNumber: data.gaugeNumber
            };

            const result = await SystemService.createLocation(context.system.id, request);

            if (!result.success) {
                Toast.error(result.failure.message);
                return;
            }

            Toast.success(`Successfully created location ${result.data.name}`);

            onLocationCreated(result.data);
        } else {
            const request: UpdateLocationRequest = {
                name: data.name,
                gaugeNumber: data.gaugeNumber
            };

            const updatedLocation = await SystemService.updateLocation(context.system.id, context.location.id, request);

            Toast.success(`Successfully updated location`);

            onLocationUpdated(updatedLocation);
        }

        onClose();
    };

    return (
        <ERPModal title={title} show={show} onClose={onClose}>
            <div>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <FormInput
                        labelText="Name"
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Location name"
                        register={register("name")}
                        error={errors?.name}
                        disabled={isSubmitting}
                    />

                    <FormInput
                        labelText="Gauge Number (Optional)"
                        id="gaugeNumber"
                        name="gaugeNumber"
                        type="text"
                        placeholder="Gauge Number"
                        register={register("gaugeNumber")}
                        error={errors?.gaugeNumber}
                        disabled={isSubmitting}
                    />

                    <FormActions>
                        <Button variant="secondary" disabled={isSubmitting} onClick={onClose}>
                            Cancel
                        </Button>

                        <LoadingButton disabled={!isDirty} isLoading={isSubmitting} variant="primary" type="submit">
                            {!isEdit ? "Create" : "Update"}
                        </LoadingButton>
                    </FormActions>
                </form>
            </div>
        </ERPModal>
    );
};

export default LocationModal;
