import React, { useState } from "react";
import { ManageAccountTabType } from "./types";
import ManageAccountTabs from "./ManageAccountTabs";
import ChangePassword from "./ChangePassword";
import ManageGroups from "./ManageGroups";

import "./ManageAccount.scss";
import PageTitle from "../../Components/PageTitle";

const ManageAccount = () => {
    const [activeTab, setActiveTab] = useState<ManageAccountTabType>(ManageAccountTabType.CHANGE_PASSWORD);

    return (
        <div className="manage-account-container">
            <PageTitle title="Manage Account" />

            <ManageAccountTabs selectedTab={activeTab} onTabSelected={setActiveTab} />

            {activeTab === ManageAccountTabType.CHANGE_PASSWORD && <ChangePassword />}

            {activeTab === ManageAccountTabType.YOUR_GROUPS && <ManageGroups />}
        </div>
    );
};

export default ManageAccount;
