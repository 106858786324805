import React from "react";
import { numberToMonth } from "../../utils/utils";
import PluginParameterVariableSeasonMonthCell from "./PluginParameterVariableSeasonMonthCell";
import PluginParameterVariableSeasonSuccessCell from "./PluginParameterVariableSeasonSuccessCell";

interface PluginParameterVariableSeasonRowProps {
    id: string;
    values: number[];
    disabled: boolean;
    onChanged: (index: number, value: number) => void;
}

const PluginParameterVariableSeasonRow = ({
    id,
    values,
    disabled,
    onChanged
}: PluginParameterVariableSeasonRowProps) => {
    return (
        <tr>
            <PluginParameterVariableSeasonMonthCell key={`${id}-curve-cell-${0}`} value={numberToMonth(values[0])} />
            <PluginParameterVariableSeasonSuccessCell
                key={`${id}-curve-cell-${1}`}
                value={values[1]}
                disabled={disabled}
                onChanged={val => onChanged(1, val)}
            />
        </tr>
    );
};

export default PluginParameterVariableSeasonRow;
