import React, { useMemo } from "react";
import FileSelectorDrag from "../../Components/FileSelector/FileSelectorDrag";
import { PluginDefinition } from "../../types/plugin";
import ModelService from "../../Services/model.service";
import { DataType, ModelLocationSiteDataModel } from "../../types/models";
import { ObservedDataSettings } from "../FileSelector/types";
import DataFile from "../DataFile";
import { useFileUploader } from "../FileUploader/FileUploaderContext";

interface SiteDataDefinitionProps {
    /**
     * Unique identifier to make sure this input is uniquely rendered amongst other plugin parameters.
     * This is important to avoid lose of focus on re-render caused by state change during parameter saving.
     */
    id: string;
    definition: PluginDefinition;
    data: Record<string, any>;
    disabled: boolean;
    onFilesAdded: (files: ModelLocationSiteDataModel[]) => void;
    onFileUpdated: (file: ModelLocationSiteDataModel) => void;
    onFileRemoved: (file: ModelLocationSiteDataModel) => void;
}

const DATA_TYPES: DataType[] = [
    DataType.DEPTH,
    DataType.FLOW,
    DataType.RAINFALL,
    DataType.TEMPERATURE,
    DataType.CLIMATE,
    DataType.BATHYMETRY,
    DataType.EVAPORATION,
    DataType.COMPLIANCE,
    DataType.OTHER_TIMESERIES,
    DataType.SALINITY
];

const SiteDataParameterDefinition = ({
    definition,
    data,
    disabled,
    onFilesAdded,
    onFileUpdated,
    onFileRemoved
}: SiteDataDefinitionProps) => {
    const { modelId, locationId, siteId } = data;

    const dataFiles: ModelLocationSiteDataModel[] = data.dataFiles ?? [];

    const uploader = useFileUploader();

    const handleFileSelected = async (files: File[]) => {
        const work = files.map(f => {
            return (onProgress: (progress: number) => void) => {
                return ModelService.uploadModelLocationSiteData(
                    modelId,
                    locationId,
                    siteId,
                    f,
                    null,
                    false,
                    null,
                    onProgress
                );
            };
        });

        const result = await uploader({ fileUploads: work });

        if (!result.success) {
            return;
        }

        onFilesAdded(result.data as ModelLocationSiteDataModel[]);
    };

    const handleDataRemoved = async (dataFile: ModelLocationSiteDataModel) => {
        await ModelService.deleteModelLocationSiteData(modelId, locationId, siteId, dataFile.id);

        onFileRemoved(dataFile);
    };

    const handleDataTypeChanged = async (dataFile: ModelLocationSiteDataModel, dataType: DataType) => {
        if (dataFile.dataType === dataType) {
            return;
        }

        const nextData = await ModelService.updateModelLocationSiteDataOptions(
            modelId,
            locationId,
            siteId,
            dataFile.id,
            dataType
        );

        onFileUpdated(nextData);
    };

    const handleObservedDataFileUpdated = async (
        _data: ModelLocationSiteDataModel,
        file: File,
        connectionSettings: ObservedDataSettings
    ) => {
        const dataType = !dataFiles.some(d => d.id !== _data.id && d.dataType === connectionSettings.dataType)
            ? connectionSettings.dataType
            : null;

        const work = [file].map(f => {
            return (onProgress: (progress: number) => void) => {
                return ModelService.updateModelLocationSiteDataFile(
                    modelId,
                    locationId,
                    siteId,
                    _data.id,
                    f,
                    dataType,
                    true,
                    connectionSettings,
                    onProgress
                );
            };
        });

        const result = await uploader({ fileUploads: work });

        const newData = result.data[0] as ModelLocationSiteDataModel;

        onFileUpdated(newData);
    };

    const handleObservedDataFileSelected = async (file: File, connectionSettings: ObservedDataSettings) => {
        const dataType = !dataFiles.some(d => d.dataType === connectionSettings.dataType)
            ? connectionSettings.dataType
            : null;

        const work = [file].map(f => {
            return (onProgress: (progress: number) => void) => {
                return ModelService.uploadModelLocationSiteData(
                    modelId,
                    locationId,
                    siteId,
                    f,
                    dataType,
                    true,
                    connectionSettings,
                    onProgress
                );
            };
        });

        const result = await uploader({ fileUploads: work });

        if (!result.success) {
            return;
        }

        onFilesAdded(result.data as ModelLocationSiteDataModel[]);
    };

    const avaliableDataTypes: DataType[] = useMemo(() => {
        const usedDataTypes = dataFiles.map(d => d.dataType);
        return DATA_TYPES.filter(d => !usedDataTypes.some(ud => ud === d));
    }, [dataFiles]);

    return (
        <div className="plugin-definition">
            <h5>{definition.name}</h5>

            {dataFiles.map(dataFile => {
                return (
                    <DataFile
                        avaliableDataTypes={avaliableDataTypes}
                        key={dataFile.id}
                        data={dataFile}
                        disabled={disabled}
                        className="model-location-site--data"
                        onDataTypeChanged={handleDataTypeChanged}
                        onDataRemoved={handleDataRemoved}
                        onObservedDataFileUpdated={handleObservedDataFileUpdated}
                    />
                );
            })}

            <FileSelectorDrag
                className="waterhole-site-data-upload"
                allowMultiple={true}
                accept=".csv"
                disabled={disabled}
                onFileSelected={handleFileSelected}
                onObservedDataFileSelected={handleObservedDataFileSelected}
            />
        </div>
    );
};

export default SiteDataParameterDefinition;
