import React from "react";
import { faSave } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormInput from "../../Components/FormInput/FormInput";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import LoadingButton from "../../Components/LoadingButton";
import { Toast } from "../../Components/Toast";
import UserService from "../../Services/user.service";
import { ChangePasswordRequest } from "../../types/requests";

const schema = yup.object().shape({
    currentPassword: yup
        .string()
        .required("Please enter your current password")
        .matches(
            /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
            "Password must be at least 8 characters and contain at least one of each - uppercase, lowercase, number and special character."
        ),
    newPassword: yup
        .string()
        .required("Please enter your new password")
        .matches(
            /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
            "Password must be at least 8 characters and contain at least one of each - uppercase, lowercase, number and special character."
        ),
    confirmPassword: yup.string().oneOf([yup.ref("newPassword"), null], "Passwords must match")
});

interface ChangePasswordForm {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
}

const ChangePassword = () => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isDirty, isSubmitting }
    } = useForm<ChangePasswordForm>({
        resolver: yupResolver(schema)
    });

    const changePassword = async (data: ChangePasswordForm) => {
        try {
            const request: ChangePasswordRequest = {
                currentPassword: data.currentPassword,
                newPassword: data.newPassword
            };

            await UserService.changePassword(request);

            Toast.success("Successfully changed password");

            reset();
        } catch (error) {
            Toast.error("Failed to change password verify that your password is correct");
        }
    };

    return (
        <div className="change-password">
            <form onSubmit={handleSubmit(changePassword)}>
                <div className="change-password--inputs">
                    <FormInput
                        placeholder="Current password"
                        id="currentPassword"
                        name="currentPassword"
                        type="password"
                        register={register("currentPassword")}
                        error={errors.currentPassword}
                        disabled={isSubmitting}
                    />

                    <FormInput
                        placeholder="New password"
                        id="newPassword"
                        name="newPassword"
                        type="password"
                        register={register("newPassword")}
                        error={errors.newPassword}
                        disabled={isSubmitting}
                    />

                    <FormInput
                        placeholder="Confirm new password"
                        id="confirmPassword"
                        name="confirmPassword"
                        type="password"
                        register={register("confirmPassword")}
                        error={errors.confirmPassword}
                        disabled={isSubmitting}
                    />
                </div>

                <div className="change-password--action">
                    <LoadingButton type="submit" isLoading={isSubmitting} disabled={!isDirty || isSubmitting}>
                        Save new password <FontAwesomeIcon icon={faSave} />
                    </LoadingButton>
                </div>
            </form>
        </div>
    );
};

export default ChangePassword;
