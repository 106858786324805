import React, { useMemo, useState } from "react";
import PageTitle from "../../Components/PageTitle";
import ExplorerHeader from "./ExplorerHeader";
import ExplorerTable from "./ExplorerTable";
import { FolderModel, ModelModel, SystemModel } from "../../types/models";
import { useFileSystem } from "../../Components/FileSystem/useFileSystem";
import FileSystemBreadcrumbs from "../../Components/FileSystem/FileSystemBreadcrumbs";
import { Breadcrumb } from "../../Components/FileSystem/types";
import { useSelection } from "./useSelection";
import { Toast } from "../../Components/Toast";
import { useFileSystemModal } from "../../Components/FileSystem/FileSystemModalContext";
import FolderModal, { FolderModalContext } from "./FolderModal";
import { isNil } from "lodash";
import { useConfirmation } from "../../Components/ConfirmDialog/ConfirmationContext";
import ModelService from "../../Services/model.service";
import { ExplorerEntity, ExplorerItem, SelectionState } from "./types";
import { isNilOrEmpty } from "../../utils/utils";
import SystemService from "../../Services/system.service";
import FolderService from "../../Services/folder.service";
import { useAuth0 } from "../../auth/react-auth0-spa";
import SearchView from "./SearchView";
import ModelModal, { ModelModalContext } from "./ModelModal";
import SystemModal, { SystemModalContext } from "./SystemModal";
import { useSort } from "../../Components/SortableHeader/useSort";

import "./Explorer.scss";

const Explorer = () => {
    const { erpUser } = useAuth0();

    const {
        folders,
        stack,
        view,
        breadcrumbs,
        navigateToFolder,
        navigateBack,
        navigateBackToFolder,
        addFolder,
        addFolderToView,
        addFolders,
        addFoldersToView,
        updateFolder,
        updateFolders,
        removeFolder,
        removeFolderFromView,
        removeFolderByIds,
        removeFolderFromViewByIds,
        addModel,
        addModels,
        updateModel,
        removeModel,
        removeModelByIds,
        addSystem,
        addSystems,
        updateSystem,
        removeSystem,
        removeSystemByIds,
        buildNewStackToFolder,
        isLoading
    } = useFileSystem();

    const {
        selection,
        selectAll,
        deselectAll,
        selectFolder,
        deselectFolder,
        selectSystem,
        deselectSystem,
        selectModel,
        deselectModel,
        clearSelection
    } = useSelection(view.subFolders, view.systems, view.models);

    const confirm = useConfirmation();
    const fs = useFileSystemModal();

    const [folderModalContext, setFolderModalContext] = useState<FolderModalContext>(null);
    const [modelModalContext, setModelModalContext] = useState<ModelModalContext>(null);
    const [systemModalContext, setSystemModalContext] = useState<SystemModalContext>(null);
    const [search, setSearch] = useState<string>("");

    const items = useMemo(() => {
        if (isNil(view)) {
            return [];
        }

        const items: ExplorerItem<ExplorerEntity>[] = [
            ...view.subFolders.map(folder => {
                const item: ExplorerItem<FolderModel> = {
                    item: folder,
                    type: "folder"
                };
                return item;
            }),
            ...view.systems.map(system => {
                const item: ExplorerItem<SystemModel> = {
                    item: system,
                    type: "system"
                };
                return item;
            }),
            ...view.models.map(model => {
                const item: ExplorerItem<ModelModel> = {
                    item: model,
                    type: "model"
                };
                return item;
            })
        ];

        return items;
    }, [view]);

    const { sortedItems: displayedItems, handleSort, sortContext } = useSort(items);

    const handleNewFolder = () => {
        setFolderModalContext({ folder: null, parentFolder: view.folder });
    };

    const handleFolderEdit = (folder: FolderModel) => {
        setFolderModalContext({ folder: folder, parentFolder: view.folder });
    };

    const handleSearch = (newSearch: string) => {
        setSearch(newSearch);
    };

    const clearSearch = () => {
        setSearch("");
    };

    const isSearching = !isNilOrEmpty(search);

    const handleFolderMove = async (folder: FolderModel) => {
        const copiedStack = stack.copy();

        const result = await fs({
            title: `Move ${folder.name} to`,
            folders: folders,
            fromStack: copiedStack,
            disabledFolders: new Set<string>([folder.id])
        });

        if (!result.success) {
            return;
        }

        if (result.folder.id === folder.parentId) {
            return;
        }

        const movedFolder = await FolderService.moveFolder(folder.id, result.folder.id);

        removeFolderFromView(folder);

        updateFolder(movedFolder);

        deselectFolder(folder);

        Toast.success(`Successfully moved folder ${folder.name}`);
    };

    const handleFolderDeleted = async (folder: FolderModel) => {
        const result = await confirm({
            title: "Folder delete",
            description: "You are about to delete a folder and all its content. Do you wish to continue ?"
        });

        if (!result.success) {
            return;
        }

        removeFolder(folder);
        removeFolderFromView(folder);
        deselectFolder(folder);

        const deleteResult = await FolderService.deleteFolder(folder.id);

        if (!deleteResult.success) {
            Toast.error(deleteResult.failure.message);
        } else {
            Toast.success("Successfully deleted folder");
        }
    };

    const handleFolderCopy = async (folder: FolderModel) => {
        const copiedStack = stack.copy();

        const result = await fs({
            title: `Copy ${folder.name} to`,
            folders: folders,
            fromStack: copiedStack,
            disabledFolders: new Set<string>([folder.id])
        });

        if (!result.success) {
            return;
        }

        const copiedFolders = await FolderService.copyFolder(folder.id, result.folder.id);

        if (folder.parentId === result.folder.id) {
            const sourceFolder = copiedFolders.find(f => f.parentId === result.folder.id);
            addFolderToView(sourceFolder);
        }

        addFolders(copiedFolders);

        Toast.success(`Successfully copied folder ${folder.name}`);
    };

    const handleFolderCreated = (folder: FolderModel) => {
        clearSearch();
        addFolder(folder);
        addFolderToView(folder);
    };

    const handleFolderUpdated = (folder: FolderModel) => {
        updateFolder(folder);
    };

    const handleFolderNavgiation = (folder: FolderModel) => {
        navigateToFolder(folder);
        deselectAll();
    };

    const handleBreadcrumbSelected = (breadcrumb: Breadcrumb): void => {
        navigateBackToFolder(breadcrumb.index);
    };

    const handleBackNavigation = () => {
        navigateBack();
    };

    const handleSystemMove = async (system: SystemModel) => {
        const result = await fs({ title: `Move ${system.name} to`, folders: folders, fromStack: stack });

        if (!result.success) {
            return;
        }

        if (result.folder.id === system.folderId) {
            return;
        }

        await SystemService.moveSystem(system.id, result.folder.id);

        removeSystem(system);

        deselectSystem(system);

        Toast.success(`Successfully moved system ${system.name}`);
    };

    const handleSystemDelete = async (system: SystemModel) => {
        const result = await confirm({
            title: "Delete system",
            description: `You are about to delete a system. This cannot be undone, do you wish to continue?`
        });

        if (!result.success) {
            return;
        }

        removeSystem(system);
        deselectSystem(system);

        await SystemService.deleteSystem(system.id);

        Toast.success("Successfully deleted system");
    };

    const handleSystemCopy = async (system: SystemModel) => {
        const result = await fs({ title: `Copy ${system.name} to`, folders: folders, fromStack: stack });

        if (!result.success) {
            return;
        }

        const copiedSystem = await SystemService.copySystem(system.id, result.folder.id);

        if (result.folder.id === view.folder.id) {
            addSystem(copiedSystem);
        }

        Toast.success(`Successfully copied system ${system.name}`);
    };

    const handleSystemEdit = (system: SystemModel) => {
        setSystemModalContext({ system: system });
    };

    const handleSystemUpdated = (system: SystemModel) => {
        updateSystem(system);
    };

    const handleModelMove = async (model: ModelModel) => {
        const result = await fs({ title: `Move ${model.name} to`, folders: folders, fromStack: stack });

        if (!result.success) {
            return;
        }

        if (result.folder.id === model.folderId) {
            return;
        }

        await ModelService.moveModel(model.id, result.folder.id);

        removeModel(model);

        deselectModel(model);

        Toast.success(`Successfully moved model ${model.name}`);
    };

    const handleModelDelete = async (model: ModelModel) => {
        const result = await confirm({
            title: "Delete Model",
            description: `You are about to delete a model. This cannot be undone, do you wish to continue?`
        });

        if (!result.success) {
            return;
        }

        removeModel(model);
        deselectModel(model);

        await ModelService.deleteModel(model.id);

        Toast.success("Successfully deleted model");
    };

    const handleModelCopy = async (model: ModelModel) => {
        const result = await fs({ title: `Copy ${model.name} to`, folders: folders, fromStack: stack });

        if (!result.success) {
            return;
        }

        const copiedModel = await ModelService.copyModel(model.id, result.folder.id);

        if (result.folder.id === view.folder.id) {
            addModel(copiedModel);
        }

        Toast.success(`Successfully copied model ${model.name}`);
    };

    const handleModelEdit = (model: ModelModel) => {
        setModelModalContext({ model: model });
    };

    const handleModelUpdated = (model: ModelModel) => {
        updateModel(model);
    };

    const handleBulkDelete = async (state: SelectionState) => {
        const result = await confirm({
            title: "Delete selection",
            description: `You are about to delete the selected items. This cannot be undone, do you wish to continue?`
        });

        if (!result.success) {
            return;
        }

        let folders = Array.from(state.folders.keys());
        const models: string[] = [];
        const systems: string[] = [];

        const selected_models = Array.from(state.models.keys());
        const selected_systems = Array.from(state.systems.keys());

        for (let i = 0; i < selected_models.length; i++) {
            const selected_model = selected_models[i];

            const matching = view.models.find(m => m.id === selected_model);

            if (isNil(matching.lock) || matching?.lock?.owner?.id === erpUser.id) {
                models.push(selected_model);
            }
        }

        for (let i = 0; i < selected_systems.length; i++) {
            const selected_system = selected_systems[i];

            const matching = view.systems.find(s => s.id === selected_system);

            if (isNil(matching.lock) || matching?.lock?.owner?.id === erpUser.id) {
                systems.push(selected_system);
            }
        }

        removeFolderByIds(folders);
        removeFolderFromViewByIds(folders);
        removeModelByIds(models);
        removeSystemByIds(systems);

        if (!isNilOrEmpty(folders)) {
            const result = await FolderService.deleteFolders(folders);

            if (!result.success) {
                Toast.error(result.failure.message);
                folders = [];
            }
        }

        if (!isNilOrEmpty(models)) {
            await ModelService.deleteModels(models);
        }

        if (!isNilOrEmpty(systems)) {
            await SystemService.deleteSystems(systems);
        }

        clearSelection();

        const total = folders.length + models.length + systems.length;

        if (total > 0) {
            Toast.success(`Successfully deleted ${folders.length + models.length + systems.length} item(s)`);
        }
    };

    const handleBulkCopy = async (state: SelectionState) => {
        const folders_to_copy = Array.from(state.folders.keys());
        const models_to_copy = Array.from(state.models.keys());
        const systems_to_copy = Array.from(state.systems.keys());

        const totalSelected = folders_to_copy.length + systems_to_copy.length + models_to_copy.length;

        const copiedStack = stack.copy();

        const result = await fs({
            title: `Copy ${totalSelected} selected item(s) to`,
            folders: folders,
            fromStack: copiedStack,
            disabledFolders: new Set<string>(folders_to_copy)
        });

        if (!result.success) {
            return;
        }

        if (!isNilOrEmpty(folders_to_copy)) {
            const copiedFolders = await FolderService.copyFolders(folders_to_copy, result.folder.id);

            if (view.folder.id === result.folder.id) {
                const sourceFolders = copiedFolders.filter(f => f.parentId === result.folder.id);

                addFoldersToView(sourceFolders);
            }

            addFolders(copiedFolders);
        }

        if (!isNilOrEmpty(models_to_copy)) {
            const copiedModels = await ModelService.copyModels(models_to_copy, result.folder.id);

            if (result.folder.id === view.folder.id) {
                addModels(copiedModels);
            }
        }

        if (!isNilOrEmpty(systems_to_copy)) {
            const copiedSystems = await SystemService.copySystems(systems_to_copy, result.folder.id);

            if (result.folder.id === view.folder.id) {
                addSystems(copiedSystems);
            }
        }

        clearSelection();

        Toast.success(`Successfully copied ${totalSelected} items(s)`);
    };

    const handleBulkMove = async (state: SelectionState) => {
        const folders_to_move = Array.from(state.folders.keys());
        const models_to_move = Array.from(state.models.keys());
        const systems_to_move = Array.from(state.systems.keys());

        const totalSelected = folders_to_move.length + systems_to_move.length + models_to_move.length;

        const copiedStack = stack.copy();

        const result = await fs({
            title: `Move ${totalSelected} selected item(s) to`,
            folders: folders,
            fromStack: copiedStack,
            disabledFolders: new Set<string>(folders_to_move)
        });

        if (!result.success) {
            return;
        }

        if (result.folder.id === view.folder.id) {
            return;
        }

        if (!isNilOrEmpty(folders_to_move)) {
            const movedFolders = await FolderService.moveFolders(folders_to_move, result.folder.id);
            removeFolderFromViewByIds(folders_to_move);
            updateFolders(movedFolders);
        }

        if (!isNilOrEmpty(models_to_move)) {
            await ModelService.moveModels(models_to_move, result.folder.id);
            removeModelByIds(models_to_move);
        }

        if (!isNilOrEmpty(systems_to_move)) {
            await SystemService.moveSystems(systems_to_move, result.folder.id);
            removeSystemByIds(systems_to_move);
        }

        clearSelection();

        Toast.success(`Successfully moved ${totalSelected} items(s)`);
    };

    const handleSearchFolderClick = (folderId: string, ancestorIds: string[]) => {
        if (view.folder.id !== folderId) {
            buildNewStackToFolder(folderId, ancestorIds);
        }

        clearSearch();
    };

    return (
        <div className="explorer-container">
            <PageTitle title="Explorer" />

            <ExplorerHeader
                folderId={view?.folder?.id}
                onNewFolder={handleNewFolder}
                onSearch={handleSearch}
                search={search}
            />

            {!isSearching && (
                <>
                    <FileSystemBreadcrumbs
                        currentViewIndex={view.index}
                        breadcrumbs={breadcrumbs}
                        containerClassName="explorer--breadcrumbs"
                        onBack={handleBackNavigation}
                        onBreadcrumbSelected={handleBreadcrumbSelected}
                    />

                    <ExplorerTable
                        items={displayedItems}
                        onSort={handleSort}
                        sortContext={sortContext}
                        selection={selection}
                        user={erpUser}
                        isLoading={isLoading}
                        onFolderNavigation={handleFolderNavgiation}
                        onFolderSelected={selectFolder}
                        onFolderMove={handleFolderMove}
                        onFolderEdit={handleFolderEdit}
                        onFolderDelete={handleFolderDeleted}
                        onFolderCopy={handleFolderCopy}
                        onSystemSelected={selectSystem}
                        onSystemMove={handleSystemMove}
                        onSystemDelete={handleSystemDelete}
                        onSystemCopy={handleSystemCopy}
                        onSystemEdit={handleSystemEdit}
                        onModelSelected={selectModel}
                        onModelMove={handleModelMove}
                        onModelDelete={handleModelDelete}
                        onModelCopy={handleModelCopy}
                        onModelEdit={handleModelEdit}
                        onAllSelected={selectAll}
                        onBulkDelete={handleBulkDelete}
                        onBulkCopy={handleBulkCopy}
                        onBulkMove={handleBulkMove}
                    />
                </>
            )}

            {isSearching && <SearchView search={search} onFolderClick={handleSearchFolderClick} />}

            {!isNil(folderModalContext) && (
                <FolderModal
                    context={folderModalContext}
                    show
                    onClose={() => setFolderModalContext(null)}
                    onFolderCreated={handleFolderCreated}
                    onFolderUpdated={handleFolderUpdated}
                />
            )}

            {!isNil(modelModalContext) && (
                <ModelModal
                    context={modelModalContext}
                    show
                    onClose={() => setModelModalContext(null)}
                    onModelUpdated={handleModelUpdated}
                />
            )}

            {!isNil(systemModalContext) && (
                <SystemModal
                    context={systemModalContext}
                    show
                    onClose={() => setSystemModalContext(null)}
                    onSystemUpdated={handleSystemUpdated}
                />
            )}
        </div>
    );
};

export default Explorer;
