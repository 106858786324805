import { isNil } from "lodash";
import { Month } from "../types/plugin";

export const guid = () => {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
};

export const sleep = (time = 2000) => {
    return new Promise(resolve => {
        setTimeout(resolve, time);
    });
};

export function isEnum<T>(enums: T, val: string) {
    return Object.values(enums).includes(val);
}

export const tryParseFloat = (val: string): number => {
    if (isNil(val)) {
        return null;
    }

    try {
        const time = parseFloat(val);

        if (isNaN(time)) {
            return null;
        }

        return time;
    } catch (error) {
        return null;
    }
};

export function fullnameFromString(firstName?: string, lastName?: string): string {
    return `${firstName} ${lastName}`;
}

export function fullname<T extends { firstName?: string; lastName?: string }>(obj: T): string {
    if (isNil(obj)) {
        return "";
    }

    return `${obj.firstName} ${obj.lastName}`;
}

export function isNilOrEmpty<T>(val: string | T[]) {
    return isNil(val) || val.length === 0;
}

export function isValidEmail(email: string): boolean {
    return /^[a-zA-Z0-9].+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(email);
}

export function numberToMonth(number: number): string {
    if (isNil(number)) {
        return null;
    }

    const months: Record<number, Month> = {
        1: Month.JANUARY,
        2: Month.FEBRUARY,
        3: Month.MARCH,
        4: Month.APRIL,
        5: Month.MAY,
        6: Month.JUNE,
        7: Month.JULY,
        8: Month.AUGUST,
        9: Month.SEPTEMBER,
        10: Month.OCTOBER,
        11: Month.NOVEMBER,
        12: Month.DECEMBER
    };

    return months[number];
}
