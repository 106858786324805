import React from "react";
import { PluginFieldValue } from "../../types/plugin";
import Label from "../../Components/Label";
import { WaterholeCalibrationPredictors } from "./types";
import PluginWaterholeCalibrateModalField from "./PluginWaterholeCalibrateModalField";
import { WaterholeParameters } from "../../types/plugin-parameter-types";

interface PluginWaterholeCalibrateModalFieldsProps {
    siteValues: Partial<WaterholeParameters>;
    baseParameterValues: Partial<WaterholeParameters>;
    predictors: WaterholeCalibrationPredictors;
    onPredictorsChanged?: (next: WaterholeCalibrationPredictors) => void;
    onBaseParameterValuesChanged?: (next: Partial<WaterholeParameters>) => void;
}

const PluginWaterholeCalibrateModalFields = ({
    siteValues,
    baseParameterValues,
    predictors,
    onPredictorsChanged,
    onBaseParameterValuesChanged
}: PluginWaterholeCalibrateModalFieldsProps) => {
    const handlePredictorOptimised = (section: string, field: string) => {
        const id = `${section}.${field}`;

        const next = { ...predictors };

        if (next.optimiseIds.find(i => i === id)) {
            next.optimiseIds = next.optimiseIds.filter(i => i !== id);
        } else {
            next.optimiseIds.push(id);
        }

        onPredictorsChanged(next);
    };

    const handleBaseParameterValueChanged = (section: string, field: string, value: PluginFieldValue) => {
        const next = { ...baseParameterValues };
        next[section][field] = value;
        onBaseParameterValuesChanged(next);
    };

    return (
        <div className="fields">
            <table>
                <thead>
                    <tr>
                        <th style={{ width: 100 }}></th>
                        <th>Current</th>
                        <th>Modified</th>
                        <th>Optimise</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key="parameters">
                        <td colSpan={4}>{<Label>Site Parameters</Label>}</td>
                    </tr>
                    <PluginWaterholeCalibrateModalField
                        id="extraction.ctf"
                        key="extraction.ctf"
                        section="extraction"
                        field="ctf"
                        name="Discharge CTF"
                        siteValue={siteValues.extraction.ctf}
                        baseParameterValue={baseParameterValues.extraction.ctf}
                        canOptimise={false}
                        isOptimised={predictors?.optimiseIds.includes("extraction.ctf")}
                        onValueChanged={handleBaseParameterValueChanged}
                        onOptimiseChanged={handlePredictorOptimised}
                    />
                    <PluginWaterholeCalibrateModalField
                        id="extraction.lag"
                        key="extraction.lag"
                        section="extraction"
                        field="lag"
                        name="Lag"
                        siteValue={siteValues.extraction.lag}
                        baseParameterValue={baseParameterValues.extraction.lag}
                        canOptimise={false}
                        isOptimised={predictors?.optimiseIds.includes("extraction.lag")}
                        unit="Day(s)"
                        onValueChanged={handleBaseParameterValueChanged}
                        onOptimiseChanged={handlePredictorOptimised}
                    />
                    <tr key="evaporation">
                        <td colSpan={4}>{<Label>Evaporation</Label>}</td>
                    </tr>
                    <PluginWaterholeCalibrateModalField
                        id="evaporation.scaling"
                        key="evaporation.scaling"
                        section="evaporation"
                        field="scaling"
                        siteValue={siteValues.evaporation.scaling}
                        baseParameterValue={baseParameterValues.evaporation.scaling}
                        canOptimise={true}
                        isOptimised={predictors?.optimiseIds.includes("evaporation.scaling")}
                        unit="%"
                        onValueChanged={handleBaseParameterValueChanged}
                        onOptimiseChanged={handlePredictorOptimised}
                    />
                    <tr key="seepage">
                        <td colSpan={4}>{<Label>Seepage</Label>}</td>
                    </tr>
                    <PluginWaterholeCalibrateModalField
                        id="seepage.rate"
                        key="seepage.rate"
                        section="seepage"
                        field="rate"
                        siteValue={siteValues.seepage.rate}
                        baseParameterValue={baseParameterValues.seepage.rate}
                        canOptimise={true}
                        isOptimised={predictors?.optimiseIds.includes("seepage.rate")}
                        unit="mm/d"
                        onValueChanged={handleBaseParameterValueChanged}
                        onOptimiseChanged={handlePredictorOptimised}
                    />
                    <tr key="rainfall">
                        <td colSpan={4}>{<Label>Rainfall</Label>}</td>
                    </tr>
                    <PluginWaterholeCalibrateModalField
                        id="rainfall.area"
                        key="rainfall.area"
                        section="rainfall"
                        field="area"
                        siteValue={siteValues.rainfall.area}
                        baseParameterValue={baseParameterValues.rainfall.area}
                        canOptimise={true}
                        isOptimised={predictors?.optimiseIds.includes("rainfall.area")}
                        unit="㎡"
                        onValueChanged={handleBaseParameterValueChanged}
                        onOptimiseChanged={handlePredictorOptimised}
                    />
                    <PluginWaterholeCalibrateModalField
                        id="rainfall.max_infiltration"
                        key="rainfall.max_infiltration"
                        section="rainfall"
                        field="max_infiltration"
                        siteValue={siteValues.rainfall.max_infiltration}
                        baseParameterValue={baseParameterValues.rainfall.max_infiltration}
                        canOptimise={true}
                        isOptimised={predictors?.optimiseIds.includes("rainfall.max_infiltration")}
                        unit="mm/d"
                        onValueChanged={handleBaseParameterValueChanged}
                        onOptimiseChanged={handlePredictorOptimised}
                    />
                    <tr key="groundwater">
                        <td colSpan={4}>{<Label>Groundwater</Label>}</td>
                    </tr>
                    <PluginWaterholeCalibrateModalField
                        id="groundwater.area"
                        key="groundwater.area"
                        section="groundwater"
                        field="area"
                        siteValue={siteValues.groundwater.area}
                        baseParameterValue={baseParameterValues.groundwater.area}
                        canOptimise={true}
                        isOptimised={predictors?.optimiseIds.includes("groundwater.area")}
                        unit="㎡"
                        onValueChanged={handleBaseParameterValueChanged}
                        onOptimiseChanged={handlePredictorOptimised}
                    />
                    <PluginWaterholeCalibrateModalField
                        id="groundwater.inflow"
                        key="groundwater.inflow"
                        section="groundwater"
                        field="inflow"
                        siteValue={siteValues.groundwater.inflow}
                        baseParameterValue={baseParameterValues.groundwater.inflow}
                        canOptimise={true}
                        isOptimised={predictors?.optimiseIds.includes("groundwater.inflow")}
                        unit="%"
                        onValueChanged={handleBaseParameterValueChanged}
                        onOptimiseChanged={handlePredictorOptimised}
                    />
                    <PluginWaterholeCalibrateModalField
                        id="groundwater.loss_to_deep_drainage"
                        key="groundwater.loss_to_deep_drainage"
                        section="groundwater"
                        field="loss_to_deep_drainage"
                        siteValue={siteValues.groundwater.loss_to_deep_drainage}
                        baseParameterValue={baseParameterValues.groundwater.loss_to_deep_drainage}
                        canOptimise={true}
                        isOptimised={predictors?.optimiseIds.includes("groundwater.loss_to_deep_drainage")}
                        unit="%"
                        onValueChanged={handleBaseParameterValueChanged}
                        onOptimiseChanged={handlePredictorOptimised}
                    />
                    <tr key="extraction">
                        <td colSpan={4}>{<Label>Extraction</Label>}</td>
                    </tr>
                    <PluginWaterholeCalibrateModalField
                        id="extraction.commence_pumping_depth"
                        key="extraction.commence_pumping_depth"
                        section="extraction"
                        field="commence_pumping_depth"
                        siteValue={siteValues.extraction.commence_pumping_depth}
                        baseParameterValue={baseParameterValues.extraction.commence_pumping_depth}
                        canOptimise={false}
                        isOptimised={predictors?.optimiseIds.includes("extraction.commence_pumping_depth")}
                        unit="m"
                        onValueChanged={handleBaseParameterValueChanged}
                        onOptimiseChanged={handlePredictorOptimised}
                    />
                    <PluginWaterholeCalibrateModalField
                        id="extraction.cease_pumping_depth"
                        key="extraction.cease_pumping_depth"
                        section="extraction"
                        field="cease_pumping_depth"
                        siteValue={siteValues.extraction.cease_pumping_depth}
                        baseParameterValue={baseParameterValues.extraction.cease_pumping_depth}
                        canOptimise={false}
                        isOptimised={predictors?.optimiseIds.includes("extraction.cease_pumping_depth")}
                        unit="m"
                        onValueChanged={handleBaseParameterValueChanged}
                        onOptimiseChanged={handlePredictorOptimised}
                    />
                    <PluginWaterholeCalibrateModalField
                        id="extraction.extraction_rate"
                        key="extraction.extraction_rate"
                        section="extraction"
                        field="extraction_rate"
                        siteValue={siteValues.extraction.extraction_rate}
                        baseParameterValue={baseParameterValues.extraction.extraction_rate}
                        canOptimise={false}
                        isOptimised={predictors?.optimiseIds.includes("extraction.extraction_rate")}
                        unit="ML/d"
                        onValueChanged={handleBaseParameterValueChanged}
                        onOptimiseChanged={handlePredictorOptimised}
                    />
                    <PluginWaterholeCalibrateModalField
                        id="extraction.max_annual_take"
                        key="extraction.max_annual_take"
                        section="extraction"
                        field="max_annual_take"
                        siteValue={siteValues.extraction.max_annual_take}
                        baseParameterValue={baseParameterValues.extraction.max_annual_take}
                        canOptimise={false}
                        isOptimised={predictors?.optimiseIds.includes("extraction.max_annual_take")}
                        unit="ML"
                        onValueChanged={handleBaseParameterValueChanged}
                        onOptimiseChanged={handlePredictorOptimised}
                    />
                    <PluginWaterholeCalibrateModalField
                        id="extraction.start_month"
                        key="extraction.start_month"
                        section="extraction"
                        field="start_month"
                        siteValue={siteValues.extraction.start_month}
                        baseParameterValue={baseParameterValues.extraction.start_month}
                        canOptimise={false}
                        isOptimised={predictors?.optimiseIds.includes("extraction.start_month")}
                        onValueChanged={handleBaseParameterValueChanged}
                        onOptimiseChanged={handlePredictorOptimised}
                    />
                </tbody>
            </table>
        </div>
    );
};

export default PluginWaterholeCalibrateModalFields;
