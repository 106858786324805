import React, { useEffect, useState } from "react";
import { faUsersMedical } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../Components/Button";
import GroupTable from "./GroupTable";
import { GroupModel, UserRole } from "../../types/models";
import GroupModal from "./GroupModal";
import { useAuth0 } from "../../auth/react-auth0-spa";

const ManageGroups = () => {
    const [groups, setGroups] = useState<GroupModel[]>([]);
    const [showGroupModal, setShowGroupModal] = useState<boolean>(false);
    const [editingGroup, setEditingGroup] = useState<GroupModel>(null);
    const { erpUser, updateUserGroup, addUserGroup } = useAuth0();

    useEffect(() => {
        const getGroups = async () => {
            const myGroups = erpUser.groups;

            setGroups(myGroups);
        };

        getGroups();
    }, []);

    const handleGroupCreated = (group: GroupModel) => {
        setGroups([...groups, group]);
        addUserGroup(group);
    };

    const handleGroupEdit = (group: GroupModel) => {
        setEditingGroup(group);
        setShowGroupModal(true);
    };

    const handleGroupUpdated = async (group: GroupModel) => {
        const next = groups.map(grp => {
            if (grp.id === group.id) {
                return {
                    ...group
                };
            }

            return grp;
        });

        setGroups(next);

        setEditingGroup(null);

        updateUserGroup(group);
    };

    const handleMemberRemoved = (group: GroupModel) => {
        const next = groups.map(grp => {
            if (grp.id === group.id) {
                return {
                    ...group
                };
            }

            return grp;
        });

        setGroups(next);
    };

    const handleMemberAdded = (group: GroupModel) => {
        const next = groups.map(grp => {
            if (grp.id === group.id) {
                return {
                    ...group
                };
            }

            return grp;
        });

        setGroups(next);
    };

    const canAddOrEditGroups = erpUser.role === UserRole.ROOT || erpUser.role === UserRole.APP_ADMIN;

    return (
        <div className="manage-groups">
            <div className="manage-groups--header">
                <h4>Your Groups</h4>

                {canAddOrEditGroups && (
                    <Button onClick={() => setShowGroupModal(true)}>
                        Create a group <FontAwesomeIcon icon={faUsersMedical} />
                    </Button>
                )}
            </div>

            <GroupTable
                groups={groups}
                currentUser={erpUser}
                canEditGroups={canAddOrEditGroups}
                onGroupEdit={handleGroupEdit}
                onMemberAdded={handleMemberAdded}
                onMemberRemoved={handleMemberRemoved}
            />

            {showGroupModal && (
                <GroupModal
                    show={showGroupModal}
                    groups={groups}
                    group={editingGroup}
                    onGroupCreated={handleGroupCreated}
                    onGroupUpdated={handleGroupUpdated}
                    onClose={() => {
                        setShowGroupModal(false);
                        setEditingGroup(null);
                    }}
                />
            )}
        </div>
    );
};

export default ManageGroups;
