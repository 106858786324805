import { OffshoreReefFisheryParameters } from "../../types/plugin-parameter-types";
import { PluginParameters } from "../../types/plugin";
import { BaseParameterMerger } from "./base-parameter-merger";

export class OffshoreReefFisheryParameterMerger extends BaseParameterMerger<OffshoreReefFisheryParameters> {
    public merge(
        source: PluginParameters,
        defaultParameters: OffshoreReefFisheryParameters
    ): OffshoreReefFisheryParameters {
        return {
            constants: {
                a: this.defaultTo(source?.constants?.a, defaultParameters?.constants?.a),
                b: this.defaultTo(source?.constants?.b, defaultParameters?.constants?.b),
                c: this.defaultTo(source?.constants?.c, defaultParameters?.constants?.c),
                d: this.defaultTo(source?.constants?.d, defaultParameters?.constants?.d),
                f: this.defaultTo(source?.constants?.f, defaultParameters?.constants?.f),
                m: this.defaultTo(source?.constants?.m, defaultParameters?.constants?.m),
                g: this.defaultTo(source?.constants?.g, defaultParameters?.constants?.g)
            },
            flow_parameters: {
                high_flow_year_threshold: this.defaultTo(
                    source?.flow_parameters?.high_flow_year_threshold,
                    defaultParameters?.flow_parameters?.high_flow_year_threshold
                ),
                season_start: this.defaultTo(
                    source?.flow_parameters?.season_start,
                    defaultParameters?.flow_parameters?.season_start
                ),
                season_end: this.defaultTo(
                    source?.flow_parameters?.season_end,
                    defaultParameters?.flow_parameters?.season_end
                )
            },
            assessment: {
                yearly_conversion: this.defaultTo(
                    source?.assessment?.yearly_conversion,
                    defaultParameters?.assessment?.yearly_conversion
                ),
                temporal_agg: this.defaultTo(
                    source?.assessment?.temporal_agg,
                    defaultParameters?.assessment?.temporal_agg
                )
            },
            spatial_agg: {
                risk_categories: this.defaultTo(
                    source?.spatial_agg?.risk_categories,
                    defaultParameters?.spatial_agg?.risk_categories
                )
            }
        };
    }
}
