import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { isNil } from "lodash";
import FileProgress from "./FileProgress";
import { sleep } from "../../utils/utils";
import { Attachment } from "../../types/models";
import ModelService from "../../Services/model.service";

import "./FileUploader.scss";

export interface ProgressContext {
    progress: number;
    label: string;
}

export interface AttachmentFileUploaderModalOptions {
    modelId: string;
    file: File;
}

interface AttachmentFileUploaderModalProps extends AttachmentFileUploaderModalOptions {
    show: boolean;
    onClose: () => void;
    onFileUploaded: (data: Attachment) => void;
}

const AttachmentFileUploaderModal = ({ modelId, file, onFileUploaded }: AttachmentFileUploaderModalProps) => {
    const [context, setContext] = useState<ProgressContext>({ progress: 0, label: "Uploading File" });

    useEffect(() => {
        const doUpload = async () => {
            const attatchmentData = await ModelService.uploadAttachment(modelId, file, progress => {
                setContext({ progress: progress, label: "Uploading File" });
            });

            setContext({ progress: 100, label: "Uploading File" });

            await sleep(500);

            onFileUploaded(attatchmentData);
        };

        doUpload();
    }, []);

    return (
        <Modal show={open} centered backdrop="static">
            <Modal.Body>
                {!isNil(context) && <FileProgress progress={context?.progress ?? 0} label={context?.label} />}
            </Modal.Body>
        </Modal>
    );
};

export default AttachmentFileUploaderModal;
