import { BarramundiJuvenileRecruitmentParameters } from "../../types/plugin-parameter-types";
import { PluginParameters } from "../../types/plugin";
import { BaseParameterMerger } from "./base-parameter-merger";

export class BarramundiJuvenileRecruitmentParameterMerger extends BaseParameterMerger<BarramundiJuvenileRecruitmentParameters> {
    public merge(
        source: PluginParameters,
        defaultParameters: BarramundiJuvenileRecruitmentParameters
    ): BarramundiJuvenileRecruitmentParameters {
        return {
            total_flow_volume: {
                season_start: this.defaultTo(
                    source?.total_flow_volume?.season_start,
                    defaultParameters?.total_flow_volume?.season_start
                ),
                season_end: this.defaultTo(
                    source?.total_flow_volume?.season_end,
                    defaultParameters?.total_flow_volume?.season_end
                ),
                flow_threshold: this.defaultTo(
                    source?.total_flow_volume?.flow_threshold,
                    defaultParameters?.total_flow_volume?.flow_threshold
                )
            },
            maximum_flow_timing: {
                season_start: this.defaultTo(
                    source?.maximum_flow_timing?.season_start,
                    defaultParameters?.maximum_flow_timing?.season_start
                ),
                season_end: this.defaultTo(
                    source?.maximum_flow_timing?.season_end,
                    defaultParameters?.maximum_flow_timing?.season_end
                )
            },
            monthly_flow: {
                season_start: this.defaultTo(
                    source?.monthly_flow?.season_start,
                    defaultParameters?.monthly_flow?.season_start
                ),
                season_end: this.defaultTo(
                    source?.monthly_flow?.season_end,
                    defaultParameters?.monthly_flow?.season_end
                ),
                months_exceeding: this.defaultTo(
                    source?.monthly_flow?.months_exceeding,
                    defaultParameters?.monthly_flow?.months_exceeding
                ),
                flow_threshold: this.defaultTo(
                    source?.monthly_flow?.flow_threshold,
                    defaultParameters?.monthly_flow?.flow_threshold
                )
            },
            intermediate_assessment: {
                annual_recruitment_strength: this.defaultTo(
                    source?.intermediate_assessment?.annual_recruitment_strength,
                    defaultParameters?.intermediate_assessment?.annual_recruitment_strength
                ),
                temporal_risk: this.defaultTo(
                    source?.intermediate_assessment?.temporal_risk,
                    defaultParameters?.intermediate_assessment?.temporal_risk
                )
            },
            assessment: {
                temporal_agg: this.defaultTo(
                    source?.assessment?.temporal_agg,
                    defaultParameters?.assessment?.temporal_agg
                )
            },
            spatial_agg: {
                risk_categories: this.defaultTo(
                    source?.spatial_agg?.risk_categories,
                    defaultParameters?.spatial_agg?.risk_categories
                )
            }
        };
    }
}
