import React, { useEffect, useMemo, useState } from "react";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import ERPIcon from "../../Components/ERPIcon";
import {
    ModelLocationModel,
    ModelLocationSiteDataModel,
    ModelLocationSiteModel,
    PluginModel
} from "../../types/models";
import { PluginFieldValue, PluginParameters as PluginFieldParameters, PluginScopes } from "../../types/plugin";
import PluginParameters from "../../Components/Plugin/PluginParameters";
import ModelService from "../../Services/model.service";
import Switch from "../../Components/Switch";
import { Toast } from "../../Components/Toast";
import ModelSystemLocationSites from "./ModelSystemLocationSites";
import { isNilOrEmpty, sleep } from "../../utils/utils";
import LoadingIndicator from "../../Components/LoadingIndicator";
import Divider from "../../Components/Divider";
import { ParameterMergerFactory } from "../../plugin/mergers/parameter-merger-factory";
import { cloneDeep } from "lodash";

interface ModelSystemLocationProps {
    location: ModelLocationModel;
    plugin: PluginModel;
    defaultParameters: PluginFieldParameters;
    readOnly: boolean;
    onLocationStateChanged: (location: ModelLocationModel, enabled: boolean) => void;
}

const ModelSystemLocation = ({
    location,
    plugin,
    defaultParameters,
    readOnly,
    onLocationStateChanged
}: ModelSystemLocationProps) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [parameters, setParameters] = useState<PluginFieldParameters>(location.parameters);
    const [sites, setSites] = useState<ModelLocationSiteModel[]>([]);
    const [dataFiles, setDataFiles] = useState<ModelLocationSiteDataModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const useSites = plugin.schema.features?.use_site ?? false;

    const handleValueChanged = async (definition: string, field: string, value: PluginFieldValue) => {
        const next = { ...parameters, [definition]: { ...(parameters?.[definition] ?? {}), [field]: value } };

        //FIXME: Should we debounce this ?
        const result = await ModelService.updateLocationParameters(location.modelId, location.id, next);

        if (!result.success) {
            Toast.error(result.failure.message);
            return;
        }

        setParameters(result.parameters);
    };

    const handleSiteParametersChanged = async (site: ModelLocationSiteModel, parameters: PluginFieldParameters) => {
        const nextSites = sites.map(s => {
            if (s.id === site.id) {
                return {
                    ...s,
                    parameters: parameters
                };
            }

            return s;
        });

        setSites(nextSites);
    };

    const handleLocationStateChanged = async (enabled: boolean) => {
        onLocationStateChanged(location, enabled);
    };

    const handleSiteAdded = (site: ModelLocationSiteModel) => {
        setSites([...sites, site]);
    };

    const handleSiteDeleted = (site: ModelLocationSiteModel) => {
        const next = sites.filter(s => s.id !== site.id);

        setSites(next);
    };

    const handleSiteUpdated = (site: ModelLocationSiteModel) => {
        const next = sites.map(s => {
            if (s.id === site.id) {
                return {
                    ...s,
                    name: site.name,
                    description: site.description
                };
            }

            return s;
        });

        setSites(next);
    };

    const handleFilesAdded = (files: ModelLocationSiteDataModel[]) => {
        const next = [...dataFiles, ...files];

        setDataFiles(next);
    };

    const handleFileUpdated = (file: ModelLocationSiteDataModel) => {
        const next = dataFiles.map(df => {
            if (df.id === file.id) {
                return file;
            }

            return df;
        });

        setDataFiles(next);
    };

    const handleFileRemoved = (file: ModelLocationSiteDataModel) => {
        const next = dataFiles.filter(df => df.id !== file.id);

        setDataFiles(next);
    };

    useEffect(() => {
        const getSites = async () => {
            try {
                setIsLoading(true);

                const [_sites, _dataFiles] = await Promise.all([
                    ModelService.getModelLocationSites(location.modelId, location.id),
                    ModelService.getModelLocationSitesData(location.modelId, location.id)
                ]);

                setDataFiles(_dataFiles);
                setSites(_sites);
            } finally {
                await sleep(250);

                setIsLoading(false);
            }
        };

        if (useSites && expanded && isNilOrEmpty(sites)) {
            getSites();
        }
    }, [expanded]);

    const _defaultParameters = useMemo(() => {
        return cloneDeep(defaultParameters);
    }, [defaultParameters]);

    const displayedParameters = useMemo(() => {
        const factory = new ParameterMergerFactory();
        const merger = factory.getMerger(plugin.schema.id);

        return merger.merge(parameters, _defaultParameters);
    }, [_defaultParameters, parameters]);

    return (
        <div className="model-system-location">
            <div className="model-system-location--header" onClick={() => setExpanded(!expanded)}>
                <div className="model-system-location--header-title">
                    <Switch checked={location.enabled} disabled={readOnly} onChecked={handleLocationStateChanged} />
                    <span className="header-title">{location.name}</span>
                </div>

                <ERPIcon icon={expanded ? faChevronUp : faChevronDown} className="expand-icon" size="lg" />
            </div>

            {expanded && (
                <div className="model-system-location--parameters">
                    {useSites && isLoading && <LoadingIndicator centered />}

                    {!isLoading && (
                        <PluginParameters
                            id={location.id}
                            parameters={displayedParameters}
                            schema={plugin.schema}
                            disabled={readOnly}
                            scope={PluginScopes.NODE}
                            onValueChanged={handleValueChanged}
                        />
                    )}

                    {useSites && !isLoading && (
                        <>
                            <Divider />

                            <ModelSystemLocationSites
                                location={location}
                                sites={sites}
                                dataFiles={dataFiles}
                                defaultParameters={defaultParameters}
                                plugin={plugin}
                                readOnly={readOnly}
                                onSiteDeleted={handleSiteDeleted}
                                onSiteAdded={handleSiteAdded}
                                onSiteUpdated={handleSiteUpdated}
                                onSiteParametersChanged={handleSiteParametersChanged}
                                onFilesAdded={handleFilesAdded}
                                onFileUpdated={handleFileUpdated}
                                onFileRemoved={handleFileRemoved}
                            />
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default ModelSystemLocation;
