import Label from "../../Components/Label";
import NumericInput from "../../Components/NumericInput";
import Select, { SelectOption } from "../../Components/Select/Select";
import React from "react";
import { WaterholeCalibrationFitStatisticType, WaterholeCalibrationSettings } from "./types";

interface PluginWaterholeCalibrateModalSettingsProps {
    settings: WaterholeCalibrationSettings;
    disabled?: boolean;
    onSettingUpdate: (newSetting: WaterholeCalibrationSettings) => void;
}

const FIT_STATISTIC_SELECT_OPTIONS: SelectOption[] = [
    { value: WaterholeCalibrationFitStatisticType.NNSE, label: "NNSE" },
    { value: WaterholeCalibrationFitStatisticType.RMSE, label: "RMSE" },
    { value: WaterholeCalibrationFitStatisticType.R2, label: "R²" },
    { value: WaterholeCalibrationFitStatisticType.PBIAS, label: "PBIAS" },
    { value: WaterholeCalibrationFitStatisticType.RSR, label: "RSR" }
];

const PluginWaterholeCalibrateModalSettings = ({
    settings,
    disabled,
    onSettingUpdate
}: PluginWaterholeCalibrateModalSettingsProps) => {
    const handleFitStatisticChanged = (next: string) => {
        onSettingUpdate({
            ...settings,
            fit_statistic: next as WaterholeCalibrationFitStatisticType
        });
    };

    return (
        <div className="settings">
            <div className="field">
                <Label>Population</Label>
                <NumericInput
                    value={settings?.population}
                    onChange={value => {
                        onSettingUpdate({ ...settings, population: value });
                    }}
                    min={0}
                    disabled={disabled}
                />
            </div>
            <div className="field">
                <Label>Generations</Label>
                <NumericInput
                    value={settings?.generations}
                    onChange={value => {
                        onSettingUpdate({ ...settings, generations: value });
                    }}
                    min={0}
                    disabled={disabled}
                />
            </div>
            <div className="field">
                <Label>Scaling</Label>
                <NumericInput
                    value={settings?.scaling}
                    onChange={value => {
                        onSettingUpdate({ ...settings, scaling: value });
                    }}
                    min={0}
                    disabled={disabled}
                />
            </div>
            <div className="field">
                <Label>Fit Statistic</Label>
                <Select
                    values={FIT_STATISTIC_SELECT_OPTIONS}
                    value={FIT_STATISTIC_SELECT_OPTIONS.find(o => o.value === settings?.fit_statistic)}
                    onSelected={(option: SelectOption) => {
                        handleFitStatisticChanged(option.value);
                    }}
                    menuClassName="select"
                    isSearchable={false}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};

export default PluginWaterholeCalibrateModalSettings;
