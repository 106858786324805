import EmptyRow from "../../Components/EmptyRow";
import { isNil, round } from "lodash";
import React, { useMemo } from "react";
import { ModelExploreScenario, ModelExploreSummaryResults, RiskLevel } from "../../types/models";
import { calcPercentDiff } from "../../utils/explorer.utils";

interface ModelExploreRiskTableProps {
    selectedScenarios: ModelExploreScenario[];
    baselineScenario: ModelExploreScenario;
    resultsData: ModelExploreSummaryResults;
    title: string;
}

interface TableRowData {
    id: string;
    name: string;
    low: number;
    moderate: number;
    high: number;
}

const ModelExploreRiskTable = ({
    selectedScenarios,
    baselineScenario,
    resultsData,
    title
}: ModelExploreRiskTableProps) => {
    const tableRows: TableRowData[] = useMemo(() => {
        const scenariosToDisplay = selectedScenarios.filter(s => s.id !== baselineScenario.id);
        const baselineResult = resultsData.spatialResults.find(r => r.scenarioId === baselineScenario.id);

        return scenariosToDisplay.map(s => {
            const scenarioResult = resultsData.spatialResults.find(r => r.scenarioId === s.id);

            return {
                id: s.id,
                name: s.name,
                low: calcPercentDiff(baselineResult, scenarioResult, RiskLevel.LOW),
                moderate: calcPercentDiff(baselineResult, scenarioResult, RiskLevel.MODERATE),
                high: calcPercentDiff(baselineResult, scenarioResult, RiskLevel.HIGH)
            };
        });
    }, [selectedScenarios, baselineScenario, resultsData]);

    const isEmpty = tableRows.length === 0;

    return (
        <div className="risk-table">
            <h5>{title}</h5>

            <table style={{ width: "100%", tableLayout: "fixed" }}>
                <thead>
                    <tr>
                        <th>Scenario</th>

                        <th style={{ color: "#018039" }}>Low</th>

                        <th style={{ color: "#FCAB2E" }}>Moderate</th>

                        <th style={{ color: "#D12A2F" }}>High</th>
                    </tr>
                </thead>

                <tbody>
                    {isEmpty && (
                        <EmptyRow
                            message="Select additional scenarios or change the baseline to see results"
                            colSpan={4}
                        />
                    )}

                    {tableRows.map(r => {
                        return (
                            <tr key={r.id}>
                                <td>{r.name}</td>

                                <td>{!isNil(r.low) ? `${round(r.low, 1)} %` : null}</td>

                                <td>{!isNil(r.moderate) ? `${round(r.moderate, 1)} %` : null}</td>

                                <td>{!isNil(r.high) ? `${round(r.high, 1)} %` : null}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default ModelExploreRiskTable;
