import React, { useMemo } from "react";
import { isNil } from "lodash";
import { DataType, LocationModel, ScenarioLocationDataModel, ScenarioModel } from "../../types/models";
import ScenarioEmpty from "./ScenarioEmpty";
import Checkbox from "../../Components/Checkbox";
import ScenarioLocation from "./ScenarioLocation";
import ScenarioLocationCreate from "./ScenarioLocationCreate";
import ScenarioDescription from "./ScenarioDescription";
import SystemService from "../../Services/system.service";
import { UpdateDataFileRequest } from "../../types/requests";
import { useSystem } from "./SystemContext";
import { ObservedDataSettings } from "../../Components/FileSelector/types";
import { useFileUploader } from "../../Components/FileUploader/FileUploaderContext";

interface ScenarioInfoProps {
    scenario: ScenarioModel;
    onLocationAdded: (location: LocationModel) => void;
    onLocationUpdate: (location: LocationModel) => void;
    onLocationDelete: (location: LocationModel) => void;
    onScenarioUpdated: (scenario: ScenarioModel) => void;
}

const ScenarioInfo = ({
    scenario,
    onLocationAdded,
    onLocationUpdate,
    onLocationDelete,
    onScenarioUpdated
}: ScenarioInfoProps) => {
    const { locations, readOnly, data, updateData } = useSystem();

    const uploader = useFileUploader();

    const handleDescriptionChanged = (description: string) => {
        onScenarioUpdated({ ...scenario, description: description });
    };

    const handleIsDefaultChanged = (isDefault: boolean) => {
        onScenarioUpdated({ ...scenario, isDefault: isDefault });
    };

    const handleFileSelected = async (location: LocationModel, files: File[]) => {
        const work = files.map(f => {
            return (onProgress: (progress: number) => void) => {
                return SystemService.uploadScenarioLocationFile(
                    scenario.systemId,
                    scenario.id,
                    location.id,
                    f,
                    null,
                    false,
                    null,
                    onProgress
                );
            };
        });

        const result = await uploader({ fileUploads: work });

        if (!result.success) {
            return;
        }

        const nextData = [...data, ...(result.data as ScenarioLocationDataModel[])];

        updateData(nextData);
    };

    const handleObservedDataFileSelected = async (
        location: LocationModel,
        file: File,
        connectionSettings: ObservedDataSettings
    ) => {
        const scenarioLocationData = data.filter(d => d.scenarioId === scenario.id && d.locationId === location.id);

        const dataType = !scenarioLocationData.some(d => d.dataType === connectionSettings.dataType)
            ? connectionSettings.dataType
            : null;

        const work = [file].map(f => {
            return (onProgress: (progress: number) => void) => {
                return SystemService.uploadScenarioLocationFile(
                    scenario.systemId,
                    scenario.id,
                    location.id,
                    f,
                    dataType,
                    true,
                    connectionSettings,
                    onProgress
                );
            };
        });

        const result = await uploader({ fileUploads: work });

        if (!result.success) {
            return;
        }

        const nextData = [...data, ...(result.data as ScenarioLocationDataModel[])];

        updateData(nextData);
    };

    const handleObservedDataFileUpdated = async (
        _data: ScenarioLocationDataModel,
        file: File,
        connectionSettings: ObservedDataSettings
    ) => {
        const dataType = !data.some(d => d.id !== _data.id && d.dataType === connectionSettings.dataType)
            ? connectionSettings.dataType
            : null;

        const work = [file].map(f => {
            return (onProgress: (progress: number) => void) => {
                return SystemService.updateScenarioLocationDataFile(
                    scenario.systemId,
                    scenario.id,
                    _data.id,
                    f,
                    dataType,
                    true,
                    connectionSettings,
                    onProgress
                );
            };
        });

        const result = await uploader({ fileUploads: work });

        const newData = result.data[0] as ScenarioLocationDataModel;

        const nextData = [
            ...data.map(d => {
                if (d.id === newData.id) {
                    return newData;
                }

                return d;
            })
        ];

        updateData(nextData);
    };

    const handleDataRemoved = async (_data: ScenarioLocationDataModel) => {
        await SystemService.deleteScenarioLocationData(scenario.systemId, scenario.id, _data.id);

        const nextData = [...data.filter(d => d.id !== _data.id)];

        updateData(nextData);
    };

    const handleDataTypeChanged = async (_data: ScenarioLocationDataModel, dataType: DataType) => {
        if (_data.dataType === dataType) {
            return;
        }

        const request: UpdateDataFileRequest = {
            dataType: dataType
        };

        await SystemService.updateScenarioLocationDataOptions(scenario.systemId, scenario.id, _data.id, request);

        const nextData = [
            ...data.map(d => {
                if (d.id === _data.id) {
                    return {
                        ..._data,
                        dataType: dataType
                    };
                }

                return d;
            })
        ];

        updateData(nextData);
    };

    const isDisabled = useMemo(() => {
        return readOnly;
    }, [readOnly]);

    return (
        <div className="scenario">
            {isNil(scenario) && <ScenarioEmpty />}

            {!isNil(scenario) && (
                <>
                    <div className="scenario-locations">
                        <div className="locations">
                            {locations.map(location => {
                                const locationData = data.filter(
                                    d => d.scenarioId === scenario.id && d.locationId === location.id
                                );

                                return (
                                    <ScenarioLocation
                                        key={location.id}
                                        location={location}
                                        data={locationData}
                                        disabled={isDisabled}
                                        onLocationUpdate={onLocationUpdate}
                                        onLocationDelete={onLocationDelete}
                                        onFileSelected={handleFileSelected}
                                        onObservedDataFileSelected={handleObservedDataFileSelected}
                                        onDataRemoved={handleDataRemoved}
                                        onDataTypeChanged={handleDataTypeChanged}
                                        onObservedDataFileUpdated={handleObservedDataFileUpdated}
                                    />
                                );
                            })}

                            <ScenarioLocationCreate disabled={isDisabled} onLocationAdded={onLocationAdded} />
                        </div>

                        <Checkbox
                            containerClassName="default-scenario-enable-checkbox"
                            label="Default scenario"
                            checked={scenario.isDefault}
                            disabled={isDisabled}
                            onChecked={handleIsDefaultChanged}
                        />
                    </div>

                    <ScenarioDescription
                        scenario={scenario}
                        disabled={isDisabled}
                        onChanged={handleDescriptionChanged}
                    />
                </>
            )}
        </div>
    );
};

export default ScenarioInfo;
