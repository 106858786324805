import React from "react";
import { Link } from "react-router-dom";

interface SystemEditLinkProps {
    systemId: string;
}

const SystemEditLink = ({ systemId }: SystemEditLinkProps) => {
    return (
        <div className="edit-system-link">
            <Link target="_blank" to={`/system/${systemId}`}>
                Edit system
            </Link>
        </div>
    );
};

export default SystemEditLink;
