import React from "react";
import { BaseDataModel, ScenarioLocationDataModel } from "../../types/models";
import FileUploaderModal, { FileUploaderModalOptions } from "./FileUploaderModal";

interface FileUploaderResult {
    success: boolean;
    data: BaseDataModel[];
}

const FileUploaderModalContext = React.createContext<
    (options: FileUploaderModalOptions) => Promise<FileUploaderResult>
>(Promise.reject);

export const useFileUploader = () => React.useContext(FileUploaderModalContext);

export const FileUploaderModalProvider = ({ children }: { children: React.ReactNode }) => {
    const [options, setOptions] = React.useState<FileUploaderModalOptions | null>(null);

    const awaitingPromiseRef = React.useRef<{
        resolve: (r: FileUploaderResult) => void;
        reject: () => void;
    }>();

    const openConfirmation = (opts: FileUploaderModalOptions) => {
        setOptions(opts);

        return new Promise<FileUploaderResult>((resolve, reject) => {
            awaitingPromiseRef.current = { resolve, reject };
        });
    };

    const handleClose = () => {
        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.resolve({ success: false, data: null });
        }

        setOptions(null);
    };

    const handleFileUploaded = (data: ScenarioLocationDataModel[]) => {
        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.resolve({ success: true, data: data });
        }

        setOptions(null);
    };

    const isOpen = Boolean(options);

    return (
        <>
            <FileUploaderModalContext.Provider value={openConfirmation}>{children}</FileUploaderModalContext.Provider>

            {isOpen && (
                <FileUploaderModal show onFileUploaded={handleFileUploaded} onClose={handleClose} {...options} />
            )}
        </>
    );
};
