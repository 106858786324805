import { isNil } from "lodash";
import React from "react";
import { useFormContext } from "react-hook-form";
import { PluginDefinition, PluginFieldValue } from "../../types/plugin";
import PluginParameterField from "./PluginParameterField";
import { PluginForm } from "./types";

interface PluginParameterDefinitionProps {
    /**
     * Unique identifier to make sure this input is uniquely rendered amongst other plugin parameters.
     * This is important to avoid lose of focus on re-render caused by state change during parameter saving.
     */
    id: string;
    definition: PluginDefinition;
    disabled: boolean;
    onValueChanged?: (definition: string, field: string, value: PluginFieldValue) => void;
}

const PluginParameterDefinition = ({ id, definition, disabled, onValueChanged }: PluginParameterDefinitionProps) => {
    const { control, setValue } = useFormContext<PluginForm>();

    const handleValueChanged = async (field: string, value: PluginFieldValue) => {
        setValue(`${definition.id}.${field}`, value);

        if (!isNil(onValueChanged)) {
            onValueChanged(definition.id, field, value);
        }
    };

    return (
        <div className="plugin-definition">
            <span className="plugin-definition--header">{definition.name}</span>

            <div className="plugin-definition-fields">
                {definition.fields.map(field => {
                    const _id = `${id}-${field.id}`;

                    return (
                        <PluginParameterField
                            key={_id}
                            id={_id}
                            definition={definition}
                            field={field}
                            control={control}
                            disabled={disabled}
                            onValueChanged={handleValueChanged}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default PluginParameterDefinition;
