import React from "react";
import { FolderModel, ModelModel, SystemModel } from "../../types/models";
import FileSystemModal, { FileSystemModalOptions } from "./FileSystemModal";

interface FileSystemResult {
    success: boolean;
    folder: FolderModel;
    systems: SystemModel[];
    models: ModelModel[];
}

const FileSystemModalContext = React.createContext<(options: FileSystemModalOptions) => Promise<FileSystemResult>>(
    Promise.reject
);

export const useFileSystemModal = () => React.useContext(FileSystemModalContext);

export const FileSystemModalProvider = ({ children }: { children: React.ReactNode }) => {
    const [options, setOptions] = React.useState<FileSystemModalOptions | null>(null);

    const awaitingPromiseRef = React.useRef<{
        resolve: (r: FileSystemResult) => void;
        reject: () => void;
    }>();

    const openConfirmation = (opts: FileSystemModalOptions) => {
        setOptions(opts);

        return new Promise<FileSystemResult>((resolve, reject) => {
            awaitingPromiseRef.current = { resolve, reject };
        });
    };

    const handleClose = () => {
        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.resolve({ success: false, folder: null, systems: [], models: [] });
        }

        setOptions(null);
    };

    const handleFolderSelected = (folder: FolderModel) => {
        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.resolve({ success: true, folder: folder, systems: [], models: [] });
        }

        setOptions(null);
    };

    const isOpen = Boolean(options);

    return (
        <>
            <FileSystemModalContext.Provider value={openConfirmation}>{children}</FileSystemModalContext.Provider>

            {isOpen && (
                <FileSystemModal show onFolderSelected={handleFolderSelected} onClose={handleClose} {...options} />
            )}
        </>
    );
};
