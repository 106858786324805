import React, { useState } from "react";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import {
    ModelLocationModel,
    ModelLocationSiteDataModel,
    ModelLocationSiteModel,
    PluginModel
} from "../../types/models";
import { PluginParameters as PluginFieldParameters } from "../../types/plugin";
import ModelSystemLocationSite from "./ModelSystemLocationSite";
import LoadingButton from "../../Components/LoadingButton";
import { CreateModelLocationSiteRequest } from "../../types/requests";
import ModelService from "../../Services/model.service";

interface ModelSystemLocationSitesProps {
    location: ModelLocationModel;
    sites: ModelLocationSiteModel[];
    dataFiles: ModelLocationSiteDataModel[];
    plugin: PluginModel;
    defaultParameters: PluginFieldParameters;
    readOnly: boolean;
    onSiteAdded: (site: ModelLocationSiteModel) => void;
    onSiteDeleted: (site: ModelLocationSiteModel) => void;
    onSiteUpdated: (site: ModelLocationSiteModel) => void;
    onSiteParametersChanged: (site: ModelLocationSiteModel, parametes: PluginFieldParameters) => void;
    onFilesAdded: (files: ModelLocationSiteDataModel[]) => void;
    onFileUpdated: (file: ModelLocationSiteDataModel) => void;
    onFileRemoved: (file: ModelLocationSiteDataModel) => void;
}

const ModelSystemLocationSites = ({
    location,
    sites,
    dataFiles,
    plugin,
    defaultParameters,
    readOnly,
    onSiteAdded,
    onSiteDeleted,
    onSiteUpdated,
    onSiteParametersChanged,
    onFilesAdded,
    onFileUpdated,
    onFileRemoved
}: ModelSystemLocationSitesProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSiteAdded = async () => {
        try {
            setIsLoading(true);

            const request: CreateModelLocationSiteRequest = {
                name: "New Site",
                description: null
            };

            const result = await ModelService.createModelLocationSite(location.modelId, location.id, request);

            onSiteAdded(result.site);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="model-system-location-sites">
            {sites.map(site => {
                const _dataFiles = dataFiles.filter(df => df.siteId === site.id);

                return (
                    <ModelSystemLocationSite
                        key={`${location.id}-${site.id}`}
                        location={location}
                        site={site}
                        dataFiles={_dataFiles}
                        plugin={plugin}
                        defaultParameters={defaultParameters}
                        readOnly={readOnly}
                        onSiteDeleted={onSiteDeleted}
                        onSiteUpdated={onSiteUpdated}
                        onParametersChanged={onSiteParametersChanged}
                        onFilesAdded={onFilesAdded}
                        onFileUpdated={onFileUpdated}
                        onFileRemoved={onFileRemoved}
                    />
                );
            })}

            <div className="model-system-location-sites--add-site">
                <LoadingButton icon={faPlus} isLoading={isLoading} disabled={readOnly} onClick={handleSiteAdded}>
                    Add site
                </LoadingButton>
            </div>
        </div>
    );
};

export default ModelSystemLocationSites;
