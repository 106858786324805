import Select, { SelectOption } from "../../Components/Select/Select";
import { isNil } from "lodash";
import React, { useMemo } from "react";

//FIXME: Ideally this would inherit SelectProps
interface YearSelectProps {
    id: string;
    label?: string;
    value: number;
    className?: string;
    disabled?: boolean;
    isClearable?: boolean;
    placeholder?: string;
    minYear?: number;
    maxYear?: number;
    isLoading?: boolean;
    onYearChanged: (year: number) => void;
}

const DEFAULT_MIN_YEAR = 1800;
const DEFAULT_MAX_YEAR = new Date().getFullYear();

const YearSelect = ({
    id,
    label,
    value,
    className,
    disabled,
    isClearable,
    placeholder,
    minYear = null,
    maxYear = null,
    isLoading,
    onYearChanged
}: YearSelectProps) => {
    const years = useMemo(() => {
        const _years: SelectOption[] = [];

        const year = maxYear ?? DEFAULT_MAX_YEAR;

        const _minYear = minYear ?? DEFAULT_MIN_YEAR;

        for (let i = year; i >= _minYear; i--) {
            const day = i.toString();

            _years.push({ label: day, value: day });
        }

        return _years;
    }, [minYear, maxYear]);

    const year = useMemo(() => {
        if (isNil(value)) {
            return null;
        }

        const matching = years.find(m => m.value === value.toString());

        return matching;
    }, [value]);

    return (
        <Select
            id={id}
            labelText={label}
            placeholder={placeholder ?? "Year"}
            value={year}
            values={years}
            onSelected={(option: SelectOption) => {
                const value = !isNil(option?.value) ? Number(option.value) : null;

                if (value !== Number(year?.value)) {
                    onYearChanged(value);
                }
            }}
            isClearable={isClearable}
            isSearchable={false}
            menuClassName={className}
            disabled={disabled}
            isLoading={isLoading}
        />
    );
};

export default YearSelect;
