import { LowflowSpawningFishParameters } from "../../types/plugin-parameter-types";
import { PluginParameters } from "../../types/plugin";
import { BaseParameterMerger } from "./base-parameter-merger";

export class LowflowSpawningFishParameterMerger extends BaseParameterMerger<LowflowSpawningFishParameters> {
    public merge(
        source: PluginParameters,
        defaultParameters: LowflowSpawningFishParameters
    ): LowflowSpawningFishParameters {
        return {
            lowflow: {
                flow_type: this.defaultTo(source?.lowflow?.flow_type, defaultParameters?.lowflow?.flow_type)
            },
            breeding_season: {
                season_type: this.defaultTo(
                    source?.breeding_season?.season_type,
                    defaultParameters?.breeding_season?.season_type
                ),
                season_start: this.defaultTo(
                    source?.breeding_season?.season_start,
                    defaultParameters?.breeding_season?.season_start
                ),
                season_end: this.defaultTo(
                    source?.breeding_season?.season_end,
                    defaultParameters?.breeding_season?.season_end
                ),
                temperature_threshold: this.defaultTo(
                    source?.breeding_season?.temperature_threshold,
                    defaultParameters?.breeding_season?.temperature_threshold
                )
            },
            life_stages_criteria_egg: {
                max_change: this.defaultTo(
                    source?.life_stages_criteria_egg?.max_change,
                    defaultParameters?.life_stages_criteria_egg?.max_change
                ),
                consecutive_days: this.defaultTo(
                    source?.life_stages_criteria_egg?.consecutive_days,
                    defaultParameters?.life_stages_criteria_egg?.consecutive_days
                )
            },
            life_stages_criteria_larvae: {
                consider_larvae_criteria: this.defaultTo(
                    source?.life_stages_criteria_larvae?.consider_larvae_criteria,
                    defaultParameters?.life_stages_criteria_larvae?.consider_larvae_criteria
                ),
                max_daily_change: this.defaultTo(
                    source?.life_stages_criteria_larvae?.max_daily_change,
                    defaultParameters?.life_stages_criteria_larvae?.max_daily_change
                ),
                consecutive_days: this.defaultTo(
                    source?.life_stages_criteria_larvae?.consecutive_days,
                    defaultParameters?.life_stages_criteria_larvae?.consecutive_days
                )
            },
            assessment: {
                yearly_agg: this.defaultTo(source?.assessment?.yearly_agg, defaultParameters?.assessment?.yearly_agg),
                temporal_agg: this.defaultTo(
                    source?.assessment?.temporal_agg,
                    defaultParameters?.assessment?.temporal_agg
                )
            },
            spatial_agg: {
                risk_categories: this.defaultTo(
                    source?.spatial_agg?.risk_categories,
                    defaultParameters?.spatial_agg?.risk_categories
                )
            }
        };
    }
}
