import React, { useEffect, useState } from "react";
import FormTextArea from "../../Components/FormTextArea/FormTextArea";
import { ScenarioModel } from "../../types/models";

interface ScenarioDescriptionProps {
    scenario: ScenarioModel;
    disabled: boolean;
    onChanged: (description: string) => void;
}

const ScenarioDescription = ({ scenario, disabled, onChanged }: ScenarioDescriptionProps) => {
    const [value, setValue] = useState<string>(scenario?.description ?? "");

    useEffect(() => {
        setValue(scenario?.description ?? "");
    }, [scenario?.id, scenario?.description]);

    return (
        <FormTextArea
            containerClassName="scenario-description"
            labelText="Scenario description"
            placeholder="Describe the system, locations, nodes, scenarios, etc."
            rows={5}
            name="description"
            value={value}
            disabled={disabled}
            onChange={e => setValue(e.target.value)}
            onBlur={e => {
                onChanged(e.target.value);
            }}
        />
    );
};

export default ScenarioDescription;
