import React, { useEffect, useState } from "react";
import EDSService from "../../Services/eds.service";
import { DataType } from "../../types/models";
import Label from "../Label";
import Select, { SelectOption } from "../Select/Select";
import { BOMConnectionSettings } from "./types";

interface ObservedDataModalBOMOptionsProps {
    dataType: DataType;
    settings: BOMConnectionSettings;
    onSettingsChanged: (nextSettings: BOMConnectionSettings) => void;
}

const SITES_LIMIT = 100;

const ObservedDataModalBOMOptions = ({ dataType, settings, onSettingsChanged }: ObservedDataModalBOMOptionsProps) => {
    const [sites, setSites] = useState<SelectOption[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleGaugeChanged = (nextGauge: string) => {
        if (nextGauge !== settings?.gauge) {
            onSettingsChanged({ ...settings, gauge: nextGauge });
        }
    };

    useEffect(() => {
        const getSites = async () => {
            try {
                setIsLoading(true);

                const _sites = await EDSService.getBomSites(dataType);

                const _siteOptions = _sites.map(s => {
                    return {
                        label: `${s.name} (${s.identifier})`,
                        value: s.identifier,
                        site: s
                    };
                });

                setSites(_siteOptions);
            } finally {
                setIsLoading(false);
            }
        };

        getSites();
    }, [dataType]);

    const filterSites = (search: string): SelectOption[] => {
        const matchingSites = sites.filter(s => {
            const normalisedSearch = search.toLowerCase();

            const normalisedIdentifier = s.site.identifier.toLowerCase();

            if (normalisedIdentifier.includes(normalisedSearch)) {
                return true;
            }

            const normalisedName = s.site.name.toLowerCase();

            if (normalisedName.includes(normalisedSearch)) {
                return true;
            }

            return false;
        });

        return matchingSites.length > SITES_LIMIT ? matchingSites.slice(0, 100) : matchingSites;
    };

    const loadOptions = (inputValue: string, callback: (options: SelectOption[]) => void) => {
        callback(filterSites(inputValue));
    };

    return (
        <div className="option-field">
            <Label>Gauge</Label>

            <Select
                isAsync={true}
                loadValues={loadOptions}
                value={sites.find(s => s.value === settings?.gauge) ?? null}
                placeholder="Search by gauge name or identifier"
                onSelected={(o: SelectOption) => handleGaugeChanged(o.value)}
                isLoading={isLoading}
            />
        </div>
    );
};

export default ObservedDataModalBOMOptions;
