import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { PluginFieldProps, PluginForm } from "./types";
import FormNumericInput from "../FormInput/FormNumericInput";
import Checkbox from "../Checkbox";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";

interface PluginParameterVelocityAdjustmentProps extends PluginFieldProps {
    onChange?: (field: string, value: Record<string, string | number | boolean>) => void;
}

const PluginParameterVelocityAdjustment = ({
    id,
    definition,
    field,
    control,
    disabled,
    onChange
}: PluginParameterVelocityAdjustmentProps) => {
    const { getValues, watch } = useFormContext<PluginForm>();

    const [watchedValues] = watch([`${definition.id}.${field.id}`]);

    const shouldAdjust: boolean = watchedValues?.should_adjust;

    const handleShouldAdjustChanged = (shouldAdjust: boolean) => {
        const currentDepth = getValues(`${definition.id}.${field.id}.depth`);
        const currentParticleSize = getValues(`${definition.id}.${field.id}.particle_size`);

        onChange("adjustment", {
            should_adjust: shouldAdjust,
            depth: currentDepth,
            particle_size: currentParticleSize
        });
    };

    const handleDepthChanged = (depth: number) => {
        const currentShouldAdjust = getValues(`${definition.id}.${field.id}.should_adjust`);
        const currentParticleSize = getValues(`${definition.id}.${field.id}.particle_size`);

        onChange("adjustment", {
            should_adjust: currentShouldAdjust,
            depth: depth,
            particle_size: currentParticleSize
        });
    };

    const handleParticleSizeChanged = (particle_size: number) => {
        const currentShouldAdjust = getValues(`${definition.id}.${field.id}.should_adjust`);
        const currentDepth = getValues(`${definition.id}.${field.id}.depth`);

        onChange("adjustment", {
            should_adjust: currentShouldAdjust,
            depth: currentDepth,
            particle_size: particle_size
        });
    };

    return (
        <div className="velocity-adjustment-parameter-field">
            <Controller
                control={control}
                name={`${definition.id}.${field.id}.should_adjust`}
                render={({ field: _field }) => {
                    return (
                        <Checkbox
                            id={id}
                            checked={_field.value}
                            disabled={disabled}
                            onChecked={handleShouldAdjustChanged}
                            label="Adjust to near bed velocity"
                            containerClassName="velocity-adjustment-checkbox"
                        />
                    );
                }}
            />

            <div className="velocity-adjustment-fields">
                <Controller
                    control={control}
                    name={`${definition.id}.${field.id}.depth`}
                    render={({ field: _field }) => {
                        return (
                            <FormNumericInput
                                id={`${id}.depth`}
                                value={shouldAdjust ? _field.value : null}
                                disabled={disabled || !shouldAdjust}
                                labelText="Depth"
                                labelClass="plugin-definition-field-label"
                                onChange={handleDepthChanged}
                                unit="m"
                                containerClassName="velocity-adjustment-field"
                                icon={faQuestionCircle}
                                iconTooltip="This value is typically ~2x particle size"
                            />
                        );
                    }}
                />

                <Controller
                    control={control}
                    name={`${definition.id}.${field.id}.particle_size`}
                    render={({ field: _field }) => {
                        return (
                            <FormNumericInput
                                id={`${id}.particle_size`}
                                value={shouldAdjust ? _field.value : null}
                                disabled={disabled || !shouldAdjust}
                                labelText="Particle Size"
                                labelClass="plugin-definition-field-label"
                                onChange={handleParticleSizeChanged}
                                unit="m"
                                containerClassName="velocity-adjustment-field"
                                icon={faQuestionCircle}
                                iconTooltip="Typical values for this parameter are: silt/clay (0.1mm), sand (1mm), gravel (10mm), cobble (0.05m) and boulder (0.3m)"
                            />
                        );
                    }}
                />
            </div>
        </div>
    );
};

export default PluginParameterVelocityAdjustment;
