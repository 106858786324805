import React from "react";
import { FolderModel } from "../../types/models";
import ERPModal from "../../Components/Modals/ERPModal";
import { Breadcrumb, FileSystemView, Stack } from "../../Components/FileSystem/types";
import { useFileSystem } from "./useFileSystem";
import FileSystemBreadcrumbs from "./FileSystemBreadcrumbs";
import FileSystemModalFolder from "./FileSystemModalFolder";
import FileSystemModalFooter from "./FileSystemModalFooter";
import FileSystemModalSystem from "./FileSystemModalSystem";
import FileSystemModalModel from "./FileSystemModalModel";
import LoadingIndicator from "../../Components/LoadingIndicator";

import "./FileSystem.scss";

export enum SelectionMode {
    FOLDER = "folder"
}

export interface FileSystemModalOptions {
    title: string;
    folders: FolderModel[];
    fromStack: Stack<FileSystemView>;
    mode?: SelectionMode;
    disabledFolders?: Set<string>;
}

interface FileSystemModalProps extends FileSystemModalOptions {
    show: boolean;
    onClose: () => void;
    onFolderSelected: (folder: FolderModel) => void;
}

const FileSystemModal = ({
    title,
    folders,
    fromStack,
    show,
    mode = SelectionMode.FOLDER,
    disabledFolders = new Set<string>(),
    onFolderSelected,
    onClose
}: FileSystemModalProps) => {
    const { view, breadcrumbs, navigateBack, navigateBackToFolder, navigateToFolder, isLoading } = useFileSystem(
        folders,
        {
            fromStack: fromStack,
            alwaysLoadCollectionsOnBackNavigation: false
        }
    );

    const handleBreadcrumbSelected = (breadcrumb: Breadcrumb): void => {
        navigateBackToFolder(breadcrumb.index);
    };

    const handleBackNavigation = () => {
        navigateBack();
    };

    const handleSelect = () => {
        if (mode === SelectionMode.FOLDER) {
            onFolderSelected(view.folder);
        }
    };

    const canSelect = mode === SelectionMode.FOLDER;

    return (
        <ERPModal
            show={show}
            onClose={onClose}
            title={title}
            size="lg"
            backdropClassName="fs-modal-backdrop"
            bodyClassName="fs-modal-body"
            footerClassName="fs-modal-footer"
            footer={<FileSystemModalFooter canSelect={canSelect} onSelect={handleSelect} onCancel={onClose} />}
        >
            <div className="fs-modal">
                <div className="fs-modal-header">
                    <FileSystemBreadcrumbs
                        breadcrumbs={breadcrumbs}
                        currentViewIndex={view?.index}
                        onBack={handleBackNavigation}
                        onBreadcrumbSelected={handleBreadcrumbSelected}
                    />
                </div>

                <div className="fs-modal-items">
                    {isLoading && <LoadingIndicator centered className="fs-modal-loader" />}

                    {!isLoading && (
                        <>
                            {view.subFolders.map(folder => {
                                return (
                                    <FileSystemModalFolder
                                        key={folder.id}
                                        folder={folder}
                                        disabled={disabledFolders.has(folder.id)}
                                        onSelected={navigateToFolder}
                                    />
                                );
                            })}

                            {view.systems.map(system => {
                                return (
                                    <FileSystemModalSystem
                                        key={system.id}
                                        system={system}
                                        disabled={mode === SelectionMode.FOLDER}
                                    />
                                );
                            })}

                            {view.models.map(model => {
                                return (
                                    <FileSystemModalModel
                                        key={model.id}
                                        model={model}
                                        disabled={mode === SelectionMode.FOLDER}
                                    />
                                );
                            })}
                        </>
                    )}
                </div>
            </div>
        </ERPModal>
    );
};

export default FileSystemModal;
