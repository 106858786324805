import { FishBarriersAndConnectivityParameters } from "../../types/plugin-parameter-types";
import { PluginParameters } from "../../types/plugin";
import { BaseParameterMerger } from "./base-parameter-merger";

export class FishBarriersAndConnectivityParameterMerger extends BaseParameterMerger<FishBarriersAndConnectivityParameters> {
    public merge(
        source: PluginParameters,
        defaultParameters: FishBarriersAndConnectivityParameters
    ): FishBarriersAndConnectivityParameters {
        return {
            global_parameters: {
                swim_speed: this.defaultTo(
                    source?.global_parameters?.swim_speed,
                    defaultParameters?.global_parameters?.swim_speed
                ),
                downstream_end_amtd: this.defaultTo(
                    source?.global_parameters?.downstream_end_amtd,
                    defaultParameters?.global_parameters?.downstream_end_amtd
                ),
                upstream_season_end: this.defaultTo(
                    source?.global_parameters?.upstream_season_end,
                    defaultParameters?.global_parameters?.upstream_season_end
                ),
                upstream_start_amtd: this.defaultTo(
                    source?.global_parameters?.upstream_start_amtd,
                    defaultParameters?.global_parameters?.upstream_start_amtd
                ),
                downstream_season_end: this.defaultTo(
                    source?.global_parameters?.downstream_season_end,
                    defaultParameters?.global_parameters?.downstream_season_end
                ),
                upstream_season_start: this.defaultTo(
                    source?.global_parameters?.upstream_season_start,
                    defaultParameters?.global_parameters?.upstream_season_start
                ),
                downstream_season_start: this.defaultTo(
                    source?.global_parameters?.downstream_season_start,
                    defaultParameters?.global_parameters?.downstream_season_start
                ),
                consider_upstream_movement: this.defaultTo(
                    source?.global_parameters?.consider_upstream_movement,
                    defaultParameters?.global_parameters?.consider_upstream_movement
                ),
                consider_downstream_movement: this.defaultTo(
                    source?.global_parameters?.consider_downstream_movement,
                    defaultParameters?.global_parameters?.consider_downstream_movement
                )
            },
            data: {
                amtd: this.defaultTo(source?.data?.amtd, defaultParameters?.data?.amtd)
            },
            velocity_parameters: {
                slope: this.defaultTo(
                    source?.velocity_parameters?.slope,
                    defaultParameters?.velocity_parameters?.slope
                ),
                width: this.defaultTo(
                    source?.velocity_parameters?.width,
                    defaultParameters?.velocity_parameters?.width
                ),
                roughness: this.defaultTo(
                    source?.velocity_parameters?.roughness,
                    defaultParameters?.velocity_parameters?.roughness
                )
            },
            upstream_requirements: {
                drownout: this.defaultTo(
                    source?.upstream_requirements?.drownout,
                    defaultParameters?.upstream_requirements?.drownout
                ),
                duration: this.defaultTo(
                    source?.upstream_requirements?.duration,
                    defaultParameters?.upstream_requirements?.duration
                )
            },
            downstream_requirements: {
                drownout: this.defaultTo(
                    source?.downstream_requirements?.drownout,
                    defaultParameters?.downstream_requirements?.drownout
                ),
                duration: this.defaultTo(
                    source?.downstream_requirements?.duration,
                    defaultParameters?.downstream_requirements?.duration
                )
            }
        };
    }
}
