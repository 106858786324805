import { RifflesParameters } from "../../types/plugin-parameter-types";
import { PluginParameters } from "../../types/plugin";
import { BaseParameterMerger } from "./base-parameter-merger";

export class RifflesParameterMerger extends BaseParameterMerger<RifflesParameters> {
    public merge(source: PluginParameters, defaultParameters: RifflesParameters): RifflesParameters {
        return {
            velocity: {
                calculation: this.defaultTo(source?.velocity?.calculation, defaultParameters?.velocity?.calculation),
                cross_section: this.defaultTo(
                    source?.velocity?.cross_section,
                    defaultParameters?.velocity?.cross_section
                ),
                adjustment: this.defaultTo(source?.velocity?.adjustment, defaultParameters?.velocity?.adjustment)
            },
            season: {
                season_start: this.defaultTo(source?.season?.season_start, defaultParameters?.season?.season_start),
                season_end: this.defaultTo(source?.season?.season_end, defaultParameters?.season?.season_end)
            },
            event: {
                spell_type: this.defaultTo(source?.event?.spell_type, defaultParameters?.event?.spell_type),
                independence: this.defaultTo(source?.event?.independence, defaultParameters?.event?.independence),
                min_spell_length: this.defaultTo(
                    source?.event?.min_spell_length,
                    defaultParameters?.event?.min_spell_length
                ),
                max_spell_length: this.defaultTo(
                    source?.event?.max_spell_length,
                    defaultParameters?.event?.max_spell_length
                )
            },
            event_assessment: {
                assessment_metric: this.defaultTo(
                    source?.event_assessment?.assessment_metric,
                    defaultParameters?.event_assessment?.assessment_metric
                )
            },
            assessment: {
                yearly_conversion: this.defaultTo(
                    source?.assessment?.yearly_conversion,
                    defaultParameters?.assessment?.yearly_conversion
                ),
                temporal_agg: this.defaultTo(
                    source?.assessment?.temporal_agg,
                    defaultParameters?.assessment?.temporal_agg
                )
            },
            spatial_agg: {
                risk_categories: this.defaultTo(
                    source?.spatial_agg?.risk_categories,
                    defaultParameters?.spatial_agg?.risk_categories
                )
            }
        };
    }
}
