import { faPlay } from "@fortawesome/pro-solid-svg-icons";
import React from "react";
import ResultsWarning from "./ResultsWarning";
import { Warnings } from "./types";

interface ResultsWarningsProps {
    warnings: Warnings[];
    onRerun: () => void;
    onFetchResults: () => void;
}

const ResultsWarnings = ({ warnings, onRerun, onFetchResults }: ResultsWarningsProps) => {
    return (
        <div>
            {warnings.map((w, index) => {
                switch (w) {
                    case "results_changed":
                        return (
                            <ResultsWarning
                                message="The results below are outdated"
                                buttonText="Reload with latest results"
                                onUpdate={onFetchResults}
                                key={index}
                            />
                        );

                    case "scenario_changed":
                        return (
                            <ResultsWarning
                                message="The selected scenarios are outdated"
                                buttonText="Rerun with the latest configuration"
                                onUpdate={onRerun}
                                icon={faPlay}
                                key={index}
                            />
                        );

                    case "empty_results":
                        return (
                            <ResultsWarning
                                message="The run results have no data (Due to either the run period, or empty base data)"
                                buttonText="Rerun for a different period"
                                onUpdate={onRerun}
                                icon={faPlay}
                                key={index}
                            />
                        );
                }
            })}
        </div>
    );
};

export default ResultsWarnings;
