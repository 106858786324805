import Explorer from "../Scenes/Explorer";
import Model from "../Scenes/Model";
import System from "../Scenes/System";
import ManageAccount from "../Scenes/ManageAccount";
import Register from "../Scenes/Register";
import { ERPRouteMap } from "./types";

const AppRoutes: ERPRouteMap = Object.freeze({
    Home: {
        route: "/explorer",
        scene: Explorer,
        exact: true,
        authenticated: true,
        admin: false
    },
    System: {
        route: "/system/:id",
        scene: System,
        exact: true,
        authenticated: true,
        admin: false
    },
    Model: {
        route: "/model/:id",
        scene: Model,
        exact: true,
        authenticated: true,
        admin: false
    },
    ManageAccount: {
        route: "/manage-account",
        scene: ManageAccount,
        exact: true,
        authenticated: true,
        admin: false
    },
    Register: {
        route: "/register",
        scene: Register,
        exact: true,
        authenticated: false,
        admin: false
    }
});

export default AppRoutes;
