import { DataType } from "../../types/models";

export enum ObservedDataSourceTypes {
    SILO = "silo",
    BOM = "bom"
}

export interface ObservedDataSettings {
    source: ObservedDataSourceTypes;
    dataType?: DataType;
    period?: ObservedDataPeriodSettings;
    settings?: ObservedDataConnectionSettings;
}

export interface ObservedDataPeriodSettings {
    startYear: number;
    endYear: number;
}

export type ObservedDataConnectionSettings = SILOConnectionSettings | BOMConnectionSettings;

export interface SILOConnectionSettings {
    latitude: number;
    longitude: number;
}

export interface BOMConnectionSettings {
    gauge: string;
}
