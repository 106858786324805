import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { isNil } from "lodash";
import ObservedDataModal from "./ObservedDataModal";
import clsx from "clsx";

import "./FileSelector.scss";
import { ObservedDataSettings } from "./types";

interface FileSelectorProps {
    accept?: string | string[];
    children?: React.ReactNode;
    allowMultiple?: boolean;
    fileOnly?: boolean;
    disabled?: boolean;
    className?: string;
    onFileSelected: (file: File | File[], isObserved?: boolean, observationSettings?: ObservedDataSettings) => void;
    onObservedDataFileSelected?: (file: File, connectionSettings: ObservedDataSettings) => void;
}

const FileSelectorDrag = ({
    accept,
    children,
    allowMultiple = false,
    fileOnly = false,
    disabled,
    className,
    onFileSelected,
    onObservedDataFileSelected
}: FileSelectorProps) => {
    const [showDataConnectionModal, setShowDataConnectionModal] = useState<boolean>(false);

    const handleFileSelected = (files: File[]) => {
        if (files?.length === 0) {
            return;
        }

        if (allowMultiple) {
            onFileSelected(files);
        } else {
            onFileSelected(files[0]);
        }
    };

    const handleObservedDataSaved = (file: File, observationSettings: ObservedDataSettings) => {
        setShowDataConnectionModal(false);

        onObservedDataFileSelected(file, observationSettings);
    };

    return (
        <Dropzone disabled={disabled} noClick noKeyboard onDrop={handleFileSelected} accept={accept}>
            {({ getRootProps, getInputProps, open }) => (
                <div {...getRootProps()} className={className}>
                    {!isNil(children) && children}

                    {isNil(children) && (
                        <div className={clsx("dropzone-container", { disabled: disabled })}>
                            {!fileOnly && (
                                <span className="dropzone-label">
                                    <span>Drag files here,</span>
                                    <span className="clickable-label" onClick={open}>
                                        browse
                                    </span>
                                    <span>or</span>
                                    <span className="clickable-label" onClick={() => setShowDataConnectionModal(true)}>
                                        use observed data
                                    </span>
                                </span>
                            )}

                            {fileOnly && (
                                <span className="dropzone-label">
                                    <span>Drag files here or</span>
                                    <span className="clickable-label" onClick={open}>
                                        browse
                                    </span>
                                </span>
                            )}

                            {showDataConnectionModal && (
                                <ObservedDataModal
                                    show={showDataConnectionModal}
                                    onSave={handleObservedDataSaved}
                                    onClose={() => setShowDataConnectionModal(false)}
                                />
                            )}
                        </div>
                    )}

                    <input {...getInputProps()} />
                </div>
            )}
        </Dropzone>
    );
};

export default FileSelectorDrag;
