import React from "react";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import ERPIcon from "../../Components/ERPIcon";

const NoPluginWarning = () => {
    return (
        <div className="no-plugin-warning">
            <ERPIcon icon={faExclamationTriangle} size="lg" />
            You must select a plugin to continue.
        </div>
    );
};

export default NoPluginWarning;
