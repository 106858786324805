import React from "react";
import Button from "../../Components/Button";
import { TaskModel } from "../../types/models";
import { isNil } from "lodash";
import TaskStatusDisplay from "../../Components/TaskStatusDisplay/TaskStatusDisplay";
import { WaterholeComputationType } from "../../types/requests";

interface PluginWaterholeCalibrateModalFooterProps {
    task: TaskModel;
    isRunning: boolean;
    onSaveClose: () => void;
    onClose: () => void;
    onRun: (runType: WaterholeComputationType) => void;
}

const PluginWaterholeCalibrateModalFooter = ({
    task,
    isRunning,
    onSaveClose,
    onClose,
    onRun
}: PluginWaterholeCalibrateModalFooterProps) => {
    return (
        <div className="waterhole-optimisation-modal-footer">
            <div className="button-group">
                <Button
                    className="control-button"
                    onClick={() => {
                        onRun(WaterholeComputationType.RUN_ONCE);
                    }}
                    disabled={isRunning}
                >
                    Run Once
                </Button>
                <Button
                    className="control-button"
                    onClick={() => {
                        onRun(WaterholeComputationType.OPTIMISE);
                    }}
                    disabled={isRunning}
                >
                    Optimise
                </Button>
                {!isNil(task) && <TaskStatusDisplay task={task} label="Computation" showProgress={true} />}
            </div>
            <div className="button-group">
                <Button variant="grey" onClick={onClose} disabled={isRunning}>
                    Close
                </Button>
                <Button variant="secondary" onClick={onSaveClose} disabled={isRunning}>
                    Apply modified paramaters to site and close
                </Button>
            </div>
        </div>
    );
};

export default PluginWaterholeCalibrateModalFooter;
