import { KNOWN_HTTP_STATUS_CODES } from "../api/base.api";
import { isNil } from "lodash";
import { DataType, LocationModel, ScenarioLocationDataModel, ScenarioModel, SystemModel } from "../types/models";
import { ApiResult } from "../types/plugin";
import {
    BulkCopySystemRequest,
    BulkMoveSystemRequest,
    BulkSystemDeleteRequest,
    CopySystemRequest,
    CreateLocationRequest,
    CreateScenarioRequest,
    MoveSystemRequest,
    UpdateDataFileRequest,
    UpdateLocationRequest,
    UpdateScenarioRequest,
    UpdateSystemNameRequest,
    UpdateSystemRequest
} from "../types/requests";
import API from "../api/data.api";
import { URL } from "../Constants/api";
import { ObservedDataSettings } from "../Components/FileSelector/types";

export default class SystemService {
    public static async getSystem(id: string): Promise<SystemModel> {
        const system = await API.get<SystemModel>(`${URL.SYSTEMS}/${id}`);
        return system;
    }

    public static async getSystems(): Promise<SystemModel[]> {
        const systems = await API.get<SystemModel[]>(`${URL.SYSTEMS}`);
        return systems;
    }

    public static async updateSystem(id: string, request: UpdateSystemRequest): Promise<SystemModel> {
        const system = await API.patch<SystemModel>(`${URL.SYSTEMS}/${id}`, request);
        return system;
    }

    public static async updateSystemName(id: string, request: UpdateSystemNameRequest): Promise<SystemModel> {
        const system = await API.patch<SystemModel>(`${URL.SYSTEMS}/${id}/name`, request);
        return system;
    }

    public static async deleteSystem(id: string): Promise<void> {
        await API.delete<void>(`${URL.SYSTEMS}/${id}`);
    }

    public static async deleteSystems(ids: string[]): Promise<void> {
        const request: BulkSystemDeleteRequest = {
            systems: ids ?? []
        };

        await API.delete<void>(`${URL.SYSTEMS}`, request);
    }

    public static async copySystem(id: string, destinationFolderId: string): Promise<SystemModel> {
        const request: CopySystemRequest = {
            systemId: id,
            toFolder: destinationFolderId
        };

        const copiedSystem = await API.post<SystemModel>(`${URL.SYSTEMS}/copy`, request);

        return copiedSystem;
    }

    public static async copySystems(ids: string[], destinationFolderId: string): Promise<SystemModel[]> {
        const request: BulkCopySystemRequest = {
            systemIds: ids,
            toFolder: destinationFolderId
        };

        const copiedSystems = await API.post<SystemModel[]>(`${URL.SYSTEMS}/copy/bulk`, request);

        return copiedSystems;
    }

    public static async moveSystem(id: string, destinationFolderId: string): Promise<SystemModel> {
        const request: MoveSystemRequest = {
            systemId: id,
            toFolder: destinationFolderId
        };

        const movedSystem = await API.post<SystemModel>(`${URL.SYSTEMS}/move`, request);

        return movedSystem;
    }

    public static async moveSystems(ids: string[], destinationFolderId: string): Promise<SystemModel[]> {
        const request: BulkMoveSystemRequest = {
            systemIds: ids,
            toFolder: destinationFolderId
        };

        const movedSystems = await API.post<SystemModel[]>(`${URL.SYSTEMS}/move/bulk`, request);

        return movedSystems;
    }

    public static async createScenario(systemId: string, request: CreateScenarioRequest): Promise<ScenarioModel> {
        const scenario = await API.post<ScenarioModel>(`${URL.SYSTEMS}/${systemId}/scenarios`, request);
        return scenario;
    }

    public static async updateScenario(
        systemId: string,
        scenarioId: string,
        request: UpdateScenarioRequest
    ): Promise<ScenarioModel> {
        const scenario = await API.patch<ScenarioModel>(`${URL.SYSTEMS}/${systemId}/scenarios/${scenarioId}`, request);
        return scenario;
    }

    public static async deleteScenario(systemId: string, scenarioId: string): Promise<void> {
        await API.delete<void>(`${URL.SYSTEMS}/${systemId}/scenarios/${scenarioId}`);
    }

    public static async getScenarios(id: string): Promise<ScenarioModel[]> {
        const scenarios = await API.get<ScenarioModel[]>(`${URL.SYSTEMS}/${id}/scenarios`);
        return scenarios;
    }

    public static async createLocation(
        systemId: string,
        request: CreateLocationRequest
    ): Promise<ApiResult<LocationModel>> {
        try {
            const location = await API.post<LocationModel>(`${URL.SYSTEMS}/${systemId}/locations`, request);
            return { data: location, success: true, failure: null };
        } catch (error) {
            if (API.isAxiosError(error)) {
                if (error.response.status === KNOWN_HTTP_STATUS_CODES.BAD_REQUEST) {
                    const apiError = error.response.data.error;

                    return {
                        data: null,
                        success: false,
                        failure: { code: apiError.code, message: apiError.message }
                    };
                }
            } else {
                throw error;
            }
        }
    }

    public static async updateLocation(
        systemId: string,
        locationId: string,
        request: UpdateLocationRequest
    ): Promise<LocationModel> {
        const location = await API.patch<LocationModel>(`${URL.SYSTEMS}/${systemId}/locations/${locationId}`, request);
        return location;
    }

    public static async getLocations(id: string): Promise<LocationModel[]> {
        const locations = await API.get<LocationModel[]>(`${URL.SYSTEMS}/${id}/locations`);
        return locations;
    }

    public static async deleteLocation(systemId: string, locationId: string): Promise<void> {
        await API.delete<void>(`${URL.SYSTEMS}/${systemId}/locations/${locationId}`);
    }

    public static async getScenarioData(systemId: string, scenarioId: string): Promise<ScenarioLocationDataModel[]> {
        const data = await API.get<ScenarioLocationDataModel[]>(
            `${URL.SYSTEMS}/${systemId}/scenarios/${scenarioId}/data`
        );

        return data;
    }

    public static async getData(systemId: string): Promise<ScenarioLocationDataModel[]> {
        const data = await API.get<ScenarioLocationDataModel[]>(`${URL.SYSTEMS}/${systemId}/data`);

        return data;
    }

    public static async uploadScenarioLocationFile(
        systemId: string,
        scenarioId: string,
        locationId: string,
        file: File,
        dataType: DataType,
        isObserved?: boolean,
        observationSettings?: ObservedDataSettings,
        onProgress?: (progress: number) => void
    ): Promise<ScenarioLocationDataModel> {
        const formData = new FormData();

        formData.append("file", file);
        formData.append("location", locationId);

        if (!isNil(dataType)) {
            formData.append("dataType", dataType);
        }

        if (!isNil(isObserved)) {
            formData.append("isObserved", String(isObserved));
        }

        if (!isNil(observationSettings)) {
            formData.append("observationSettings", JSON.stringify(observationSettings));
        }

        const data = await API.postFormData<ScenarioLocationDataModel>(
            `${URL.SYSTEMS}/${systemId}/scenarios/${scenarioId}/data`,
            formData,
            {
                "Content-Type": "multipart/form-data"
            },
            onProgress
        );

        return data;
    }

    public static async updateScenarioLocationDataOptions(
        systemId: string,
        scenarioId: string,
        dataFileId: string,
        request: UpdateDataFileRequest
    ): Promise<ScenarioLocationDataModel> {
        const data = await API.patch<ScenarioLocationDataModel>(
            `${URL.SYSTEMS}/${systemId}/scenarios/${scenarioId}/data/${dataFileId}/options`,
            request
        );

        return data;
    }

    public static async updateScenarioLocationDataFile(
        systemId: string,
        scenarioId: string,
        dataFileId: string,
        file: File,
        dataType?: DataType,
        isObserved?: boolean,
        observationSettings?: ObservedDataSettings,
        onProgress?: (progress: number) => void
    ): Promise<ScenarioLocationDataModel> {
        const formData = new FormData();

        formData.append("file", file);

        if (!isNil(dataType)) {
            formData.append("dataType", dataType);
        }

        if (!isNil(isObserved)) {
            formData.append("isObserved", String(isObserved));
        }

        if (!isNil(observationSettings)) {
            formData.append("observationSettings", JSON.stringify(observationSettings));
        }

        const data = await API.patchFormData<ScenarioLocationDataModel>(
            `${URL.SYSTEMS}/${systemId}/scenarios/${scenarioId}/data/${dataFileId}/file`,
            formData,
            {
                "Content-Type": "multipart/form-data"
            },
            onProgress
        );

        return data;
    }

    public static async deleteScenarioLocationData(
        systemId: string,
        scenarioId: string,
        dataFileId: string
    ): Promise<void> {
        await API.delete<void>(`${URL.SYSTEMS}/${systemId}/scenarios/${scenarioId}/data/${dataFileId}`);
    }
}
