import React, { useEffect, useMemo, useState } from "react";
import LoadingIndicator from "../../Components/LoadingIndicator";
import { ModelLocationModel, ModelModel } from "../../types/models";
import { isNilOrEmpty, sleep } from "../../utils/utils";
import ModelSystemLocation from "./ModelSystemLocation";
import ModalSystemLocationEmpty from "./ModalSystemLocationEmpty";
import ModelService from "../../Services/model.service";
import ModelLocationAllSelectionButton from "./ModelLocationAllSelectionButton";

interface ModelSystemLocationsProps {
    model: ModelModel;
    readOnly: boolean;
}

const ModelSystemLocations = ({ model, readOnly }: ModelSystemLocationsProps) => {
    const [locations, setLocations] = useState<ModelLocationModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleLocationStateChanged = async (location: ModelLocationModel, enabled: boolean) => {
        const next = locations.map(l => {
            if (l.id === location.id) {
                return {
                    ...l,
                    enabled: enabled
                };
            }

            return l;
        });

        setLocations(next);

        await ModelService.updateLocationState(location.modelId, location.id, enabled);
    };

    const handleAllLocationStateChanged = async (enabled: boolean) => {
        const next = locations.map(l => {
            return {
                ...l,
                enabled: enabled
            };
        });

        setLocations(next);

        await ModelService.updateAllLocationsState(model.id, enabled);
    };

    useEffect(() => {
        const getLocations = async (modelId: string) => {
            try {
                setIsLoading(true);

                const _locations = await ModelService.getLocations(modelId);

                setLocations(_locations);
            } finally {
                await sleep(250);

                setIsLoading(false);
            }
        };

        getLocations(model.id);
    }, [model.id]);

    const allSelected = useMemo(() => {
        /**
         * If this ends up being a performance bottle nect due to the number of locations, then
         * we can replace the locations array with a Set<T> to represent the selected locations only.
         * Then this can be changed to set.size === locations.length and each individual ModelSystemLocation
         * can have an enabled property to is filled by checking if a location id is present in the Set<T>
         */
        return locations.every(l => l.enabled);
    }, [locations]);

    const hasLocations = !isNilOrEmpty(locations);

    return (
        <div className="model-location-settings--system">
            <div className="locations-header">
                <h5>Locations</h5>

                <span>Select locations within the system to apply model to and configure any local settings</span>
            </div>

            {isLoading && <LoadingIndicator centered size="lg" className="location-loader" />}

            {!isLoading && !hasLocations && <ModalSystemLocationEmpty />}

            {!isLoading && hasLocations && (
                <div className="model-system-locations">
                    <ModelLocationAllSelectionButton
                        disabled={readOnly}
                        allSelected={allSelected}
                        onSelectAll={handleAllLocationStateChanged}
                    />

                    {locations.map(location => {
                        return (
                            <ModelSystemLocation
                                key={location.id}
                                location={location}
                                defaultParameters={model.parameters}
                                readOnly={readOnly}
                                plugin={model.plugin}
                                onLocationStateChanged={handleLocationStateChanged}
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default ModelSystemLocations;
