import { KNOWN_HTTP_STATUS_CODES } from "../api/base.api";
import { OptimisationParameters } from "../Components/Plugin/types";
import {
    Attachment,
    TaskModel,
    DataType,
    ModelExploreScenario,
    ModelLocationModel,
    ModelLocationSiteDataModel,
    ModelLocationSiteModel,
    ModelModel,
    ModelScenarioModel,
    ModelScenarioRunModel,
    ModelVersionModel,
    ModelLocationSiteOptimisationModel,
    OptimisationPluginId,
    ModelLocationSiteOptimisationDataModel,
    ScenarioLocationDataModel,
    ModelBaseScenario
} from "../types/models";
import { ParameterUpdateResult, PluginParameters } from "../types/plugin";
import {
    BulkCopyModelRequest,
    BulkModelDeleteRequest,
    BulkMoveModelRequest,
    CopyModelRequest,
    CreateModelLocationSiteDataResponse,
    CreateModelLocationSiteOptimisationDataResponse,
    CreateModelLocationSiteRequest,
    CreateModelLocationSiteResponse,
    LocationStateChangedResponse,
    ModelLocationSiteDataDeleteResponse,
    ModelLocationSiteDeleteResponse,
    ModelLocationSiteOptimisationDataDeleteResponse,
    MoveModelRequest,
    SetModelPluginRequest,
    SetModelPluginResponse,
    SetModelSystemRequest,
    SetModelSystemResponse,
    UpdateDataFileRequest,
    UpdateModelLocationSiteDataResponse,
    UpdateModelLocationSiteOptimisationDataResponse,
    UpdateModelLocationSiteRequest,
    UpdateModelNameRequest,
    UpdateModelPluginResponse,
    UpdateModelRequest,
    UpdateModelRunSettingsRequest,
    UpdateOptimisationParametersRequest,
    UpdateParametersRequest,
    UpdateParametersResponse
} from "../types/requests";
import API from "../api/data.api";
import { URL } from "../Constants/api";
import { ObservedDataSettings } from "../Components/FileSelector/types";
import { isNil } from "lodash";

export default class ModelService {
    public static async getModel(id: string): Promise<ModelModel> {
        const model = await API.get<ModelModel>(`${URL.MODELS}/${id}`);
        return model;
    }

    public static async getAttachments(id: string): Promise<Attachment[]> {
        const attachments = await API.get<Attachment[]>(`${URL.MODELS}/${id}/attachments`);
        return attachments;
    }

    public static async updateModel(id: string, request: UpdateModelRequest): Promise<ModelModel> {
        const model = await API.patch<ModelModel>(`${URL.MODELS}/${id}`, request);
        return model;
    }

    public static async updateModelName(id: string, request: UpdateModelNameRequest): Promise<ModelModel> {
        const model = await API.patch<ModelModel>(`${URL.MODELS}/${id}/name`, request);
        return model;
    }

    public static async updateModelRunSettings(
        id: string,
        request: UpdateModelRunSettingsRequest
    ): Promise<ModelModel> {
        const model = await API.patch<ModelModel>(`${URL.MODELS}/${id}/run-settings`, request);
        return model;
    }

    public static async setPlugin(id: string, pluginId: string): Promise<PluginParameters> {
        const request: SetModelPluginRequest = {
            pluginId: pluginId
        };

        const response = await API.post<SetModelPluginResponse>(`${URL.MODELS}/${id}/plugin`, request);

        return response.parameters;
    }

    public static async updatePlugin(id: string): Promise<PluginParameters> {
        const response = await API.patch<UpdateModelPluginResponse>(`${URL.MODELS}/${id}/plugin/update`);

        return response.parameters;
    }

    public static async updateParameters(id: string, parameters: PluginParameters): Promise<ParameterUpdateResult> {
        try {
            const request: UpdateParametersRequest = {
                parameters: parameters
            };

            const response = await API.patch<UpdateParametersResponse>(`${URL.MODELS}/${id}/parameters`, request);

            return { parameters: response.parameters, version: response.version, success: true, failure: null };
        } catch (error) {
            if (API.isAxiosError(error)) {
                if (error.response.status === KNOWN_HTTP_STATUS_CODES.BAD_REQUEST) {
                    const apiError = error.response.data.error;

                    return {
                        parameters: null,
                        version: null,
                        success: false,
                        failure: { code: apiError.code, message: apiError.message }
                    };
                }
            } else {
                throw error;
            }
        }
    }

    public static async setSystem(id: string, systemId: string): Promise<string> {
        const request: SetModelSystemRequest = {
            systemId: systemId
        };

        const response = await API.post<SetModelSystemResponse>(`${URL.MODELS}/${id}/system`, request);

        return response.version;
    }

    public static async deleteModel(id: string): Promise<void> {
        await API.delete<void>(`${URL.MODELS}/${id}`);
    }

    public static async deleteModels(ids: string[]): Promise<void> {
        const request: BulkModelDeleteRequest = {
            models: ids ?? []
        };

        await API.delete<void>(`${URL.MODELS}`, request);
    }

    public static async copyModel(id: string, destinationFolderId: string): Promise<ModelModel> {
        const request: CopyModelRequest = {
            modelId: id,
            toFolder: destinationFolderId
        };

        const copiedModel = await API.post<ModelModel>(`${URL.MODELS}/copy`, request);

        return copiedModel;
    }

    public static async copyModels(ids: string[], destinationFolderId: string): Promise<ModelModel[]> {
        const request: BulkCopyModelRequest = {
            modelIds: ids,
            toFolder: destinationFolderId
        };

        const copiedModels = await API.post<ModelModel[]>(`${URL.MODELS}/copy/bulk`, request);

        return copiedModels;
    }

    public static async moveModel(id: string, destinationFolderId: string): Promise<ModelModel> {
        const request: MoveModelRequest = {
            modelId: id,
            toFolder: destinationFolderId
        };

        const movedModel = await API.post<ModelModel>(`${URL.MODELS}/move`, request);

        return movedModel;
    }

    public static async moveModels(ids: string[], destinationFolderId: string): Promise<ModelModel[]> {
        const request: BulkMoveModelRequest = {
            modelIds: ids,
            toFolder: destinationFolderId
        };

        const movedModels = await API.post<ModelModel[]>(`${URL.MODELS}/move/bulk`, request);

        return movedModels;
    }

    public static async getLocations(id: string): Promise<ModelLocationModel[]> {
        const locations = await API.get<ModelLocationModel[]>(`${URL.MODELS}/${id}/locations`);
        return locations;
    }

    public static async updateLocationParameters(
        id: string,
        locationId: string,
        parameters: PluginParameters
    ): Promise<ParameterUpdateResult> {
        try {
            const request: UpdateParametersRequest = {
                parameters: parameters
            };

            const response = await API.patch<UpdateParametersResponse>(
                `${URL.MODELS}/${id}/locations/${locationId}/parameters`,
                request
            );

            return { parameters: response.parameters, version: response.version, success: true, failure: null };
        } catch (error) {
            if (API.isAxiosError(error)) {
                if (error.response.status === KNOWN_HTTP_STATUS_CODES.BAD_REQUEST) {
                    const apiError = error.response.data.error;

                    return {
                        parameters: null,
                        version: null,
                        success: false,
                        failure: { code: apiError.code, message: apiError.message }
                    };
                }
            } else {
                throw error;
            }
        }
    }

    public static async updateLocationState(modelId: string, locationId: string, enabled: boolean): Promise<string> {
        const endpoint = enabled ? "enable" : "disable";

        const response = await API.patch<LocationStateChangedResponse>(
            `${URL.MODELS}/${modelId}/locations/${locationId}/${endpoint}`
        );

        return response.version;
    }

    public static async updateAllLocationsState(modelId: string, enabled: boolean): Promise<string> {
        const endpoint = enabled ? "enable" : "disable";

        const response = await API.patch<LocationStateChangedResponse>(
            `${URL.MODELS}/${modelId}/locations/${endpoint}`
        );

        return response.version;
    }

    public static async createModelLocationSite(
        modelId: string,
        locationId: string,
        request: CreateModelLocationSiteRequest
    ): Promise<CreateModelLocationSiteResponse> {
        const response = await API.post<CreateModelLocationSiteResponse>(
            `${URL.MODELS}/${modelId}/locations/${locationId}/sites`,
            request
        );

        return response;
    }

    public static async updateModelLocationSite(
        modelId: string,
        locationId: string,
        siteId: string,
        request: UpdateModelLocationSiteRequest
    ): Promise<ModelLocationSiteModel> {
        const site = await API.patch<ModelLocationSiteModel>(
            `${URL.MODELS}/${modelId}/locations/${locationId}/sites/${siteId}`,
            request
        );
        return site;
    }

    public static async getModelLocationSites(modelId: string, locationId: string): Promise<ModelLocationSiteModel[]> {
        const sites = await API.get<ModelLocationSiteModel[]>(`${URL.MODELS}/${modelId}/locations/${locationId}/sites`);

        return sites;
    }

    public static async getModelLocationSiteOptimisations(
        modelId: string,
        locationId: string,
        siteId: string
    ): Promise<ModelLocationSiteOptimisationModel[]> {
        const optimisations = await API.get<ModelLocationSiteOptimisationModel[]>(
            `${URL.MODELS}/${modelId}/locations/${locationId}/sites/${siteId}/optimisations`
        );

        return optimisations;
    }

    public static async getModelLocationSiteOptimisationByOptimisationPluginId(
        modelId: string,
        locationId: string,
        siteId: string,
        optimisationPluginId: OptimisationPluginId
    ): Promise<ModelLocationSiteOptimisationModel> {
        const optimisations = await API.get<ModelLocationSiteOptimisationModel>(
            `${URL.MODELS}/${modelId}/locations/${locationId}/sites/${siteId}/optimisations/${optimisationPluginId}`
        );

        return optimisations;
    }

    public static async updateModelLocationSiteParameters(
        modelId: string,
        locationId: string,
        siteId: string,
        parameters: PluginParameters
    ): Promise<ParameterUpdateResult> {
        try {
            const request: UpdateParametersRequest = {
                parameters: parameters
            };

            const response = await API.patch<UpdateParametersResponse>(
                `${URL.MODELS}/${modelId}/locations/${locationId}/sites/${siteId}/parameters`,
                request
            );

            return { parameters: response.parameters, version: response.version, success: true, failure: null };
        } catch (error) {
            if (API.isAxiosError(error)) {
                if (error.response.status === KNOWN_HTTP_STATUS_CODES.BAD_REQUEST) {
                    const apiError = error.response.data.error;

                    return {
                        parameters: null,
                        version: null,
                        success: false,
                        failure: { code: apiError.code, message: apiError.message }
                    };
                }
            } else {
                throw error;
            }
        }
    }

    public static async updateModelLocationSiteOptimisationParameters(
        modelId: string,
        locationId: string,
        siteId: string,
        optimisationId: string,
        parameters: OptimisationParameters
    ): Promise<ParameterUpdateResult> {
        try {
            const request: UpdateOptimisationParametersRequest = {
                parameters: parameters
            };

            const response = await API.patch<UpdateParametersResponse>(
                `${URL.MODELS}/${modelId}/locations/${locationId}/sites/${siteId}/optimisations/${optimisationId}/parameters`,
                request
            );

            return { parameters: response.parameters, version: response.version, success: true, failure: null };
        } catch (error) {
            if (API.isAxiosError(error)) {
                if (error.response.status === KNOWN_HTTP_STATUS_CODES.BAD_REQUEST) {
                    const apiError = error.response.data.error;

                    return {
                        parameters: null,
                        version: null,
                        success: false,
                        failure: { code: apiError.code, message: apiError.message }
                    };
                }
            } else {
                throw error;
            }
        }
    }

    public static async deleteModelLocationSite(modelId: string, locationId: string, siteId: string): Promise<string> {
        const response = await API.delete<ModelLocationSiteDeleteResponse>(
            `${URL.MODELS}/${modelId}/locations/${locationId}/sites/${siteId}`
        );

        return response.version;
    }

    public static async uploadAttachment(
        modelId: string,
        file: File,
        onProgress: (progress: number) => void
    ): Promise<Attachment> {
        const formData = new FormData();

        formData.append("file", file);

        const data = await API.postFormData<Attachment>(
            `${URL.MODELS}/${modelId}/attachments`,
            formData,
            {
                "Content-Type": "multipart/form-data"
            },
            onProgress
        );

        return data;
    }

    public static async deleteAttachment(modelId: string, attachmentId: string): Promise<void> {
        await API.delete<void>(`${URL.MODELS}/${modelId}/attachments/${attachmentId}`);
    }

    public static async getVersion(id: string): Promise<ModelVersionModel> {
        const version = await API.get<ModelVersionModel>(`${URL.MODELS}/${id}/version`);
        return version;
    }

    public static async getScenarios(id: string): Promise<ModelScenarioModel[]> {
        const scenarios = await API.get<ModelScenarioModel[]>(`${URL.MODELS}/${id}/scenarios`);
        return scenarios;
    }

    public static async getModelResults(modelId: string): Promise<ModelBaseScenario[]> {
        const results = await API.get<ModelExploreScenario[]>(`${URL.MODELS}/${modelId}/results`);
        return results;
    }

    public static async getModelLocationSiteData(
        modelId: string,
        locationId: string,
        siteId: string
    ): Promise<ModelLocationSiteDataModel[]> {
        const data = await API.get<ModelLocationSiteDataModel[]>(
            `${URL.MODELS}/${modelId}/locations/${locationId}/sites/${siteId}/data`
        );
        return data;
    }

    public static async getModelLocationSiteOptimisationData(
        modelId: string,
        locationId: string,
        siteId: string,
        optimisationId: string
    ): Promise<ModelLocationSiteOptimisationDataModel[]> {
        const data = await API.get<ModelLocationSiteOptimisationDataModel[]>(
            `${URL.MODELS}/${modelId}/locations/${locationId}/sites/${siteId}/optimisations/${optimisationId}/data`
        );
        return data;
    }

    public static async getModelLocationSitesData(
        modelId: string,
        locationId: string
    ): Promise<ModelLocationSiteDataModel[]> {
        const data = await API.get<ModelLocationSiteDataModel[]>(
            `${URL.MODELS}/${modelId}/locations/${locationId}/sites/data`
        );

        return data;
    }

    public static async uploadModelLocationSiteData(
        modelId: string,
        locationId: string,
        siteId: string,
        file: File,
        dataType: DataType,
        isObserved?: boolean,
        observationSettings?: ObservedDataSettings,
        onProgress?: (progress: number) => void
    ): Promise<ModelLocationSiteDataModel> {
        const formData = new FormData();

        formData.append("file", file);

        if (!isNil(dataType)) {
            formData.append("dataType", dataType);
        }

        if (!isNil(isObserved)) {
            formData.append("isObserved", String(isObserved));
        }

        if (!isNil(observationSettings)) {
            formData.append("observationSettings", JSON.stringify(observationSettings));
        }

        const data = await API.postFormData<CreateModelLocationSiteDataResponse>(
            `${URL.MODELS}/${modelId}/locations/${locationId}/sites/${siteId}/data`,
            formData,
            {
                "Content-Type": "multipart/form-data"
            },
            onProgress
        );

        return data.data;
    }

    public static async uploadModelLocationSiteOptimisationData(
        modelId: string,
        locationId: string,
        siteId: string,
        optimisationId: string,
        file: File,
        dataType: DataType,
        isObserved?: boolean,
        observationSettings?: ObservedDataSettings,
        onProgress?: (progress: number) => void
    ): Promise<ModelLocationSiteOptimisationDataModel> {
        const formData = new FormData();

        formData.append("file", file);

        if (!isNil(dataType)) {
            formData.append("dataType", dataType);
        }

        if (!isNil(isObserved)) {
            formData.append("isObserved", String(isObserved));
        }

        if (!isNil(observationSettings)) {
            formData.append("observationSettings", JSON.stringify(observationSettings));
        }

        const data = await API.postFormData<CreateModelLocationSiteOptimisationDataResponse>(
            `${URL.MODELS}/${modelId}/locations/${locationId}/sites/${siteId}/optimisations/${optimisationId}/data`,
            formData,
            {
                "Content-Type": "multipart/form-data"
            },
            onProgress
        );

        return data.data;
    }

    public static async updateModelLocationSiteDataOptions(
        modelId: string,
        locationId: string,
        siteId: string,
        dataFileId: string,
        dataType: DataType
    ): Promise<ModelLocationSiteDataModel> {
        const request: UpdateDataFileRequest = {
            dataType: dataType
        };

        const data = await API.patch<UpdateModelLocationSiteDataResponse>(
            `${URL.MODELS}/${modelId}/locations/${locationId}/sites/${siteId}/data/${dataFileId}/options`,
            request
        );

        return data.data;
    }

    public static async updateModelLocationSiteDataFile(
        modelId: string,
        locationId: string,
        siteId: string,
        dataFileId: string,
        file: File,
        dataType: DataType,
        isObserved?: boolean,
        observationSettings?: ObservedDataSettings,
        onProgress?: (progress: number) => void
    ): Promise<ModelLocationSiteDataModel> {
        const formData = new FormData();

        formData.append("file", file);

        if (!isNil(dataType)) {
            formData.append("dataType", dataType);
        }

        if (!isNil(isObserved)) {
            formData.append("isObserved", String(isObserved));
        }

        if (!isNil(observationSettings)) {
            formData.append("observationSettings", JSON.stringify(observationSettings));
        }

        const data = await API.patchFormData<UpdateModelLocationSiteDataResponse>(
            `${URL.MODELS}/${modelId}/locations/${locationId}/sites/${siteId}/data/${dataFileId}/file`,
            formData,
            {
                "Content-Type": "multipart/form-data"
            },
            onProgress
        );

        return data.data;
    }

    public static async updateModelLocationSiteOptimisationDataOptions(
        modelId: string,
        locationId: string,
        siteId: string,
        optimisationId: string,
        dataFileId: string,
        dataType: DataType
    ): Promise<ModelLocationSiteOptimisationDataModel> {
        const request: UpdateDataFileRequest = {
            dataType: dataType
        };

        const data = await API.patch<UpdateModelLocationSiteOptimisationDataResponse>(
            `${URL.MODELS}/${modelId}/locations/${locationId}/sites/${siteId}/optimisations/${optimisationId}/data/${dataFileId}/options`,
            request
        );

        return data.data;
    }

    public static async updateModelLocationSiteOptimisationDataFile(
        modelId: string,
        locationId: string,
        siteId: string,
        optimisationId: string,
        dataFileId: string,
        file: File,
        dataType: DataType,
        isObserved?: boolean,
        observationSettings?: ObservedDataSettings,
        onProgress?: (progress: number) => void
    ): Promise<ModelLocationSiteOptimisationDataModel> {
        const formData = new FormData();

        formData.append("file", file);

        if (!isNil(dataType)) {
            formData.append("dataType", dataType);
        }

        if (!isNil(isObserved)) {
            formData.append("isObserved", String(isObserved));
        }

        if (!isNil(observationSettings)) {
            formData.append("observationSettings", JSON.stringify(observationSettings));
        }

        const data = await API.patchFormData<UpdateModelLocationSiteOptimisationDataResponse>(
            `${URL.MODELS}/${modelId}/locations/${locationId}/sites/${siteId}/optimisations/${optimisationId}/data/${dataFileId}/file`,
            formData,
            {
                "Content-Type": "multipart/form-data"
            },
            onProgress
        );

        return data.data;
    }

    public static async deleteModelLocationSiteData(
        modelId: string,
        locationId: string,
        siteId: string,
        dataFileId: string
    ): Promise<ModelLocationSiteDataDeleteResponse> {
        const data = await API.delete<ModelLocationSiteDataDeleteResponse>(
            `${URL.MODELS}/${modelId}/locations/${locationId}/sites/${siteId}/data/${dataFileId}`
        );
        return data;
    }

    public static async deleteModelLocationSiteOptimisationData(
        modelId: string,
        locationId: string,
        siteId: string,
        optimisationId: string,
        dataFileId: string
    ): Promise<ModelLocationSiteOptimisationDataDeleteResponse> {
        const data = await API.delete<ModelLocationSiteOptimisationDataDeleteResponse>(
            `${URL.MODELS}/${modelId}/locations/${locationId}/sites/${siteId}/optimisations/${optimisationId}/data/${dataFileId}`
        );
        return data;
    }

    public static async getComputationStatus(id: string): Promise<TaskModel[]> {
        const tasks = await API.get<TaskModel[]>(`${URL.MODELS}/${id}/computation/status`);
        return tasks;
    }

    public static async getModelLocationSiteOptimisationStatus(
        modelId: string,
        locationId: string,
        siteId: string,
        optimisationId: string
    ): Promise<TaskModel> {
        const task = await API.get<TaskModel>(
            `${URL.MODELS}/${modelId}/locations/${locationId}/sites/${siteId}/optimisations/${optimisationId}/computation/status`
        );
        return task;
    }

    public static async getComputationRuns(id: string): Promise<ModelScenarioRunModel[]> {
        const runs = await API.get<ModelScenarioRunModel[]>(`${URL.MODELS}/${id}/computation/runs`);
        return runs;
    }

    public static async getModelDefaultScenarioData(id: string): Promise<ScenarioLocationDataModel[]> {
        const data = await API.get<ScenarioLocationDataModel[]>(`${URL.MODELS}/${id}/scenarios/default-data`);
        return data;
    }
}
