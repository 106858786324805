import React from "react";
import Switch from "../../Components/Switch";
import Tooltip from "../../Components/Tooltip";

export interface ModelExploreDownloadsSettingsOptionProps {
    isChecked: boolean;
    tooltip: string;
    label: string;
    onChecked: (nextIsChecked: boolean) => void;
}

const ModelExploreDownloadsSettingsOption = ({
    isChecked,
    tooltip,
    label,
    onChecked
}: ModelExploreDownloadsSettingsOptionProps) => {
    return (
        <Tooltip tooltipContent={tooltip}>
            <div className="downloads-settings-option">
                <Switch checked={isChecked} onChecked={onChecked} />
                <span>{label}</span>
            </div>
        </Tooltip>
    );
};

export default ModelExploreDownloadsSettingsOption;
