import React from "react";
import { ScenarioModel, SystemModel } from "../../types/models";
import ERPModal from "../../Components/Modals/ERPModal";
import { ModalProps } from "../../Components/Modals/types";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import FormActions from "../../Components/FormActions";
import Button from "../../Components/Button";
import LoadingButton from "../../Components/LoadingButton";
import FormInput from "../../Components/FormInput/FormInput";
import { CreateScenarioRequest, UpdateScenarioRequest } from "../../types/requests";
import { Toast } from "../../Components/Toast";
import SystemService from "../../Services/system.service";
import FormTextArea from "../../Components/FormTextArea/FormTextArea";
import Checkbox from "../../Components/Checkbox";
import { isNil } from "lodash";

interface ScenarioModalProps extends ModalProps {
    context: ScenarioModalContext;
    onScenarioCreated: (scenario: ScenarioModel) => void;
    onScenarioUpdated: (scenario: ScenarioModel) => void;
}

export interface ScenarioModalContext {
    system: SystemModel;
    scenario?: ScenarioModel;
}

const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    description: yup.string().optional().nullable(),
    isDefault: yup.boolean().optional()
});

interface ScenarioForm {
    name: string;
    description: string;
    isDefault: boolean;
}

const ScenarioModal = ({ context, show, onClose, onScenarioCreated, onScenarioUpdated }: ScenarioModalProps) => {
    const isEdit = !isNil(context.scenario);
    const title = isEdit ? "Edit scenario" : "Create scenario";

    const {
        control,
        register,
        handleSubmit,
        setValue,
        formState: { errors, isDirty, isSubmitting }
    } = useForm<ScenarioForm>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: context.scenario?.name ?? "",
            description: context.scenario?.description ?? "",
            isDefault: context.scenario?.isDefault ?? false
        }
    });

    const handleFormSubmit = async (data: ScenarioForm) => {
        if (!isEdit) {
            const request: CreateScenarioRequest = {
                name: data.name,
                description: data.description,
                isDefault: data.isDefault ?? false
            };

            const newScenario = await SystemService.createScenario(context.system.id, request);

            Toast.success(`Successfully created scenario ${newScenario.name}`);

            onScenarioCreated(newScenario);
        } else {
            const request: UpdateScenarioRequest = {
                name: data.name,
                description: data.description,
                isDefault: data.isDefault ?? false
            };

            const updatedScenario = await SystemService.updateScenario(context.system.id, context.scenario.id, request);

            Toast.success(`Successfully updated scenario`);

            onScenarioUpdated(updatedScenario);
        }

        onClose();
    };

    return (
        <ERPModal title={title} show={show} onClose={onClose}>
            <div className="scenario-modal">
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <FormInput
                        labelText="Name"
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Scenario name"
                        register={register("name")}
                        error={errors?.name}
                        disabled={isSubmitting}
                    />

                    <FormTextArea
                        labelText="Description"
                        id="description"
                        name="description"
                        placeholder="Describe the system, locations, nodes, scenarios, etc."
                        rows={5}
                        register={register("description")}
                        error={errors?.description}
                        disabled={isSubmitting}
                    />

                    <Controller
                        control={control}
                        name="isDefault"
                        render={({ field }) => {
                            return (
                                <Checkbox
                                    label="Default scenario"
                                    checked={field.value}
                                    disabled={isSubmitting}
                                    onChecked={checked => {
                                        setValue("isDefault", checked, { shouldDirty: true });
                                    }}
                                />
                            );
                        }}
                    />

                    <FormActions>
                        <Button variant="secondary" disabled={isSubmitting} onClick={onClose}>
                            Cancel
                        </Button>

                        <LoadingButton disabled={!isDirty} isLoading={isSubmitting} variant="primary" type="submit">
                            {!isEdit ? "Create" : "Update"}
                        </LoadingButton>
                    </FormActions>
                </form>
            </div>
        </ERPModal>
    );
};

export default ScenarioModal;
