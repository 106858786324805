import Select, { SelectOption } from "../../Components/Select/Select";
import React, { useMemo } from "react";
import {
    ModelExploreIntermediateResultType,
    ModelExploreScenario,
    ScenarioExplorerSelectOption
} from "../../types/models";
import { ExploreTabType } from "./ExploreTabs";
import Humanize from "humanize-plus";
import { getAvaliableIntermediateResultTypes } from "../../utils/explorer.utils";
import { isNilOrEmpty } from "../../utils/utils";
import { isNil } from "lodash";
import { useModel } from "./ModelContext";

interface ModelExploreScenarioSelectProps {
    activeTab: ExploreTabType;
    intermediateResultType: ModelExploreIntermediateResultType;
    setIntermediateResultType: (intermediateResultType: ModelExploreIntermediateResultType) => void;
}

interface IntermediateResultTypeSelectOption extends SelectOption {
    intermediateResultType: ModelExploreIntermediateResultType;
}

const ModelExploreScenarioSelect = ({
    activeTab,
    intermediateResultType,
    setIntermediateResultType
}: ModelExploreScenarioSelectProps) => {
    const { explorerScenarios, updateExplorerScenarios } = useModel();

    const selectedScenarios = explorerScenarios.filter(s => s.isSelected);
    const baselineScenario = explorerScenarios.find(s => s.isBaseline);

    const hasLoadedIntermediate = selectedScenarios.every(s => s.hasLoadedIntermediate);

    const intermediateResultTypes = useMemo(() => {
        if (isNilOrEmpty(selectedScenarios) || !hasLoadedIntermediate) {
            return;
        }

        const allTypes = getAvaliableIntermediateResultTypes(selectedScenarios);

        if (isNilOrEmpty(allTypes)) {
            return;
        }

        if (isNil(intermediateResultType)) {
            const defaultType = allTypes.find(d => d.id === "success") ?? allTypes[0];
            setIntermediateResultType(defaultType);
        }

        return allTypes;
    }, [selectedScenarios, hasLoadedIntermediate]);

    const scenarioOptions = useMemo(() => {
        return explorerScenarios?.map(s => {
            const option: ScenarioExplorerSelectOption = {
                value: s.id,
                label: s.name,
                scenario: s
            };

            return option;
        });
    }, [explorerScenarios.length]);

    const selectedOptions = useMemo(() => {
        return selectedScenarios?.map(s => {
            const option: ScenarioExplorerSelectOption = {
                scenario: s,
                value: s.id,
                label: s.name
            };

            return option;
        });
    }, [selectedScenarios.length]);

    const intermediateResultTypeOptions = useMemo(() => {
        return intermediateResultTypes?.map(r => {
            const option: IntermediateResultTypeSelectOption = {
                value: r.id,
                label: `${r.label} (${Humanize.capitalize(r.scale)})`,
                intermediateResultType: r
            };

            return option;
        });
    }, [intermediateResultTypes]);

    const selectedBaseline: ScenarioExplorerSelectOption = {
        value: baselineScenario?.id,
        label: baselineScenario?.name,
        scenario: baselineScenario
    };

    const selectedIntermediateResultType: IntermediateResultTypeSelectOption = useMemo(() => {
        if (isNil(intermediateResultType)) {
            return;
        }

        return {
            value: intermediateResultType?.id,
            label: `${intermediateResultType?.label} (${Humanize.capitalize(intermediateResultType?.scale)})`,
            intermediateResultType: intermediateResultType
        };
    }, [intermediateResultType]);

    const handleScenarioSelect = (newSelection: ModelExploreScenario[]) => {
        const nextScenarios = [
            ...explorerScenarios.map(s => {
                return { ...s, isSelected: newSelection.some(ss => ss.id === s.id) ? true : false };
            })
        ];
        updateExplorerScenarios(nextScenarios);
    };

    const handleBaselineSelect = (newSelection: ModelExploreScenario) => {
        const nextScenarios = [
            ...explorerScenarios.map(s => {
                return { ...s, isBaseline: newSelection.id === s.id ? true : false };
            })
        ];
        updateExplorerScenarios(nextScenarios);
    };

    const handleIntermediateResultTypeSelect = (newSelection: ModelExploreIntermediateResultType) => {
        setIntermediateResultType(newSelection);
    };

    const showScenarioSelector =
        activeTab === ExploreTabType.SUMMARY ||
        activeTab === ExploreTabType.INTERMEDIATE ||
        activeTab === ExploreTabType.OPPORTUNITIES;

    const showBaselineScenarioSelect = activeTab === ExploreTabType.PRESELECT || activeTab === ExploreTabType.SUMMARY;

    return (
        <div className="explore-scenario-select">
            {showScenarioSelector && (
                <Select
                    labelText="Select scenarios to explore"
                    menuClassName="scenario-select"
                    values={scenarioOptions}
                    value={selectedOptions}
                    isMulti
                    onSelected={(options: ScenarioExplorerSelectOption[]) => {
                        const selectedValues = options?.map(o => o.scenario) ?? [];

                        handleScenarioSelect(selectedValues);
                    }}
                />
            )}

            {showBaselineScenarioSelect && (
                <Select
                    labelText="Select scenario as baseline"
                    menuClassName="scenario-select"
                    values={scenarioOptions}
                    value={selectedBaseline}
                    onSelected={(option: ScenarioExplorerSelectOption) => {
                        handleBaselineSelect(option.scenario);
                    }}
                />
            )}

            {activeTab === ExploreTabType.INTERMEDIATE && (
                <Select
                    labelText="Select intermediate results to explore"
                    menuClassName="scenario-select"
                    values={intermediateResultTypeOptions}
                    value={selectedIntermediateResultType}
                    onSelected={(option: IntermediateResultTypeSelectOption) => {
                        handleIntermediateResultTypeSelect(option.intermediateResultType);
                    }}
                />
            )}
        </div>
    );
};

export default ModelExploreScenarioSelect;
