import React, { useEffect, useMemo } from "react";
import { ModalProps } from "../../Components/Modals/types";
import { useState } from "react";
import {
    PluginForm,
    WaterholeCalibrationBaseParameterValues,
    WaterholeCalibrationParameters,
    WaterholeCalibrationResults
} from "./types";
import { useConfirmation } from "../../Components/ConfirmDialog/ConfirmationContext";
import { isNil, merge } from "lodash";
import PluginWaterholeCalibrateModalFooter from "./PluginWaterholeCalibrateModalFooter";
import {
    ModelLocationSiteOptimisationModel,
    OptimisationPluginId,
    RunPeriodModel,
    TaskModel,
    TaskStatus
} from "../../types/models";
import PluginWaterholeCalibrationModalBody from "./PluginWaterholeCalibrationModalBody";
import { PluginParameters } from "../../types/plugin";
import { useFormContext } from "react-hook-form";
import { useModel } from "../../Scenes/Model/ModelContext";
import { OptimisationComputationRequest, WaterholeComputationType } from "../../types/requests";
import ComputationService from "../../Services/computation.service";
import { Toast } from "../../Components/Toast";
import ModelService from "../../Services/model.service";
import WaterholeOptimisationModal from "../../Components/Modals/WaterholeOptisationModal";
import { WaterholeParameters } from "../../types/plugin-parameter-types";
import { useInterval } from "../../Hooks/useInterval";

interface PluginWaterholeCalibrateModalProps extends ModalProps {
    id: string;
    locationId: string;
    siteId: string;
    siteName: string;
    disabled?: boolean;
    onValuesChanged?: (values: PluginParameters) => void;
}

const copyValues = (values: PluginParameters): PluginParameters => {
    const valuesCopy = {};
    for (const section of Object.keys(values)) {
        valuesCopy[section] = {};
        for (const field of Object.keys(values[section])) {
            valuesCopy[section][field] = values[section][field];
        }
    }
    return valuesCopy;
};

const POLLING_TIME_MS = 500;

const PluginWaterholeCalibrateModal = ({
    show,
    locationId,
    siteId,
    siteName,
    disabled,
    onValuesChanged,
    onClose
}: PluginWaterholeCalibrateModalProps) => {
    const { getValues } = useFormContext<PluginForm>();
    const { model } = useModel();
    const [waterholeCalibrationParameters, setWaterholeCalibrationParameters] =
        useState<WaterholeCalibrationParameters>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [optimisation, setOptimisation] = useState<ModelLocationSiteOptimisationModel>(null);
    const [computationTask, setComputationTask] = useState<TaskModel>(null);

    const isTaskActive =
        computationTask?.status === TaskStatus.RUNNING || computationTask?.status === TaskStatus.PENDING;

    const fetchOptimisation = async () => {
        try {
            const _optimisation = await ModelService.getModelLocationSiteOptimisationByOptimisationPluginId(
                model.id,
                locationId,
                siteId,
                OptimisationPluginId.WATERHOLE_CALIBRATION
            );

            setOptimisation(_optimisation);

            const optimisationTask = await ModelService.getModelLocationSiteOptimisationStatus(
                model.id,
                locationId,
                siteId,
                _optimisation.id
            );

            setComputationTask(optimisationTask);

            const calibrationParamaters: WaterholeCalibrationParameters = {
                settings: {
                    population: _optimisation.parameters?.settings?.population,
                    generations: _optimisation.parameters?.settings?.generations,
                    scaling: _optimisation.parameters?.settings?.scaling,
                    fit_statistic: _optimisation.parameters?.settings?.fit_statistic
                },
                season: {
                    start: _optimisation.parameters?.season?.start,
                    end: _optimisation.parameters?.season?.end
                },
                predictors: {
                    optimiseIds: _optimisation.parameters?.predictors?.optimiseIds
                }
            };

            setWaterholeCalibrationParameters(calibrationParamaters);

            const parameters = _optimisation?.result?.parameterResults as WaterholeCalibrationResults;

            if (!isNil(parameters?.predictors)) {
                const next = {
                    ...baseParameterValues,
                    values: (baseParameterValues.values = merge({}, baseParameterValues.values, parameters?.predictors))
                };
                setBaseParameterValues(next);
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        fetchOptimisation();
    }, []);

    const siteValues: Partial<WaterholeParameters> = useMemo(() => {
        const values: Partial<WaterholeParameters> = {
            evaporation: {
                scaling: getValues("evaporation.scaling")
            },
            seepage: {
                rate: getValues("seepage.rate")
            },
            rainfall: {
                area: getValues("rainfall.area"),
                max_infiltration: getValues("rainfall.max_infiltration")
            },
            groundwater: {
                area: getValues("groundwater.area"),
                inflow: getValues("groundwater.inflow"),
                loss_to_deep_drainage: getValues("groundwater.loss_to_deep_drainage")
            },
            extraction: {
                commence_pumping_depth: getValues("extraction.commence_pumping_depth"),
                cease_pumping_depth: getValues("extraction.cease_pumping_depth"),
                extraction_rate: getValues("extraction.extraction_rate"),
                max_annual_take: getValues("extraction.max_annual_take"),
                start_month: getValues("extraction.start_month"),
                ctf: getValues("extraction.ctf"),
                lag: getValues("extraction.lag")
            }
        };

        return values;
    }, []);

    const [baseParameterValues, setBaseParameterValues] = useState<WaterholeCalibrationBaseParameterValues>({
        values: copyValues(siteValues),
        isModified: false
    });

    const handleBaseParameterValuesChanged = (newValues: Partial<WaterholeParameters>) => {
        const next: WaterholeCalibrationBaseParameterValues = { values: newValues, isModified: true };
        setBaseParameterValues(next);
    };

    const confirm = useConfirmation();

    const handleSave = () => {
        onValuesChanged(baseParameterValues.values);
        onClose();
    };

    const handleNoSaveClose = async () => {
        if (baseParameterValues.isModified) {
            const result = await confirm({
                title: "Parameter Changes",
                description: "You are about to close without applying your modified parameters to the site. Continue?"
            });

            if (!result.success) {
                return;
            }
        }

        onClose();
    };

    const checkNullRunPeriod = (period: RunPeriodModel): boolean => {
        if (isNil(period?.start) && isNil(period?.end)) {
            return true;
        }
        return false;
    };

    const handleRun = async (runType: WaterholeComputationType) => {
        const modifiedParameters: PluginParameters = { ...baseParameterValues.values };

        modifiedParameters["optimisation"] = {
            population: waterholeCalibrationParameters.settings.population,
            generations: waterholeCalibrationParameters.settings.generations,
            scaling: waterholeCalibrationParameters.settings.scaling,
            predictors: waterholeCalibrationParameters.predictors.optimiseIds,
            run_type: runType,
            season: checkNullRunPeriod(waterholeCalibrationParameters.season)
                ? null
                : waterholeCalibrationParameters.season,
            fit_statistic: waterholeCalibrationParameters.settings.fit_statistic
        };

        const request: OptimisationComputationRequest = {
            modelId: model.id,
            parameters: modifiedParameters,
            locationId: locationId,
            siteId: siteId,
            optimisationId: optimisation.id
        };

        const result = await ComputationService.computeOptimisation(request);

        if (!result?.success) {
            Toast.error(result.failure.message);
            return;
        }

        setComputationTask(result.task);
    };

    useInterval(
        async () => {
            const nextTask = await ModelService.getModelLocationSiteOptimisationStatus(
                model.id,
                locationId,
                siteId,
                optimisation.id
            );

            switch (nextTask.status) {
                case TaskStatus.COMPLETED: {
                    fetchOptimisation();
                    Toast.success("Successfully computed optimisation");
                    break;
                }

                case TaskStatus.FAILED: {
                    Toast.error(nextTask.error.message);
                    break;
                }
            }

            setComputationTask(nextTask);
        },
        isTaskActive ? POLLING_TIME_MS : null
    );

    const handleParametersChanged = async (parameters: WaterholeCalibrationParameters) => {
        const result = await ModelService.updateModelLocationSiteOptimisationParameters(
            model.id,
            locationId,
            optimisation.modelLocationSiteId,
            optimisation.id,
            parameters
        );

        if (!result.success) {
            Toast.error(result.failure.message);
            return;
        }

        setWaterholeCalibrationParameters(parameters);
    };

    return (
        <WaterholeOptimisationModal
            footer={
                <PluginWaterholeCalibrateModalFooter
                    task={computationTask}
                    isRunning={isTaskActive}
                    onSaveClose={handleSave}
                    onClose={handleNoSaveClose}
                    onRun={handleRun}
                />
            }
            show={show}
            title={`${siteName} - Calibrate`}
            task={computationTask}
            handleClose={handleNoSaveClose}
            isLoading={isLoading}
        >
            <PluginWaterholeCalibrationModalBody
                siteValues={siteValues}
                baseParameterValues={baseParameterValues.values}
                calibrationParamaters={waterholeCalibrationParameters}
                results={optimisation?.result}
                modelId={model.id}
                locationId={locationId}
                siteId={siteId}
                optimisationId={optimisation?.id}
                disabled={disabled}
                onParametersChanged={handleParametersChanged}
                onBaseParameterValuesChanged={handleBaseParameterValuesChanged}
            />
        </WaterholeOptimisationModal>
    );
};

export default PluginWaterholeCalibrateModal;
