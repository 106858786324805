import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import { faDownload } from "@fortawesome/pro-solid-svg-icons";
import { ChartOptions } from "chart.js";
import ERPLineTimeseriesChart from "../../Components/Charts/ERPLineTimeseriesChart";
import LoadingIndicator from "../../Components/LoadingIndicator";
import { Toast } from "../../Components/Toast";
import TooltipIcon from "../../Components/TooltipIcon";
import { forEach, isNil } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import AzureBlobService from "../../Services/azure.blob.service";
import OptimisationDataService from "../../Services/optimisation.data.service";
import ZipGenerator, { ZipFileInfo } from "../../Services/zip-generator";
import { ERPTimerseriesChartData, OptimisationResult } from "../../types/models";
import { isNilOrEmpty } from "../../utils/utils";

interface PluginWaterholeRunToEmptyModalChartProps {
    id: string;
    resultFiles: OptimisationResult[];
    onSetDate: (date: Date) => void;
}

const CHART_OPTIONS: ChartOptions<"line"> = {
    maintainAspectRatio: false,
    normalized: true,
    interaction: {
        intersect: true,
        mode: "index"
    },
    layout: {
        padding: 5
    },
    plugins: {
        tooltip: {
            enabled: true,
            position: "nearest"
        },
        legend: {
            display: true,
            position: "top"
        },
        decimation: {
            enabled: true
        }
    },
    animation: false,
    scales: {
        x: {
            title: {
                display: true,
                text: "Date"
            },
            type: "timeseries",
            time: {
                minUnit: "day",
                displayFormats: {
                    year: "YYYY",
                    month: "MMM YYYY",
                    day: "DD MMM YYYY"
                },
                tooltipFormat: "DD/MM/YYYY"
            },
            grid: {
                display: false
            }
        },
        y: {
            type: "linear",
            display: true,
            position: "left"
        },
        y1: {
            type: "linear",
            display: true,
            position: "right",
            grid: {
                drawOnChartArea: false
            },
            ticks: {
                font: {
                    size: 16
                }
            },
            title: {
                display: true,
                text: "Flow"
            }
        }
    },
    parsing: {
        xAxisKey: "date",
        yAxisKey: "value"
    }
};

const PluginWaterholeRunToEmptyModalChart = ({
    id,
    resultFiles,
    onSetDate
}: PluginWaterholeRunToEmptyModalChartProps) => {
    const [chartData, setChartData] = useState<ERPTimerseriesChartData>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchAndSetChartData = async () => {
            const fileData = await OptimisationDataService.parseChartData(resultFiles);

            const data = [
                {
                    label: "Modelled Depth",
                    data: [],
                    backgroundColor: "#00994D",
                    pointRadius: 0,
                    borderWidth: 2,
                    borderColor: "#00994D",
                    yAxisID: "y",
                    pointHitRadius: 5
                },
                {
                    label: "Evaporation",
                    data: [],
                    backgroundColor: "#E24999",
                    pointRadius: 0,
                    borderWidth: 2,
                    borderColor: "#E24999",
                    yAxisID: "y",
                    pointHitRadius: 5
                }
            ];

            forEach(fileData.rows, day => {
                const date = moment(day.date, "DD/MM/YYYY").toDate();

                data[0].data.push({ date: date, value: parseFloat(day.modelled_depth) });
                data[1].data.push({ date: date, value: parseFloat(day.evaporation) });
            });

            setChartData(data);
        };

        fetchAndSetChartData();
    }, [resultFiles]);

    const handleClickOnPoint = (pointIndex: number) => {
        const date = chartData[0].data[pointIndex].date;

        if (isNil(date)) {
            return;
        }

        onSetDate(date);
    };

    const handleDownload = async () => {
        if (isNilOrEmpty(resultFiles)) {
            Toast.error("No results avaliable for download");
            return;
        }

        try {
            setIsLoading(true);
            const generator = new ZipGenerator();

            const files: ZipFileInfo[] = await Promise.all(
                resultFiles.map(async f => {
                    const data = await AzureBlobService.downloadBlobAsFile(f.blobId, f.name);
                    return { name: OptimisationDataService.getOptimisationResultFileNameForDownload(f), file: data };
                })
            );

            const blob = await generator.generate(files);

            AzureBlobService.downloadToDisk(blob, `waterhole_run_to_empty_results.zip`);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="run-to-empty-chart">
            <TooltipIcon
                tooltip="Use shift+drag to zoom
                Double click to reset zoom
                Pan with the mouse"
                icon={faQuestionCircle}
                size={"2x"}
                className="chart-tooltip"
            />
            {!isNilOrEmpty(resultFiles) && (
                <>
                    {!isLoading && (
                        <TooltipIcon
                            tooltip="Download Results"
                            icon={faDownload}
                            size={"2x"}
                            className="chart-tooltip"
                            onClick={handleDownload}
                        />
                    )}

                    {isLoading && <LoadingIndicator className="chart-tooltip-loading" />}
                </>
            )}
            <ERPLineTimeseriesChart
                id={id}
                data={chartData}
                options={{
                    ...CHART_OPTIONS,
                    onClick(event, elements) {
                        if (elements.length > 0) {
                            handleClickOnPoint(elements[0].index);
                        }
                    }
                }}
                height={800}
                canZoom={true}
                persistLegendState={true}
            />
        </div>
    );
};

export default PluginWaterholeRunToEmptyModalChart;
