import ExploreTabs, { ExploreTabType, TabSelection } from "./ExploreTabs";
import React, { useEffect, useMemo, useState } from "react";
import ModelExploreSummary from "./ModelExploreSummary";
import { useModel } from "./ModelContext";
import { ModelExploreIntermediateResultType } from "../../types/models";
import LoadingIndicator from "../../Components/LoadingIndicator";
import ModelExploreScenarioSelect from "./ModelExploreScenarioSelect";
import { isEmpty, isNil } from "lodash";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";
import { ExplorerFlags, ExplorerWarning } from "./types";
import ResultsWarnings from "./ResultsWarnings";
import ResultsInfo from "./ResultsInfo";
import ModelExplorerDownloads from "./ModelExplorerDownloads";
import ModelExploreOpportunities from "./ModelExploreOpportunities";
import { isNilOrEmpty } from "../../utils/utils";
import ModelExploreIntermediate from "./ModelExploreIntermediate";
import ModelExplorePreselect from "./ModelExplorePreselect";

const ModelExplore = () => {
    const {
        model,
        scenarios,
        explorerScenarios,
        explorerFlags,
        enablePreselectTab,
        setShowRunModal,
        loadExplorerScenarios,
        loadLatestModelVersion
    } = useModel();

    const [activeTab, setActiveTab] = useState<TabSelection>({
        index: 0,
        type: enablePreselectTab ? ExploreTabType.PRESELECT : ExploreTabType.SUMMARY
    });
    const [intermediateResultType, setIntermediateResultType] = useState<ModelExploreIntermediateResultType>(null);

    useEffect(() => {
        loadExplorerScenarios(false);
        loadLatestModelVersion();
    }, []);

    const handleRerun = () => {
        setShowRunModal(true);
    };

    const explorerWarnings = useMemo(() => {
        const newOutdated: ExplorerWarning = { hasWarnings: false, warnings: [] };

        //If a scenario now has results/ has new results, notify the user
        if (
            scenarios.filter(s => !isNil(s.run)).length > explorerScenarios.length ||
            explorerScenarios.some(r => r.run.id !== scenarios.find(s => s.id === r.id)?.run?.id)
        ) {
            newOutdated.hasWarnings = true;
            newOutdated.warnings.push("results_changed");
        }

        if (!newOutdated.hasWarnings) {
            //If a selected scenario has outdated results, notify the user
            if (
                explorerScenarios.some(
                    ss => model.version !== ss?.run?.modelVersion || ss.version !== ss?.run?.scenarioVersion
                )
            ) {
                newOutdated.hasWarnings = true;
                newOutdated.warnings.push("scenario_changed");
            }
        }

        if (!newOutdated.hasWarnings) {
            //If all scenarios have results but nothing to show (all results are empty)
            if (
                !isNilOrEmpty(explorerScenarios) &&
                explorerScenarios.some(r => r.hasAssessment) &&
                explorerScenarios.every(
                    s =>
                        !isNil(s?.result?.assessment) &&
                        isEmpty(s.result.assessment?.spatial) &&
                        isEmpty(s.result.assessment?.temporal)
                )
            ) {
                newOutdated.hasWarnings = true;
                newOutdated.warnings.push("empty_results");
            }
        }

        return newOutdated;
    }, [scenarios, model.version, explorerScenarios]);

    const flags: ExplorerFlags = useMemo(() => {
        return {
            hasResults: explorerScenarios.length > 0,
            hasAssessment: explorerScenarios.some(r => r.hasAssessment),
            hasIntermediate: explorerScenarios.some(r => r.hasIntermediate),
            hasSelectedScenarios: explorerScenarios.some(r => r.isSelected)
        };
    }, [explorerScenarios]);

    const shouldShowScenarioSelector = () => {
        if (
            activeTab.type === ExploreTabType.PRESELECT ||
            activeTab.type === ExploreTabType.SUMMARY ||
            activeTab.type === ExploreTabType.OPPORTUNITIES
        ) {
            return flags.hasAssessment;
        }

        if (activeTab.type === ExploreTabType.INTERMEDIATE) {
            return flags.hasIntermediate;
        }

        return false;
    };

    return (
        <div className="model-section-content model-explore">
            {explorerFlags.isLoadingScenarios && <LoadingIndicator centered />}

            {explorerFlags.hasLoadedScenarios && (
                <>
                    {explorerWarnings.hasWarnings && (
                        <ResultsWarnings
                            onRerun={handleRerun}
                            onFetchResults={() => loadExplorerScenarios(true)}
                            warnings={explorerWarnings.warnings}
                        />
                    )}

                    <ExploreTabs
                        selectedTab={activeTab}
                        onTabSelected={setActiveTab}
                        enablePreselectTab={enablePreselectTab}
                    />

                    {!flags.hasResults && (
                        <ResultsInfo
                            showButton={true}
                            buttonText="Run this configuration"
                            icon={faPlay}
                            message="This model has no results yet"
                            onClick={handleRerun}
                        />
                    )}

                    {flags.hasResults && (
                        <>
                            {activeTab.type !== ExploreTabType.DOWNLOADS && (
                                <>
                                    {(activeTab.type === ExploreTabType.SUMMARY ||
                                        activeTab.type === ExploreTabType.OPPORTUNITIES ||
                                        activeTab.type === ExploreTabType.PRESELECT) &&
                                        !flags.hasAssessment && (
                                            <ResultsInfo
                                                showButton={false}
                                                message="This model has no assessment results."
                                            />
                                        )}

                                    {activeTab.type === ExploreTabType.INTERMEDIATE && !flags.hasIntermediate && (
                                        <ResultsInfo
                                            showButton={false}
                                            message="This model has no intermediate results."
                                        />
                                    )}

                                    {shouldShowScenarioSelector() && (
                                        <>
                                            <ModelExploreScenarioSelect
                                                activeTab={activeTab.type}
                                                intermediateResultType={intermediateResultType}
                                                setIntermediateResultType={setIntermediateResultType}
                                            />

                                            {!flags.hasSelectedScenarios &&
                                                activeTab.type !== ExploreTabType.PRESELECT && (
                                                    <ResultsInfo
                                                        showButton={false}
                                                        message="Select a scenario to start exploring"
                                                    />
                                                )}
                                        </>
                                    )}

                                    {flags.hasAssessment && activeTab.type === ExploreTabType.PRESELECT && (
                                        <ModelExplorePreselect />
                                    )}

                                    {flags.hasSelectedScenarios && (
                                        <>
                                            {flags.hasAssessment && activeTab.type === ExploreTabType.SUMMARY && (
                                                <ModelExploreSummary />
                                            )}

                                            {flags.hasAssessment && activeTab.type === ExploreTabType.OPPORTUNITIES && (
                                                <ModelExploreOpportunities />
                                            )}

                                            {flags.hasIntermediate &&
                                                activeTab.type === ExploreTabType.INTERMEDIATE && (
                                                    <ModelExploreIntermediate
                                                        selectedIntermediateResultType={intermediateResultType}
                                                    />
                                                )}
                                        </>
                                    )}
                                </>
                            )}

                            {activeTab.type === ExploreTabType.DOWNLOADS && <ModelExplorerDownloads />}
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default ModelExplore;
