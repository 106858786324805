import React, { useContext, useEffect, useState } from "react";
import io, { Socket } from "socket.io-client";
import Config from "../../Config";
import { isNilOrEmpty } from "../../utils/utils";
import { useAuth0 } from "../../auth/react-auth0-spa";
import ERPTelemetry from "../../telemetry/telemetry";

interface WebsocketContextProps {
    socket: Socket;
    ready: boolean;
}

interface WebsocketContextOptions {
    children: React.ReactNode;
}

export const WebsocketContext = React.createContext<WebsocketContextProps | null>(null);

export const useWebsocket = () => useContext(WebsocketContext);

const WebsocketProvider = ({ children }: WebsocketContextOptions) => {
    const { token } = useAuth0();
    const [socket, setSocket] = useState<Socket>(null);
    const [ready, setReady] = useState<boolean>(false);

    useEffect(() => {
        if (isNilOrEmpty(token)) {
            return;
        }

        const socket = io(Config.ws.url, {
            path: Config.ws.path,
            transports: ["websocket", "polling"],
            auth: {
                token: token
            }
        });

        socket.on("connect", () => {
            console.log("ws | connected");
            ERPTelemetry.trackEvent("ws_connect");
            setReady(true);
        });

        socket.on("disconnect", () => {
            console.log("ws | disconnected");
            ERPTelemetry.trackEvent("ws_disconnected");
            setReady(false);
        });

        socket.on("connect_error", (e: any) => {
            console.log("ws | connect_error", e);
            ERPTelemetry.trackException(e);
            setReady(false);
        });

        setSocket(socket);

        return () => {
            console.log("ws | teardown");

            ERPTelemetry.trackEvent("ws_teardown");

            socket.close();

            setSocket(null);
        };
    }, [token]);

    return <WebsocketContext.Provider value={{ socket: socket, ready }}>{children}</WebsocketContext.Provider>;
};

export default WebsocketProvider;
