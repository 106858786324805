import React, { useMemo } from "react";
import { Controller } from "react-hook-form";
import { PluginFieldProps } from "./types";
import { SelectOption } from "../../Components/Select/Select";
import { PluginDropdownValue, PluginFieldArrayValue } from "../../types/plugin";
import ParameterSelect from "./ParameterSelect";

interface PluginParameterMultiSelectProps extends PluginFieldProps {
    onChanged?: (field: string, value: PluginFieldArrayValue) => void;
}

const PluginParameterMultiSelect = ({
    id,
    definition,
    field,
    control,
    disabled,
    onChanged
}: PluginParameterMultiSelectProps) => {
    const values = useMemo(() => {
        const _values = field.control.values as PluginDropdownValue[];

        return (
            _values?.map((val: PluginDropdownValue) => {
                const option: SelectOption = {
                    value: val.value,
                    label: val.label
                };

                return option;
            }) ?? []
        );
    }, []);

    return (
        <Controller
            control={control}
            name={`${definition.id}.${field.id}`}
            render={({ field: _field }) => {
                return (
                    <ParameterSelect
                        id={id}
                        label={field.name}
                        values={_field.value}
                        dropdownValues={values}
                        onChanged={(selectedValues: string[]) => {
                            onChanged(field.id, selectedValues);
                        }}
                        disabled={disabled}
                    />
                );
            }}
        />
    );
};

export default PluginParameterMultiSelect;
