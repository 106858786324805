import { ChartDataset, ChartOptions } from "chart.js";
import ERPChart from "../../Components/Charts/ERPChart";
import { merge } from "lodash";
import React, { useMemo, useState } from "react";
import { ERPChartData, ModelExploreScenario, ModelExploreSummaryResults, RiskLevel } from "../../types/models";
import ModelExploreTemporalRiskChartLegend from "./ModelExploreTemporalRiskChartLegend";

interface ModelExploreSpatialRiskChartProps {
    selectedScenarios: ModelExploreScenario[];
    resultsData: ModelExploreSummaryResults;
    title: string;
}

const CHART_OPTIONS: ChartOptions = {
    maintainAspectRatio: false,
    interaction: {
        mode: "index"
    },
    indexAxis: "y",
    scales: {
        x: {
            grid: { display: false, drawBorder: false },
            ticks: {
                stepSize: 10,
                callback(tickValue) {
                    return tickValue;
                }
            },
            min: 0,
            max: 100,
            stacked: true
        },
        y: {
            grid: { display: false, drawBorder: false },
            stacked: true
        }
    },
    layout: {
        padding: 5
    },
    plugins: {
        tooltip: {
            enabled: true,
            position: "stackedBarPositioner",
            callbacks: {
                label: function (tooltipItem) {
                    return `${tooltipItem.dataset.label} ${tooltipItem.formattedValue} %`;
                }
            }
        },
        legend: {
            display: false
        },
        title: {
            display: true,
            position: "top",
            align: "start",
            text: "Default Title",
            font: {
                size: 14
            }
        }
    },
    hover: {
        mode: "index",
        intersect: false
    },
    animation: false
};

const ModelExploreSpatialRiskChart = ({ selectedScenarios, resultsData, title }: ModelExploreSpatialRiskChartProps) => {
    const [selectedRisks, setSelectedRisks] = useState<RiskLevel[]>([
        RiskLevel.HIGH,
        RiskLevel.MODERATE,
        RiskLevel.LOW
    ]);

    const options = useMemo(() => {
        return merge({}, CHART_OPTIONS, {
            plugins: { title: { text: `${resultsData.model.system.name} (overall result)` } }
        });
    }, [resultsData.model]);

    const chartData: ERPChartData = useMemo(() => {
        const labels = selectedScenarios.map(s => s.name);

        const data: ChartDataset[] = [
            {
                label: "No Data",
                data: selectedScenarios.map(s => {
                    return resultsData.spatialResults
                        .find(r => r.scenarioId === s.id)
                        ?.results?.find(r => r.key === RiskLevel.NONE)?.percent;
                }),
                backgroundColor: "#acacae",
                barThickness: 50
            },
            {
                label: "Low",
                data: selectedScenarios.map(s => {
                    return resultsData.spatialResults
                        .find(r => r.scenarioId === s.id)
                        ?.results?.find(r => r.key === RiskLevel.LOW)?.percent;
                }),
                backgroundColor: selectedRisks.includes(RiskLevel.LOW) ? "#08bfdd" : "#efefef",
                barThickness: 50
            },
            {
                label: "Moderate",
                data: selectedScenarios.map(s => {
                    return resultsData.spatialResults
                        .find(r => r.scenarioId === s.id)
                        ?.results?.find(r => r.key === RiskLevel.MODERATE)?.percent;
                }),
                backgroundColor: selectedRisks.includes(RiskLevel.MODERATE) ? "#fdcd06" : "#efefef",
                barThickness: 50
            },
            {
                label: "High",
                data: selectedScenarios.map(s => {
                    return resultsData.spatialResults
                        .find(r => r.scenarioId === s.id)
                        ?.results?.find(r => r.key === RiskLevel.HIGH)?.percent;
                }),
                backgroundColor: selectedRisks.includes(RiskLevel.HIGH) ? "#FF5349" : "#efefef",
                barThickness: 50
            }
        ];

        return { labels: labels, data: data };
    }, [selectedScenarios, selectedRisks]);

    return (
        <div className="chart">
            <h5>{title}</h5>

            <ModelExploreTemporalRiskChartLegend selectedRisks={selectedRisks} onRiskChanged={setSelectedRisks} />

            <ERPChart
                id="spatial_risk_chart"
                type="bar"
                labels={chartData.labels}
                data={chartData.data}
                options={options}
                height={100 + 75 * selectedScenarios.length}
            />
        </div>
    );
};

export default ModelExploreSpatialRiskChart;
