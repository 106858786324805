import React, { useState } from "react";
import { ModelExploreScenarioResult, ModelModel } from "../../types/models";
import { faDownload } from "@fortawesome/pro-solid-svg-icons";
import AzureBlobService from "../../Services/azure.blob.service";
import TooltipIcon from "../../Components/TooltipIcon";
import clsx from "clsx";
import ZipGenerator, { ZipFileInfo } from "../../Services/zip-generator";
import { forEach } from "lodash";
import ExploreDataService from "../../Services/explore.data.service";
import { useModel } from "./ModelContext";
import { formatFullDownloadName, formatScenarioDate } from "../../utils/explorer.utils";

interface ScenarioDownloadIconProps {
    model: ModelModel;
    scenarioResult: ModelExploreScenarioResult;
    className?: string;
}

const ScenarioDownloadIcon = ({ model, scenarioResult, className }: ScenarioDownloadIconProps) => {
    const { downloadsSettings } = useModel();

    const [isDownloading, setIsDownloading] = useState<boolean>(false);

    const handleResultDownload = async () => {
        try {
            setIsDownloading(true);

            const generator = new ZipGenerator();

            const files: ZipFileInfo[] = [];

            const results = scenarioResult.results;

            for (let i = 0; i < results.length; i++) {
                const result = results[i];

                const resultName = downloadsSettings.shouldUseFullDownloadNames
                    ? formatFullDownloadName(result)
                    : result.name;

                const resultFiles = await ExploreDataService.fetchResultFiles(result, resultName, downloadsSettings);

                forEach(resultFiles, file => {
                    files.push({ name: `${file.name}`, file: file });
                });
            }

            const blob = await generator.generate(files);

            AzureBlobService.downloadToDisk(
                blob,
                `${model.name}_${scenarioResult.scenario.name}_${formatScenarioDate(
                    scenarioResult.scenario
                )}_results.zip`
            );
        } finally {
            setIsDownloading(false);
        }
    };

    return (
        <TooltipIcon
            icon={faDownload}
            tooltip="Download all results"
            size="lg"
            className={clsx("download-icon", className)}
            isLoading={isDownloading}
            onClick={handleResultDownload}
        />
    );
};

export default ScenarioDownloadIcon;
