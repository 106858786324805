import ERPModal from "../Modals/ERPModal";
import React, { useEffect, useMemo, useState } from "react";
import Label from "../Label";
import Select, { SelectOption } from "../Select/Select";
import { DataType } from "../../types/models";
import ObservedDataModelFooter from "./ObservedDataModelFooter";
import { isNilOrEmpty } from "../../utils/utils";
import PreviewDataService from "../../Services/preview.data.service";
import ERPTimeseriesChart from "../Charts/ERPTimeseriesChart";
import EDSService from "../../Services/eds.service";
import ObservedDataModalBOMOptions from "./ObservedDataModelBOMOptions";
import ObservedDataModalSILOOptions from "./ObservedDataModelSiloOptions";
import {
    ObservedDataConnectionSettings,
    ObservedDataSettings,
    ObservedDataSourceTypes,
    SILOConnectionSettings,
    BOMConnectionSettings,
    ObservedDataPeriodSettings
} from "./types";
import Humanize from "humanize-plus";
import { isNil } from "lodash";
import YearSelect from "../YearSelect";
import TooltipIcon from "../TooltipIcon";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
import { EDSDataResult } from "../../types/eds";

interface ObservedDataModalProps {
    show: boolean;
    settings?: ObservedDataSettings;
    onSave: (file: File, observationSettings: ObservedDataSettings) => void;
    onClose: () => void;
}

const CONNECTION_SOURCE_OPTIONS: SelectOption[] = [
    { label: "SILO", value: ObservedDataSourceTypes.SILO },
    { label: "BOM", value: ObservedDataSourceTypes.BOM }
];

const SILO_DATA_TYPE_OPTIONS: SelectOption[] = [
    { label: "Rainfall", value: DataType.RAINFALL },
    { label: "Evaporation", value: DataType.EVAPORATION },
    { label: "Temperature (max)", value: DataType.TEMPERATURE }
];

const BOM_DATA_TYPE_OPTIONS: SelectOption[] = [
    { label: "Flow", value: DataType.FLOW },
    { label: "Depth", value: DataType.DEPTH },
    { label: "Rainfall", value: DataType.RAINFALL },
    { label: "Temperature", value: DataType.TEMPERATURE },
    { label: "Salinity", value: DataType.SALINITY }
];

const ObservedDataModal = ({ show, settings, onSave, onClose }: ObservedDataModalProps) => {
    const [connectionSettings, setConnectionSettings] = useState<ObservedDataSettings>(
        !isNil(settings) ? settings : { source: null }
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasFetchedData, setHasFetchedData] = useState<boolean>(false);
    const [data, setData] = useState<EDSDataResult>({ data: [] });
    const [error, setError] = useState<string>(null);
    const [allowedYearPeriod, setAllowedYearPeriod] = useState<ObservedDataPeriodSettings>(null);
    const [isLoadingYearPeriod, setIsLoadingYearPeriod] = useState<boolean>(false);

    const handleConnectionSourceChanged = (nextConnectionSource: ObservedDataSourceTypes) => {
        if (nextConnectionSource === connectionSettings.source) {
            return;
        }

        setConnectionSettings({ source: nextConnectionSource });
    };

    const handleSettingsChanged = (nextSettings: ObservedDataConnectionSettings) => {
        setConnectionSettings({ ...connectionSettings, settings: nextSettings, period: null });
    };

    useEffect(() => {
        resetData();
    }, [connectionSettings]);

    const resetData = () => {
        setData({ data: [] });
        setError(null);
        setHasFetchedData(false);
    };

    const previewChartData = useMemo(() => {
        if (isNilOrEmpty(data.data)) {
            return;
        }

        return PreviewDataService.formatTimeseriesData(data.data, connectionSettings.dataType, data.unit);
    }, [data, connectionSettings?.dataType]);

    const handleSave = () => {
        const file = PreviewDataService.formatTimeseriesAsFile(
            data.data,
            `${data.name}_${connectionSettings.source.toUpperCase()}_${Humanize.capitalize(
                connectionSettings.dataType
            )}.csv`,
            `${data.name}_${Humanize.capitalize(connectionSettings.dataType)}_${data.unit}`
        );

        onSave(file, connectionSettings);
    };

    const handleFetchData = async () => {
        try {
            setIsLoading(true);

            if (connectionSettings.source === ObservedDataSourceTypes.SILO) {
                const siloSettings = connectionSettings.settings as SILOConnectionSettings;

                const [_data, _error] = await EDSService.getSiloData(
                    connectionSettings.dataType,
                    siloSettings.latitude,
                    siloSettings.longitude,
                    connectionSettings?.period?.startYear ?? null,
                    connectionSettings?.period?.endYear ?? null
                );

                setData(_data);
                setError(_error);
            }

            if (connectionSettings.source === ObservedDataSourceTypes.BOM) {
                const bomSettings = connectionSettings.settings as BOMConnectionSettings;

                const [_data, _error] = await EDSService.getBOMData(
                    connectionSettings.dataType,
                    bomSettings.gauge,
                    connectionSettings?.period?.startYear ?? null,
                    connectionSettings?.period?.endYear ?? null
                );

                setData(_data);
                setError(_error);
            }
        } finally {
            setIsLoading(false);
            setHasFetchedData(true);
        }
    };

    const handleDataTypeChanged = (nextDataType: DataType) => {
        if (nextDataType !== settings?.dataType) {
            setConnectionSettings({ ...connectionSettings, dataType: nextDataType, settings: null, period: null });
        }
    };

    const handleStartYearChanged = (nextStartYear: number) => {
        if (nextStartYear !== settings?.period?.startYear) {
            setConnectionSettings({
                ...connectionSettings,
                period: { ...connectionSettings.period, startYear: nextStartYear }
            });
        }
    };

    const handleEndYearChanged = (nextEndYear: number) => {
        if (nextEndYear !== settings?.period?.endYear) {
            setConnectionSettings({
                ...connectionSettings,
                period: { ...connectionSettings.period, endYear: nextEndYear }
            });
        }
    };

    const dataTypeSelectOptions: SelectOption[] = useMemo(() => {
        if (connectionSettings?.source === ObservedDataSourceTypes.SILO) {
            return SILO_DATA_TYPE_OPTIONS;
        }

        if (connectionSettings?.source === ObservedDataSourceTypes.BOM) {
            return BOM_DATA_TYPE_OPTIONS;
        }

        return [];
    }, [connectionSettings?.source]);

    useEffect(() => {
        if (
            connectionSettings?.source !== ObservedDataSourceTypes.BOM ||
            isNilOrEmpty((connectionSettings?.settings as BOMConnectionSettings)?.gauge)
        ) {
            setAllowedYearPeriod(null);
            return;
        }

        const getAvailability = async () => {
            try {
                setIsLoadingYearPeriod(true);

                const bomSettings = connectionSettings.settings as BOMConnectionSettings;

                const _availbility = await EDSService.getBomAvailability(
                    bomSettings.gauge,
                    connectionSettings.dataType
                );

                setAllowedYearPeriod({
                    startYear: moment(_availbility.from, "YYYYMMDD").toDate().getFullYear(),
                    endYear: moment(_availbility.to, "YYYYMMDD").toDate().getFullYear()
                });
            } finally {
                setIsLoadingYearPeriod(false);
            }
        };

        getAvailability();
    }, [connectionSettings?.settings]);

    return (
        <ERPModal
            show={show}
            title="Observed Data Connection"
            onClose={onClose}
            size="xl"
            scrollable
            backdrop={true}
            bodyClassName="data-connection-modal-body"
            backdropClassName="data-connection-modal-backdrop"
            canClose={true}
            footer={
                <ObservedDataModelFooter
                    connectionSettings={connectionSettings}
                    isLoading={isLoading}
                    hasFetchedData={hasFetchedData}
                    dataLength={data.data.length}
                    error={error}
                    onFetchData={handleFetchData}
                    onSave={handleSave}
                />
            }
        >
            <div className="general-settings-container">
                <div className="option-field">
                    <Label>Data source</Label>
                    <Select
                        values={CONNECTION_SOURCE_OPTIONS}
                        value={CONNECTION_SOURCE_OPTIONS.find(s => s.value === connectionSettings.source)}
                        onSelected={(next: SelectOption) =>
                            handleConnectionSourceChanged(next.value as ObservedDataSourceTypes)
                        }
                        menuClassName="select"
                    />
                </div>

                {!isNil(connectionSettings.source) && (
                    <div className="option-field">
                        <Label>Data type</Label>
                        <Select
                            menuClassName="select"
                            values={dataTypeSelectOptions}
                            value={dataTypeSelectOptions.find(o => o.value === connectionSettings?.dataType) ?? null}
                            onSelected={(option: SelectOption) => handleDataTypeChanged(option.value as DataType)}
                        />
                    </div>
                )}
            </div>

            {!isNil(connectionSettings.source) && !isNil(connectionSettings.dataType) && (
                <div className="data-settings-container">
                    {connectionSettings.source === ObservedDataSourceTypes.SILO && (
                        <ObservedDataModalSILOOptions
                            settings={connectionSettings?.settings as SILOConnectionSettings}
                            onSettingsChanged={handleSettingsChanged}
                        />
                    )}

                    {connectionSettings.source === ObservedDataSourceTypes.BOM && (
                        <ObservedDataModalBOMOptions
                            dataType={connectionSettings.dataType}
                            settings={connectionSettings?.settings as BOMConnectionSettings}
                            onSettingsChanged={handleSettingsChanged}
                        />
                    )}

                    <div className="option-field">
                        <div className="label-container">
                            <Label>Start year</Label>
                            <TooltipIcon
                                icon={faQuestionCircle}
                                tooltip="If a start year is provided, only data in or after this year will be included"
                            />
                        </div>

                        <YearSelect
                            id="start-year-select"
                            value={connectionSettings?.period?.startYear}
                            onYearChanged={handleStartYearChanged}
                            isClearable={true}
                            className="select"
                            placeholder="Year (optional)"
                            minYear={allowedYearPeriod?.startYear ?? null}
                            maxYear={allowedYearPeriod?.endYear ?? null}
                            isLoading={isLoadingYearPeriod}
                        />
                    </div>

                    <div className="option-field">
                        <div className="label-container">
                            <Label>End year</Label>
                            <TooltipIcon
                                icon={faQuestionCircle}
                                tooltip="If a end year is provided, only data in or before this year will be included"
                            />
                        </div>

                        <YearSelect
                            id="end-year-select"
                            value={connectionSettings?.period?.endYear}
                            onYearChanged={handleEndYearChanged}
                            isClearable={true}
                            className="select"
                            placeholder="Year (optional)"
                            minYear={allowedYearPeriod?.startYear ?? null}
                            maxYear={allowedYearPeriod?.endYear ?? null}
                            isLoading={isLoadingYearPeriod}
                        />
                    </div>
                </div>
            )}

            {!isNilOrEmpty(previewChartData) && (
                <ERPTimeseriesChart
                    id="timeseries-chart"
                    chartData={previewChartData}
                    dataType={connectionSettings.dataType}
                    height={400}
                    persistLegendState={false}
                    persistZoomState={false}
                />
            )}
        </ERPModal>
    );
};

export default ObservedDataModal;
