import React, { useState } from "react";
import { faMinusCircle } from "@fortawesome/pro-regular-svg-icons";
import ERPIcon from "../../Components/ERPIcon";
import PluginParameterCurveCell from "./PluginParameterCurveCell";

interface PluginParameterCurveRowProps {
    id: string;
    values: number[];
    disabled: boolean;
    disableX: boolean;
    disableY: boolean;
    disableRemove: boolean;
    onChanged: (index: number, value: number) => void;
    onRemoved: () => void;
}

const PluginParameterCurveRow = ({
    id,
    values,
    disabled,
    disableX,
    disableY,
    disableRemove,
    onChanged,
    onRemoved
}: PluginParameterCurveRowProps) => {
    const [isHovering, setIsHovering] = useState<boolean>(false);

    return (
        <tr onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
            <PluginParameterCurveCell
                key={`${id}-curve-cell-${0}`}
                value={values[0]}
                disabled={disableX || disabled}
                onChanged={val => onChanged(0, val)}
            />
            <PluginParameterCurveCell
                key={`${id}-curve-cell-${1}`}
                value={values[1]}
                disabled={disableY || disabled}
                onChanged={val => onChanged(1, val)}
            />

            {!disableRemove && !disabled && isHovering && (
                <ERPIcon className="remove-row-icon" icon={faMinusCircle} onClick={onRemoved} />
            )}
        </tr>
    );
};

export default PluginParameterCurveRow;
