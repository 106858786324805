import React, { useMemo } from "react";
import { SuccessType } from "../../types/models";
import ChartLegendItem from "./ChartLegendItem";

interface ModelExploreNodeTemporalSuccessChartLegendProps {
    selectedSuccess: SuccessType[];
    onSuccessChanged: (success: SuccessType[]) => void;
}

const ModelExploreNodeTemporalSuccessChartLegend = ({
    selectedSuccess,
    onSuccessChanged
}: ModelExploreNodeTemporalSuccessChartLegendProps) => {
    const successes = useMemo(() => {
        return [SuccessType.FAILURE, SuccessType.SUCCESS].map(success => {
            return {
                label: success,
                isSelected: selectedSuccess.includes(success)
            };
        });
    }, [selectedSuccess.length]);

    const handleSuccessSelected = (success: SuccessType, active: boolean) => {
        if (!active) {
            onSuccessChanged(selectedSuccess.filter(s => s !== success));
        } else {
            onSuccessChanged([...selectedSuccess, success]);
        }
    };

    return (
        <div className="chart-legend">
            <ChartLegendItem key={`temporal_success_chart_legend_no_data`} label="no-data" isSelectable={false} />
            {successes.map(success => {
                return (
                    <ChartLegendItem
                        key={`temporal_success_chart_legend_${success.label}`}
                        label={success.label}
                        isSelectable
                        isSelected={success.isSelected}
                        onSelected={handleSuccessSelected}
                    />
                );
            })}
        </div>
    );
};

export default ModelExploreNodeTemporalSuccessChartLegend;
