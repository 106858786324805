import Select, { SelectOption } from "../../Components/Select/Select";
import { MONTHS } from "../../Constants/months";
import React, { useMemo } from "react";
import { isNilOrEmpty } from "../../utils/utils";

//FIXME: Ideally this would inherit SelectProps
interface MonthSelectProps {
    id: string;
    label?: string;
    value: string;
    className?: string;
    labelClassName?: string;
    disabled?: boolean;
    isClearable?: boolean;
    onMonthChanged: (month: string) => void;
}

const MonthSelect = ({
    id,
    label,
    labelClassName,
    value,
    className,
    disabled,
    isClearable,
    onMonthChanged
}: MonthSelectProps) => {
    const months = useMemo(() => {
        return MONTHS.map(m => {
            const option: SelectOption = {
                value: m.toLowerCase(),
                label: m
            };

            return option;
        });
    }, []);

    const month = useMemo(() => {
        if (isNilOrEmpty(value)) {
            return null;
        }

        const matching = months.find(m => m.value === value.toLowerCase());

        return matching;
    }, [value]);

    return (
        <Select
            id={id}
            labelText={label}
            labelClassName={labelClassName}
            placeholder="Month"
            value={month}
            values={months}
            onSelected={(option: SelectOption) => {
                const value = !isNilOrEmpty(option?.value) ? option.value : null;

                if (value !== month?.value) {
                    onMonthChanged(option?.value ?? null);
                }
            }}
            isClearable={isClearable}
            isSearchable={false}
            menuClassName={className}
            disabled={disabled}
        />
    );
};

export default MonthSelect;
