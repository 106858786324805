import { NorthernSnakeNeckedTurtleParameters } from "../../types/plugin-parameter-types";
import { PluginParameters } from "../../types/plugin";
import { BaseParameterMerger } from "./base-parameter-merger";

export class NorthernSnakeNeckedTurtleParameterMerger extends BaseParameterMerger<NorthernSnakeNeckedTurtleParameters> {
    public merge(
        source: PluginParameters,
        defaultParameters: NorthernSnakeNeckedTurtleParameters
    ): NorthernSnakeNeckedTurtleParameters {
        return {
            data: {
                season_start: this.defaultTo(source?.data?.season_start, defaultParameters?.data?.season_start),
                season_end: this.defaultTo(source?.data?.season_end, defaultParameters?.data?.season_end)
            },
            flow_parameters: {
                clutch_season_start: this.defaultTo(
                    source?.flow_parameters?.clutch_season_start,
                    defaultParameters?.flow_parameters?.clutch_season_start
                ),
                clutch_season_end: this.defaultTo(
                    source?.flow_parameters?.clutch_season_end,
                    defaultParameters?.flow_parameters?.clutch_season_end
                ),
                ari: this.defaultTo(source?.flow_parameters?.ari, defaultParameters?.flow_parameters?.ari)
            },
            egg_criteria: {
                max_clutches_per_season: this.defaultTo(
                    source?.egg_criteria?.max_clutches_per_season,
                    defaultParameters?.egg_criteria?.max_clutches_per_season
                ),
                incubation_period: this.defaultTo(
                    source?.egg_criteria?.incubation_period,
                    defaultParameters?.egg_criteria?.incubation_period
                ),
                max_diapause: this.defaultTo(
                    source?.egg_criteria?.max_diapause,
                    defaultParameters?.egg_criteria?.max_diapause
                ),
                time_between_clutches: this.defaultTo(
                    source?.egg_criteria?.time_between_clutches,
                    defaultParameters?.egg_criteria?.time_between_clutches
                ),
                soil_moisture_drying: this.defaultTo(
                    source?.egg_criteria?.soil_moisture_drying,
                    defaultParameters?.egg_criteria?.soil_moisture_drying
                )
            },
            assessment: {
                yearly_agg: this.defaultTo(source?.assessment?.yearly_agg, defaultParameters?.assessment?.yearly_agg),
                temporal_agg: this.defaultTo(
                    source?.assessment?.temporal_agg,
                    defaultParameters?.assessment?.temporal_agg
                )
            },
            spatial_agg: {
                risk_categories: this.defaultTo(
                    source?.spatial_agg?.risk_categories,
                    defaultParameters?.spatial_agg?.risk_categories
                )
            }
        };
    }
}
