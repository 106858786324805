import { ChartDataset } from "chart.js";
import { WaterholeCalibrationResults, WaterholeRunToEmptyResults } from "../Components/Plugin/types";
import { SelectOption } from "../Components/Select/Select";
import { PluginId, PluginParameters, PluginSchema } from "./plugin";
import { CSV } from "./global";
import { DataInfillSettingsModel } from "./requests";
import { ObservedDataSettings } from "../Components/FileSelector/types";

export enum GroupPrivilege {
    /**
     * Root Admin Privilege
     * Can only manage group users. Cannot see data
     */
    CAN_MANAGE_USERS = 1,

    /**
     * Group User.
     * Can only user data not manage user in group
     */
    CAN_USE = 6,

    /**
     * Group Admin.
     * Can user data and manage user in group
     */
    CAN_BE_GROUP_ADMIN = 7
}

export enum UserRole {
    ROOT = "root",
    APP_ADMIN = "app_admin",
    USER = "user"
}

export enum DataType {
    FLOW = "flow",
    DEPTH = "depth",
    TEMPERATURE = "temperature",
    RAINFALL = "rainfall",
    CLIMATE = "climate",
    BATHYMETRY = "bathymetry",
    EVAPORATION = "evaporation",
    COMPLIANCE = "compliance",
    OTHER_TIMESERIES = "other_timeseries",
    SALINITY = "salinity"
}

export enum ResourceType {
    SYSTEM = "system",
    MODEL = "model",
    FOLDER = "folder"
}

export enum ScenarioResultType {
    DAILY_RESULTS = "daily_results",
    YEARLY_RESULTS = "yearly_results",
    TEMPORAL_RESULTS = "temporal_results",
    SPATIAL_RESULTS = "spatial_results",
    DAILY_INTERMEDIATE = "daily_intermediate_results",
    YEARLY_INTERMEDIATE = "yearly_intermediate_results",
    SUMMARY_INTERMEDIATE = "summary_intermediate_results",
    EVENTS_INTERMEDIATE = "events_intermediate_results",
    DAILY_SPATIAL_INTERMEDIATE = "daily_spatial_intermediate_results",
    YEARLY_SPATIAL_INTERMEDIATE = "yearly_spatial_intermediate_results",
    SUMMARY_SPATIAL_INTERMEDIATE = "summary_spatial_intermediate_results",
    EVENTS_SPATIAL_INTERMEDIATE = "events_spatial_intermediate_results",
    RUN_SETTINGS_LOG = "run_settings_log",
    PARAMETER_RESULTS = "parameter_results",
    CUSTOM_RESULTS = "custom_results"
}

export enum TaskStatus {
    PENDING = "pending",
    RUNNING = "running",
    COMPLETED = "completed",
    FAILED = "failed"
}

export enum TaskType {
    SCENARIO = "scenario",
    OPTIMISATION = "optimisation"
}

export enum RiskLevel {
    NONE = "none",
    LOW = "low",
    MODERATE = "moderate",
    HIGH = "high"
}

export enum SuccessType {
    NONE = "none",
    SUCCESS = "success",
    FAILURE = "failure"
}

export enum OptimisationPluginId {
    WATERHOLE_CALIBRATION = "waterhole_calibration",
    WATERHOLE_RUN_TO_EMPTY = "waterhole_run_to_empty"
}

export interface BaseEntity {
    id: string;
    createdAt?: string;
    updatedAt?: string;
}

export interface FolderModel extends BaseEntity {
    name: string;
    parentId: string;
    isRoot: boolean;
    createdBy: CreatedByUserModel;
}

export interface SystemModel extends BaseEntity {
    folderId: string;
    name: string;
    description: string;
    createdBy: CreatedByUserModel;
    lock: LockModel;
}

export interface ModelModel extends BaseEntity {
    name: string;
    folderId: string;
    authors: string;
    description: string;
    assessmentEndpoint: string;
    measurementEndpoint: string;
    caveats: string;
    system: ModelSystem;
    plugin: PluginModel;
    parameters: PluginParameters;
    createdBy: CreatedByUserModel;
    lock: LockModel;
    version: string;
    runPeriod: RunPeriodModel;
    dataInfillSettings: DataInfillSettingsModel;
}

export interface SearchModel extends BaseEntity {
    name: string;
    type: ResourceType;
    createdBy: CreatedByUserModel;
    breadcrumbs: string;
    ancestorIds: string[];
}

export interface ModelSystem {
    id: string;
    name: string;
}

export interface GroupModel extends BaseEntity {
    name: string;
    rootFolderId: string;
    privilege: GroupPrivilege;
}

export interface User extends BaseEntity {
    id: string;
    auth0Id?: string;
    currentGroupId: string;
    email: string;
    normalisedEmail: string;
    firstName: string;
    lastName: string;
    role: UserRole;
    verified: boolean;
    groups: GroupModel[];
}

export interface GroupMember {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    username: string;
    privilege: GroupPrivilege;
    verified: boolean;
    role: UserRole;
}

export interface ScenarioModel extends BaseEntity {
    systemId: string;
    name: string;
    description: string;
    isDefault: boolean;
}

export interface LocationModel extends BaseEntity {
    systemId: string;
    name: string;
    gaugeNumber: string;
}

export interface ScenarioLocationDataErrorModel {
    code: string;
    message: string;
}

export interface BaseDataModel extends BaseEntity {
    name: string;
    blobId: string;
    dataType: DataType;
    extension: string;
    size: number;
    isObserved: boolean;
    observationSettings: ObservedDataSettings;
}

export interface ScenarioLocationDataModel extends BaseDataModel {
    locationId: string;
    scenarioId: string;
}

export interface Attachment extends BaseEntity {
    name: string;
    extension: string;
    blobId: string;
}

export interface ModelLocationModel extends BaseEntity {
    modelId: string;
    systemLocationId: string;
    systemId: string;
    name: string;
    enabled: boolean;
    parameters: Record<any, any>;
}

export interface ModelLocationSiteModel extends BaseEntity {
    name: string;
    description: string;
    modelLocationId: string;
    parameters: Record<any, any>;
}

export interface ModelLocationSiteOptimisationModel extends BaseEntity {
    modelLocationSiteId: string;
    parameters: Record<any, any>;
    optimisationPluginId: OptimisationPluginId;
    result: OptimisationResultsModel;
}

export interface ModelLocationSiteDataModel extends BaseDataModel {
    siteId: string;
}

export interface ModelLocationSiteOptimisationDataModel extends BaseDataModel {
    optimisationId: string;
}

export interface ModelScenarioModel extends BaseEntity {
    modelId: string;
    systemScenarioId: string;
    systemId: string;
    name: string;
    description: string;
    run: ModelScenarioRunModel;
    version: string;
    isDefault: boolean;
}

export interface ModelVersionModel {
    version: string;
}

export interface ModelScenarioRunModel {
    id: string;
    scenarioId: string;
    scenarioVersion: string;
    modelVersion: string;
    createdAt: Date;
}

export interface TaskError {
    code: string;
    message: string;
}

export interface TaskModel {
    id: string;
    resourceId: string;
    status: TaskStatus;
    type: TaskType;
    error?: TaskError;
    progress: number;
    updatedAt: Date;
}

export type OptimisationResultsModel =
    | WaterholeCalibrationOptimisationResultsModel
    | WaterholeRunToEmptyOptimisationResultsModel;

export interface WaterholeCalibrationOptimisationResultsModel {
    files: OptimisationResult[];
    parameterResults: WaterholeCalibrationResults;
}

export interface WaterholeRunToEmptyOptimisationResultsModel {
    files: OptimisationResult[];
    parameterResults: WaterholeRunToEmptyResults;
}

export interface OptimisationResult {
    name: string;
    sizeInBytes: number;
    extension: string;
    blobId: string;
    resultType: ScenarioResultType;
}

export interface PluginModel extends BaseEntity {
    name: string;
    identifier: PluginId;
    schema: PluginSchema;
    version: string;
    isCurrent: boolean;
}

export interface CreatedByUserModel extends BaseEntity {
    id: string;
    firstName: string;
    lastName: string;
}

export interface ResourceLockModel {
    id: string;
    resourceId: string;
    resourceType: ResourceType;
    owner: ResourceLockOwnerModel;
    createdAt: Date;
}

export enum ResourceLockErrors {
    ENTITY_NOT_FOUND = "ENTITY_NOT_FOUND"
}

export interface ResourceLockOwnerModel {
    id: string;
    firstName: string;
    lastName: string;
}

export interface ResourceLockResultModel {
    lock: ResourceLockModel;
    error?: ResourceLockError;
    success: boolean;
}

interface ResourceLockError {
    code: string;
    message: string;
}

export interface ResourceUnLockResultModel {
    error?: ResourceLockError;
    success: boolean;
}

export interface LockModel {
    id: string;
    owner: ResourceLockOwnerModel;
}

export interface RunPeriodModel {
    start: PeriodModel;
    end: PeriodModel;
}

export interface PeriodModel {
    day: number;
    month: string;
    year: number;
}

export type PluginResultData = CSV<string, string>;

export interface ModelExploreScenarioAssessmentResultModel {
    spatial: PluginResultData;
    temporal: PluginResultData;
}

export interface ModelExploreScenarioIntermediateResultModel {
    yearly: PluginResultData;
    daily: PluginResultData;
}

export interface ModelExploreScenarioResultModel {
    assessment: ModelExploreScenarioAssessmentResultModel;
    intermediate: ModelExploreScenarioIntermediateResultModel;
}

export interface OpportunitiesYearResult {
    label: RiskLevel | SuccessType;
    data: number[];
}

export interface SummaryPercentResult {
    key: string;
    percent: number;
}

export type OpportunitiesResult = Record<number, OpportunitiesYearResult>;

export interface SummaryResult {
    scenarioId: string;
    results: SummaryPercentResult[];
}

export interface ModelBaseScenario {
    id: string;
    name: string;
    version: string;
    isDefault: boolean;
    hasAssessment: boolean;
    hasIntermediate: boolean;
    run: ModelScenarioRunModel;
    files: ModelExploreScenarioFile[];
}

export interface ModelExploreScenario extends ModelBaseScenario {
    isSelected: boolean;
    isBaseline: boolean;
    hasLoadedAssessment: boolean;
    hasLoadedIntermediate: boolean;
    result: ModelExploreScenarioResultModel;
}

export interface ModelExploreIntermediateResultType {
    id: string;
    label: string;
    scale: ModelExploreIntermediateResultTypeScale;
}

export enum ModelExploreIntermediateResultTypeScale {
    DAILY = "daily",
    YEARLY = "yearly"
}

export interface ModelExploreScenarioResult {
    scenario: ModelExploreScenario;
    results: ModelExploreResult[];
}

export interface ModelExploreResult {
    type: ScenarioResultType;
    shouldDisplay: boolean;
    downloadUrl: string;
    modelName: string;
    scenarioName: string;
    date: string;
    extension: string;
    name: string;
}

export interface ModelExploreScenarioFile {
    id: string;
    blobId: string;
    name: string;
    extension: string;
    type: ScenarioResultType;
    createdAt: Date;
}

export interface ScenarioExplorerSelectOption extends SelectOption {
    scenario: ModelExploreScenario;
}

export interface ERPChartData {
    labels: Array<string | string[] | number | number[] | Date | Date[]>;
    data: Array<ChartDataset>;
}

export type ERPTimerseriesChartData = Array<ChartDataset<"line", TimeseriesDay[]>>;

export type ERPPointChartData = Array<ChartDataset<"line", { x: number; y: number }[]>>;

export interface ModelExploreSummaryResults {
    spatialResults: SummaryResult[];
    nodeResults: SummaryNodeResult[];
    model: ModelModel;
}

export interface ModelExploreOpportunitiesResults {
    spatialResults: OpportunitiesResult[];
    nodeResults: OpportunitiesNodeResult[];
    resultPeriod: ExplorePeriodModel;
    model: ModelModel;
}

export interface ModelExploreIntermediateResults {
    nodeResults: Record<string, IntermediateNodeResult>;
    yearlyResultPeriod: ExplorePeriodModel;
    dailyResultPeriod?: ExploreDailyPeriodModel;
    minValue?: number;
    maxValue?: number;
    resultsType: ModelExploreIntermediateResultType;
}

export interface SummaryNodeResult {
    name: string;
    results: SummaryResult[];
}

export interface OpportunitiesNodeResult {
    name: string;
    results: OpportunitiesResult[];
}

export type IntermediateNodeResult = Record<string, IntermediateNodeScenarioResult>;

export type IntermediateNodeScenarioResult = Record<number, IntermediateNodeScenarioYearResult> | TimeseriesDay[];

export type IntermediateNodeScenarioYearResult = TimeseriesDay[];

export interface ExplorePeriodModel {
    startYear: number;
    endYear: number;
    years: number[];
    sliderMarks: number[];
}

export interface ExploreDailyPeriodModel {
    startDay: number;
    endDay: number;
    days: number[];
    sliderMarks: number[];
}

export interface OpportunitiesSpatialResults {
    scenarioResults: OpportunitiesResult[];
    resultPeriod: ExplorePeriodModel;
}

export interface WaterholeCalibrationBathymetryDataPoint {
    depth: number;
    height: number;
    volume: number;
    area: number;
}

export interface SystemDataPreviewSettings {
    selectedScenario: ScenarioModel;
    selectedLocation: LocationModel;
    selectedDataType: DataType;
}

export interface TimeseriesDay {
    date: Date;
    value: number;
}

export interface SystemDataPreviewScenarioData {
    scenarioId: string;
    scenarioName: string;
    locationsData: SystemDataPreviewLocationData[];
}

export interface SystemDataPreviewLocationData {
    locationId: string;
    dataType: DataType;
    data: TimeseriesDay[];
}
