import React from "react";
import { ModelExploreOpportunitiesResults, ModelExploreScenario } from "../../types/models";
import ModelExploreNodeTemporalSuccessChart from "./ModelExploreNodeTemporalSuccessChart";

interface ModelExploreNodeTemporalSuccessChartsProps {
    selectedScenarios: ModelExploreScenario[];
    resultsData: ModelExploreOpportunitiesResults;
    title: string;
}

const ModelExploreNodeTemporalSuccessCharts = ({
    selectedScenarios,
    resultsData,
    title
}: ModelExploreNodeTemporalSuccessChartsProps) => {
    return (
        <>
            <h5>{title}</h5>

            {resultsData.nodeResults.map((node, idx) => {
                return (
                    <ModelExploreNodeTemporalSuccessChart
                        key={`${node.name}_${idx}`}
                        selectedScenarios={selectedScenarios}
                        results={node.results}
                        resultPeriod={resultsData.resultPeriod}
                        node={node.name}
                    />
                );
            })}
        </>
    );
};

export default ModelExploreNodeTemporalSuccessCharts;
