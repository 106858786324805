import React from "react";
import { faCopy, faPencilAlt, faTrashAlt, faFileExport } from "@fortawesome/pro-regular-svg-icons";
import TooltipIcon from "../../Components/TooltipIcon";

interface ModelRowActionsProps {
    canEdit: boolean;
    canDelete: boolean;
    onMove: () => void;
    onDelete: () => void;
    onCopy: () => void;
    onEdit: () => void;
}

const ModelRowActions = ({ canEdit, canDelete, onMove, onDelete, onCopy, onEdit }: ModelRowActionsProps) => {
    return (
        <div className="action-container">
            <TooltipIcon icon={faPencilAlt} tooltip="Edit model" disabled={!canEdit} onClick={onEdit} />
            <TooltipIcon icon={faTrashAlt} tooltip="Delete model" disabled={!canDelete} onClick={onDelete} />
            <TooltipIcon icon={faCopy} tooltip="Copy model" onClick={onCopy} />
            <TooltipIcon icon={faFileExport} tooltip="Move model" onClick={onMove} />
        </div>
    );
};

export default ModelRowActions;
