import Label from "../../Components/Label";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CriteriaTypes } from "../../types/plugin";
import { PluginFieldProps, PluginForm } from "./types";
import NumericInput from "../../Components/NumericInput";
import RadioButton from "../../Components/RadioButton";
import ComparisonSelect from "../../Components/ComparisonSelect";

interface PluginParameterDailyAggProps extends PluginFieldProps {
    onChange: (field: string, value: Record<string, string | number>) => void;
}

const PluginParameterDailyAgg = ({ definition, field, control, disabled, onChange }: PluginParameterDailyAggProps) => {
    const { getValues, watch } = useFormContext<PluginForm>();

    const [dailyAgg] = watch([`${definition.id}.${field.id}`]);
    const enableThreshold = dailyAgg?.criteria === CriteriaTypes.THRESHOLD;

    const handleComparisonChanged = (value: string) => {
        const criteria = getValues(`${definition.id}.${field.id}.criteria`);
        const threshold = getValues(`${definition.id}.${field.id}.threshold`);

        onChange(field.id, {
            comparison: value,
            criteria: criteria,
            threshold: threshold
        });
    };

    const handleCriteriaChanged = (type: CriteriaTypes) => {
        const comparison = getValues(`${definition.id}.${field.id}.comparison`);
        const threshold = getValues(`${definition.id}.${field.id}.threshold`);

        onChange(field.id, {
            comparison: comparison,
            threshold: type !== CriteriaTypes.MEDIAN ? threshold : null,
            criteria: type
        });
    };

    const handleThresholdChanged = (value: number) => {
        const comparison = getValues(`${definition.id}.${field.id}.comparison`);
        const criteria = getValues(`${definition.id}.${field.id}.criteria`);

        onChange(field.id, {
            threshold: value ?? null,
            comparison: comparison,
            criteria: criteria
        });
    };

    return (
        <div className="assessment-parameter-field">
            <Label className="plugin-definition-field-label">{field.name}</Label>
            <div className="assessment-params">
                Daily success if daily value is{" "}
                <Controller
                    control={control}
                    name={`${definition.id}.${field.id}.comparison`}
                    render={({ field: _field }) => {
                        return (
                            <ComparisonSelect
                                id={`${definition.id}.${field.id}.comparison`}
                                value={_field.value}
                                onComparisonChanged={handleComparisonChanged}
                                className="assessment-select"
                                disabled={disabled}
                            />
                        );
                    }}
                />
                <Controller
                    control={control}
                    name={`${definition.id}.${field.id}.criteria`}
                    render={({ field: _field }) => {
                        return (
                            <div className="assessment-button-group">
                                <RadioButton
                                    containerClassName="assessment-radio-button"
                                    label="Median"
                                    checked={_field.value === CriteriaTypes.MEDIAN}
                                    onChecked={() => handleCriteriaChanged(CriteriaTypes.MEDIAN)}
                                    disabled={disabled}
                                />
                                <RadioButton
                                    containerClassName="assessment-radio-button"
                                    label="Threshold"
                                    checked={_field.value === CriteriaTypes.THRESHOLD}
                                    onChecked={() => handleCriteriaChanged(CriteriaTypes.THRESHOLD)}
                                    disabled={disabled}
                                />{" "}
                            </div>
                        );
                    }}
                />
                <Controller
                    control={control}
                    name={`${definition.id}.${field.id}.threshold`}
                    render={({ field: _field }) => {
                        return (
                            <NumericInput
                                value={_field.value !== null ? _field.value : ""}
                                onChange={handleThresholdChanged}
                                containerClassName="assessment-select"
                                disabled={!enableThreshold || disabled}
                            />
                        );
                    }}
                />
            </div>
        </div>
    );
};

export default PluginParameterDailyAgg;
