import React from "react";
import Humanize from "humanize-plus";
import clsx from "clsx";

interface ChartLegendItemProps {
    label: string;
    isSelectable: boolean;
    isSelected?: boolean;
    onSelected?: (label: string, active: boolean) => void;
}

const ChartLegendItem = ({ label, isSelectable, isSelected, onSelected }: ChartLegendItemProps) => {
    const handleSelected = () => {
        if (isSelectable) {
            onSelected(label, !isSelected);
        }
    };

    return (
        <div className="chart-legend-item" onClick={handleSelected}>
            <div className={clsx("legend-colour", label)}></div>

            <span className={clsx("legend-text", { "not-active": !isSelected && isSelectable })}>
                {Humanize.capitalizeAll(label.replace("-", " "))}
            </span>
        </div>
    );
};

export default ChartLegendItem;
