import React, { useState } from "react";
import Button from "../../Components/Button";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { Dropdown } from "react-bootstrap";
import { faFolderPlus } from "@fortawesome/pro-light-svg-icons";
import SystemIcon from "../../Components/ERPIcon/SystemIcon";
import ModelIcon from "../../Components/ERPIcon/ModelIcon";
import { useHistory } from "react-router-dom";
import { CreateModelRequest, CreateSystemRequest } from "../../types/requests";
import FolderService from "../../Services/folder.service";
import ERPIcon from "../../Components/ERPIcon";

interface NewButtonProps {
    folderId: string;
    onNewFolder: () => void;
}

const NewButton = ({ folderId, onNewFolder }: NewButtonProps) => {
    const history = useHistory();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleNewSystem = async () => {
        try {
            setIsLoading(true);

            const request: CreateSystemRequest = {
                name: "New System",
                description: null
            };

            const system = await FolderService.createSystem(folderId, request);

            history.push(`/system/${system.id}`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleNewModel = async () => {
        try {
            setIsLoading(true);

            const request: CreateModelRequest = {
                name: "New Model",
                description: null,
                assessmentEndpoint: null,
                authors: null,
                caveats: null,
                measurementEndpoint: null
            };

            const model = await FolderService.createModel(folderId, request);

            history.push(`/model/${model.id}`);
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <Dropdown>
            <Dropdown.Toggle className="new-button" disabled={isLoading}>
                New <ERPIcon className="new-button-icon" icon={faPlus} isLoading={isLoading} />
            </Dropdown.Toggle>

            <Dropdown.Menu align="right" className="new-button-menu">
                <Dropdown.Item as={Button} variant="none" eventKey="1" onClick={onNewFolder}>
                    <ERPIcon icon={faFolderPlus} />
                    Folder
                </Dropdown.Item>

                <Dropdown.Divider />

                <Dropdown.Item as={Button} variant="none" eventKey="2" onClick={handleNewSystem}>
                    <SystemIcon />
                    System
                </Dropdown.Item>

                <Dropdown.Item as={Button} variant="none" eventKey="3" onClick={handleNewModel}>
                    <ModelIcon />
                    Model
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default NewButton;
