import { BassParameters } from "../../types/plugin-parameter-types";
import { PluginParameters } from "../../types/plugin";
import { BaseParameterMerger } from "./base-parameter-merger";

export class BassParameterMerger extends BaseParameterMerger<BassParameters> {
    public merge(source: PluginParameters, defaultParameters: BassParameters): BassParameters {
        return {
            flow_parameters: {
                use_flow_percentiles: this.defaultTo(
                    source?.flow_parameters?.use_flow_percentiles,
                    defaultParameters?.flow_parameters?.use_flow_percentiles
                ),
                in_season_threshold: this.defaultTo(
                    source?.flow_parameters?.in_season_threshold,
                    defaultParameters?.flow_parameters?.in_season_threshold
                ),
                out_of_season_threshold: this.defaultTo(
                    source?.flow_parameters?.out_of_season_threshold,
                    defaultParameters?.flow_parameters?.out_of_season_threshold
                ),
                depth_threshold: this.defaultTo(
                    source?.flow_parameters?.depth_threshold,
                    defaultParameters?.flow_parameters?.depth_threshold
                )
            },
            season: {
                season_start: this.defaultTo(source?.season?.season_start, defaultParameters?.season?.season_start),
                season_end: this.defaultTo(source?.season?.season_end, defaultParameters?.season?.season_end),
                season_type: this.defaultTo(source?.season?.season_type, defaultParameters?.season?.season_type),
                temperature_threshold: this.defaultTo(
                    source?.season?.temperature_threshold,
                    defaultParameters?.season?.temperature_threshold
                )
            },
            assessment: {
                daily_agg: this.defaultTo(source?.assessment?.daily_agg, defaultParameters?.assessment?.daily_agg),
                yearly_agg: this.defaultTo(source?.assessment?.yearly_agg, defaultParameters?.assessment?.yearly_agg),
                temporal_agg: this.defaultTo(
                    source?.assessment?.temporal_agg,
                    defaultParameters?.assessment?.temporal_agg
                )
            },
            spatial_agg: {
                risk_categories: this.defaultTo(
                    source?.spatial_agg?.risk_categories,
                    defaultParameters?.spatial_agg?.risk_categories
                )
            }
        };
    }
}
