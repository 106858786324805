import { forEach, groupBy, isNil } from "lodash";
import { DownloadsSettingsModel } from "../Scenes/Model/ModelContext";
import { CSV } from "../types/global";
import {
    ModelBaseScenario,
    ModelExploreResult,
    ModelExploreScenarioAssessmentResultModel,
    ModelExploreScenarioFile,
    ModelExploreScenarioIntermediateResultModel,
    ModelExploreScenarioResult,
    ModelScenarioRunModel,
    PluginResultData,
    ScenarioResultType
} from "../types/models";
import {
    getComputationResultTypeDisplayName,
    isComputationFileTypeCustom,
    isComputationFileTypeDaily,
    isComputationFileTypeToBeCombined
} from "../utils/explorer.utils";
import { isNilOrEmpty } from "../utils/utils";
import AzureBlobService from "./azure.blob.service";
import { CSVParser } from "./csv-parser";
import ZipGenerator, { ZipFileInfo } from "./zip-generator";

export default class ExploreDataService {
    private static readonly generator = new ZipGenerator();
    private static readonly csvParser = new CSVParser();

    static async zipFiles(files: ZipFileInfo[]): Promise<Blob> {
        return this.generator.generate(files);
    }

    static async fetchResultFiles(
        result: ModelExploreResult,
        name: string,
        downloadSettings: DownloadsSettingsModel,
        isIndividualDownload?: boolean
    ): Promise<File[]> {
        if (
            !isIndividualDownload &&
            !downloadSettings.shouldIncludeDailyResults &&
            isComputationFileTypeDaily(result.type)
        ) {
            return [];
        }

        if (isComputationFileTypeCustom(result.type)) {
            const customFiles = await this.fetchAndFormatCustomResultFiles(
                result,
                name,
                downloadSettings.shouldSeperateDailyResults
            );

            return customFiles;
        } else {
            const data = await AzureBlobService.downloadBlobAsFile(result.downloadUrl, `${name}.${result.extension}`);

            return [data];
        }
    }

    static async fetchAndFormatCustomResultFiles(
        result: ModelExploreResult,
        name: string,
        shouldSeperateDailyResults: boolean
    ): Promise<File[]> {
        switch (result.type) {
            case ScenarioResultType.DAILY_INTERMEDIATE:
                return this.generateDailyResultFiles(result, name, shouldSeperateDailyResults);
            case ScenarioResultType.DAILY_RESULTS:
                return this.generateDailyResultFiles(result, name, shouldSeperateDailyResults);
            default:
                return [];
        }
    }

    static async generateDailyResultFiles(
        result: ModelExploreResult,
        name: string,
        shouldSeperateDailyResults: boolean
    ): Promise<File[]> {
        if (!shouldSeperateDailyResults) {
            return [await AzureBlobService.downloadBlobAsFile(result.downloadUrl, `${name}.${result.extension}`)];
        }

        const files: File[] = [];

        const data = await AzureBlobService.downloadBlobAsString(result.downloadUrl);

        if (isNilOrEmpty(data)) {
            return;
        }

        const parsedData = this.csvParser.parse<string, string>(data);

        files.push(
            new File([this.csvParser.formatToString<string, string>(parsedData)], `${name}/${name}.${result.extension}`)
        );

        const headers = parsedData.headers;
        const nodes = groupBy(parsedData.rows, r => r.node);

        forEach(nodes, (data, nodeName) => {
            const csvData = { headers: headers, rows: data } as CSV<string, string>;

            files.push(
                new File(
                    [this.csvParser.formatToString<string, string>(csvData)],
                    `${name}/${nodeName}_${name}.${result.extension}`
                )
            );
        });

        return files;
    }

    static async createCombinedResultFiles(
        scenarioResults: ModelExploreScenarioResult[],
        name: string,
        downloadSettings: DownloadsSettingsModel
    ): Promise<File[]> {
        if (!downloadSettings.shouldCreateCombinedResults) {
            return [];
        }

        const files: File[] = [];

        const resultsFlat = scenarioResults.map(r => r.results).flat();

        const resultsFlatFiltered = resultsFlat.filter(r => isComputationFileTypeToBeCombined(r.type));

        const resultsGrouped = groupBy(resultsFlatFiltered, r => r.type);

        const resultTypes = Object.keys(resultsGrouped) as ScenarioResultType[];

        for (let i = 0; i < resultTypes.length; i++) {
            const resultType = resultTypes[i];
            const results = resultsGrouped[resultType];
            const resultData = [];
            const resultFileName = getComputationResultTypeDisplayName(resultType);

            for (let j = 0; j < results.length; j++) {
                const result = results[j];

                const data = await AzureBlobService.downloadBlobAsString(result.downloadUrl);

                const parsedData = this.csvParser.parse<string, string>(data);

                const parsedDataWithScenario = this.addScenarioToCSV(parsedData, result.scenarioName);

                resultData.push(parsedDataWithScenario);
            }

            const combinedRows = resultData.map(r => r.rows).flat();
            const headers = !isNilOrEmpty(resultData) ? resultData[0].headers : [];

            const csvData = { headers: headers, rows: combinedRows } as CSV<string, string>;

            const resultName = !isNil(name) ? `${name}_${resultFileName}` : `${resultFileName}`;

            files.push(
                new File([this.csvParser.formatToString<string, string>(csvData)], `Combined/${resultName}.csv`)
            );
        }

        return files;
    }

    static addScenarioToCSV(csvData: CSV<string, string>, scenarioName: string): CSV<string, string> {
        const newHeader = ["scenario", ...csvData.headers];

        const newRows = csvData.rows.map(r => {
            return { scenario: scenarioName, ...r };
        });

        const newCsvData: CSV<string, string> = { headers: newHeader, rows: newRows };

        return newCsvData;
    }

    static async loadScenarioAssessmentResults(
        scenario: ModelBaseScenario
    ): Promise<ModelExploreScenarioAssessmentResultModel> {
        const [spatial, temporal] = await Promise.all([
            this.fetchAndParseResult(
                scenario.run,
                scenario.files.find(r => r.type === ScenarioResultType.SPATIAL_RESULTS)
            ),
            this.fetchAndParseResult(
                scenario.run,
                scenario.files.find(r => r.type === ScenarioResultType.TEMPORAL_RESULTS)
            )
        ]);

        const result: ModelExploreScenarioAssessmentResultModel = {
            spatial: spatial,
            temporal: temporal
        };

        return result;
    }

    static async loadScenarioIntermediateResults(
        scenario: ModelBaseScenario
    ): Promise<ModelExploreScenarioIntermediateResultModel> {
        const [daily, yearly] = await Promise.all([
            this.fetchAndParseResult(
                scenario.run,
                scenario.files.find(r => r.type === ScenarioResultType.DAILY_INTERMEDIATE)
            ),
            this.fetchAndParseResult(
                scenario.run,
                scenario.files.find(r => r.type === ScenarioResultType.YEARLY_INTERMEDIATE)
            )
        ]);

        const result: ModelExploreScenarioIntermediateResultModel = {
            daily: daily,
            yearly: yearly
        };

        return result;
    }

    static async fetchAndParseResult(
        run: ModelScenarioRunModel,
        file: ModelExploreScenarioFile
    ): Promise<PluginResultData> {
        if (isNil(file) || isNil(run)) {
            return { rows: [], headers: [] };
        }

        const data = await AzureBlobService.downloadBlobAsString(`${run.id}/${file.blobId}`);

        if (isNilOrEmpty(data)) {
            return { rows: [], headers: [] };
        }

        const parsedData = this.csvParser.parse<string, string>(data);

        return parsedData;
    }
}
