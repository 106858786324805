import React from "react";
import NumericInput from "../../Components/NumericInput";
import RadioButton from "../../Components/RadioButton";
import { Controller, useFormContext } from "react-hook-form";
import { ARITypes } from "../../types/plugin";
import { PluginFieldProps, PluginForm } from "./types";
import Checkbox from "../../Components/Checkbox";

interface PluginParameterARIProps extends PluginFieldProps {
    onChange?: (field: string, value: Record<string, string | number | boolean>) => void;
}

const PluginParameterARI = ({ id, definition, field, control, disabled, onChange }: PluginParameterARIProps) => {
    const { watch } = useFormContext<PluginForm>();

    const [watchedValues] = watch([`${definition.id}.${field.id}`]);
    const isFlowTypeSelected = watchedValues?.type === ARITypes.FLOW;

    const handleARIChanged = (value: number) => {
        const currentType = watchedValues?.type;
        const currentUseDefaultData = watchedValues?.use_default_data;

        onChange("ari", {
            type: currentType,
            value: value,
            use_default_data: currentUseDefaultData
        });
    };

    const handleARITypeChanged = (type: ARITypes) => {
        const currentValue = watchedValues?.value;
        const currentUseDefaultData = watchedValues?.use_default_data;

        onChange("ari", {
            type: type,
            value: currentValue,
            use_default_data: currentUseDefaultData
        });
    };

    const handleARIUseDefaultDataChanged = (useDefaultData: boolean) => {
        const currentType = watchedValues?.type;
        const currentValue = watchedValues?.value;

        onChange("ari", {
            type: currentType,
            value: currentValue,
            use_default_data: useDefaultData
        });
    };

    return (
        <div className="ari-parameter-field">
            <Controller
                control={control}
                name={`${definition.id}.${field.id}.type`}
                render={({ field: _field }) => {
                    return (
                        <div className="ari-radio-button-group">
                            <RadioButton
                                containerClassName="ari-radio-button"
                                label="Use ARI flow"
                                checked={_field.value === ARITypes.FLOW}
                                disabled={disabled}
                                onChecked={() => handleARITypeChanged(ARITypes.FLOW)}
                            />

                            <RadioButton
                                containerClassName="ari-radio-button"
                                label="Floodplain wetland connection threshold"
                                checked={_field.value === ARITypes.THRESHOLD}
                                disabled={disabled}
                                onChecked={() => handleARITypeChanged(ARITypes.THRESHOLD)}
                            />
                        </div>
                    );
                }}
            />

            {isFlowTypeSelected && (
                <div className="ari-group">
                    <Controller
                        control={control}
                        name={`${definition.id}.${field.id}.value`}
                        render={({ field: _field }) => {
                            return (
                                <NumericInput
                                    id={id}
                                    containerClassName="ari-input"
                                    value={isFlowTypeSelected ? _field.value : ""}
                                    min={0}
                                    disabled={disabled || !isFlowTypeSelected}
                                    onChange={handleARIChanged}
                                    unit="years"
                                />
                            );
                        }}
                    />

                    <Controller
                        control={control}
                        name={`${definition.id}.${field.id}.use_default_data`}
                        render={({ field: _field }) => {
                            return (
                                <Checkbox
                                    id={id}
                                    containerClassName="ari-data-checkbox"
                                    label="Calculate from default data"
                                    checked={_field.value && isFlowTypeSelected}
                                    onChecked={handleARIUseDefaultDataChanged}
                                    disabled={disabled || !isFlowTypeSelected}
                                />
                            );
                        }}
                    />
                </div>
            )}

            {!isFlowTypeSelected && (
                <Controller
                    control={control}
                    name={`${definition.id}.${field.id}.value`}
                    render={({ field: _field }) => {
                        return (
                            <NumericInput
                                id={id}
                                containerClassName="threshold-input"
                                value={!isFlowTypeSelected ? _field.value : ""}
                                min={0}
                                disabled={disabled || isFlowTypeSelected}
                                onChange={handleARIChanged}
                            />
                        );
                    }}
                />
            )}
        </div>
    );
};

export default PluginParameterARI;
