import React from "react";
import Button from "../../Components/Button";

interface ModelLocationAllSelectionButtonProps {
    allSelected: boolean;
    disabled: boolean;
    onSelectAll: (selectAll: boolean) => void;
}

const ModelLocationAllSelectionButton = ({
    allSelected,
    disabled,
    onSelectAll
}: ModelLocationAllSelectionButtonProps) => {
    const label = allSelected ? "Disable All" : "Enable All";

    const handleClick = () => {
        onSelectAll(!allSelected);
    };

    return (
        <Button disabled={disabled} onClick={handleClick}>
            {label}
        </Button>
    );
};

export default ModelLocationAllSelectionButton;
