import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Checkbox from "../../Components/Checkbox";
import { FolderModel } from "../../types/models";
import { faFolder } from "@fortawesome/pro-solid-svg-icons";
import FolderRowActions from "./FolderRowActions";
import DateTimeCell from "./DateTimeCell";
import { fullname } from "../../utils/utils";

interface FolderRowProps {
    folder: FolderModel;
    isSelected: boolean;
    onFolderNavigation: (folder: FolderModel) => void;
    onFolderMove: (folder: FolderModel) => void;
    onFolderEdit: (folder: FolderModel) => void;
    onFolderDelete: (folder: FolderModel) => void;
    onFolderCopy: (folder: FolderModel) => void;
    onSelected: (folder: FolderModel, selected: boolean) => void;
}

const FolderRow = ({
    folder,
    isSelected,
    onFolderNavigation,
    onFolderMove,
    onFolderEdit,
    onFolderDelete,
    onFolderCopy,
    onSelected
}: FolderRowProps) => {
    const [isHovering, setIsHovering] = useState<boolean>(false);

    const handleFolderNavigation = () => {
        onFolderNavigation(folder);
    };

    const handleMove = () => {
        onFolderMove(folder);
    };

    const handleEdit = () => {
        onFolderEdit(folder);
    };

    const handleDelete = () => {
        onFolderDelete(folder);
    };

    const handleCopy = () => {
        onFolderCopy(folder);
    };

    const handleSelected = (selected: boolean) => {
        onSelected(folder, selected);
    };

    return (
        <tr className="folder-row" onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
            <td style={{ width: 50 }} className="selection-cell">
                <Checkbox checked={isSelected} onChecked={handleSelected} />
            </td>

            <td onClick={handleFolderNavigation} style={{ maxWidth: 50 }}>
                <FontAwesomeIcon icon={faFolder} />
            </td>

            <td onClick={handleFolderNavigation}>{folder.name}</td>

            <td onClick={handleFolderNavigation} className="centered-cell status-cell">
                Folder
            </td>

            <td onClick={handleFolderNavigation} className="centered-cell status-cell">
                {fullname(folder.createdBy)}
            </td>

            <DateTimeCell dateTime={folder.updatedAt} onClick={handleFolderNavigation} />

            <td className="action-cell">
                {isHovering && (
                    <FolderRowActions
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        onCopy={handleCopy}
                        onMove={handleMove}
                    />
                )}
            </td>
        </tr>
    );
};

export default FolderRow;
