import React, { useEffect, useState } from "react";
import { SearchModel } from "../../types/models";
import { debounce } from "lodash";
import FolderService from "../../Services/folder.service";
import SearchResultsHeader from "./SearchResultsHeader";
import SearchResultsTable from "./SearchResultsTable";

interface SearchViewProps {
    search: string;
    onFolderClick: (folderId: string, ancestorIds: string[]) => void;
}

const DEBOUCE_SEARCH_WAIT_MS = 200;

const SearchView = ({ search, onFolderClick }: SearchViewProps) => {
    const [searchResults, setSearchResults] = useState<SearchModel[]>([]);
    const [searching, setSearching] = useState<boolean>(false);

    const doSearch = async (searchText: string) => {
        try {
            const searchResults = await FolderService.searchFolders(searchText);

            setSearchResults(searchResults);
        } finally {
            setSearching(false);
        }
    };

    const [debouncedSearch] = useState(() => debounce(doSearch, DEBOUCE_SEARCH_WAIT_MS));

    useEffect(() => {
        setSearching(true);
        debouncedSearch(search);
    }, [search]);

    return (
        <>
            <SearchResultsHeader resultsCount={searchResults.length} />

            <SearchResultsTable searchResults={searchResults} isLoading={searching} onFolderClick={onFolderClick} />
        </>
    );
};

export default SearchView;
