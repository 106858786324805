import React, { useEffect, useMemo, useState } from "react";
import {
    ModelExploreScenario,
    ModelExploreIntermediateResultType,
    ModelExploreIntermediateResultTypeScale,
    ModelExploreIntermediateResults,
    ExplorePeriodModel,
    ExploreDailyPeriodModel
} from "../../types/models";
import { formatDailyIntermediateResults, formatYearlyIntermediateResults } from "../../utils/explorer.utils";
import ModelExploreNodeIntermediateCharts from "./ModelExploreNodeIntermediateCharts";
import { SliderValues } from "../../Components/Slider/types";
import ERPSlider from "../../Components/Slider/Slider";
import { forEach, isNil } from "lodash";
import LoadingIndicator from "../../Components/LoadingIndicator";
import { useModel } from "./ModelContext";

interface ModelExploreIntermediateProps {
    selectedIntermediateResultType: ModelExploreIntermediateResultType;
}

const DEFAULT_YEAR_LENGTH = 20;

const getIntermediateData = (
    scenarios: ModelExploreScenario[],
    intermediateResultType: ModelExploreIntermediateResultType
) => {
    switch (intermediateResultType.scale) {
        case ModelExploreIntermediateResultTypeScale.DAILY:
            return formatDailyIntermediateResults(scenarios, intermediateResultType);

        case ModelExploreIntermediateResultTypeScale.YEARLY:
            return formatYearlyIntermediateResults(scenarios, intermediateResultType);

        default:
            return null;
    }
};

const ModelExploreIntermediate = ({ selectedIntermediateResultType }: ModelExploreIntermediateProps) => {
    const { explorerFlags, explorerScenarios, loadExplorerIntermediateData } = useModel();

    const [selectedYearlyResultPeriod, setSelectedYearlyResultPeriod] = useState<ExplorePeriodModel>(null);
    const [selectedDailyResultPeriod, setSelectedDailyResultPeriod] = useState<ExploreDailyPeriodModel>(null);
    const [intermediateData, setIntermediateData] = useState<ModelExploreIntermediateResults>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const selectedScenarios = explorerScenarios.filter(s => s.isSelected);

    const hasLoadedIntermediate = selectedScenarios.every(s => s.hasLoadedIntermediate);

    useEffect(() => {
        loadExplorerIntermediateData(selectedScenarios.map(s => s.id));
    }, [selectedScenarios]);

    const getData = async () => {
        setIsLoading(true);

        const data: ModelExploreIntermediateResults = await new Promise(resolve => {
            const data = getIntermediateData(selectedScenarios, selectedIntermediateResultType);
            resolve(data);
        });

        if (isNil(selectedDailyResultPeriod)) {
            setSelectedDailyResultPeriod(data.dailyResultPeriod);
        }

        if (isNil(selectedYearlyResultPeriod)) {
            // Default to show only the last DEFAULT_YEAR_LENGTH years of data
            const yearlyResultPeriod = {
                ...data.yearlyResultPeriod,
                startYear:
                    data.yearlyResultPeriod.endYear - data.yearlyResultPeriod.startYear <= DEFAULT_YEAR_LENGTH
                        ? data.yearlyResultPeriod.startYear
                        : data.yearlyResultPeriod.endYear - DEFAULT_YEAR_LENGTH
            };

            setSelectedYearlyResultPeriod(yearlyResultPeriod);
        }

        setIntermediateData(data);
        setIsLoading(false);
    };

    useEffect(() => {
        if (!hasLoadedIntermediate || isNil(selectedIntermediateResultType)) {
            return;
        }

        getData();
    }, [selectedIntermediateResultType, hasLoadedIntermediate]);

    const viewableIntermediateData: ModelExploreIntermediateResults = useMemo(() => {
        if (isNil(intermediateData)) {
            return null;
        }

        const filteredData = {};

        forEach(Object.keys(intermediateData.nodeResults), node => {
            filteredData[node] = {};

            forEach(Object.keys(intermediateData.nodeResults[node]), scenario => {
                if (selectedScenarios.some(s => s.name === scenario)) {
                    filteredData[node][scenario] = intermediateData.nodeResults[node][scenario];
                }
            });
        });

        return { ...intermediateData, nodeResults: filteredData };
    }, [selectedScenarios, intermediateData]);

    const handleSelectedYearlyPeriodChange = (newValues: SliderValues) => {
        setSelectedYearlyResultPeriod({
            ...selectedYearlyResultPeriod,
            startYear: newValues.from,
            endYear: newValues.to
        });
    };

    const handleSelectedDailyPeriodChange = (newValues: SliderValues) => {
        setSelectedDailyResultPeriod({
            ...selectedDailyResultPeriod,
            startDay: newValues.from,
            endDay: newValues.to
        });
    };

    const hasResults = hasLoadedIntermediate && !isNil(viewableIntermediateData);

    return (
        <div className="explore-summary">
            {!isNil(viewableIntermediateData) && (
                <>
                    {!isNil(selectedYearlyResultPeriod) && (
                        <ERPSlider
                            containerClassName="explore-slider"
                            values={{
                                from: selectedYearlyResultPeriod?.startYear,
                                to: selectedYearlyResultPeriod?.endYear
                            }}
                            min={selectedYearlyResultPeriod.years[0]}
                            max={selectedYearlyResultPeriod.years[selectedYearlyResultPeriod.years.length - 1]}
                            marks={selectedYearlyResultPeriod.sliderMarks}
                            title="Years"
                            onChange={handleSelectedYearlyPeriodChange}
                        />
                    )}

                    {!isNil(selectedDailyResultPeriod) &&
                        viewableIntermediateData.resultsType.scale ===
                            ModelExploreIntermediateResultTypeScale.DAILY && (
                            <ERPSlider
                                containerClassName="explore-slider"
                                values={{
                                    from: selectedDailyResultPeriod?.startDay,
                                    to: selectedDailyResultPeriod?.endDay
                                }}
                                min={selectedDailyResultPeriod.days[0]}
                                max={selectedDailyResultPeriod.days[selectedDailyResultPeriod.days.length - 1]}
                                marks={selectedDailyResultPeriod.sliderMarks}
                                title="Days"
                                onChange={handleSelectedDailyPeriodChange}
                            />
                        )}
                </>
            )}

            {(explorerFlags.isLoadingIntermediate || isLoading) && <LoadingIndicator centered={true} />}

            {!isLoading && hasResults && (
                <ModelExploreNodeIntermediateCharts
                    resultsData={viewableIntermediateData}
                    yearlyPeriod={selectedYearlyResultPeriod}
                    dailyPeriod={selectedDailyResultPeriod}
                />
            )}
        </div>
    );
};

export default ModelExploreIntermediate;
