import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import { ChartOptions } from "chart.js";
import ERPChart from "../../Components/Charts/ERPChart";
import TooltipIcon from "../../Components/TooltipIcon";
import React from "react";
import { useMemo } from "react";
import { ERPChartData, WaterholeCalibrationBathymetryDataPoint } from "../../types/models";

interface PluginWaterholeCalibrationModalBathymetryChartProps {
    id: string;
    chartData: WaterholeCalibrationBathymetryDataPoint[];
}

const CHART_OPTIONS: ChartOptions = {
    maintainAspectRatio: false,
    normalized: true,
    interaction: {
        intersect: true,
        mode: "index"
    },
    layout: {
        padding: 5
    },
    plugins: {
        tooltip: {
            enabled: true
        },
        legend: {
            display: true,
            position: "top"
        },
        decimation: {
            enabled: true
        }
    },
    animation: false,
    scales: {
        y: {
            title: {
                display: true,
                text: "Volume (m³)"
            }
        },
        y1: {
            type: "linear",
            display: true,
            position: "right",
            grid: {
                drawOnChartArea: false
            },
            ticks: {
                font: {
                    size: 16
                }
            },
            title: {
                display: true,
                text: "Area (m²)"
            }
        },
        x: {
            title: {
                display: true,
                text: "Depth (m)"
            },
            reverse: true
        }
    }
};

const PluginWaterholeCalibrationModalBathymetryChart = ({
    id,
    chartData
}: PluginWaterholeCalibrationModalBathymetryChartProps) => {
    const data: ERPChartData = useMemo(() => {
        const labels = [];
        const data = [
            {
                label: "Volume",
                data: chartData?.map(d => {
                    return {
                        x: d.depth,
                        y: d.volume
                    };
                }),
                backgroundColor: "#00994D",
                borderColor: "#00994D",
                yAxisID: "y",
                pointHitRadius: 5,
                showLine: true
            },
            {
                label: "Area",
                data: chartData?.map(d => {
                    return {
                        x: d.depth,
                        y: d.area
                    };
                }),
                backgroundColor: "#E24999",
                borderColor: "#E24999",
                yAxisID: "y1",
                pointHitRadius: 5,
                showLine: true
            }
        ];

        return { labels: labels, data: data };
    }, [chartData]);

    return (
        <div className="bathymetry-chart">
            <TooltipIcon
                tooltip="Use shift+drag to zoom
                Double click to reset zoom
                Pan with the mouse"
                icon={faQuestionCircle}
                size={"2x"}
                className="chart-tooltip"
            />
            <ERPChart
                id={id}
                type="scatter"
                data={data.data}
                labels={data.labels}
                options={CHART_OPTIONS}
                height={1000}
                canZoom={true}
            />
        </div>
    );
};

export default PluginWaterholeCalibrationModalBathymetryChart;
