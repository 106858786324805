import { useState } from "react";
import { FolderModel, ModelModel, SystemModel } from "../../types/models";
import { SelectionState } from "./types";

export function useSelection(folders: FolderModel[], systems: SystemModel[], models: ModelModel[]) {
    const [selection, setSelection] = useState<SelectionState>({
        folders: new Set<string>(),
        systems: new Set<string>(),
        models: new Set<string>()
    });

    const selectAll = (selected: boolean) => {
        const state: SelectionState = {
            folders: new Set<string>(),
            systems: new Set<string>(),
            models: new Set<string>()
        };

        if (selected) {
            for (let i = 0; i < folders.length; i++) {
                const folder = folders[i];

                state.folders.add(folder.id);
            }

            for (let i = 0; i < systems.length; i++) {
                const system = systems[i];

                state.systems.add(system.id);
            }

            for (let i = 0; i < models.length; i++) {
                const model = models[i];

                state.models.add(model.id);
            }
        } else {
            state.folders.clear();
            state.systems.clear();
            state.models.clear();
        }

        setSelection(state);
    };

    const deselectAll = () => {
        if (selection.folders.size === 0 && selection.systems.size === 0 && selection.models.size === 0) {
            return;
        }

        selectAll(false);
    };

    const selectFolder = (folder: FolderModel, selected: boolean) => {
        const state: SelectionState = { ...selection };

        if (selected) {
            state.folders.add(folder.id);
        } else {
            state.folders.delete(folder.id);
        }

        setSelection(state);
    };

    const selectSystem = (system: SystemModel, selected: boolean) => {
        const state: SelectionState = { ...selection };

        if (selected) {
            state.systems.add(system.id);
        } else {
            state.systems.delete(system.id);
        }

        setSelection(state);
    };

    const selectModel = (model: ModelModel, selected: boolean) => {
        const state: SelectionState = { ...selection };

        if (selected) {
            state.models.add(model.id);
        } else {
            state.models.delete(model.id);
        }

        setSelection(state);
    };

    const deselectFolder = (folder: FolderModel) => {
        if (!selection.folders.has(folder.id)) {
            return;
        }

        selectFolder(folder, false);
    };

    const deselectSystem = (system: SystemModel) => {
        if (!selection.systems.has(system.id)) {
            return;
        }

        selectSystem(system, false);
    };

    const deselectModel = (model: ModelModel) => {
        if (!selection.models.has(model.id)) {
            return;
        }

        selectModel(model, false);
    };

    const clearSelection = () => {
        setSelection({ folders: new Set<string>(), systems: new Set<string>(), models: new Set<string>() });
    };

    return {
        selection,
        selectAll,
        deselectAll,
        selectFolder,
        deselectFolder,
        selectSystem,
        deselectSystem,
        selectModel,
        deselectModel,
        clearSelection
    };
}
