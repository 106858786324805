import { defaultTo } from "lodash";
import { PluginFieldValue, PluginParameters } from "../../types/plugin";

export abstract class BaseParameterMerger<T extends PluginParameters> {
    public abstract merge(source: T, defaultParameters: T): T;

    protected defaultTo(target: PluginFieldValue, source: PluginFieldValue): PluginFieldValue {
        return defaultTo(target, source) ?? null;
    }
}
