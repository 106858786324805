import { ChartDataset, ChartOptions } from "chart.js";
import { Control } from "react-hook-form";
import { PeriodModel, RunPeriodModel } from "../../types/models";
import { PluginDefinition, PluginField, PluginFieldValue } from "../../types/plugin";
import { WaterholeParameters } from "../../types/plugin-parameter-types";

export type PluginForm = Record<string, Record<string, any>>;

export interface PluginFieldProps {
    /**
     * Unique identifier to make sure this input is uniquely rendered amongst other plugin parameters.
     * This is important to avoid lose of focus on re-render caused by state change during parameter saving.
     */
    id: string;
    definition: PluginDefinition;
    field: PluginField;
    control: Control<PluginForm>;
    disabled?: boolean;
}

export interface ParameterCurveChartInfo {
    minX: number;
    maxX: number;
    minY: number;
    maxY: number;
    labels: string[] | number[];
    dataset: ChartDataset;
    options: ChartOptions;
}

export interface Point {
    x: number;
    y: number;
}

export type WaterholeCalibrationInputType = "numericInput" | "select";

export interface WaterholeCalibrationFieldTypes {
    id: string;
    type: WaterholeCalibrationInputType;
}

export interface WaterholeCalibrationBaseParameterValues {
    values: Partial<WaterholeParameters>;
    isModified: boolean;
}

export type WaterholeFields = Record<string, string[]>;

export interface WaterholeCalibrationSettings {
    population: number;
    generations: number;
    scaling: number;
    fit_statistic: WaterholeCalibrationFitStatisticType;
}

export interface WaterholeCalibrationPredictors {
    optimiseIds: string[];
}

export interface WaterholeCalibrationResults {
    rmse: number;
    r2: number;
    nnse: number;
    pbias: number;
    rsr: number;
    predictors?: Partial<WaterholeParameters>;
}

export interface WaterholeRunToEmptyResults {
    days: number;
}

export enum WaterholeRunToEmptyEvaporationType {
    DATA = "data",
    MEAN = "mean",
    VALUE = "value",
    DATA_PLUS_PERCENT = "data_plus_percent",
    DATA_PLUS_VALUE = "data_plus_value"
}

export enum WaterholeCalibrationFitStatisticType {
    RMSE = "rmse",
    R2 = "r2",
    NNSE = "nnse",
    PBIAS = "pbias",
    RSR = "rsr"
}

export interface WaterholeRunToEmptySettings {
    depth: number;
    evaporation_type: WaterholeRunToEmptyEvaporationType;
    evaporation_value: number;
}

export interface WaterholeCalibrationParameters {
    settings: WaterholeCalibrationSettings;
    season: RunPeriodModel;
    predictors: WaterholeCalibrationPredictors;
}

export interface WaterholeRunToEmptyParameters {
    settings: WaterholeRunToEmptySettings;
    season: RunPeriodModel;
}

export type OptimisationParameters =
    | OptimisationParametersWaterholeCalibration
    | OptimisationParametersWaterholeRunToEmpty;

export interface OptimisationParametersWaterholeCalibration {
    season: {
        start: PeriodModel;
        end: PeriodModel;
    };
    settings: {
        population: PluginFieldValue;
        generations: PluginFieldValue;
        scaling: PluginFieldValue;
    };
}

export interface OptimisationParametersWaterholeRunToEmpty {
    season: {
        start: PeriodModel;
    };
    settings: {
        depth: PluginFieldValue;
        evaporation_type: PluginFieldValue;
        evaporation_value: PluginFieldValue;
    };
}
