import React, { useState } from "react";
import Checkbox from "../../Components/Checkbox";
import { ModelModel } from "../../types/models";
import DateTimeCell from "./DateTimeCell";
import ModelRowActions from "./ModelRowActions";
import ModelIcon from "../../Components/ERPIcon/ModelIcon";
import ModelLinkCell from "./ModelLinkCell";
import { fullname } from "../../utils/utils";
import LockedIcon from "./LockedIcon";
import PluginOutOfDateIcon from "../../Components/ERPIcon/PluginOutOfDateIcon";

interface ModelRowProps {
    model: ModelModel;
    isSelected: boolean;
    isLocked: boolean;
    onSelected: (model: ModelModel, selected: boolean) => void;
    onModelMove: (model: ModelModel) => void;
    onModelDelete: (model: ModelModel) => void;
    onModelCopy: (model: ModelModel) => void;
    onModelEdit: (model: ModelModel) => void;
}

const ModelRow = ({
    model,
    isSelected,
    isLocked,
    onSelected,
    onModelMove,
    onModelDelete,
    onModelCopy,
    onModelEdit
}: ModelRowProps) => {
    const [isHovering, setIsHovering] = useState<boolean>(false);

    const handleSelected = (selected: boolean) => {
        onSelected(model, selected);
    };

    const handleMove = () => {
        onModelMove(model);
    };

    const handleDelete = () => {
        onModelDelete(model);
    };

    const handleCopy = () => {
        onModelCopy(model);
    };

    const handleEdit = () => {
        onModelEdit(model);
    };

    return (
        <tr className="model-row" onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
            <td style={{ width: 50 }} className="selection-cell">
                <Checkbox checked={isSelected} onChecked={handleSelected} />
            </td>

            <ModelLinkCell id={model.id} style={{ maxWidth: 50 }}>
                <ModelIcon />
            </ModelLinkCell>

            <ModelLinkCell id={model.id}>
                {model.name}

                {isLocked && <LockedIcon type="Model" owner={model.lock.owner} />}

                {model.plugin?.isCurrent === false && <PluginOutOfDateIcon />}
            </ModelLinkCell>

            <ModelLinkCell id={model.id} className="centered-cell status-cell">
                Model
            </ModelLinkCell>

            <ModelLinkCell id={model.id} className="centered-cell status-cell">
                {fullname(model.createdBy)}
            </ModelLinkCell>

            <DateTimeCell dateTime={model.updatedAt} />

            <td className="action-cell">
                {isHovering && (
                    <ModelRowActions
                        canEdit={!isLocked}
                        canDelete={!isLocked}
                        onMove={handleMove}
                        onDelete={handleDelete}
                        onCopy={handleCopy}
                        onEdit={handleEdit}
                    />
                )}
            </td>
        </tr>
    );
};

export default ModelRow;
