import LoadingIndicator from "../../Components/LoadingIndicator";
import { isNil } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import ModelService from "../../Services/model.service";
import PluginService from "../../Services/plugin.service";
import SystemService from "../../Services/system.service";
import {
    TaskModel,
    ModelModel,
    ModelScenarioModel,
    ModelSystem,
    PluginModel,
    SystemModel,
    ModelExploreScenario
} from "../../types/models";
import { PluginParameters } from "../../types/plugin";
import ExploreDataService from "../../Services/explore.data.service";

interface ModelContextProps {
    model: ModelModel;
    readOnly: boolean;
    systems: SystemModel[];
    plugins: PluginModel[];
    isSaving: boolean;
    tasks: TaskModel[];
    scenarios: ModelScenarioModel[];
    explorerScenarios: ModelExploreScenario[];
    explorerFlags: ExplorerFlagsModel;
    showRunModal: boolean;
    downloadsSettings: DownloadsSettingsModel;
    enablePreselectTab: boolean;
    setSaving: (saving: boolean) => void;
    updateModel: (update: ModelModel) => void;
    updateParameters: (parameters: PluginParameters) => void;
    setSystem: (system: ModelSystem) => void;
    setPlugin: (plugin: PluginModel, parameters: PluginParameters) => void;
    updateTasks: (tasks: TaskModel[]) => void;
    updateScenarios: (scenarios: ModelScenarioModel[]) => void;
    updateExplorerScenarios: (scenarios: ModelExploreScenario[]) => void;
    setShowRunModal: (next: boolean) => void;
    loadExplorerScenarios: (forceReload: boolean) => void;
    loadExplorerAssessmentData: (scenariosToLoad: string[]) => void;
    loadExplorerIntermediateData: (scenariosToLoad: string[]) => void;
    loadLatestModelVersion: () => void;
    updateDownloadsSettings: (nextDownloadsSettings: DownloadsSettingsModel) => void;
}

interface ModelContextOptions {
    modelId: string;
    readOnly: boolean;
    children: React.ReactNode;
}

interface ExplorerFlagsModel {
    isLoadingScenarios: boolean;
    hasLoadedScenarios: boolean;
    isLoadingAssessment: boolean;
    isLoadingIntermediate: boolean;
}

export interface DownloadsSettingsModel {
    shouldUseFullDownloadNames: boolean;
    shouldIncludeDailyResults: boolean;
    shouldSeperateDailyResults: boolean;
    shouldCreateCombinedResults: boolean;
}

const ENABLE_PRESELECT_MIN_SCENARIOS_COUNT = 5;

export const ModelContext = React.createContext<ModelContextProps | null>(null);

export const useModel = () => useContext(ModelContext);

const ModelContextProvider = ({ modelId, readOnly, children }: ModelContextOptions) => {
    const [model, setModel] = useState<ModelModel>(null);
    const [systems, setSystems] = useState<SystemModel[]>([]);
    const [plugins, setPlugins] = useState<PluginModel[]>([]);
    const [isSaving, setSaving] = useState<boolean>(false);
    const [tasks, setTasks] = useState<TaskModel[]>([]);
    const [scenarios, setScenarios] = useState<ModelScenarioModel[]>([]);
    const [showRunModal, setShowRunModal] = useState<boolean>(false);
    const [explorerScenarios, setExplorerScenarios] = useState<ModelExploreScenario[]>([]);
    const [explorerFlags, setExplorerFlags] = useState<ExplorerFlagsModel>({
        isLoadingScenarios: false,
        hasLoadedScenarios: false,
        isLoadingAssessment: false,
        isLoadingIntermediate: false
    });
    const [downloadsSettings, setDownloadsSettings] = useState<DownloadsSettingsModel>({
        shouldUseFullDownloadNames: false,
        shouldIncludeDailyResults: true,
        shouldSeperateDailyResults: true,
        shouldCreateCombinedResults: true
    });

    const enablePreselectTab = scenarios.filter(s => !isNil(s.run)).length >= ENABLE_PRESELECT_MIN_SCENARIOS_COUNT;

    useEffect(() => {
        const getModel = async (id: string) => {
            const getModel = ModelService.getModel(id);
            const getPlugins = PluginService.getPlugins();
            const getSystems = SystemService.getSystems();
            const getStatus = ModelService.getComputationStatus(id);

            const [_model, _plugins, _systems, _tasks] = await Promise.all([
                getModel,
                getPlugins,
                getSystems,
                getStatus
            ]);

            setPlugins(_plugins);
            setSystems(_systems);
            setTasks(_tasks);
            setModel(_model);
        };

        getModel(modelId);
    }, [modelId]);

    useEffect(() => {
        const getScenarios = async () => {
            const _scenarios = await ModelService.getScenarios(model.id);

            updateScenarios(_scenarios);
        };

        if (!isNil(model) && !isNil(model.system)) {
            getScenarios();
        }
    }, [model?.system?.id, model?.plugin?.id]);

    const loadLatestModelVersion = async () => {
        const version = await ModelService.getVersion(model.id);
        updateModelVersion(version.version);
    };

    const loadExplorerScenarios = async (forceReload: boolean) => {
        if (explorerFlags.isLoadingScenarios) {
            return;
        }

        if (!forceReload && explorerFlags.hasLoadedScenarios) {
            return;
        }

        setExplorerFlags(_explorerFlags => {
            return {
                ..._explorerFlags,
                isLoadingScenarios: true,
                hasLoadedScenarios: false,
                hasLoadedAssessment: false,
                hasLoadedIntermediate: false
            };
        });

        try {
            const _baseScenarios = await ModelService.getModelResults(model.id);

            const _scenarios: ModelExploreScenario[] = await Promise.all(
                _baseScenarios.map(async s => {
                    return {
                        ...s,
                        isSelected: enablePreselectTab ? false : true,
                        isBaseline: s.isDefault ? true : false,
                        hasLoadedAssessment: false,
                        hasLoadedIntermediate: false,
                        result: {
                            assessment: null,
                            intermediate: null
                        }
                    };
                })
            );

            if (_scenarios.length > 0 && !_scenarios.some(s => s.isBaseline)) {
                _scenarios[0].isBaseline = true;
            }

            updateExplorerScenarios(_scenarios);
        } finally {
            setExplorerFlags(_explorerFlags => {
                return { ..._explorerFlags, hasLoadedScenarios: true, isLoadingScenarios: false };
            });
        }
    };

    const loadExplorerAssessmentData = async (scenariosToLoad: string[]) => {
        if (
            explorerFlags.isLoadingAssessment ||
            scenariosToLoad.every(id => explorerScenarios.find(s => s.id === id).hasLoadedAssessment)
        ) {
            return;
        }

        try {
            setExplorerFlags(_explorerFlags => {
                return { ..._explorerFlags, isLoadingAssessment: true };
            });

            const _scenarios: ModelExploreScenario[] = await Promise.all(
                explorerScenarios.map(async s => {
                    if (!scenariosToLoad.includes(s.id) || s.hasLoadedAssessment) {
                        return s;
                    }

                    return {
                        ...s,
                        hasLoadedAssessment: true,
                        result: {
                            ...s.result,
                            assessment: await ExploreDataService.loadScenarioAssessmentResults(s)
                        }
                    };
                })
            );

            updateExplorerScenarios(_scenarios);
        } finally {
            setExplorerFlags(_explorerFlags => {
                return { ..._explorerFlags, isLoadingAssessment: false };
            });
        }
    };

    const loadExplorerIntermediateData = async (scenariosToLoad: string[]) => {
        if (
            explorerFlags.isLoadingIntermediate ||
            scenariosToLoad.every(id => explorerScenarios.find(s => s.id === id).hasLoadedIntermediate)
        ) {
            return;
        }

        try {
            setExplorerFlags(_explorerFlags => {
                return { ..._explorerFlags, isLoadingIntermediate: true };
            });

            const _scenarios: ModelExploreScenario[] = await Promise.all(
                explorerScenarios.map(async s => {
                    if (!scenariosToLoad.includes(s.id) || s.hasLoadedIntermediate) {
                        return s;
                    }

                    return {
                        ...s,
                        hasLoadedIntermediate: true,
                        result: {
                            ...s.result,
                            intermediate: await ExploreDataService.loadScenarioIntermediateResults(s)
                        }
                    };
                })
            );

            updateExplorerScenarios(_scenarios);
        } finally {
            setExplorerFlags(_explorerFlags => {
                return { ..._explorerFlags, isLoadingIntermediate: false };
            });
        }
    };

    const updateModel = (model: ModelModel) => {
        setModel({ ...model });
    };

    const updateModelVersion = (version: string) => {
        setModel({ ...model, version: version });
    };

    const setSystem = (system: ModelSystem) => {
        setModel({ ...model, system: { ...system } });
        setTasks([]);
    };

    const setPlugin = (plugin: PluginModel, parameters: PluginParameters) => {
        setModel({ ...model, plugin: { ...plugin }, parameters: parameters });
        setTasks([]);
    };

    const updateParameters = (parameters: PluginParameters) => {
        setModel({ ...model, parameters: { ...parameters } });
    };

    const updateTasks = (tasks: TaskModel[]) => {
        setTasks([...tasks]);
    };

    const updateScenarios = (scenarios: ModelScenarioModel[]) => {
        setScenarios(scenarios);
    };

    const updateExplorerScenarios = (explorerScenarios: ModelExploreScenario[]) => {
        setExplorerScenarios(explorerScenarios);
    };

    const updateDownloadsSettings = (nextDownloadsSettings: DownloadsSettingsModel) => {
        setDownloadsSettings(nextDownloadsSettings);
    };

    return (
        <ModelContext.Provider
            value={{
                model: model,
                readOnly: readOnly,
                systems: systems,
                plugins: plugins,
                isSaving: isSaving,
                tasks: tasks,
                scenarios: scenarios,
                explorerScenarios: explorerScenarios,
                showRunModal: showRunModal,
                explorerFlags: explorerFlags,
                downloadsSettings: downloadsSettings,
                enablePreselectTab: enablePreselectTab,
                setSaving: setSaving,
                updateModel: updateModel,
                setSystem: setSystem,
                setPlugin: setPlugin,
                updateParameters: updateParameters,
                updateTasks: updateTasks,
                updateScenarios: updateScenarios,
                updateExplorerScenarios: updateExplorerScenarios,
                setShowRunModal: setShowRunModal,
                loadExplorerScenarios: loadExplorerScenarios,
                loadExplorerAssessmentData: loadExplorerAssessmentData,
                loadExplorerIntermediateData: loadExplorerIntermediateData,
                loadLatestModelVersion: loadLatestModelVersion,
                updateDownloadsSettings: updateDownloadsSettings
            }}
        >
            {isNil(model) && <LoadingIndicator className="model-loading" centered />}

            {!isNil(model) && children}
        </ModelContext.Provider>
    );
};

export default ModelContextProvider;
