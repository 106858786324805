import { PluginId, PluginParameters } from "../../types/plugin";
import {
    AmbassisAgassiziiParameterMerger,
    AustralianBassParameterMerger,
    BananaPrawnGrowthParameterMerger,
    BarramundiGrowthParameterMerger,
    BarramundiJuvenileRecruitmentParameterMerger,
    BarramundiYearClassStrengthParameterMerger,
    BaseParameterMerger,
    BassParameterMerger,
    CarpRecruitmentParameterMerger,
    FishBarriersAndConnectivityParameterMerger,
    FishResilienceParameterMerger,
    ColwellsIndexParameterMerger,
    FreshesPartialSeasonParameterMerger,
    KingThreadfinSalmonParameterMerger,
    LowflowPartialSeasonParameterMerger,
    LowflowSpawningFishParameterMerger,
    MacrophyteGrowthParameterMerger,
    MaryRiverCodParameterMerger,
    MaryRiverTurtlesParameterMerger,
    MultiYearFreshesPartialSeasonParameterMerger,
    NorthernSnakeNeckedTurtleParameterMerger,
    OffshoreReefFisheryParameterMerger,
    OversupplyPartialSeasonParameterMerger,
    RatingCurveParameterMerger,
    SimultaneousSpellAnalysisParameterMerger,
    SpellAnalysisParameterMerger,
    SummaryParameterMerger,
    TandanusParameterMerger,
    TurtleParameterMerger,
    WaterholeParameterMerger,
    BaseflowSeparationParameterMerger,
    RifflesParameterMerger
} from ".";

export class ParameterMergerFactory {
    public getMerger(pluginId: PluginId): BaseParameterMerger<PluginParameters> {
        switch (pluginId) {
            case PluginId.RATING_CURVE:
                return new RatingCurveParameterMerger();
            case PluginId.AMBASSIS_AGASSIZII:
                return new AmbassisAgassiziiParameterMerger();
            case PluginId.BANANA_PRAWN_GROWTH:
                return new BananaPrawnGrowthParameterMerger();
            case PluginId.FRESHES_PARTIAL_SEASON:
                return new FreshesPartialSeasonParameterMerger();
            case PluginId.LOWFLOW_PARTIAL_SEASON:
                return new LowflowPartialSeasonParameterMerger();
            case PluginId.OVERSUPPLY_PARTIAL_SEASON:
                return new OversupplyPartialSeasonParameterMerger();
            case PluginId.MULTIYEAR_FRESHES_PARTIAL_SEASON:
                return new MultiYearFreshesPartialSeasonParameterMerger();
            case PluginId.MARY_RIVER_COD:
                return new MaryRiverCodParameterMerger();
            case PluginId.MARY_RIVER_TURTLES:
                return new MaryRiverTurtlesParameterMerger();
            case PluginId.NORTHERN_SNAKE_NECKED_TURTLE:
                return new NorthernSnakeNeckedTurtleParameterMerger();
            case PluginId.TURTLE:
                return new TurtleParameterMerger();
            case PluginId.TANDANUS:
                return new TandanusParameterMerger();
            case PluginId.OFFSHORE_REEF_FISHERY:
                return new OffshoreReefFisheryParameterMerger();
            case PluginId.LOW_FLOW_SPAWNING_FISH:
                return new LowflowSpawningFishParameterMerger();
            case PluginId.KING_THREADFIN_SALMON:
                return new KingThreadfinSalmonParameterMerger();
            case PluginId.FISH_RESILIENCE:
                return new FishResilienceParameterMerger();
            case PluginId.CARP_RECRUITMENT:
                return new CarpRecruitmentParameterMerger();
            case PluginId.BASS:
                return new BassParameterMerger();
            case PluginId.BARRAMUNDI_YEAR_CLASS_STRENGTH:
                return new BarramundiYearClassStrengthParameterMerger();
            case PluginId.BARRAMUNDI_JUVENILE_RECRUITMENT:
                return new BarramundiJuvenileRecruitmentParameterMerger();
            case PluginId.BARRAMUNDI_GROWTH:
                return new BarramundiGrowthParameterMerger();
            case PluginId.WATERHOLE:
                return new WaterholeParameterMerger();
            case PluginId.FISH_BARRIERS_AND_CONNECTIVITY:
                return new FishBarriersAndConnectivityParameterMerger();
            case PluginId.MACHROPHYTE_GROWTH:
                return new MacrophyteGrowthParameterMerger();
            case PluginId.SUMMARY:
                return new SummaryParameterMerger();
            case PluginId.SPELL_ANALYSIS:
                return new SpellAnalysisParameterMerger();
            case PluginId.SIMULTANEOUS_SPELL_ANALYSIS:
                return new SimultaneousSpellAnalysisParameterMerger();
            case PluginId.COLWELLS_INDEX:
                return new ColwellsIndexParameterMerger();
            case PluginId.BASEFLOW_SEPARATION:
                return new BaseflowSeparationParameterMerger();
            case PluginId.AUSTRALIAN_BASS:
                return new AustralianBassParameterMerger();
            case PluginId.RIFFLES:
                return new RifflesParameterMerger();
            default:
                throw new Error(`Parameter merger for plugin ${pluginId} could not be found`);
        }
    }
}
