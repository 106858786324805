import { MaryRiverTurtlesParameters } from "../../types/plugin-parameter-types";
import { PluginParameters } from "../../types/plugin";
import { BaseParameterMerger } from "./base-parameter-merger";

export class MaryRiverTurtlesParameterMerger extends BaseParameterMerger<MaryRiverTurtlesParameters> {
    public merge(source: PluginParameters, defaultParameters: MaryRiverTurtlesParameters): MaryRiverTurtlesParameters {
        return {
            partnering_event: {
                season_start: this.defaultTo(
                    source?.partnering_event?.season_start,
                    defaultParameters?.partnering_event?.season_start
                ),
                season_end: this.defaultTo(
                    source?.partnering_event?.season_end,
                    defaultParameters?.partnering_event?.season_end
                ),
                ctf: this.defaultTo(source?.partnering_event?.ctf, defaultParameters?.partnering_event?.ctf),
                amount_above_ctf: this.defaultTo(
                    source?.partnering_event?.amount_above_ctf,
                    defaultParameters?.partnering_event?.amount_above_ctf
                )
            },
            rainfall_event: {
                rainfall: this.defaultTo(source?.rainfall_event?.rainfall, defaultParameters?.rainfall_event?.rainfall),
                days_since_partnering: this.defaultTo(
                    source?.rainfall_event?.days_since_partnering,
                    defaultParameters?.rainfall_event?.days_since_partnering
                )
            },
            stable_water_level: {
                duration: this.defaultTo(
                    source?.stable_water_level?.duration,
                    defaultParameters?.stable_water_level?.duration
                ),
                nests_inundated_curve: this.defaultTo(
                    source?.stable_water_level?.nests_inundated_curve,
                    defaultParameters?.stable_water_level?.nests_inundated_curve
                ),
                inundation_risk_curve: this.defaultTo(
                    source?.stable_water_level?.inundation_risk_curve,
                    defaultParameters?.stable_water_level?.inundation_risk_curve
                ),
                inundation_risk_threshold: this.defaultTo(
                    source?.stable_water_level?.inundation_risk_threshold,
                    defaultParameters?.stable_water_level?.inundation_risk_threshold
                )
            },
            assessment: {
                temporal_agg: this.defaultTo(
                    source?.assessment?.temporal_agg,
                    defaultParameters?.assessment?.temporal_agg
                )
            },
            spatial_agg: {
                risk_categories: this.defaultTo(
                    source?.spatial_agg?.risk_categories,
                    defaultParameters?.spatial_agg?.risk_categories
                )
            }
        };
    }
}
