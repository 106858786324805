import { last } from "lodash";
import moment from "moment";
import React, { useMemo } from "react";
import { ModelExploreScenario } from "../../types/models";
import { isNilOrEmpty } from "../../utils/utils";
import Humanize from "humanize-plus";
import ScenarioInformation from "./ScenarioInformation";
import { ScenarioDownloadInfo } from "./types";

interface ScenarioDownloadInformationProps {
    scenario: ModelExploreScenario;
}

const ScenarioDownloadInformation = ({ scenario }: ScenarioDownloadInformationProps) => {
    const info = useMemo(() => {
        const _info: ScenarioDownloadInfo = {
            lastRun: moment(scenario.run.createdAt).format("DD MMM, YYYY hh:mm A"),
            length: null
        };

        if (!isNilOrEmpty(scenario?.result?.assessment?.spatial?.rows)) {
            const start = scenario.result.assessment.spatial.rows[0];

            if (scenario.result.assessment.spatial.rows.length === 1) {
                _info.length = "1 year";
            } else {
                const end = last(scenario.result.assessment.spatial.rows);

                const _length = Number(end.year) - Number(start.year);

                _info.length = `${_length} ${Humanize.pluralize(_length, "year", "years")}`;
            }
        }

        return _info;
    }, [scenario.run.id]);

    return (
        <div className="scenario-info">
            <ScenarioInformation label="Last run" value={info.lastRun} />
            {!isNilOrEmpty(info.length) && <ScenarioInformation label="Length" value={info.length} />}
        </div>
    );
};

export default ScenarioDownloadInformation;
