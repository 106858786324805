import React from "react";
import clsx from "clsx";
import { ScenarioModel } from "../../types/models";
import { useContextMenu } from "react-contexify";
import ScenarioContextMenu, { menuId } from "./ScenarioContextMenu";

interface ScenarioPickerScenarioProps {
    scenario: ScenarioModel;
    isActive: boolean;
    onSelected: (scenario: ScenarioModel) => void;
    onScenarioEdit: (scenario: ScenarioModel) => void;
    onScenarioDelete: (scenario: ScenarioModel) => void;
}

const ScenarioPickerScenario = ({
    scenario,
    isActive,
    onSelected,
    onScenarioEdit,
    onScenarioDelete
}: ScenarioPickerScenarioProps) => {
    const { show } = useContextMenu({ id: menuId(scenario) });

    const handleSelect = () => {
        onSelected(scenario);
    };

    const handleEdit = () => {
        onScenarioEdit(scenario);
    };

    const handleDelete = () => {
        onScenarioDelete(scenario);
    };

    return (
        <div
            className={clsx("scenario-picker--scenario", { active: isActive === true })}
            onClick={handleSelect}
            onContextMenu={show}
        >
            <div className="scenario-picker-name">{scenario.name}</div>

            <ScenarioContextMenu scenario={scenario} onEdit={handleEdit} onDelete={handleDelete} />
        </div>
    );
};

export default ScenarioPickerScenario;
