import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { PluginDropdownValue, PluginFieldArrayValue, VelocityCalculationType } from "../../types/plugin";
import { PluginFieldProps, PluginForm } from "./types";
import Select, { SelectOption } from "../../Components/Select/Select";
import FormNumericInput from "../FormInput/FormNumericInput";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import CrossSection from "../CrossSection";

interface PluginParameterVelocityCalculationProps extends PluginFieldProps {
    onObjectChange?: (field: string, value: Record<string, string | number | boolean>) => void;
    onArrayChange?: (field: string, value: PluginFieldArrayValue) => void;
}

const velocityTypeValues: PluginDropdownValue[] = [
    { label: "Width and roughness", value: VelocityCalculationType.WIDTH_ROUGHNESS },
    { label: "Cross section", value: VelocityCalculationType.CROSS_SECTION }
];

interface TypeTooltipModel {
    type: VelocityCalculationType;
    tooltip: string;
}

const TOOLTIPS: TypeTooltipModel[] = [
    {
        type: VelocityCalculationType.WIDTH_ROUGHNESS,
        tooltip: "Based on the numerical solution to Manning's equation by Abdulrahman (2017)"
    },
    {
        type: VelocityCalculationType.CROSS_SECTION,
        tooltip:
            "Based on the empirically derived discharge-cross section relationships for natural channels by Dingman and Sharma (1997)"
    }
];

const PluginParameterVelocityCalculation = ({
    id,
    definition,
    field,
    control,
    disabled,
    onObjectChange,
    onArrayChange
}: PluginParameterVelocityCalculationProps) => {
    const { getValues, watch } = useFormContext<PluginForm>();

    const [watchedValues] = watch([`${definition.id}.${field.id}`]);

    const method: VelocityCalculationType = watchedValues?.method;

    const handleMethodChanged = (method: VelocityCalculationType) => {
        const currentSlope = getValues(`${definition.id}.${field.id}.slope`);
        const currentWidth = getValues(`${definition.id}.${field.id}.width`);
        const currentRoughness = getValues(`${definition.id}.${field.id}.roughness`);

        onObjectChange("calculation", {
            method: method,
            slope: currentSlope,
            width: currentWidth,
            roughness: currentRoughness
        });
    };

    const handleSlopeChanged = (slope: number) => {
        const currentMethod = getValues(`${definition.id}.${field.id}.method`);
        const currentWidth = getValues(`${definition.id}.${field.id}.width`);
        const currentRoughness = getValues(`${definition.id}.${field.id}.roughness`);

        onObjectChange("calculation", {
            method: currentMethod,
            slope: slope,
            width: currentWidth,
            roughness: currentRoughness
        });
    };

    const handleWidthChanged = (width: number) => {
        const currentMethod = getValues(`${definition.id}.${field.id}.method`);
        const currentSlope = getValues(`${definition.id}.${field.id}.slope`);
        const currentRoughness = getValues(`${definition.id}.${field.id}.roughness`);

        onObjectChange("calculation", {
            method: currentMethod,
            slope: currentSlope,
            width: width,
            roughness: currentRoughness
        });
    };

    const handleRoughnessChanged = (roughness: number) => {
        const currentMethod = getValues(`${definition.id}.${field.id}.method`);
        const currentSlope = getValues(`${definition.id}.${field.id}.slope`);
        const currentWidth = getValues(`${definition.id}.${field.id}.width`);

        onObjectChange("calculation", {
            method: currentMethod,
            slope: currentSlope,
            width: currentWidth,
            roughness: roughness
        });
    };

    const handleCrossSectionChanged = (crossSection: number[][]) => {
        onArrayChange("cross_section", crossSection);
    };

    return (
        <div className="velocity-calculation-parameter-field">
            <Controller
                control={control}
                name={`${definition.id}.${field.id}.method`}
                render={({ field: _field }) => {
                    return (
                        <div className="velocity-calculation-method-select">
                            <Select
                                id={id}
                                labelText={field.name}
                                labelClassName="plugin-definition-field-label"
                                value={velocityTypeValues.find(v => v.value === _field.value)}
                                values={velocityTypeValues}
                                onSelected={(option: SelectOption) => {
                                    handleMethodChanged(option.value as VelocityCalculationType);
                                }}
                                isClearable={false}
                                isSearchable={false}
                                disabled={disabled}
                                icon={faQuestionCircle}
                                iconTooltip={TOOLTIPS.find(t => t.type === _field.value)?.tooltip}
                            />
                        </div>
                    );
                }}
            />

            <div
                className={
                    method === VelocityCalculationType.WIDTH_ROUGHNESS
                        ? "width-roughness-fields"
                        : "cross-section-fields"
                }
            >
                <Controller
                    control={control}
                    name={`${definition.id}.${field.id}.slope`}
                    render={({ field: _field }) => {
                        return (
                            <FormNumericInput
                                id={`${id}.slope`}
                                value={_field.value}
                                disabled={disabled}
                                labelText="Slope"
                                labelClass="plugin-definition-field-label"
                                onChange={handleSlopeChanged}
                                containerClassName="velocity-calculation-field"
                            />
                        );
                    }}
                />

                {method === VelocityCalculationType.WIDTH_ROUGHNESS && (
                    <>
                        <Controller
                            control={control}
                            name={`${definition.id}.${field.id}.width`}
                            render={({ field: _field }) => {
                                return (
                                    <FormNumericInput
                                        id={`${id}.width`}
                                        value={_field.value}
                                        disabled={disabled}
                                        labelText="Width"
                                        labelClass="plugin-definition-field-label"
                                        onChange={handleWidthChanged}
                                        containerClassName="velocity-calculation-field"
                                        unit="m"
                                    />
                                );
                            }}
                        />

                        <Controller
                            control={control}
                            name={`${definition.id}.${field.id}.roughness`}
                            render={({ field: _field }) => {
                                return (
                                    <FormNumericInput
                                        id={`${id}.roughness`}
                                        value={_field.value}
                                        disabled={disabled}
                                        labelText="Roughness"
                                        labelClass="plugin-definition-field-label"
                                        onChange={handleRoughnessChanged}
                                        containerClassName="velocity-calculation-field"
                                        icon={faQuestionCircle}
                                        iconTooltip="Typical values for this parameter are: 0.030 (straight, low vegetation, fine substrate, no big pools), 0.045 (winding, moderate vegetation, some pools, gravel substrate), 0.060 (winding, heavy vegetation, deep pools, cobble substrate)"
                                    />
                                );
                            }}
                        />
                    </>
                )}

                {method === VelocityCalculationType.CROSS_SECTION && (
                    <Controller
                        control={control}
                        name={`${definition.id}.cross_section`}
                        render={({ field: _field }) => {
                            return (
                                <CrossSection
                                    id={`${id}.cross_section`}
                                    label="Cross Section"
                                    values={_field.value ?? []}
                                    disabled={disabled}
                                    onChange={handleCrossSectionChanged}
                                />
                            );
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default PluginParameterVelocityCalculation;
