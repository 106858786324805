import React, { useMemo } from "react";
import { ScenarioModel } from "../../types/models";
import { Item, ItemParams, Menu } from "react-contexify";
import { useSystem } from "./SystemContext";
import ZipGenerator, { ZipFileInfo } from "../../Services/zip-generator";
import { Toast } from "../../Components/Toast";
import AzureBlobService from "../../Services/azure.blob.service";

interface ScenarioContextMenuProps {
    scenario: ScenarioModel;
    onEdit: () => void;
    onDelete: () => void;
}

export function menuId(scenario: ScenarioModel): string {
    return `scenario-menu-${scenario.id}`;
}

//For now we are creating a menu for each scenario, if for some reason this impact
// performance then we could instead only ever show 1 menu and pass the scenario as props before showing the menu
const ScenarioContextMenu = ({ scenario, onEdit, onDelete }: ScenarioContextMenuProps) => {
    const { data } = useSystem();

    const handleDelete = (e: ItemParams<any, any>) => {
        e?.event?.stopPropagation();

        onDelete();
    };

    const handleDownloadScenarioData = async () => {
        const scenarioData = data.filter(d => d.scenarioId === scenario.id);

        if (scenarioData.length < 1) {
            Toast.error("Scenario has no data");
            return;
        }

        const generator = new ZipGenerator();

        const files: ZipFileInfo[] = await Promise.all(
            scenarioData.map(async d => {
                const data = await AzureBlobService.downloadBlobAsFile(d.blobId, d.name);
                return { name: data.name, file: data };
            })
        );

        const blob = await generator.generate(files);

        AzureBlobService.downloadToDisk(blob, `${scenario.name}_data.zip`);
    };

    const _menuId = useMemo(() => {
        return menuId(scenario);
    }, [scenario?.id]);

    return (
        <Menu id={_menuId} animation={false}>
            <Item onClick={onEdit}>Edit scenario</Item>
            <Item onClick={handleDelete}>Delete scenario</Item>
            <Item onClick={handleDownloadScenarioData}>Download scenario data</Item>
        </Menu>
    );
};

export default ScenarioContextMenu;
