import React from "react";
import ActivableButton from "../../Components/Button/ActivableButton";
import { ManageAccountTabType } from "./types";

interface ManageAccountTabProps {
    type: ManageAccountTabType;
    isActive: boolean;
    label: string;
    onSelected: (type: ManageAccountTabType) => void;
}

const ManageAccountTab = ({ type, isActive, label, onSelected }: ManageAccountTabProps) => {
    const handleSelected = () => {
        onSelected(type);
    };

    return (
        <div className="manage-account--tab">
            <ActivableButton onClick={handleSelected} isActive={isActive}>
                {label}
            </ActivableButton>
        </div>
    );
};

interface ManageAccountTabsProps {
    selectedTab: ManageAccountTabType;
    onTabSelected: (type: ManageAccountTabType) => void;
}

const ManageAccountTabs = ({ selectedTab, onTabSelected }: ManageAccountTabsProps) => {
    const handleTabSelected = (type: ManageAccountTabType) => {
        if (type === selectedTab) {
            return;
        }

        onTabSelected(type);
    };

    return (
        <div className="manage-account--tabs">
            <ManageAccountTab
                type={ManageAccountTabType.CHANGE_PASSWORD}
                label="Change Password"
                isActive={selectedTab === ManageAccountTabType.CHANGE_PASSWORD}
                onSelected={handleTabSelected}
            />

            <ManageAccountTab
                type={ManageAccountTabType.YOUR_GROUPS}
                label="Your Groups"
                isActive={selectedTab === ManageAccountTabType.YOUR_GROUPS}
                onSelected={handleTabSelected}
            />
        </div>
    );
};

export default ManageAccountTabs;
