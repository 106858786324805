import Select, { SelectOption } from "../../Components/Select/Select";
import React, { useMemo } from "react";
import { DataType, LocationModel, ScenarioModel, SystemDataPreviewSettings } from "../../types/models";
import { DataPreviewTabType } from "./DataPreviewTabs";
import { DataTypeSelectOption } from "../../Components/DataTypeSelect";
import { useSystem } from "./SystemContext";

interface DataPreviewOptionsProps {
    previewOptions: SystemDataPreviewSettings;
    activeTab: DataPreviewTabType;
    setPreviewOptions: (previewOptions: SystemDataPreviewSettings) => void;
}

interface ScenarioSelectOption extends SelectOption {
    scenario: ScenarioModel;
}

interface LocationSelectOption extends SelectOption {
    location: LocationModel;
}

const DATA_TYPES: DataTypeSelectOption[] = [
    { label: "Depth", value: DataType.DEPTH },
    { label: "Flow", value: DataType.FLOW },
    { label: "Rainfall", value: DataType.RAINFALL },
    { label: "Temperature", value: DataType.TEMPERATURE },
    { label: "Evaporation", value: DataType.EVAPORATION },
    { label: "Compliance", value: DataType.COMPLIANCE },
    { label: "Salinity", value: DataType.SALINITY },
    { label: "Other Timeseries", value: DataType.OTHER_TIMESERIES }
];

const DataPreviewOptions = ({ previewOptions, activeTab, setPreviewOptions }: DataPreviewOptionsProps) => {
    const { scenarios, locations } = useSystem();

    const scenarioOptions = useMemo(() => {
        return scenarios?.map(s => {
            const option: ScenarioSelectOption = {
                value: s.id,
                label: s.name,
                scenario: s
            };

            return option;
        });
    }, [scenarios.length]);

    const locationOptions = useMemo(() => {
        return locations?.map(l => {
            const option: LocationSelectOption = {
                value: l.id,
                label: l.name,
                location: l
            };

            return option;
        });
    }, [locations.length]);

    const selectedScenario: ScenarioSelectOption = {
        value: previewOptions?.selectedScenario?.id,
        label: previewOptions?.selectedScenario?.name,
        scenario: previewOptions?.selectedScenario
    };

    const selectedLocation: LocationSelectOption = {
        value: previewOptions?.selectedLocation?.id,
        label: previewOptions?.selectedLocation?.name,
        location: previewOptions?.selectedLocation
    };

    const selectedDataType = useMemo(() => {
        const option: SelectOption = DATA_TYPES.find(d => d.value === previewOptions?.selectedDataType);

        return option;
    }, [previewOptions?.selectedDataType]);

    const handleScenarioSelect = (newSelection: ScenarioModel) => {
        if (newSelection === previewOptions?.selectedScenario) {
            return;
        }

        setPreviewOptions({ ...previewOptions, selectedScenario: newSelection });
    };

    const handleLocationSelect = (newSelection: LocationModel) => {
        if (newSelection === previewOptions?.selectedLocation) {
            return;
        }

        setPreviewOptions({ ...previewOptions, selectedLocation: newSelection });
    };

    const handleDataTypeSelect = (newSelection: DataType) => {
        if (newSelection === previewOptions?.selectedDataType) {
            return;
        }

        setPreviewOptions({ ...previewOptions, selectedDataType: newSelection });
    };

    return (
        <div className="data-preview-options">
            {activeTab === DataPreviewTabType.LOCATIONS && (
                <Select
                    labelText="Select a scenario"
                    menuClassName="select"
                    values={scenarioOptions}
                    value={selectedScenario}
                    onSelected={(option: ScenarioSelectOption) => {
                        handleScenarioSelect(option.scenario);
                    }}
                />
            )}

            {activeTab === DataPreviewTabType.SCENARIOS && (
                <Select
                    labelText="Select a location"
                    menuClassName="select"
                    values={locationOptions}
                    value={selectedLocation}
                    onSelected={(option: LocationSelectOption) => {
                        handleLocationSelect(option.location);
                    }}
                />
            )}

            <Select
                labelText="Select a data type"
                menuClassName="select"
                values={DATA_TYPES}
                value={selectedDataType}
                onSelected={(option: SelectOption) => {
                    handleDataTypeSelect(option.value as DataType);
                }}
            />
        </div>
    );
};

export default DataPreviewOptions;
