import clsx from "clsx";
import { round } from "lodash";
import React from "react";

import "./ProgressBar.scss";

interface ERPProgressBarProps {
    progress: number;
    className?: string;
}

const ERPProgressBar = ({ progress, className }: ERPProgressBarProps) => {
    return (
        <div className={clsx("erp-progress-bar", className)}>
            <div className="progress-bar">
                <div className="progress" style={{ width: `${Math.min(progress, 100)}%` }}>
                    <div className="label-container">{round(progress, 0)}%</div>
                </div>
            </div>
        </div>
    );
};

export default ERPProgressBar;
