import React from "react";
import PluginWaterholeCalibrateModalResultsField from "./PluginWaterholeCalibrationModalResultsField";
import { WaterholeCalibrationResults } from "./types";

interface PluginWaterholeCalibrateModalCalibrationProps {
    results: WaterholeCalibrationResults;
}

const PluginWaterholeCalibrateModalResults = ({ results }: PluginWaterholeCalibrateModalCalibrationProps) => {
    return (
        <div className="result">
            <PluginWaterholeCalibrateModalResultsField
                value={results?.nnse}
                name="NNSE"
                tooltipLabel="Normalised Nash-Sutcliffe Efficiency Coefficient"
                tooltipRange="1 to 0"
                tooltipPerformance={[
                    "Very Good: 1 to 0.75",
                    "Good: 0.75 to 0.65",
                    "Satisfactory: 0.65 to 0.5",
                    "Unsatisfactory: < 0.5"
                ]}
            />
            <PluginWaterholeCalibrateModalResultsField
                value={results?.rmse}
                name="RMSE"
                tooltipLabel="Root Mean Squared Error"
                tooltipRange="0 to +∞"
                tooltipPerformance={["Based on unit (likely m)"]}
            />
            <PluginWaterholeCalibrateModalResultsField
                value={results?.r2}
                name="R²"
                tooltipLabel="Coefficient of Determination"
                tooltipRange="1 to -∞"
                tooltipPerformance={[
                    "Very Good: 1 to 0.7",
                    "Good: 0.7 to 0.6",
                    "Satisfactory: 0.6 to 0.3",
                    "Unsatisfactory: < 0.3"
                ]}
            />
            <PluginWaterholeCalibrateModalResultsField
                value={results?.pbias}
                name="PBIAS"
                tooltipLabel="Percent Bias"
                tooltipRange="-∞ to +∞"
                tooltipAdditionalInfo="0 = Perfect Fit, Negative = Overestimation, Positive = Underestimation"
                tooltipPerformance={[
                    "Very Good: < |10|",
                    "Good: |10| to |15|",
                    "Satisfactory: |15| to |25|",
                    "Unsatisfactory: > |25|"
                ]}
            />
            <PluginWaterholeCalibrateModalResultsField
                value={results?.rsr}
                name="RSR"
                tooltipLabel="RMSE Standard Deviation Ratio"
                tooltipRange="0 to +∞"
                tooltipPerformance={[
                    "Very Good: 0 to 0.5",
                    "Good: 0.5 to 0.6",
                    "Satisfactory: 0.6 to 0.7",
                    "Unsatisfactory: > 0.7"
                ]}
            />
        </div>
    );
};

export default PluginWaterholeCalibrateModalResults;
