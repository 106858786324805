import React from "react";
import Button from "../../Components/Button";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { ScenarioModel } from "../../types/models";
import ScenarioPickerScenario from "./ScenarioPickerScenario";
import { useSystem } from "./SystemContext";

interface ScenarioPickerProps {
    onScenarioSelected: (scenario: ScenarioModel) => void;
    onScenarioAdded: () => void;
    onScenarioEdit: (scenario: ScenarioModel) => void;
    onScenarioDelete: (scenario: ScenarioModel) => void;
}

const ScenarioPicker = ({
    onScenarioSelected,
    onScenarioAdded,
    onScenarioEdit,
    onScenarioDelete
}: ScenarioPickerProps) => {
    const { scenarios, selectedScenario, readOnly } = useSystem();

    return (
        <div className="scenario-picker">
            <div className="scenario-picker--scenarios">
                {scenarios.map(scenario => {
                    return (
                        <ScenarioPickerScenario
                            key={scenario.id}
                            scenario={scenario}
                            isActive={scenario.id === selectedScenario?.id}
                            onSelected={onScenarioSelected}
                            onScenarioEdit={onScenarioEdit}
                            onScenarioDelete={onScenarioDelete}
                        />
                    );
                })}
            </div>

            <div className="new-scenario-button">
                <Button icon={faPlus} onClick={onScenarioAdded} disabled={readOnly}>
                    New scenario
                </Button>
            </div>
        </div>
    );
};

export default ScenarioPicker;
