import { RatingCurveParameters } from "../../types/plugin-parameter-types";
import { PluginParameters } from "../../types/plugin";
import { BaseParameterMerger } from "./base-parameter-merger";

export class RatingCurveParameterMerger extends BaseParameterMerger<RatingCurveParameters> {
    public merge(source: PluginParameters, defaultParameters: RatingCurveParameters): RatingCurveParameters {
        return {
            data: {
                data_type: this.defaultTo(source?.data?.data_type, defaultParameters?.data?.data_type),
                curve: this.defaultTo(source?.data?.curve, defaultParameters?.data?.curve)
            },
            assessment: {
                daily_agg: this.defaultTo(source?.assessment?.daily_agg, defaultParameters?.assessment?.daily_agg),
                yearly_agg: this.defaultTo(source?.assessment?.yearly_agg, defaultParameters?.assessment?.yearly_agg),
                temporal_agg: this.defaultTo(
                    source?.assessment?.temporal_agg,
                    defaultParameters?.assessment?.temporal_agg
                )
            },
            spatial_agg: {
                risk_categories: this.defaultTo(
                    source?.spatial_agg?.risk_categories,
                    defaultParameters?.spatial_agg?.risk_categories
                )
            }
        };
    }
}
