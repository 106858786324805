import { BarramundiGrowthParameters } from "../../types/plugin-parameter-types";
import { PluginParameters } from "../../types/plugin";
import { BaseParameterMerger } from "./base-parameter-merger";

export class BarramundiGrowthParameterMerger extends BaseParameterMerger<BarramundiGrowthParameters> {
    public merge(source: PluginParameters, defaultParameters: BarramundiGrowthParameters): BarramundiGrowthParameters {
        return {
            constants_a: {
                summer: this.defaultTo(source?.constants_a?.summer, defaultParameters?.constants_a?.summer),
                autumn: this.defaultTo(source?.constants_a?.autumn, defaultParameters?.constants_a?.autumn),
                winter: this.defaultTo(source?.constants_a?.winter, defaultParameters?.constants_a?.winter),
                spring: this.defaultTo(source?.constants_a?.spring, defaultParameters?.constants_a?.spring)
            },
            constants_b: {
                summer: this.defaultTo(source?.constants_b?.summer, defaultParameters?.constants_b?.summer),
                autumn: this.defaultTo(source?.constants_b?.autumn, defaultParameters?.constants_b?.autumn),
                winter: this.defaultTo(source?.constants_b?.winter, defaultParameters?.constants_b?.winter),
                spring: this.defaultTo(source?.constants_b?.spring, defaultParameters?.constants_b?.spring)
            },
            flow_threshold: {
                summer: this.defaultTo(source?.flow_threshold?.summer, defaultParameters?.flow_threshold?.summer),
                autumn: this.defaultTo(source?.flow_threshold?.autumn, defaultParameters?.flow_threshold?.autumn),
                winter: this.defaultTo(source?.flow_threshold?.winter, defaultParameters?.flow_threshold?.winter),
                spring: this.defaultTo(source?.flow_threshold?.spring, defaultParameters?.flow_threshold?.spring)
            },
            assessment: {
                daily_agg: this.defaultTo(source?.assessment?.daily_agg, defaultParameters?.assessment?.daily_agg),
                yearly_agg: this.defaultTo(source?.assessment?.yearly_agg, defaultParameters?.assessment?.yearly_agg),
                temporal_agg: this.defaultTo(
                    source?.assessment?.temporal_agg,
                    defaultParameters?.assessment?.temporal_agg
                )
            },
            spatial_agg: {
                risk_categories: this.defaultTo(
                    source?.spatial_agg?.risk_categories,
                    defaultParameters?.spatial_agg?.risk_categories
                )
            }
        };
    }
}
