import Label from "../../Components/Label";
import { SelectOption } from "../../Components/Select/Select";
import React, { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Select from "../../Components/Select/Select";
import { PluginDropdownValue } from "../../types/plugin";
import { PluginFieldProps, PluginForm } from "./types";
import NumericInput from "../../Components/NumericInput";
import ComparisonSelect from "../../Components/ComparisonSelect";

interface PluginParameterTemporalAggFailureProps extends PluginFieldProps {
    onChange: (field: string, value: Record<string, string | number>) => void;
}

const PluginParameterTemporalAggFailure = ({
    definition,
    field,
    control,
    disabled,
    onChange
}: PluginParameterTemporalAggFailureProps) => {
    const { getValues } = useFormContext<PluginForm>();

    const handleNumYearsChanged = (value: number) => {
        const agg_type = getValues(`${definition.id}.${field.id}.agg_type`);
        const comparison = getValues(`${definition.id}.${field.id}.comparison`);
        const threshold = getValues(`${definition.id}.${field.id}.threshold`);
        onChange(field.id, { agg_type: agg_type, num_years: value, comparison: comparison, threshold: threshold });
    };

    const handleComparisonChanged = (value: string) => {
        const num_years = getValues(`${definition.id}.${field.id}.num_years`);
        const agg_type = getValues(`${definition.id}.${field.id}.agg_type`);
        const threshold = getValues(`${definition.id}.${field.id}.threshold`);
        onChange(field.id, { comparison: value, num_years: num_years, agg_type: agg_type, threshold: threshold });
    };

    const handleThresholdChanged = (value: number) => {
        const num_years = getValues(`${definition.id}.${field.id}.num_years`);
        const agg_type = getValues(`${definition.id}.${field.id}.agg_type`);
        const comparison = getValues(`${definition.id}.${field.id}.comparison`);
        onChange(field.id, {
            threshold: value ?? null,
            comparison: comparison,
            num_years: num_years,
            agg_type: agg_type
        });
    };

    const NumYearValues: PluginDropdownValue[] = useMemo(() => {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(v => {
            const option: SelectOption = {
                value: v.toString(),
                label: v.toString()
            };
            return option;
        });
    }, []);

    return (
        <div className="assessment-parameter-field">
            <Label className="plugin-definition-field-label">{field.name}</Label>

            <div className="assessment-params">
                Temporal failure if sum of annual failures across{" "}
                <Controller
                    control={control}
                    name={`${definition.id}.${field.id}.num_years`}
                    render={({ field: _field }) => {
                        return (
                            <Select
                                values={NumYearValues}
                                menuClassName={"assessment-select"}
                                onSelected={(option: PluginDropdownValue) => {
                                    handleNumYearsChanged(+option?.value ?? null);
                                }}
                                value={NumYearValues.find(v => v.value === _field.value?.toString()) ?? null}
                                disabled={disabled}
                            />
                        );
                    }}
                />
                years is{" "}
                <Controller
                    control={control}
                    name={`${definition.id}.${field.id}.comparison`}
                    render={({ field: _field }) => {
                        return (
                            <ComparisonSelect
                                id={`${definition.id}.${field.id}.comparison`}
                                value={_field.value}
                                onComparisonChanged={handleComparisonChanged}
                                className="assessment-select"
                                disabled={disabled}
                            />
                        );
                    }}
                />
                <Controller
                    control={control}
                    name={`${definition.id}.${field.id}.threshold`}
                    render={({ field: _field }) => {
                        return (
                            <NumericInput
                                value={_field.value ?? null}
                                onChange={handleThresholdChanged}
                                containerClassName="assessment-select"
                                disabled={disabled}
                            />
                        );
                    }}
                />
            </div>
        </div>
    );
};

export default PluginParameterTemporalAggFailure;
