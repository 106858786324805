import { OversupplyPartialSeasonParameters } from "../../types/plugin-parameter-types";
import { PluginParameters } from "../../types/plugin";
import { BaseParameterMerger } from "./base-parameter-merger";

export class OversupplyPartialSeasonParameterMerger extends BaseParameterMerger<OversupplyPartialSeasonParameters> {
    public merge(
        source: PluginParameters,
        defaultParameters: OversupplyPartialSeasonParameters
    ): OversupplyPartialSeasonParameters {
        return {
            data: {
                variable_season: this.defaultTo(
                    source?.data?.variable_season,
                    defaultParameters?.data?.variable_season
                ),
                start_month: this.defaultTo(source?.data?.start_month, defaultParameters?.data?.start_month)
            },
            rules_dry: {
                duration: this.defaultTo(source?.rules_dry?.duration, defaultParameters?.rules_dry?.duration),
                magnitude: this.defaultTo(source?.rules_dry?.magnitude, defaultParameters?.rules_dry?.magnitude)
            },
            rules_wet: {
                duration: this.defaultTo(source?.rules_wet?.duration, defaultParameters?.rules_wet?.duration),
                magnitude: this.defaultTo(source?.rules_wet?.magnitude, defaultParameters?.rules_wet?.magnitude)
            },
            rules_average: {
                duration: this.defaultTo(source?.rules_average?.duration, defaultParameters?.rules_average?.duration),
                magnitude: this.defaultTo(source?.rules_average?.magnitude, defaultParameters?.rules_average?.magnitude)
            },
            rules_drought: {
                duration: this.defaultTo(source?.rules_drought?.duration, defaultParameters?.rules_drought?.duration),
                magnitude: this.defaultTo(source?.rules_drought?.magnitude, defaultParameters?.rules_drought?.magnitude)
            },
            rules_very_dry: {
                duration: this.defaultTo(source?.rules_very_dry?.duration, defaultParameters?.rules_very_dry?.duration),
                magnitude: this.defaultTo(
                    source?.rules_very_dry?.magnitude,
                    defaultParameters?.rules_very_dry?.magnitude
                )
            },
            partial_tables: {
                duration: this.defaultTo(source?.partial_tables?.duration, defaultParameters?.partial_tables?.duration)
            },
            assessment: {
                yearly_conversion: this.defaultTo(
                    source?.assessment?.yearly_conversion,
                    defaultParameters?.assessment?.yearly_conversion
                ),
                temporal_agg: this.defaultTo(
                    source?.assessment?.temporal_agg,
                    defaultParameters?.assessment?.temporal_agg
                )
            },
            spatial_agg: {
                risk_categories: this.defaultTo(
                    source?.spatial_agg?.risk_categories,
                    defaultParameters?.spatial_agg?.risk_categories
                )
            }
        };
    }
}
