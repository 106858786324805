import React from "react";
import { faSlidersH } from "@fortawesome/pro-solid-svg-icons";
import ERPIcon, { ERPIconProps } from ".";

type ModelIconProps = Omit<ERPIconProps, "icon">;

const ModelIcon = (props: ModelIconProps) => {
    return <ERPIcon className="model-icon" icon={faSlidersH} {...props} />;
};

export default ModelIcon;
