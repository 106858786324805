import React, { useState } from "react";
import { faDownload, faMinusCircle } from "@fortawesome/pro-regular-svg-icons";
import TooltipIcon from "../../Components/TooltipIcon";
import { BaseDataModel, DataType } from "../../types/models";
import DataTypeSelect from "../../Components/DataTypeSelect";
import AzureBlobService from "../../Services/azure.blob.service";
import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import ObservedDataModal from "../../Components/FileSelector/ObservedDataModal";
import { ObservedDataSettings } from "../../Components/FileSelector/types";
import { useConfirmation } from "../../Components/ConfirmDialog/ConfirmationContext";

import "./DataFile.scss";
import clsx from "clsx";

interface DataFileProps {
    data: BaseDataModel;
    disabled: boolean;
    avaliableDataTypes: DataType[];
    className?: string;
    onDataTypeChanged: (data: BaseDataModel, dataType: DataType) => void;
    onDataRemoved: (data: BaseDataModel) => void;
    onObservedDataFileUpdated: (data: BaseDataModel, file: File, connectionSettings: ObservedDataSettings) => void;
}

const DataFile = ({
    data,
    disabled,
    avaliableDataTypes,
    className,
    onDataTypeChanged,
    onDataRemoved,
    onObservedDataFileUpdated
}: DataFileProps) => {
    const [showDataConnectionModal, setShowDataConnectionModal] = useState<boolean>(false);

    const confirm = useConfirmation();

    const handleDataTypeChanged = (dataType: DataType) => {
        onDataTypeChanged(data, dataType);
    };

    const handleRemoved = async () => {
        const result = await confirm({
            title: "Delete data",
            description: "Are you sure you want to delete this data file?"
        });

        if (!result.success) {
            return;
        }

        onDataRemoved(data);
    };

    const handleDownload = async () => {
        await AzureBlobService.downloadBlob(data.blobId, data.name);
    };

    const handleObservedDataSaved = (file: File, observationSettings: ObservedDataSettings) => {
        setShowDataConnectionModal(false);

        onObservedDataFileUpdated(data, file, observationSettings);
    };

    return (
        <div className={clsx("data-file--container", className)}>
            <div className="data-file--info">
                <DataTypeSelect
                    menuClassName="data-type-select-menu"
                    dataType={data.dataType}
                    disabled={disabled}
                    allowedDataTypes={avaliableDataTypes}
                    onChanged={handleDataTypeChanged}
                    isClearable
                />

                <span className="data-file--info-name">{data.name}</span>
            </div>

            <div className="data-file--icons">
                {data.isObserved && (
                    <TooltipIcon
                        className="data-file--icon edit-icon"
                        icon={faPencil}
                        tooltip="Edit observed data"
                        onClick={() => setShowDataConnectionModal(true)}
                    />
                )}

                <TooltipIcon
                    className="data-file--icon"
                    icon={faDownload}
                    tooltip="Download file"
                    onClick={handleDownload}
                />

                <TooltipIcon
                    className="data-file--icon remove-icon"
                    icon={faMinusCircle}
                    tooltip="Remove file"
                    disabled={disabled}
                    onClick={handleRemoved}
                />
            </div>

            {showDataConnectionModal && (
                <ObservedDataModal
                    show={showDataConnectionModal}
                    settings={data.observationSettings}
                    onSave={handleObservedDataSaved}
                    onClose={() => setShowDataConnectionModal(false)}
                />
            )}
        </div>
    );
};

export default DataFile;
