import React from "react";
import MonthSelect from "../../Components/MonthSelect";
import { Controller } from "react-hook-form";
import { PluginFieldProps } from "./types";

interface PluginParameterMonthSelectProps extends PluginFieldProps {
    onMonthChanged: (month: string) => void;
}

const PluginParameterMonthSelect = ({
    id,
    definition,
    field,
    control,
    disabled,
    onMonthChanged
}: PluginParameterMonthSelectProps) => {
    return (
        <Controller
            control={control}
            name={`${definition.id}.${field.id}`}
            render={({ field: _field }) => {
                return (
                    <MonthSelect
                        id={id}
                        label={field.name}
                        labelClassName="plugin-definition-field-label"
                        value={_field.value}
                        disabled={disabled}
                        onMonthChanged={(month: string) => {
                            if (month !== _field.value) {
                                onMonthChanged(month);
                            }
                        }}
                    />
                );
            }}
        />
    );
};

export default PluginParameterMonthSelect;
